import Link from "next/link"
import * as React from "react"
import { Button, Flex, Heading } from "@chakra-ui/react"

interface PageHeadingActionButton {
	icon?: React.ReactElement<any>
	label?: string
	title?: string
	target?: string
}

interface PageHeadingProps {
	title?: string | React.ReactNode
	children?: React.ReactNode
	actionButtons?: Array<PageHeadingActionButton>
}

export const PageHeading = ({
	title,
	children,
	actionButtons,
}: PageHeadingProps) => {
	return (
		<Flex
			direction="row"
			marginBottom={["4", "6", "10"]}
			// justify="center"
			// alignItems="center"
		>
			<Flex flex="1">
				<Heading size={"xl"}>{title || children}</Heading>
			</Flex>
			<div>
				{actionButtons?.map((button) => (
					<Link key={button.target} href={button.target} passHref>
						<Button
							variant="ghost"
							aria-label={button.label || button.title}
							leftIcon={button.icon}
							float="right"
							size="md"
							ml="4"
						>
							{button.title}
						</Button>
					</Link>
				))}
			</div>
		</Flex>
	)
}
