import { useRouter } from "next/router"
import * as React from "react"
import { AiOutlineClose } from "react-icons/ai"
import { BiMapPin } from "react-icons/bi"
import { GoCloudUpload, GoSettings } from "react-icons/go"
import {
	Box,
	Flex,
	FlexProps,
	IconButton,
	Spacer,
	Stack,
} from "@chakra-ui/react"
import useIsMobile from "@common/hooks/useIsMobile"
import useUser from "@common/hooks/useUser"
import AdminModeIcon from "@common/icons/AdminModeIcon"
import DevModeIcon from "@common/icons/DevModeIcon"
import Logo from "./Logo"
import NavLink from "./NavLink"
import { SettingsContext } from "./SettingsProvider"
import Tooltip from "./Tooltip"
import UserProfile from "./UserProfile"

interface SidebarProps extends FlexProps {
	setSidebarIsVisible: (isVisible: boolean) => void
	isFloating?: boolean
}

const Sidebar = ({
	setSidebarIsVisible,
	isFloating,
	...rest
}: SidebarProps) => {
	const { user } = useUser()
	const { settings, openSettings } = React.useContext(SettingsContext)
	const router = useRouter()
	const isMobile = useIsMobile()

	return (
		<Flex
			direction="column"
			width="100%"
			height="100%"
			display="flex"
			{...rest}
		>
			<Flex direction="row" ml="10px" mt={3}>
				<Box flex={1}>
					<Tooltip label="Hide menu">
						<IconButton
							ml="8px"
							aria-label="Close Menu"
							variant="ghost"
							tabIndex={-1}
							onClick={() => setSidebarIsVisible(false)}
							icon={<Logo height={35} logoStyle="symbol" />}
							color="theme_text_desc"
						/>
					</Tooltip>

					<Logo
						width={75}
						height={16}
						color="theme_text_desc"
						logoStyle="text"
						sx={{ pb: "2px", textAlign: "left" }}
					/>
				</Box>

				{isMobile && (
					<Tooltip label="Hide menu">
						<IconButton
							onClick={() => setSidebarIsVisible(false)}
							variant="ghost"
							aria-label="Close Menu"
							tabIndex={-1}
							icon={
								<AiOutlineClose
									// {...DefaultIconButtonProps}
									fontSize="1.5rem"
								/>
							}
							// mt={-1}
							mr={4}
						/>
					</Tooltip>
				)}
			</Flex>

			{user && (
				<Flex direction="column" flex="1" p="4" pt="7" overflowY="auto">
					{/* <Box mb="6">
						<SearchField />
					</Box> */}

					<Stack spacing="6" as="nav" aria-label="Sidebar Navigation">
						<Stack spacing="1">
							<NavLink
								label="Projects"
								icon={BiMapPin}
								href="/projects"
								isActive={
									router.pathname.startsWith("/projects") ||
									router.pathname.startsWith("/p/")
								}
							/>
							<NavLink label="New Project" icon={GoCloudUpload} href="/new" />
							<Flex
								pt="5"
								sx={{
									button: {
										px: 0,
										mx: 0,
										maxWidth: "20px",
									},
									svg: {
										fontSize: "1.25rem",
									},
									".settingsBadge": {
										cursor: "default",
										minWidth: "25px",
									},
								}}
							>
								<Tooltip label="Settings">
									<IconButton
										sx={{
											minWidth: "43px",
										}}
										aria-label="Settings"
										variant="ghost"
										onClick={openSettings}
										icon={<GoSettings />}
									/>
								</Tooltip>
								<Spacer />
								{settings.devMode && (
									<Tooltip label="Dev mode is active">
										<IconButton
											className="settingsBadge"
											aria-label="Dev mode"
											variant="ghost"
											/* tabIndex={-1} */
											icon={<DevModeIcon />}
										/>
									</Tooltip>
								)}
								{settings.adminMode && (
									<Tooltip label="Admin mode is active">
										<IconButton
											className="settingsBadge"
											aria-label="Admin mode"
											variant="ghost"
											/* tabIndex={-1} */
											icon={<AdminModeIcon />}
										/>
									</Tooltip>
								)}
							</Flex>
						</Stack>
					</Stack>
				</Flex>
			)}

			<Spacer />

			<Flex direction="column" flex="0" p="4" pt="0">
				{user && (
					<NavLink href="/me">
						<UserProfile
							name={user.name}
							image={user.picture}
							email={user.email}
						/>
					</NavLink>
				)}
			</Flex>
		</Flex>
	)
}

export default Sidebar
