import { AppProps } from "next/app"
import Head from "next/head"
import Router from "next/router"
import * as React from "react"
import { useEffect, useState } from "react"
import {
	Box,
	Container,
	Drawer,
	DrawerContent,
	DrawerOverlay,
	VStack,
} from "@chakra-ui/react"
import { PageHeading } from "@common/components/PageHeading"
import TopToolbar from "@common/components/TopToolbar"
import { PageOptions, RaisedFloatingProps } from "@common/constants/Ui"
import useIsMobile from "@common/hooks/useIsMobile"
import usePrevious from "@common/hooks/usePrevious"
import Sidebar from "@modules/_common/components/Sidebar"
import TelemetryUploadStatusPanel from "@modules/import/components/TelemetryUploadStatusPanel"
import AppLoading from "./AppLoading"

const AppLayout = ({ Component, pageProps }: AppProps) => {
	const [pageOptions, setPageOptions] = useState<PageOptions>(null)
	const [isRouteChanging, setRouteChanging] = useState<boolean>(false)

	// Monitor desktop<>mobile state transitions
	const isMobile = useIsMobile()
	const prevIsMobile = usePrevious<boolean>(isMobile)

	// Initialize the SetPageOptions callback which is provided as context to each view
	const SetPageOptions = (pageOptions: PageOptions) => {
		setPageOptions(pageOptions)
	}

	// Extract custom view options from pageOptions, such as the page heading.
	// Use sane defaults for all cases (such as when rendering pages which do not leverage the SetPageOptions callback).
	const pageTopToolbarContent = (pageOptions as any)?.topToolbarContent || null
	const pageContentWidthMax = (pageOptions as any)?.pageWidthPx || 800
	const pageTitle = (pageOptions as any)?.title
		? `Teleseer | ${(pageOptions as any)?.title}`
		: "Teleseer"
	const pageHeading = (pageOptions as any)?.heading || null
	const pageActionButtons = (pageOptions as any)?.actionButtons || null
	const pageIsFullCanvas = pageContentWidthMax === -1

	// Manually reset pageOptions when switching pages.
	// This ensures that pages which do not leverage the SetPageOptions callback render as expected.
	Router.events.on("routeChangeStart", () => {
		setRouteChanging(true)
	})
	Router.events.on("routeChangeComplete", () => {
		setRouteChanging(false)
		setPageOptions(null)
		// Auto-reset page options to defaults when route changes occur
	})

	// Manage sidebar visibility, including autocollapse/expansion and responsive layout logic
	const hideSidebar = isMobile || (pageOptions as any)?.hideSidebar || false
	const [sidebarIsExpanded, setSidebarIsExpanded] =
		// isExpanded governs the sidebar's visibility when hideSidebar IS specified
		useState(false)
	const [sidebarIsCollapsed, setSidebarIsCollapsed] =
		// isCollapsed governs the sidebar's visibility when hideSidebar IS NOT specified
		useState(false)
	const sidebarIsVisible = hideSidebar ? sidebarIsExpanded : !sidebarIsCollapsed
	const setSidebarIsVisible = (isVisible: boolean): void => {
		if (hideSidebar) {
			setSidebarIsExpanded(isVisible)
		} else {
			setSidebarIsCollapsed(!isVisible)
		}
	}
	useEffect(() => {
		// Auto reset sidebar visibility overrides when transitioning between desktop and mobile layouts
		if (!prevIsMobile !== isMobile) {
			setSidebarIsExpanded(false)
			setSidebarIsCollapsed(false)
		}
	}, [isMobile, prevIsMobile])
	Router.events.on("routeChangeComplete", (url) => {
		// Auto close sidebar on route changes
		if (sidebarIsExpanded) {
			setSidebarIsExpanded(false)
		}
	})

	// Initialize pageContent
	let pageContent = <AppLoading />
	if (!isRouteChanging) {
		pageContent = <Component {...{ ...pageProps, SetPageOptions }} />
	}

	// Inject PageHeading element when appropriate
	if (pageHeading || pageActionButtons) {
		pageContent = (
			<>
				<PageHeading actionButtons={pageActionButtons}>
					{pageHeading}
				</PageHeading>
				{pageContent}
			</>
		)
	}

	// Add standardized padding & margins around content of non-full-canvas pages
	if (!pageIsFullCanvas) {
		pageContent = (
			<Container centerContent maxW="100%" overflowY="auto">
				<Box
					mt={["1", "2", "4"]}
					w="100%"
					maxWidth={
						pageContentWidthMax > 0 ? `${pageContentWidthMax}px` : "100%"
					}
				>
					{pageContent}
				</Box>
			</Container>
		)
	}

	// Add page title & top toolbar
	pageContent = (
		<>
			<Head>
				<title>{pageTitle}</title>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, shrink-to-fit=no, user-scalable=no"
				/>
			</Head>
			<TelemetryUploadStatusPanel />
			<VStack className="page-container" minHeight="100vh" spacing="0">
				<TopToolbar
					sidebarIsVisible={sidebarIsVisible}
					setSidebarIsVisible={setSidebarIsVisible}
				>
					{pageTopToolbarContent}
				</TopToolbar>
				<Box className="page-content" width="100%" flex="1" p={["2", "3"]}>
					{pageContent}
				</Box>
			</VStack>
		</>
	)

	// Render page with floating sidebar in drawer
	if (hideSidebar || sidebarIsCollapsed) {
		return (
			<>
				<Drawer
					size={isMobile ? "full" : "xs"}
					placement="left"
					blockScrollOnMount={false}
					isOpen={sidebarIsVisible}
					onClose={() => {
						setSidebarIsVisible(false)
					}}
				>
					<DrawerOverlay />
					<DrawerContent sx={RaisedFloatingProps}>
						<Sidebar setSidebarIsVisible={setSidebarIsVisible} isFloating />
					</DrawerContent>
				</Drawer>
				{pageContent}
			</>
		)
	}

	// Render page with fixed, permanently visible sidebar
	return (
		<>
			<Box
				bg="theme_accent"
				sx={{
					position: "fixed",
					top: 0,
					left: 0,
					bottom: 0,
					width: isMobile ? "100%" : "240px",
					// boxShadow: "dark-md",
				}}
			>
				<Sidebar setSidebarIsVisible={setSidebarIsVisible} />
			</Box>
			<Box marginLeft={isMobile ? 0 : "240px"}>{pageContent}</Box>
		</>
	)
}

export default AppLayout
