import * as React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

interface LogoProps extends IconProps {
	width?: number
	height?: number
	iconColor?: string
	alt?: string
	logoStyle?: "full" | "symbol" | "text"
}

const Logo = ({
	width,
	height,
	logoStyle,
	iconColor,
	alt,
	...rest
}: LogoProps) => {
	if (logoStyle === "symbol") {
		return (
			<Icon
				viewBox="0 0 1000 1000"
				aria-label={alt || "Teleseer"}
				width={width ? `${width}px` : "40px"}
				height={height ? `${height}px` : "40px"}
				{...rest}
			>
				<polygon
					fill="currentColor"
					points="925 500 790.79 578.95 500 407.89 253.95 552.63 500 697.37 701.32 578.95 746.05 605.26 500 750 164.47 552.63 500 355.26 790.79 526.32 835.53 500 500 302.63 119.74 526.32 75 500 500 250 925 500"
				/>
				<polygon
					fill="currentColor"
					points="500 644.74 343.42 552.63 500 460.53 656.58 552.63 500 644.74"
				/>
			</Icon>
		)
	}

	if (logoStyle === "text") {
		return (
			<Icon
				viewBox="0 0 86 18"
				aria-label={alt || "Teleseer"}
				width={width ? `${width}px` : "86px"}
				height={height ? `${height}px` : "18px"}
				{...rest}
			>
				<path
					d="M4.70092 3.63594V17.3545H7.79786V3.63594H12.0813V0.916136H0.41748V3.63594H4.70092Z"
					fill="currentColor"
				/>
				<path
					d="M11.3412 12.2735C11.3412 15.3321 13.8248 17.6733 17.1832 17.6733C19.345 17.6733 21.1952 16.697 22.1805 15.0631L20.0589 13.9971C19.2847 14.9236 18.4501 15.352 17.3441 15.352C15.5543 15.352 14.3577 14.2561 14.3577 12.6322H22.4018C22.4219 12.4031 22.4319 12.2437 22.4319 12.0544C22.4319 9.0855 20.1293 6.83394 17.0625 6.83394C13.855 6.83394 11.3412 9.17516 11.3412 12.2735ZM16.9821 8.87629C18.3797 8.87629 19.3853 9.78289 19.5562 11.1777H14.4482C14.5287 9.81277 15.5643 8.87629 16.9821 8.87629Z"
					fill="currentColor"
				/>
				<path
					d="M27.4213 17.3545V0.21875H24.4852V17.3545H27.4213Z"
					fill="currentColor"
				/>
				<path
					d="M29.5306 12.2735C29.5306 15.3321 32.0142 17.6733 35.3726 17.6733C37.5344 17.6733 39.3845 16.697 40.3699 15.0631L38.2483 13.9971C37.4741 14.9236 36.6395 15.352 35.5335 15.352C33.7437 15.352 32.5471 14.2561 32.5471 12.6322H40.5911C40.6112 12.4031 40.6213 12.2437 40.6213 12.0544C40.6213 9.0855 38.3187 6.83394 35.2519 6.83394C32.0444 6.83394 29.5306 9.17516 29.5306 12.2735ZM35.1715 8.87629C36.5691 8.87629 37.5746 9.78289 37.7456 11.1777H32.6376C32.718 9.81277 33.7537 8.87629 35.1715 8.87629Z"
					fill="currentColor"
				/>
				<path
					d="M41.7968 16.0295C42.8928 17.1851 44.2502 17.6733 45.9193 17.6733C48.5437 17.6733 50.1827 16.5276 50.1827 14.5052C50.1827 12.8414 48.9459 11.9149 46.945 11.1976C45.6177 10.7194 44.934 10.3906 44.934 9.76296C44.934 9.21502 45.4367 8.81651 46.3517 8.81651C47.1058 8.81651 47.9002 9.05561 48.5337 9.54378L49.5894 7.98961C48.6342 7.24241 47.4276 6.84391 46.1506 6.84391C43.7374 6.84391 42.1487 8.1789 42.1487 9.92236C42.1487 11.7754 43.9385 12.4927 45.2658 12.9809C46.4221 13.4093 47.1159 13.748 47.1159 14.4354C47.1159 15.1627 46.4623 15.5214 45.6378 15.5214C44.7831 15.5214 43.838 15.1627 43.0738 14.4255L41.7968 16.0295Z"
					fill="currentColor"
				/>
				<path
					d="M51.7165 12.2735C51.7165 15.3321 54.2 17.6733 57.5584 17.6733C59.7203 17.6733 61.5704 16.697 62.5558 15.0631L60.4342 13.9971C59.6599 14.9236 58.8254 15.352 57.7193 15.352C55.9295 15.352 54.733 14.2561 54.733 12.6322H62.777C62.7971 12.4031 62.8071 12.2437 62.8071 12.0544C62.8071 9.0855 60.5045 6.83394 57.4378 6.83394C54.2302 6.83394 51.7165 9.17516 51.7165 12.2735ZM57.3573 8.87629C58.755 8.87629 59.7605 9.78289 59.9314 11.1777H54.8235C54.9039 9.81277 55.9396 8.87629 57.3573 8.87629Z"
					fill="currentColor"
				/>
				<path
					d="M64.1595 12.2735C64.1595 15.3321 66.6431 17.6733 70.0015 17.6733C72.1633 17.6733 74.0135 16.697 74.9989 15.0631L72.8772 13.9971C72.103 14.9236 71.2684 15.352 70.1624 15.352C68.3726 15.352 67.176 14.2561 67.176 12.6322H75.2201C75.2402 12.4031 75.2502 12.2437 75.2502 12.0544C75.2502 9.0855 72.9476 6.83394 69.8809 6.83394C66.6733 6.83394 64.1595 9.17516 64.1595 12.2735ZM69.8004 8.87629C71.1981 8.87629 72.2036 9.78289 72.3745 11.1777H67.2665C67.347 9.81277 68.3827 8.87629 69.8004 8.87629Z"
					fill="currentColor"
				/>
				<path
					d="M77.3236 17.3545H80.2597V12.2038C80.2597 10.3707 81.3657 9.35449 82.6528 9.35449C83.2159 9.35449 83.8191 9.54378 84.2917 9.84266L85.5084 7.38189C84.9855 7.06309 84.2213 6.83394 83.4572 6.83394C82.1601 6.83394 80.9535 7.47155 80.2597 8.82647V7.15275H77.3236V17.3545Z"
					fill="currentColor"
				/>
			</Icon>
		)
	}

	return (
		<Icon
			viewBox="0 0 131 25"
			aria-label={"Teleseer™️"}
			width={width ? `${width}px` : "131px"}
			height={height ? `${height}px` : "25px"}
			{...rest}
		>
			<path
				d="M49.7009 6.63594V20.3545H52.7979V6.63594H57.0813V3.91614H45.4175V6.63594H49.7009Z"
				fill="currentColor"
			/>
			<path
				d="M56.3412 15.2735C56.3412 18.3321 58.8248 20.6733 62.1832 20.6733C64.345 20.6733 66.1952 19.697 67.1805 18.0631L65.0589 16.9971C64.2847 17.9236 63.4501 18.352 62.3441 18.352C60.5543 18.352 59.3577 17.2561 59.3577 15.6322H67.4018C67.4219 15.4031 67.4319 15.2437 67.4319 15.0544C67.4319 12.0855 65.1293 9.83394 62.0625 9.83394C58.855 9.83394 56.3412 12.1752 56.3412 15.2735ZM61.9821 11.8763C63.3797 11.8763 64.3853 12.7829 64.5562 14.1777H59.4482C59.5287 12.8128 60.5643 11.8763 61.9821 11.8763Z"
				fill="currentColor"
			/>
			<path
				d="M72.4213 20.3545V3.21875H69.4852V20.3545H72.4213Z"
				fill="currentColor"
			/>
			<path
				d="M74.5306 15.2735C74.5306 18.3321 77.0142 20.6733 80.3726 20.6733C82.5344 20.6733 84.3845 19.697 85.3699 18.0631L83.2483 16.9971C82.4741 17.9236 81.6395 18.352 80.5335 18.352C78.7437 18.352 77.5471 17.2561 77.5471 15.6322H85.5911C85.6112 15.4031 85.6213 15.2437 85.6213 15.0544C85.6213 12.0855 83.3187 9.83394 80.2519 9.83394C77.0444 9.83394 74.5306 12.1752 74.5306 15.2735ZM80.1715 11.8763C81.5691 11.8763 82.5746 12.7829 82.7456 14.1777H77.6376C77.718 12.8128 78.7537 11.8763 80.1715 11.8763Z"
				fill="currentColor"
			/>
			<path
				d="M86.7968 19.0295C87.8928 20.1851 89.2502 20.6733 90.9193 20.6733C93.5437 20.6733 95.1827 19.5276 95.1827 17.5052C95.1827 15.8414 93.9459 14.9149 91.945 14.1976C90.6177 13.7194 89.934 13.3906 89.934 12.763C89.934 12.215 90.4367 11.8165 91.3517 11.8165C92.1058 11.8165 92.9002 12.0556 93.5337 12.5438L94.5894 10.9896C93.6342 10.2424 92.4276 9.84391 91.1506 9.84391C88.7374 9.84391 87.1487 11.1789 87.1487 12.9224C87.1487 14.7754 88.9385 15.4927 90.2658 15.9809C91.4221 16.4093 92.1159 16.748 92.1159 17.4354C92.1159 18.1627 91.4623 18.5214 90.6378 18.5214C89.7831 18.5214 88.838 18.1627 88.0738 17.4255L86.7968 19.0295Z"
				fill="currentColor"
			/>
			<path
				d="M96.7165 15.2735C96.7165 18.3321 99.2 20.6733 102.558 20.6733C104.72 20.6733 106.57 19.697 107.556 18.0631L105.434 16.9971C104.66 17.9236 103.825 18.352 102.719 18.352C100.93 18.352 99.733 17.2561 99.733 15.6322H107.777C107.797 15.4031 107.807 15.2437 107.807 15.0544C107.807 12.0855 105.505 9.83394 102.438 9.83394C99.2302 9.83394 96.7165 12.1752 96.7165 15.2735ZM102.357 11.8763C103.755 11.8763 104.76 12.7829 104.931 14.1777H99.8235C99.9039 12.8128 100.94 11.8763 102.357 11.8763Z"
				fill="currentColor"
			/>
			<path
				d="M109.16 15.2735C109.16 18.3321 111.643 20.6733 115.002 20.6733C117.163 20.6733 119.013 19.697 119.999 18.0631L117.877 16.9971C117.103 17.9236 116.268 18.352 115.162 18.352C113.373 18.352 112.176 17.2561 112.176 15.6322H120.22C120.24 15.4031 120.25 15.2437 120.25 15.0544C120.25 12.0855 117.948 9.83394 114.881 9.83394C111.673 9.83394 109.16 12.1752 109.16 15.2735ZM114.8 11.8763C116.198 11.8763 117.204 12.7829 117.374 14.1777H112.267C112.347 12.8128 113.383 11.8763 114.8 11.8763Z"
				fill="currentColor"
			/>
			<path
				d="M122.324 20.3545H125.26V15.2038C125.26 13.3707 126.366 12.3545 127.653 12.3545C128.216 12.3545 128.819 12.5438 129.292 12.8427L130.508 10.3819C129.986 10.0631 129.221 9.83394 128.457 9.83394C127.16 9.83394 125.953 10.4716 125.26 11.8265V10.1527H122.324V20.3545Z"
				fill="currentColor"
			/>
			<path
				d="M34.9628 16.7359L41.5083 12.9463L20.781 0.946289L0.0537109 12.9463L2.23568 14.2096L20.781 3.47253L37.1448 12.9463L34.9628 14.2096L20.781 5.99877L4.41717 15.4725L20.781 24.9463L32.7809 17.9988L30.5994 16.7359L20.781 22.42L8.78111 15.4725L20.781 8.52501L34.9628 16.7359ZM13.1447 15.4721L20.7811 19.8934L28.4175 15.4721L20.7811 11.0513L13.1447 15.4721Z"
				fill="currentColor"
			/>
			{/* <path
				d="M93.2756 3.30016H92.6191V2.84277H94.4389V3.30016H93.7825V5.18935H93.2756V3.30016Z"
				fill="currentColor"
			/>
			<path
				d="M94.6964 2.84277H95.4633L95.9929 4.37401H95.9994L96.5324 2.84277H97.296V5.18935H96.7891V3.38964H96.7826L96.1782 5.18935H95.7915L95.2098 3.38964H95.2033V5.18935H94.6964V2.84277Z"
				fill="currentColor"
			/> */}
		</Icon>
	)
	// <chakra.svg viewBox="0 0 123 24" fill="none" {...rest}>
	//   <path
	//     d="M23 0H7a1 1 0 00-1 1v16H3c-1.654 0-3 1.346-3 3v4h14v-3.583c0-.808.645-1.417 1.5-1.417.723 0 1.5.47 1.5 1.5 0 1.93 1.57 3.5 3.5 3.5s3.5-1.57 3.5-3.5V1a1 1 0 00-1-1zM12 20.417V22H2v-2a1 1 0 011-1h9.304c-.196.43-.304.909-.304 1.417zM20 13H10v-2h10v2zm0-6H10V5h10v2z"
	//     fill={color}
	//   />
	//   <path
	//     d="M36.102 19h10.142v-2.855h-6.627v-2.99h6.108v-2.862h-6.108V7.31h6.627V4.455H36.102V19zM51.518 12.778c.007-1.242.732-1.981 1.833-1.981 1.1 0 1.754.724 1.747 1.932V19h3.473v-6.953c.007-2.457-1.492-4.098-3.786-4.098-1.612 0-2.833.817-3.316 2.145h-.121V8.09h-3.303V19h3.473v-6.222zM70.513 8.09h-3.658l-2.017 7.515h-.113l-2.01-7.514h-3.658L62.793 19h3.977l3.743-10.91zM76.192 19.206c2.863 0 4.745-1.385 5.142-3.53l-3.189-.092c-.27.724-.98 1.115-1.889 1.115-1.335 0-2.159-.888-2.159-2.223v-.092h7.273v-.867c0-3.594-2.188-5.568-5.284-5.568-3.296 0-5.412 2.258-5.412 5.64 0 3.493 2.088 5.617 5.518 5.617zm-2.095-6.84c.05-1.086.91-1.91 2.06-1.91 1.143 0 1.967.796 1.981 1.91h-4.04zM86.217 4.455h-3.473V19h3.473V4.455zM93.12 19.206c3.43 0 5.511-2.266 5.511-5.625 0-3.367-2.08-5.632-5.511-5.632-3.43 0-5.512 2.265-5.512 5.632 0 3.36 2.081 5.625 5.512 5.625zm.021-2.62c-1.285 0-1.996-1.222-1.996-3.026 0-1.811.71-3.04 1.996-3.04 1.243 0 1.953 1.229 1.953 3.04 0 1.804-.71 3.025-1.953 3.025zM100.01 23.091h3.473v-5.86h.071c.441 1.01 1.421 1.925 3.068 1.925 2.415 0 4.361-1.889 4.361-5.603 0-3.85-2.059-5.604-4.339-5.604-1.726 0-2.671 1.008-3.09 2.01h-.106V8.09h-3.438v15zm3.402-9.546c0-1.782.739-2.883 2.01-2.883 1.286 0 1.996 1.13 1.996 2.883 0 1.762-.71 2.905-1.996 2.905-1.271 0-2.01-1.136-2.01-2.904zM117.503 19.206c2.862 0 4.744-1.385 5.142-3.53l-3.189-.092c-.27.724-.98 1.115-1.89 1.115-1.335 0-2.159-.888-2.159-2.223v-.092h7.273v-.867c0-3.594-2.187-5.568-5.284-5.568-3.295 0-5.412 2.258-5.412 5.64 0 3.493 2.088 5.617 5.519 5.617zm-2.096-6.84c.05-1.086.909-1.91 2.06-1.91 1.144 0 1.967.796 1.982 1.91h-4.042z"
	//     fill="currentColor"
	//   />
	// </chakra.svg>
}

export default Logo
