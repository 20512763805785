import Tippy from "@tippyjs/react"
import { useTooltipTarget } from "./TooltipSingletonProvider"

interface TooltipProps {
	children: React.ReactElement<any>
	label: string | JSX.Element
}

const Tooltip = ({ children, label }: TooltipProps): JSX.Element => {
	const { target } = useTooltipTarget()
	if (!label) {
		return children
	}
	return (
		<Tippy content={label} singleton={target}>
			{children}
		</Tippy>
	)
}

export default Tooltip
