import { floor } from "lodash"
import router from "next/router"
import { useEffect } from "react"
import { AlertStatus } from "@chakra-ui/alert"
import { ToastPositionWithLogical } from "@chakra-ui/toast"
import useAppToast from "@common/hooks/useAppToast"
import useLogout from "@modules/auth/hooks/useLogout"
import { useAppToken } from "./AppTokenProvider"

const AuthTimeoutManager = () => {
	const toast = useAppToast()
	const { jwtPayload } = useAppToken()
	const { exp: tokenExpires } = jwtPayload || {}
	const expirationCheckInterval = 60 * 1000 // 1 minute
	const logout = useLogout()

	// Initiate a periodic JWT expiration check
	useEffect(() => {
		const logoutTimeout = setInterval(() => {
			if (!tokenExpires) {
				return
			}

			const minutesRemaining = (tokenExpires - Date.now() / 1000) / 60
			if (minutesRemaining < 0) {
				toast({
					status: "error",
					title: `Session Timeout`,
					description: "Please log in again.",
				})

				setTimeout(() => {
					logout()
				}, 3000)

				return
			} else if (minutesRemaining > 120) {
				return
			}

			const id = "auth-timeout-toast"
			const toastDetails = {
				description: `Your session is ending in ${floor(
					minutesRemaining
				)} minutes. Please save your work and reload this window to continue.`,
				status: "warning" as AlertStatus,
				title: `Login Timeout`,
				isClosable: false,
				position: "bottom" as ToastPositionWithLogical,
				duration: 1000 * 60 * 60 * 24,
			}

			if (toast.isActive(id)) {
				toast.update(id, toastDetails)
			} else {
				toast({ ...toastDetails, id })
			}
		}, expirationCheckInterval)

		return () => clearInterval(logoutTimeout)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jwtPayload])

	return null
}

export default AuthTimeoutManager
