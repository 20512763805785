// Source: https://stackoverflow.com/a/38552302
export const ParseJwt = (token) => {
	var base64Url = token.split(".")[1]
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
	var jsonPayload = decodeURIComponent(
		atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join("")
	)

	return JSON.parse(jsonPayload)
}

// Source: https://developer.mozilla.org/en-US/docs/Glossary/Base64
export const b64EncodeUnicode = (str) => {
	return btoa(
		encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
			return String.fromCharCode(parseInt(`0x${p1}`, 16))
		})
	)
}
