import "tippy.js/dist/tippy.css"

import React, { useContext } from "react"
import Tippy, { useSingleton } from "@tippyjs/react"

const TooltipTargetContext = React.createContext({
	target: null,
})

const TooltipTargetProvider = TooltipTargetContext.Provider

export const useTooltipTarget = () => useContext(TooltipTargetContext)

export default function TooltipSingletonProvider({ children }) {
	const [source, target] = useSingleton()
	return (
		<TooltipTargetProvider value={{ target }}>
			<Tippy
				singleton={source}
				delay={500}
				moveTransition="transform 0.8s cubic-bezier(0.22, 1, 0.36, 1)"
			/>
			{children}
		</TooltipTargetProvider>
	)
}
