import Link from "next/link"
import { useRouter } from "next/router"
import * as React from "react"
import {
	Link as ChakraLink,
	HStack,
	Icon,
	LinkProps,
	Text,
	useColorModeValue as mode,
} from "@chakra-ui/react"

interface NavLinkProps extends LinkProps {
	href: string
	isActive?: boolean
	label?: string
	icon?: React.ElementType
	children?: React.ReactNode
}

const NavLink = (props: NavLinkProps) => {
	const router = useRouter()

	const { icon, isActive, href, label, children, ...rest } = props
	let renderIsActive =
		isActive === true || isActive === false
			? isActive
			: router.pathname.startsWith(href)

	return (
		<Link href={href} passHref>
			<ChakraLink
				display="block"
				py="2"
				px="3"
				borderRadius="md"
				transition="all 0.3s"
				fontWeight="medium"
				fontSize="sm"
				userSelect="none"
				aria-current={renderIsActive ? "page" : undefined}
				color="theme_text_desc"
				_hover={{
					bg: mode("gray.100", "theme_hilight"),
					color: "theme_text_desc",
				}}
				_activeLink={{
					bg: mode("gray.200", "theme_hilight"),
					color: "white",
				}}
				{...rest}
			>
				{children ? (
					children
				) : (
					<HStack spacing="4">
						<Icon as={icon} fontSize="xl" opacity={0.64} />
						<Text as="span">{label}</Text>
					</HStack>
				)}
			</ChakraLink>
		</Link>
	)
}
export default NavLink
