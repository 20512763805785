import { useContext, useEffect } from "react"
import React from "react"
import { useMutation } from "@apollo/client"
import useAppToast from "@common/hooks/useAppToast"
import useUser from "@common/hooks/useUser"
import { b64EncodeUnicode } from "@common/utils/jwt"
import {
	CreateUserInput,
	InsertOnDuplicateKeyUpdateUserDocument,
} from "@gql/normalized/operations"

export const UserDetailsContext = React.createContext<{} | null>(null)
export const UserDetailsProvider = UserDetailsContext.Provider

interface UserDetailsProviderProps {
	children: React.ReactNode
}

const AppUserDetailsProvider = ({ children }: UserDetailsProviderProps) => {
	const toast = useAppToast()
	const { user, isLoading: userIsLoading } = useUser()
	const [
		iodkuUserMutation,
		{
			data: userMutationData,
			// loading: userMutationLoading,
			error: userMutationError,
		},
	] = useMutation(InsertOnDuplicateKeyUpdateUserDocument)

	useEffect(() => {
		if (!userMutationError) {
			return
		}

		toast({
			status: "error",
			title: `Could not update core user record.`,
			description: `Please contact support if this issue persists.`,
		})
		console.error("User mutation error: ", userMutationError)
	}, [toast, userMutationError])

	useEffect(() => {
		if (userIsLoading || !user || !user?.sub) {
			return
		}

		const [firstName, lastName] = user.name.split(" ")

		const updateUserDetails = async (user: CreateUserInput) => {
			await iodkuUserMutation({ variables: { user } })
		}

		updateUserDetails({
			auth0UserId: b64EncodeUnicode(user.sub),
			contactEmail: user.email,
			firstName,
			lastName,
		})
	}, [userIsLoading, user, iodkuUserMutation])

	const UserDetails = userMutationData?.insert_core_User_one

	return (
		<UserDetailsProvider value={UserDetails}>{children}</UserDetailsProvider>
	)
}

export default AppUserDetailsProvider

export const useUserDetails = () => useContext(UserDetailsContext)
