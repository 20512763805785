const { visit } = require("graphql/language")

const replaceSpacename = (ast, { from, to } = {}) => {
	return visit(ast, {
		Name(node) {
			if (node?.value.includes(from)) {
				return { ...node, value: node?.value.replace(from, to) }
			}
		},
	})
}

module.exports = replaceSpacename
