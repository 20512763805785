import * as React from "react"
import {
	Avatar,
	Box,
	Flex,
	HStack,
	Text,
	useColorModeValue as mode,
} from "@chakra-ui/react"

interface UserProfileProps {
	name: string
	image: string
	email: string
}

const UserProfile = (props: UserProfileProps) => {
	const { name, image, email } = props
	return (
		<Flex direction="row">
			<Avatar size="sm" name={name} src={image} mr={4} />
			<Box fontWeight="medium" width="75%">
				<Text
					fontSize="sm"
					color="white"
					sx={{
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					{name}
				</Text>
				<Text
					fontSize="xs"
					lineHeight="shorter"
					color="theme_text_desc"
					sx={{
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					{email}
				</Text>
			</Box>
		</Flex>
	)
}

export default UserProfile
