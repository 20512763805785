export enum ProjectToolMode {
	"inventory",
	"explore",
	"build",
	"stats",
	"comment",
	"dev",
}

export enum ProjectDevMode {
	"query",
	"schema",
	"schemaGenerated",
}
