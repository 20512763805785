import { useContext, useEffect, useState } from "react"
import React from "react"
import useUser from "@common/hooks/useUser"
import { ParseJwt } from "@common/utils/jwt"
import useLogout from "@modules/auth/hooks/useLogout"

type jwtPayloadType = {
	iss: string
	sub: string
	aud: string[]
	iat: number
	exp: number
	azp: string
	scope: string
	permissions: string[]
}

export const TsTokenContext = React.createContext({
	jwt: "",
	jwtPayload: null as jwtPayloadType,
})
export const TsTokenProvider = TsTokenContext.Provider

interface AppTokenProviderProps {
	children: React.ReactNode
}

const AppTokenProvider = ({ children }: AppTokenProviderProps) => {
	const { user } = useUser()
	const [TsToken, setTsToken] = useState(null)
	const [parsedJWT, setParsedJWT] = useState<jwtPayloadType | null>(null)
	const logout = useLogout()

	// const apolloClient = getApolloClient(TsToken)

	useEffect(() => {
		// This AppTokenProvider approach currently retrieves an access TsToken to the client
		// from a dedicated endpoint on our Vercel server environment. It also currently regenerates
		// the apolloClient on every page load. This approach is not ideal for production operations,
		// but serves as a solid proof of concept. Further implementation scenarios are explored here:
		// https://github.com/auth0/nextjs-auth0/issues/277
		async function fetchToken() {
			try {
				const response = await fetch("/api/token")
				const { accessToken } = await response.json()
				setTsToken(accessToken)

				const newParsedJWT = ParseJwt(accessToken)
				setParsedJWT(newParsedJWT)
			} catch (error) {
				console.error("JWT has expired: ", error)
				return logout()
			}
		}
		if (user && !TsToken) {
			fetchToken()
		}
	}, [user, TsToken, logout])

	return (
		<TsTokenProvider value={{ jwt: TsToken, jwtPayload: parsedJWT }}>
			{children}
		</TsTokenProvider>
	)
}

export default AppTokenProvider

export const useAppToken = () => useContext(TsTokenContext)
