const AppConfig = {
	// Project schema versioning
	// Each sprint cycle we introduce a new schema version.
	// When this happens, the former cycle's "new" version is set to the "old" version.
	// "Old" version query variants are only implemented by systems which need to
	// maintain backwards compatibility such as the primary project graph view.
	// v20 represented our transition to the Timescale-powered MK II backend.
	project_old_schema_version: "20",
	project_new_schema_version: "21",

	// Note: ts_api configuration has been temporarily duplicated inside next.config.js
	// to benefit from Next's builtin CORS-bypassing proxy.
	ts_api_host:
		process.env.NEXT_PUBLIC_LOCAL_TS_API_HOST || "https://dev-api.teleseer.com",
	ts_gql_host:
		process.env.NEXT_PUBLIC_LOCAL_TS_GQL_HOST ||
		"https://dev-gql.teleseer.com/v1/graphql",

	tusd_path: "/files/",

	// Note: the API mocking system is not currently needed for development, as
	// we now have dedicated dev-api infra in place.
	// mock_all_api_requests: false,

	// Note: the Auth0 mocking system is fully functional for authenticated user details,
	// but does not yet incorporate API access token mocking as we would need a shared
	// dev backdoor token for this.
	mock_auth0_user_auth: false,

	// Mocking system that lives inside an async service worker and can intercept any/all
	// desired network traffic (including GQL queries).
	mock_service_worker_init: true,

	// Should we send a project_close event to the Core API to spin down project graph
	// DBs whenever a user navigates away from a project? This overrides to false in local
	// dev environments to facilitate rapid test iteration and ensure that hot reloads
	// don't continuously unload projects DBs.
	spindown_project_on_close: true,

	// See ApolloProjectProvider.ApolloClient init method for details
	connect_apollo_dev_tools: true,

	auth0_audience:
		process.env.AUTH0_AUDIENCE ||
		process.env.NEXT_PUBLIC_AUTH0_AUDIENCE ||
		"dev.teleseer.com",
	auth0_scope:
		process.env.NEXT_PUBLIC_AUTH0_SCOPE ||
		process.env.AUTH0_SCOPE ||
		"openid profile email",
	auth0_domain:
		process.env.NEXT_PUBLIC_AUTH0_DOMAIN || process.env.AUTH0_DOMAIN || null,

	google_tag_manager_container_id: "",

	ts_projects_refresh_time_ms: 15 * 1000,

	project_default_tool_mode: "inventory",
	project_default_dev_mode: "query",

	yfiles_license: {
		company: "Cyberspatial",
		email: "af@cyberspatial.com",
		contact: "Aubrey Falconer",
		date: "10/20/2021",
		distribution: false,
		domains: ["*"],
		fileSystemAllowed: true,
		licensefileversion: "1.1",
		localhost: true,
		oobAllowed: true,
		package: "complete",
		product: "yFiles for HTML",
		projectname: "Teleseer",
		subscription: "10/19/2022",
		type: "project",
		version: "2.4",
		watermark: "yFiles HTML Development License",
		key: "db596b7ee25eb8b8a16b145bdfdcd0be94b7d562",
	},

	environment_context_overrides: {
		localhost: {
			spindown_project_on_close: false,
		},

		pi: {
			ts_gql_host: "https://pi-gql.teleseer.com/v1/graphql",
		},

		beta: {
			ts_api_host: "https://beta-api.teleseer.com",
			ts_gql_host: "https://beta-gql.teleseer.com/v1/graphql",
			connect_apollo_dev_tools: false,
			mock_service_worker_init: false,
		},

		prod: {
			ts_api_host: "https://go-api.teleseer.com",
			ts_gql_host: "https://go-gql.teleseer.com/v1/graphql",
			connect_apollo_dev_tools: false,

			mock_service_worker_init: false,

			yfiles_license: {
				company: "Cyberspatial",
				contact: "Aubrey Falconer",
				email: "af@cyberspatial.com",
				date: "10/20/2021",
				distribution: true,
				domains: ["teleseer.com"],
				fileSystemAllowed: false,
				licensefileversion: "1.1",
				localhost: false,
				oobAllowed: false,
				package: "complete",
				product: "yFiles for HTML",
				projectname: "Teleseer",
				subscription: "10/19/2022",
				type: "project",
				version: "2.4",
				key: "d4d524282777a9f30975ee9cd155ac974ba94aca",
			},
		},
	},
}

function contextualizeConfig(environment: string) {
	const contextualizedKeys =
		AppConfig["environment_context_overrides"][environment]
	if (!contextualizedKeys) {
		return
	}
	// console.log("AppConfig :: contextualizeConfigForHost:", contextualizedKeys)
	for (const [k, v] of Object.entries(contextualizedKeys)) {
		AppConfig[k] = v
	}
}

function contextualizeConfigForHost(hostname: string) {
	const domainElements = hostname.split(".")
	if (!domainElements || domainElements.length < 1) {
		return
	}
	contextualizeConfig(domainElements[0])
}

if (process?.env?.NEXT_PUBLIC_ENVIRONMENT) {
	contextualizeConfig(process?.env?.NEXT_PUBLIC_ENVIRONMENT)
} else if (process?.env?.VERCEL_URL) {
	contextualizeConfigForHost(process?.env?.VERCEL_URL)
} else if (process.browser) {
	contextualizeConfigForHost(window?.location?.hostname)
}

export default AppConfig
