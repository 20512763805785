import "focus-visible/dist/focus-visible"
import "@fontsource/inter"

import { NextPage } from "next"
import { AppProps } from "next/app"
import { ReactElement, ReactNode } from "react"
import { UserProvider } from "@auth0/nextjs-auth0"
import { ChakraProvider } from "@chakra-ui/react"
import AppInitBlocker from "@common/components/AppInitBlocker"
import AppInstrumentationProvider from "@common/components/AppInstrumentationProvider"
import AppProjectsProvider from "@common/components/AppProjectsProvider"
import AppTokenProvider from "@common/components/AppTokenProvider"
import AppUserDetailsProvider from "@common/components/AppUserDetailsProvider"
import AuthTimeoutManager from "@common/components/AuthTimeoutManager"
import SettingsProvider from "@common/components/SettingsProvider"
import TooltipSingletonProvider from "@common/components/Tooltip/TooltipSingletonProvider"
import AppConfig from "@common/constants/AppConfig"
import ApolloClientProvider from "@gql/ApolloClientProvider"
import AppLayout from "@modules/_common/layouts/AppLayout"
import TelemetryUploadProvider from "@modules/import/components/TelemetryUploadProvider"
import theme from "../modules/_common/utils/theme"

type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout
}

if (AppConfig.mock_service_worker_init && process.browser) {
	const { worker } = require("../mocks/browser")
	worker.start()
}

const MyApp = ({ Component, pageProps, router }: AppPropsWithLayout) => {
	// The following system attempts to disable touch zoom gestures.
	// These cause confusion in our interface when the user intends to zoom in the graph,
	// but instead zooms in on a portion of the Teleseer interface.
	// Initial inspiration: https://stackoverflow.com/questions/37808180/disable-viewport-zooming-ios-10-safari
	// This is currently disabled as it also undesirably blocks mouse scroll events in certain interface
	// elements such as the Project Stats view.
	// useEffect(() => {
	// 	const blockPageZoom = (event) => {
	// 		console.log("blockPageZoom triggered: ", event, event.scale)
	// 		if (
	// 			(event.scale && event.scale !== 1) ||
	// 			(Math.abs(event.deltaX) && !Number.isInteger(event.deltaX)) || // In Chrome and Safari as of 2022-04-13, floating-point delta values are 100% correlated with page zoom events.
	// 			(Math.abs(event.deltaY) && !Number.isInteger(event.deltaY))
	// 		) {
	// 			console.log("blockPageZoom blocked.")
	// 			event.preventDefault()
	// 		}
	// 	}
	// 	document.addEventListener("touchmove", blockPageZoom, { passive: false })
	// 	document.addEventListener("wheel", blockPageZoom, { passive: false })
	// }, [])

	// Use page-specific layouts when available
	const getLayout = Component.getLayout || ((page) => page)

	return (
		<>
			<ChakraProvider resetCSS theme={theme}>
				<UserProvider>
					<AppTokenProvider>
						<ApolloClientProvider>
							<AppUserDetailsProvider>
								<AppInitBlocker>
									<AppProjectsProvider>
										<TelemetryUploadProvider>
											<AppInstrumentationProvider>
												<SettingsProvider>
													<TooltipSingletonProvider>
														{getLayout(
															<AppLayout
																{...{ Component, pageProps, router }}
															/>
														)}
														<AuthTimeoutManager />
													</TooltipSingletonProvider>
												</SettingsProvider>
											</AppInstrumentationProvider>
										</TelemetryUploadProvider>
									</AppProjectsProvider>
								</AppInitBlocker>
							</AppUserDetailsProvider>
						</ApolloClientProvider>
					</AppTokenProvider>
				</UserProvider>
			</ChakraProvider>
		</>
	)
}

export default MyApp
