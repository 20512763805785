import { useEffect, useRef } from "react"

const usePrevious = <T,>(value: T): T | undefined => {
	const ref: any = useRef<T>()
	useEffect(() => {
		if (ref) {
			ref.current = value
		}
	}, [value])
	return ref.current
}

export default usePrevious
