import { useRouter } from "next/router"

const useLogout = () => {
    const router = useRouter()

    return (): void => {
        localStorage.clear()
        router.push("/api/auth/logout")
    }
}

export default useLogout
