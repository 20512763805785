import { Router } from "next/router"
import Script from "next/script"
import { useEffect } from "react"
import React from "react"
import useUser from "@common/hooks/useUser"
import * as snippet from "@segment/snippet"
import * as Sentry from "@sentry/nextjs"

export interface TsInstrumentationContextInterface {
	registerEvent: (eventName: string, eventData?: any) => void
}

export const TsInstrumentationContext =
	React.createContext<TsInstrumentationContextInterface>(null)
export const TsInstrumentationProvider = TsInstrumentationContext.Provider

Router.events.on("routeChangeComplete", (url) => {
	// @ts-ignore window.analytics undefined below
	window?.analytics?.page(url)
})

function renderSegmentSnippet() {
	const opts = {
		apiKey: process?.env?.NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY,
		// note: the page option only covers SSR tracking.
		// Page.js is used to track other events using `window.analytics.page()`
		page: true,
	}

	if (process.env.NODE_ENV === "development") {
		return snippet.max(opts)
	}

	return snippet.min(opts)
}

interface AppInstrumentationProviderProps {
	children: React.ReactNode
}

const AppInstrumentationProvider = ({
	children,
}: AppInstrumentationProviderProps) => {
	const { user, isLoading } = useUser()

	useEffect(() => {
		if (!process?.env?.NEXT_PUBLIC_SEGMENT_ANALYTICS_WRITE_KEY) {
			console.info(
				"Disabling analytics - No SEGMENT_ANALYTICS_WRITE_KEY defined"
			)
			return
		}

		if (isLoading || !user) {
			return
		}

		// Sentry user identity instrumentation
		Sentry.setUser({
			id: user.sub,
			username: user.name,
			email: user.email,
		})

		// Signal user identity instrumentation
		// @ts-ignore window.analytics undefined below
		window?.analytics?.identify(user.sub, {
			name: user.name,
			firstName: user.given_name,
			lastName: user.family_name,
			nickname: user.nickname,
			email: user.email,
			avatar: user.picture,
		})
	}, [user, isLoading])

	const registerEvent = (eventName: string, eventData: any = {}) => {
		// Event registration is no longer needed by our MK II infra. It was originally needed by the MK I infra to facilitate project lifecycle management.
		//
		// if (!axiosInstance) {
		// 	return console.error(
		// 		"RegisterEvent failed: No axios instance ",
		// 		eventName,
		// 		eventData
		// 	)
		// }
		// axiosInstance
		// 	.post("/user/event", {
		// 		...eventData,
		// 		event: eventName,
		// 	})
		// 	.catch(function (error) {
		// 		console.error("Error instrumenting event: ", error)
		// 	})
	}

	return (
		<TsInstrumentationProvider
			value={{
				registerEvent,
			}}
		>
			{/* Segment tracking snippet*/}
			<Script
				id="segment-script"
				dangerouslySetInnerHTML={{ __html: renderSegmentSnippet() }}
			/>
			{children}
		</TsInstrumentationProvider>
	)
}

export default AppInstrumentationProvider
