import React from "react"
import * as tus from "tus-js-client"
import { TsProjectStatuses } from "../../_common/components/AppProjectsProvider"

interface TsUploadsContextInterface {
	state: any
	dispatch: any
}

export const TsUploadsContext =
	React.createContext<TsUploadsContextInterface>(null)
export const TsUploadProvider = TsUploadsContext.Provider

interface TsUpload {
	status: typeof TsProjectStatuses
	upload: tus.Upload
}

interface TsUploadProviderState {
	uploads: Array<TsUpload>
}

const TsUploadProviderStateDefault: TsUploadProviderState = {
	uploads: [],
}

interface TelemetryUploadProviderProps {
	children: React.ReactNode
}

const AppTelemetryUploadReducer = (state, action) => {
	switch (action.type) {
		case "upload": {
			return {
				uploads: [
					...state.uploads,
					{
						status: "INITIALIZING",
						upload: action.value,
					},
				],
			}
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

const TelemetryUploadProvider = ({
	children,
}: TelemetryUploadProviderProps) => {
	const [state, dispatch] = React.useReducer(
		AppTelemetryUploadReducer,
		TsUploadProviderStateDefault
	)

	const value = { state, dispatch }
	return <TsUploadProvider value={value}>{children}</TsUploadProvider>
}

export default TelemetryUploadProvider
