import { IconBaseProps } from "react-icons"
import { RiShieldFlashFill, RiShieldFlashLine } from "react-icons/ri"

interface DevModeIconProps extends IconBaseProps {
	variant?: "solid" | "outline"
}
const DevModeIcon = ({ variant = "outline", ...props }: DevModeIconProps) => {
	if (variant === "solid") {
		return <RiShieldFlashFill {...props} />
	}
	return <RiShieldFlashLine {...props} />
}

export default DevModeIcon
