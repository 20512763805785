// #source: https://stackoverflow.com/a/9039885
export const isIOS = () => {
	if (!process.browser) {
		return false
	}

	return (
		[
			"iPad Simulator",
			"iPhone Simulator",
			"iPod Simulator",
			"iPad",
			"iPhone",
			"iPod",
		].includes(window?.navigator?.platform) ||
		// iPad on iOS 13 detection
		(window?.navigator?.userAgent.includes("Mac") && "ontouchend" in document)
	)
}
