import { AssetPatch } from "@gql/new/types"
import structuredClone from "@ungap/structured-clone"
import { GqlQueryType } from "./queries"

const NormalizeGqlResponse = (queryType: GqlQueryType, incomingData: any) => {
	// console.log(
	// 	"NormalizeGqlResponse - query_type: ",
	// 	queryType,
	// 	" - incoming_data:",
	// 	incomingData
	// )

	let response = structuredClone(incomingData)

	if (queryType === GqlQueryType.projectsList) {
		if (response && response.length > 0) {
			for (let row of response) {
				if (row.rollupSchemaVersion) {
					row.schemaVersion = row.rollupSchemaVersion
				}
			}
		}

		// Upgrade Core v7 snake_case field names to camelCase field names
		// This normalization logic can be eliminated after core is upgraded to v8 across all environments
		// if (response && response.length > 0 && response[0]["dgraph_cluster"]) {
		// 	for (let row of response) {
		// 		row.owner = {
		// 			id: row.owner?.id,
		// 			auth0UserId: row.owner?.auth0_user_id,
		// 			firstName: row.owner?.first_name,
		// 			lastName: row.owner?.last_name,
		// 			contactEmail: row.owner?.contact_email,
		// 			emailVerified: row.owner?.email_verified,
		// 			locale: row.owner?.locale,
		// 			teams: row.owner?.teams,
		// 			tier: row.owner?.tier,
		// 			lastLogin: row.owner?.last_login,
		// 			lastActivity: row.owner?.last_activity,
		// 		}
		// 		row.dgraphCluster = {
		// 			id: row.dgraph_cluster?.id,
		// 			alphaURI: row.dgraph_cluster?.alpha_uri,
		// 			zeroURI: row.dgraph_cluster?.zero_uri,
		// 			graphqlURI: row.dgraph_cluster?.graphql_uri,
		// 			lastActivity: row.dgraph_cluster?.last_activity,
		// 			databaseType: row.dgraph_cluster?.database_type,
		// 		}
		// 		row.dgraphClusterIntermediate = {
		// 			id: row.dgraph_cluster_intermediate?.id,
		// 			alphaURI: row.dgraph_cluster_intermediate?.alpha_uri,
		// 			zeroURI: row.dgraph_cluster_intermediate?.zero_uri,
		// 			graphqlURI: row.dgraph_cluster_intermediate?.graphql_uri,
		// 			lastActivity: row.dgraph_cluster_intermediate?.last_activity,
		// 			databaseType: row.dgraph_cluster_intermediate?.database_type,
		// 		}
		// 		row.importStatus = row.rollup_import_status
		// 		row.importStatusPercent = row.rollup_import_status_percent
		// 		row.schemaVersion = row.rollup_schema_version
		// 		row.rollupImportCount = row.rollup_import_count
		// 		row.rollupAssetCount = row.rollup_asset_count
		// 		row.rollupTotalFilesize = row.rollup_total_filesize
		// 		row.rollupMessageDev = row.rollup_message_dev
		// 		row.rollupMessageUser = row.rollup_message_user
		// 		row.rollupMessageCode = row.rollup_message_code
		// 		row.projectImports = row.rollup_project_imports
		// 		row.projectStatus = row.rollup_project_status
		// 	}
	} else if (queryType === GqlQueryType.assetDetails) {
		// Upgrade Project v12 asset details query to v13 and beyond
		// This normalization logic can be eliminated after all projects are upgraded to >=v13 across all environments
		if (response && response.getProjectAsset) {
			response.getAsset = {
				...response.getProjectAsset,
				networkInterfaces: response.getProjectAsset?.NetworkInterfaces.map(
					(nI) => {
						nI.layer2 = nI.Layer2
						nI.layer3 = nI.Layer3
						return nI
					}
				),
				cves: response.getProjectAsset?.CVEs,
				hardware: {
					...response.getProjectAsset?.Hardware,
					manufacturer: response.getProjectAsset?.Hardware?.Manufacturer,
				},
			}
		}
	}

	// console.log("NormalizeGqlResponse - normalized: ", response)

	return response
}

export default NormalizeGqlResponse

type NamesMap<T> = {
	[Property in keyof T]:
		| (() => {
				key: string
				map: NamesMap<T[Property]>
		  })
		| T[Property]
		| Property
}

const namesMap: NamesMap<Partial<AssetPatch>> = {
	hardware: () => ({
		key: "Hardware",
		map: {
			manufacturer: "Manufacturer",
			device: "device",
			cpuType: "cpuType",
		},
	}),
	os: () => ({
		key: "os",
		map: {
			name: "name",
			version: "version",
			developer: "developer",
		},
	}),
	tags: "tags",
	notes: "notes",
}

function evolve<A, B>(namesMap: NamesMap<A>, data: A): B {
	const result = {} as B
	for (const [key, evolver] of Object.entries(namesMap)) {
		if (!data?.hasOwnProperty(key)) {
			continue
		}
		const value = data[key]
		if (typeof evolver === "function") {
			const { key: newKey, map } = evolver()
			result[newKey] = evolve(map, data[key])
		} else {
			result[namesMap[key]] = value
		}
	}

	return result
}

export const NormalizeAssetPatch = (patch: AssetPatch) =>
	evolve<AssetPatch, AssetPatch>(namesMap, patch)
