import { useEffect, useState } from "react"
import {
	ApolloClient,
	ApolloProvider,
	NormalizedCacheObject,
} from "@apollo/client"
import { useUser } from "@auth0/nextjs-auth0"
import { useAppToken } from "@common/components/AppTokenProvider"
import AppLoading from "@common/layouts/AppLoading"
import AppLogin from "@common/layouts/AppLogin"
import apolloClientInitialization from "@gql/apolloClientInitialization"

interface ApolloProviderProps {
	children: JSX.Element
}

const ApolloClientProvider: React.FC<ApolloProviderProps> = ({ children }) => {
	const { jwt: graphJwt } = useAppToken()
	const [apolloClient, setApolloClient] =
		useState<ApolloClient<NormalizedCacheObject>>(null)
	const { user, isLoading: userIsLoading } = useUser()

	useEffect(() => {
		if (!apolloClient && graphJwt) {
			apolloClientInitialization({ graphJwt }).then(setApolloClient)
		}
	}, [apolloClient, graphJwt])

	if (!user && !userIsLoading) {
		return <AppLogin />
	}

	if (!apolloClient) {
		return <AppLoading />
	}

	return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default ApolloClientProvider
