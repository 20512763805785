import { AnimatePresence, motion } from "framer-motion"
import { useContext } from "react"
import { Box } from "@chakra-ui/react"
import { TsUploadsContext } from "./TelemetryUploadProvider"

const TelemetryUploadStatusPanel = () => {
	const { state } = useContext(TsUploadsContext)

	return null // @todo Work in progress!

	return (
		<AnimatePresence>
			{state?.uploads?.length > 0 && (
				<motion.div
					style={{
						position: "fixed",
						width: "400px",
						maxWidth: "90vw",
						zIndex: 6,
					}}
					initial={{ opacity: 0, right: -350 }}
					animate={{ opacity: 1, right: 0 }}
					exit={{ opacity: 0, right: -350 }}
					transition={{
						duration: 0.5,
						ease: "easeInOut",
					}}
				>
					<Box
						p="8"
						// bg="theme_accent"
						sx={{
							minHeight: "100px",
							marginTop: 6,
							marginRight: 6,
							width: "100%",
							border: "2px solid orange",
							maxHeight: "300px",
							overflow: "auto",
						}}
						borderRadius="16"
						boxShadow="dark-md"
					>
						{/* <SimpleGrid columns={{ base: 1 }} spacing="4">
							{projects["projectIdsByName"].map((id) => (
								<ProjectCard key={id} data={projects[id]} />
							))}
						</SimpleGrid> */}
						{JSON.stringify(state)}
					</Box>
				</motion.div>
			)}
		</AnimatePresence>
	)
}

export default TelemetryUploadStatusPanel
