import { IconBaseProps } from "react-icons"
import { RiShieldKeyholeFill, RiShieldKeyholeLine } from "react-icons/ri"

interface AdminModeIconProps extends IconBaseProps {
	variant?: "solid" | "outline"
}

const AdminModeIcon = ({
	variant = "outline",
	...props
}: AdminModeIconProps) => {
	if (variant === "solid") {
		return <RiShieldKeyholeFill {...props} />
	}
	return <RiShieldKeyholeLine {...props} />
}

export default AdminModeIcon
