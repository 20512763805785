import * as React from "react"
import {
	Box,
	Flex,
	IconButton,
	useColorModeValue as mode,
} from "@chakra-ui/react"
import { DefaultFloatingProps, LogoButtonProps } from "@common/constants/Ui"
import useIsMobile from "@common/hooks/useIsMobile"
import useScrollPosition from "@common/hooks/useScrollPosition"
import Logo from "./Logo"
import Tooltip from "./Tooltip"

interface TopToolbarProps {
	sidebarIsVisible: boolean
	setSidebarIsVisible: (isCollapsed: boolean) => void
	children?: React.ReactNode
}

const TopToolbar = ({
	children,
	sidebarIsVisible,
	setSidebarIsVisible,
}: TopToolbarProps) => {
	const isMobile = useIsMobile()
	const scrollPosition = useScrollPosition()

	if (!children && !isMobile && sidebarIsVisible) {
		return null
	}

	return (
		<Box
			sx={{
				width: "100%",
				height: "36px",
			}}
		>
			<Flex
				align="center"
				justify="space-between"
				px="3"
				bg={mode("gray.50", "theme_accent")}
				sx={{
					...DefaultFloatingProps,
					boxShadow: scrollPosition > 0 ? "dark-lg" : "none",
					width: "100%",
					height: "36px",
					top: 0,
					position: "fixed",
					zIndex: 12,
				}}
			>
				<Tooltip label="Show menu">
					<IconButton
						{...LogoButtonProps}
						onClick={() => setSidebarIsVisible(true)}
						aria-label="Menu"
						icon={<Logo logoStyle="symbol" height={32} />}
						ml="6px"
						mr={3}
						marginRight="-40px"
						/* tabIndex={-1} */
						disabled={sidebarIsVisible}
					/>
				</Tooltip>
				{children}
			</Flex>
		</Box>
	)
}

export default TopToolbar
