import { DocumentNode, gql } from "@apollo/client"
import AppConfig from "@common/constants/AppConfig"

export enum GqlQueryType {
	"projectsList" = "projectsList",
	"projectGraphDefault" = "projectGraphDefault",
	"assetStats" = "assetStats",
	"assetDetails" = "assetDetails",
	"assetDetailsMutation" = "assetDetailsMutation",
	"addAssetMutation" = "addAssetMutation",
	"addAssetViewMutation" = "addAssetViewMutation",
	"updateAssetViewMutation" = "updateAssetViewMutation",
	"addEdgeMutation" = "addEdgeMutation",
}

export enum GqlQuerySection {
	"broadcastDomainFragment",
	"tagFragment",
	"assetFragment",
	"assetSessionsFragment",
	"assetStatsFragment",
	"assetViewFragment",
	"edgeFragment",
}

export type GqlQueryVersionsType = {
	[key: string]: GqlQueryVersion
}

export type GqlQueryVersion = {
	[key in GqlQuerySection]?: string
}

export const GqlQueryVersions: GqlQueryVersionsType = {
	"13": {
		[GqlQuerySection.broadcastDomainFragment]: `
			fragment broadcastDomainFragment on BroadcastDomain {
				id
				vlan
				isVirtual
				networks {
					ip
					mask
					subnet
				}
			}
		`,
		[GqlQuerySection.assetFragment]: `
			fragment assetFragment on Asset {
				id
				applications {
					name
					version
					developer
				}
				badges
				cves
				description
				domains
				hardware {
					cpuType
					device
					formFactor
					manufacturer
				}
				highestCVE
				hostnames
				isVirtual
				name
				networkInterfaces {
					name
					description
					broadcastDomain {
						id
					}
					layer2
					layer3 {
						ip
					}
					connector
					type
					connectedTo {
						name
					}
				}
				notes
				os {
					name
					version
					developer
				}
				roles {
					name
					broadcastDomain {
						id
					}
				}
				routedNetworkInterfaces {
					name
					description
					broadcastDomain {
						id
					}
					layer2
					layer3 {
						ip
					}
				}
				routingTable {
					entriesAggregate {
						count
						descriptionMin
						descriptionMax
						interfaceMin:
						interfaceMax 
						networkMin
						networkMax
						nextHopMin
						nextHopMax
						typeMin
						typeMax
					}
				}
				tags {
					id
					name
				}
				unknownNetworkInterface {
					name
					description
					broadcastDomain {
						id
					}
					layer2
					layer3 {
						ip
					}
				}
				views {
					id
					view {
						name
						type
					}
					x
					y
				}
			}
		`,
		[GqlQuerySection.assetViewFragment]: `
			fragment assetViewFragment on AssetView {
				id
				x
				y
			}
		`,
	},
	"12": {
		[GqlQuerySection.assetFragment]: `
			fragment assetFragment on ProjectAsset {
				id
				name
				hostnames
				domains
				device
				role {
					roletype
				}
				roles {
					roletype
				}
				os {
					name
					version
					developer
				}
				applications {
					name
					version
					developer
				}
				Hardware {
					device
					Manufacturer
				}
				NetworkInterfaces {
					name
					description
					broadcastDomain {
						id
					}
					Layer2
					Layer3 {
						ip
					}
					connector
					type
					connectedTo {
						name
					}
				}
				RoutedNetworkInterfaces {
					name
					description
					broadcastDomain {
						id
					}
					Layer2
					Layer3 {
						ip
					}
				}
				UnknownNetworkInterface {
					name
					description
					broadcastDomain {
						id
					}
					Layer2
					Layer3 {
						ip
					}
				}
				tags {
					id
					name
				}
				CVEs
				HighestCVE
				notes
			}
		`,
	},
	"11": {
		[GqlQuerySection.assetFragment]: `
			fragment assetFragment on ProjectAsset {
				id
				name
				hostnames
				domains
				device
				role {
					roletype
				}
				roles {
					roletype
				}
				os {
					name
					version
					developer
				}
				applications {
					name
					version
					developer
				}
				Hardware {
					device
					Manufacturer
				}
				NetworkInterfaces {
					name
					description
					broadcastDomain {
						id
					}
					Layer2
					Layer3 {
						ip
					}
					connector
					type
					connectedTo {
						name
					}
				}
				RoutedNetworkInterfaces {
					name
					description
					broadcastDomain {
						id
					}
					Layer2
					Layer3 {
						ip
					}
				}
				UnknownNetworkInterface {
					name
					description
					broadcastDomain {
						id
					}
					Layer2
					Layer3 {
						ip
					}
				}
				tags {
					id
					name
				}
				CVEs
				HighestCVE
			}
		`,
	},
	default: {
		[GqlQuerySection.broadcastDomainFragment]: `
			fragment broadcastDomainFragment on BroadcastDomain {
				id
				vlan
				isVirtual
				networks {
					ip
					mask
					subnet
				}
			}
		`,
		[GqlQuerySection.tagFragment]: `
			fragment tagFragment on Tag {
					id
					name
			}
		`,
		[GqlQuerySection.assetFragment]: `
			fragment assetFragment on Asset {
				id
				applications {
					name
					version
					developer
				}
				badges
				cves
				description
				domains
				hardware {
					cpuType
					device
					formFactor
					manufacturer
				}
				highestCVE
				hostnames
				isVirtual
				name
				networkInterfaces {
					name
					description
					broadcastDomain {
						id
					}
					layer2
					layer3 {
						ip
					}
					connector
					type
					connectedTo {
						name
					}
				}
				notes
				os {
					name
					version
					developer
				}
				roles {
					name
					broadcastDomain {
						id
					}
				}
				routedNetworkInterfaces {
					name
					description
					broadcastDomain {
						id
					}
					layer2
					layer3 {
						ip
					}
				}
				routingTable {
					entriesAggregate {
						count
						descriptionMin
						descriptionMax
						interfaceMin:
						interfaceMax 
						networkMin
						networkMax
						nextHopMin
						nextHopMax
						typeMin
						typeMax
					}
				}
				tags {
					id
					name
				}
				unknownNetworkInterface {
					name
					description
					broadcastDomain {
						id
					}
					layer2
					layer3 {
						ip
					}
				}
				views {
					id
					view {
						name
						type
					}
					x
					y
				}
			}
		`,
		[GqlQuerySection.assetViewFragment]: `
			fragment assetViewFragment on AssetView {
				id
				x
				y
			}
		`,
		[GqlQuerySection.assetSessionsFragment]: `
			fragment assetSessionsFragment on ProjectAsset {
				NetworkInterfaces {
					sessions {
						server
						serverPort
						client
						protocolNames
						protocols {
							protocolName
							type
							description
						}
						auxSessions {
							server
						}
						informationSource {
							type
							subType
							Path
						}
					}
				}
			}
		`,
		[GqlQuerySection.assetStatsFragment]: `
			fragment assetStatsFragment on ProjectAsset {
				client
				server
				serverPort
				protocols {
					protocolName
				}
				sessionStats {
					clientPort
				}
				sessionStatsAggregate {
					totalBytesSum
					totalPacketsSum
				}
			}
		`,
		[GqlQuerySection.edgeFragment]: `
			fragment edgeFragment on Edge {
				id
				source {
					id
				}
				target {
					id
				}
			}
			`,
	},
}

export const GetGqlQueryRaw = (
	queryType: GqlQueryType,
	schemaVersion: string = AppConfig.project_new_schema_version
): string => {
	const getQuerySection = (querySection: GqlQuerySection) => {
		if (
			GqlQueryVersions[schemaVersion] &&
			GqlQueryVersions[schemaVersion][querySection]
		) {
			return GqlQueryVersions[schemaVersion][querySection]
		}
		return GqlQueryVersions["default"][querySection] || ""
	}

	const assetStructName =
		parseInt(schemaVersion) > 0 && parseInt(schemaVersion) < 13
			? "ProjectAsset"
			: "Asset"

	switch (queryType) {
		case GqlQueryType.projectGraphDefault:
			return `
					${getQuerySection(GqlQuerySection.broadcastDomainFragment)}
					${getQuerySection(GqlQuerySection.assetFragment)}
					${getQuerySection(GqlQuerySection.edgeFragment)}
					query projectGraphDetailed {
						queryBroadcastDomain {
							...broadcastDomainFragment
						}
						query${assetStructName} {
							...assetFragment
						}
						queryEdge {
							...edgeFragment
						}
					}
				`
		case GqlQueryType.assetStats:
			return `
					${getQuerySection(GqlQuerySection.assetStatsFragment)}
					query assetStatsQuery($id: ID!) {
						get${assetStructName}(id: $id) {
							...assetStatsFragment
						}
					}
				`
		case GqlQueryType.assetDetails:
			return `
					${getQuerySection(GqlQuerySection.assetFragment)}
					query assetQuery($id: ID!) {
						get${assetStructName}(id: $id) {
							...assetFragment
						}
					}
				`
		case GqlQueryType.assetDetailsMutation:
			return `
					${getQuerySection(GqlQuerySection.assetFragment)}
					mutation update${assetStructName}($id: [ID!], $data: ${assetStructName}Patch) {
						update${assetStructName}(input: { filter: { id: $id }, set: $data }) {
							asset {
								...assetFragment
							}
						}
					}
				`
		case GqlQueryType.addAssetMutation:
			return `
					${getQuerySection(GqlQuerySection.assetFragment)}
					mutation addAsset($input: [AddAssetInput!]!) {
						addAsset(input: $input) {
							asset {
								...assetFragment
							}
						}
					}
				`
		case GqlQueryType.addAssetViewMutation:
			return `
					${getQuerySection(GqlQuerySection.assetViewFragment)}
					mutation addAssetView($input: [AddAssetViewInput!]!) {
						addAssetView(input: $input) {
							assetView {
								...assetViewFragment
							}
						}
					}
				`
		case GqlQueryType.addEdgeMutation:
			return `
					${getQuerySection(GqlQuerySection.edgeFragment)}
					mutation addEdge($input: [AddEdgeInput!]!) {
						addEdge(input: $input) {
							edge {
								...edgeFragment
							}
						}
					}
				`
		case GqlQueryType.updateAssetViewMutation:
			return `
					${getQuerySection(GqlQuerySection.assetViewFragment)}
					mutation updateAssetView($id: [ID!], $data: AssetViewPatch) {
						updateAssetView(input: { filter: { id: $id }, set: $data }) {
							assetView {
								...assetViewFragment
							}
						}
					}
				`
		default:
			throw new Error(`Unknown query type:  ${queryType}`)
	}
}

export const GetGqlQuery = (
	queryType: GqlQueryType,
	schemaVersion: string = AppConfig.project_new_schema_version
): DocumentNode => gql(GetGqlQueryRaw(queryType, schemaVersion))

export default GetGqlQuery
