import { ThemeConfig, Tooltip, cssVar, extendTheme } from "@chakra-ui/react"
import { createBreakpoints, mode } from "@chakra-ui/theme-tools"
import { RaisedFloatingProps, Shadows } from "@common/constants/Ui"
import { isIOS } from "./userAgents"
const config: ThemeConfig = {
	initialColorMode: "dark",
	useSystemColorMode: false,
}

// Further color inspiration:
// - https://chakra-ui.com/docs/styled-system/theming/theme
const colors = {
	theme_bg: "#121212",
	theme_bg_elevated: "rgba(28, 28, 30, 0.75)",
	theme_accent: "#1d1e1d",
	theme_hilight: "#292929",
	theme_active: "#383838",
	theme_primary: "#2477b3", // #1398f0
	theme_primary_active: isIOS() ? "#79adfd" : "#4abeff", // Attempt to match default system accent color across latest Mac & iOS devices
	theme_shaded_background: "rgba(128, 128, 128, 0.15)",
	theme_error: "red.100",
	theme_text_desc: "#999a99",
	theme_text_bright: "grey.400",
	error: {
		50: "#fc8181",
		100: "#fc8181",
		200: "#fc8181",
		300: "#fc8181",
		400: "#fc8181",
		500: "#fc8181",
		600: "#fc8181",
		700: "#fc8181",
		800: "#fc8181",
		900: "#fc8181",
	},
}

const variantOutlined = () => ({
	field: {
		_focus: {
			borderColor: "var(--chakra-ui-focus-ring-color)",
			boxShadow: "0 0 0 2px var(--chakra-ui-focus-ring-color)",
		},
	},
})

const variantFilled = () => ({
	field: {
		_focus: {
			borderColor: "var(--chakra-ui-focus-ring-color)",
			boxShadow: "0 0 0 1px var(--chakra-ui-focus-ring-color)",
		},
	},
})

const variantFlushed = () => ({
	field: {
		_focus: {
			borderColor: "var(--chakra-ui-focus-ring-color)",
			boxShadow: "0 1px 0 0 var(--chakra-ui-focus-ring-color)",
		},
	},
})

const fonts = { mono: `Menlo, monospace` }
fonts[
	"body"
] = `-apple-system,BlinkMacSystemFont,"Inter",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"`
fonts["heading"] = fonts["body"]

const breakpoints = createBreakpoints({
	sm: "40em",
	md: "52em",
	lg: "64em",
	xl: "80em",
})

const styles = {
	global: (props) => ({
		// "*": {
		//  cursor: "pointer",
		// 	"-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)",
		// },

		body: {
			// color: mode("gray.800", "whiteAlpha.900")(props),
			bg: mode("gray.100", "theme_bg")(props),
			overscrollBehavior: "contain",
			scrollBehavior: "smooth",

			// Attempts to block unintended interface zoom gestures on multi touch devices.
			// #source: https://stackoverflow.com/a/68278302
			touchAction: "pan-x pan-y",
		},

		// Create a CSS variable with desired focus ring color.
		// Either :host,:root, or html work. Body does not work for button, checkbox, radio, or switch.
		":host,:root": {
			"--chakra-ui-focus-ring-color": colors.theme_primary_active,
		},

		// Hide the focus indicator if the element receives focus via the mouse (or is a popover/modal).
		// Powered by the focus-visible library, which also needs to be installed and activated.
		// Outline will still show for keyboard focus.
		// #source: https://github.com/chakra-ui/chakra-ui/issues/3449#issuecomment-924661108
		'[data-js-focus-visible] :focus:not([data-focus-visible-added], [role="dialog"], [role="menu"], .chakra-modal__content, .chakra-popover__content, .chakra-menu__menu-list)':
			{
				outline: "none",
				boxShadow: "none",
			},

		// Complimentary Chakra focus fix
		// #source: https://github.com/chakra-ui/chakra-ui/discussions/1921#discussioncomment-330998
		// ':focus:not(:focus-visible):not([role="dialog"]):not([role="menu"])': {
		// 	boxShadow: "none !important",
		// },

		/* Hide yfiles' demo notice when we are operating in local debug mode */
		/* We already use a prod license in prod mode */
		'.yfiles-svgpanel [aria-hidden="true"]': {
			display: "none !important",
		},

		".chakra-menu__menu-button.chakra-input > span": {
			textOverflow: "ellipsis",
			overflow: "hidden",
		},

		// FlexLayout (currently used for project inventory view)
		".flexlayout__layout": {
			position: "fixed",
			left: 0,
			top: "40px",
			right: 0,
			bottom: 0,
			overflow: "hidden",

			"--font-size": "12px",
			"--color-text": colors.theme_text_desc,
			"--color-background": colors.theme_accent,
			"--color-base": colors.theme_accent,
			"--color-1": "none",
			"--color-2": "none",
			"--color-3": colors.theme_accent,
			"--color-4": colors.theme_accent,
			"--color-5": colors.theme_accent,
			"--color-6": colors.theme_accent,
			"--color-overflow": "none",
			"--color-icon": colors.theme_text_desc,
			"--color-border-tab-unselected": "none",
			"--color-edge-marker": "none",
			"--color-tab-unselected": colors.theme_text_desc,
			"--color-tab-unselected-background": "transparent",
			"--color-tabset-background": "transparent",
			"--color-tabset-background-selected": "transparent",
			"--color-tabset-background-maximized": "transparent",
			"--color-tabset-divider-line": "var(--color-4)",
			"--color-tabset-header-background": "var(--color-1)",
			"--color-tabset-header": "var(--color-text)",
			"--color-border-background": "var(--color-1)",
			"--color-border-divider-line": "var(--color-4)",
			"--color-tab-selected": "var(--color-text)",
			"--color-tab-selected-background": "var(--color-4)",
			"--color-tab-textbox": "var(--color-text)",
			"--color-tab-textbox-background": "var(--color-3)",
			"--color-border-tab-selected": "var(--color-text)",
			"--color-border-tab-selected-background": "var(--color-4)",
			"--color-border-tab-unselected-background": "var(--color-2)",
			// "--color-splitter": "none",
			"--color-splitter-hover": colors.theme_text_desc,
			"--color-splitter-drag": colors.theme_primary_active,
			"--color-drag-rect-border": "var(--color-4)",
			"--color-drag-rect-background": "var(--color-1)",
			"--color-drag-rect": "var(--color-text)",
			"--color-popup-border": "var(--color-6)",
			"--color-popup-unselected": "var(--color-text)",
			"--color-popup-unselected-background": "black",
			"--color-popup-selected": "var(--color-text)",
			"--color-popup-selected-background": "var(--color-4)",

			".flexlayout__splitter": {
				backgroundColor: "var(--color-splitter)",
			},

			"@media (hover: hover)": [
				{
					".flexlayout__splitter:hover": {
						backgroundColor: "var(--color-splitter-hover)",
						transition: "background-color ease-in 0.1s",
						transitionDelay: "0.05s",
					},
				},
				{
					".flexlayout__tab_button:hover": {
						backgroundColor: "var(--color-tab-selected-background)",
						color: "var(--color-tab-selected)",
					},
				},
				{
					".flexlayout__tab_button:hover .flexlayout__tab_button_trailing": {
						visibility: "visible",
					},
				},
				{
					".flexlayout__tab_toolbar_button:hover": {
						backgroundColor: "var(--color-4)",
					},
				},
				{
					".flexlayout__border_button:hover": {
						backgroundColor: [
							"var(--color-border-tab-selected-background)",
							"var(--color-4)",
						],
						color: "var(--color-border-tab-selected)",
					},
				},
				{
					".flexlayout__border_button:hover .flexlayout__border_button_trailing":
						{
							visibility: "visible",
						},
				},
				{
					".flexlayout__border_toolbar_button:hover": {
						backgroundColor: "var(--color-4)",
					},
				},
				{
					".flexlayout__popup_menu_item:hover": {
						backgroundColor: "var(--color-6)",
					},
				},
			],
			".flexlayout__splitter_border": { zIndex: 10 },
			".flexlayout__splitter_drag": {
				zIndex: 1000,
				backgroundColor: "var(--color-splitter-drag)",
				borderRadius: "5px",
			},
			".flexlayout__splitter_extra": { backgroundColor: "transparent" },
			".flexlayout__outline_rect": {
				position: "absolute",
				pointerEvents: "none",
				boxSizing: "border-box",
				// border: `2px solid ${colors.theme_primary_active}`,
				background: colors.theme_shaded_background,
				// borderRadius: "5px",
				zIndex: 1000,
			},
			".flexlayout__outline_rect_edge": {
				pointerEvents: "none",
				// border: `2px solid ${colors.theme_primary_active}`,
				background: colors.theme_shaded_background,
				// borderRadius: "5px",
				zIndex: 1000,
				boxSizing: "border-box",
			},
			".flexlayout__edge_rect": {
				position: "absolute",
				zIndex: 1000,
				boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.2)",
				backgroundColor: "var(--color-edge-marker)",
				pointerEvents: "none",
			},
			".flexlayout__drag_rect": {
				position: "absolute",
				cursor: "move",
				color: "var(--color-drag-rect)",
				backgroundColor: "var(--color-drag-rect-background)",
				border: "2px solid var(--color-drag-rect-border)",
				borderRadius: "5px",
				zIndex: 1000,
				boxSizing: "border-box",
				opacity: 0.9,
				textAlign: "center",
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
				overflow: "hidden",
				padding: "0.3em 1em",
				wordWrap: "break-word",
				fontSize: "var(--font-size)",
				fontFamily: "var(--font-family)",
			},
			".flexlayout__tabset": {
				display: "flex",
				flexDirection: "column",
				overflow: "hidden",
				backgroundColor: "var(--color-tabset-background)",
				boxSizing: "border-box",
				fontSize: "var(--font-size)",
				fontFamily: "var(--font-family)",
			},
			".flexlayout__tabset_tab_divider": { width: "4px" },
			".flexlayout__tabset_content": {
				display: "flex",
				flexGrow: 1,
				alignItems: "center",
				justifyContent: "center",
			},
			".flexlayout__tabset_header": {
				display: "flex",
				alignItems: "center",
				padding: 0,
				margin: 0,
				border: "none",
				// borderBottom: "1px solid var(--color-tabset-divider-line)",
				boxSizing: "border-box",
				color: "var(--color-tabset-header)",
				backgroundColor: "var(--color-tabset-header-background)",
				// boxShadow: "inset 0 0 3px 0 rgba(136, 136, 136, 0.54)",
			},
			".flexlayout__tabset_header_content": { flexGrow: 1 },
			".flexlayout__tabset_tabbar_outer": {
				boxSizing: "border-box",
				backgroundColor: "var(--color-tabset-background)",
				overflow: "hidden",
				display: "flex",
			},
			".flexlayout__tabset_tabbar_outer_top": {
				// borderBottom: "1px solid var(--color-tabset-divider-line)",
			},
			".flexlayout__tabset_tabbar_outer_bottom": {
				// borderTop: "1px solid var(--color-tabset-divider-line)",
			},
			".flexlayout__tabset_tabbar_inner": {
				position: "relative",
				boxSizing: "border-box",
				display: "flex",
				flexGrow: 1,
				overflow: "hidden",
			},
			".flexlayout__tabset_tabbar_inner_tab_container": {
				display: "flex",
				// paddingLeft: "4px",
				// paddingRight: "4px",
				boxSizing: "border-box",
				position: "absolute",
				top: "0",
				bottom: "0",
				width: "10000px",
			},
			".flexlayout__tabset_tabbar_inner_tab_container_top": {
				// borderTop: "2px solid transparent",
			},
			".flexlayout__tabset_tabbar_inner_tab_container_bottom": {
				borderBottom: "2px solid transparent",
			},
			".flexlayout__tabset-selected": {
				// backgroundColor: "var(--color-tabset-background-selected)",
				// backgroundImage:
				// "linear-gradient(var(--color-background), var(--color-4))",
			},
			".flexlayout__tabset-maximized": {
				// backgroundColor: "var(--color-tabset-background-maximized)",
				// backgroundImage: "linear-gradient(var(--color-6), var(--color-2))",
			},
			".flexlayout__tab_button_stamp": {
				display: "inline-flex",
				alignItems: "center",
				gap: "0.3em",
				whiteSpace: "nowrap",
				boxSizing: "border-box",
			},
			".flexlayout__tab": {
				overflow: "auto",
				position: "absolute",
				boxSizing: "border-box",
				backgroundColor: "var(--color-background)",
				color: "var(--color-text)",
				// borderTopRightRadius: "var(--chakra-radii-md)",
				// borderBottomLeftRadius: "var(--chakra-radii-md)",
				// borderBottomRightRadius: "var(--chakra-radii-md)",
			},
			".flexlayout__tab_button": {
				display: "flex",
				gap: "0.3em",
				alignItems: "center",
				boxSizing: "border-box",
				padding: "3px 0.75em",
				cursor: "pointer",
			},
			".flexlayout__tab_button--selected": {
				backgroundColor: "var(--color-tab-selected-background)",
				color: "var(--color-tab-selected)",
			},
			".flexlayout__tab_button--unselected": {
				backgroundColor: "var(--color-tab-unselected-background)",
				color: "var(--color-tab-unselected)",
			},
			".flexlayout__tab_button_top": {
				// boxShadow: "inset -2px 0px 5px rgba(0, 0, 0, 0.1)",
				// borderTopLeftRadius: "var(--chakra-radii-md)",
				// borderTopRightRadius: "var(--chakra-radii-md)",
			},
			".flexlayout__tab_button_bottom": {
				boxShadow: "inset -2px 0px 5px rgba(0, 0, 0, 0.1)",
				// borderBottomLeftRadius: "var(--chakra-radii-md)",
				// borderBottomRightRadius: "var(--chakra-radii-md)",
			},
			".flexlayout__tab_button_leading": { display: "flex" },
			".flexlayout__tab_button_content": {
				display: "flex",
				alignItems: "center",
			},
			".flexlayout__tab_button_textbox": {
				border: ["none", "1px inset var(--color-1)"],
				fontFamily: "var(--font-family)",
				fontSize: "var(--font-size)",
				color: "var(--color-tab-textbox)",
				backgroundColor: "var(--color-tab-textbox-background)",
				// borderRadius: "var(--chakra-radii-md)",
				width: "10em",
			},
			".flexlayout__tab_button_textbox:focus": { outline: "none" },
			".flexlayout__tab_button_trailing": {
				display: "flex",
				visibility: "hidden",
				// borderRadius: "4px",
			},
			".flexlayout__tab_button_trailing:hover": {
				backgroundColor: "var(--color-6)",
			},
			".flexlayout__tab_button--selected .flexlayout__tab_button_trailing": {
				visibility: "visible",
			},
			".flexlayout__tab_button_overflow": {
				display: "flex",
				alignItems: "center",
				border: "none",
				color: "var(--color-overflow)",
				fontSize: "inherit",
				backgroundColor: "transparent",
			},
			".flexlayout__tab_toolbar": {
				display: "flex",
				alignItems: "center",
				gap: "0.3em",
				paddingLeft: "0.5em",
				// paddingRight: "0.3em",
			},
			".flexlayout__tab_toolbar_button": {
				border: "none",
				outline: "none",
				margin: "0px",
				backgroundColor: "transparent",
				fontSize: "14px",
				padding: "8px",
				// borderRadius: "4px",
			},
			".flexlayout__tab_toolbar_sticky_buttons_container": {
				display: "flex",
				gap: "0.3em",
				paddingLeft: "5px",
				alignItems: "center",
			},
			".flexlayout__tab_floating": {
				overflow: "auto",
				position: "absolute",
				boxSizing: "border-box",
				color: "var(--color-text)",
				backgroundColor: "var(--color-background)",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			},
			".flexlayout__tab_floating_inner": {
				overflow: "auto",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			},
			".flexlayout__tab_floating_inner div": {
				marginBottom: "5px",
				textAlign: "center",
			},
			".flexlayout__tab_floating_inner div a": { color: "royalblue" },
			".flexlayout__border": {
				boxSizing: "border-box",
				overflow: "hidden",
				display: "flex",
				fontSize: "var(--font-size)",
				fontFamily: "var(--font-family)",
				color: "var(--color-border)",
				backgroundColor: "var(--color-border-background)",
			},
			".flexlayout__border_top": {
				borderBottom: "1px solid var(--color-border-divider-line)",
				alignItems: "center",
			},
			".flexlayout__border_bottom": {
				borderTop: "1px solid var(--color-border-divider-line)",
				alignItems: "center",
			},
			".flexlayout__border_left": {
				borderRight: "1px solid var(--color-border-divider-line)",
				alignContent: "center",
				flexDirection: "column",
			},
			".flexlayout__border_right": {
				borderLeft: "1px solid var(--color-border-divider-line)",
				alignContent: "center",
				flexDirection: "column",
			},
			".flexlayout__border_inner": {
				position: "relative",
				boxSizing: "border-box",
				display: "flex",
				overflow: "hidden",
				flexGrow: 1,
			},
			".flexlayout__border_inner_tab_container": {
				whiteSpace: "nowrap",
				display: "flex",
				paddingLeft: "2px",
				paddingRight: "2px",
				boxSizing: "border-box",
				position: "absolute",
				top: "0",
				bottom: "0",
				width: "10000px",
			},
			".flexlayout__border_inner_tab_container_right": {
				transformOrigin: "top left",
				transform: "rotate(90deg)",
			},
			".flexlayout__border_inner_tab_container_left": {
				flexDirection: "row-reverse",
				transformOrigin: "top right",
				transform: "rotate(-90deg)",
			},
			".flexlayout__border_tab_divider": { width: "4px" },
			".flexlayout__border_button": {
				display: "flex",
				gap: "0.3em",
				alignItems: "center",
				cursor: "pointer",
				padding: "3px 0.5em",
				margin: "2px 0px",
				boxSizing: "border-box",
				whiteSpace: "nowrap",
				boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.15)",
				// borderRadius: "var(--chakra-radii-md)",
			},
			".flexlayout__border_button--selected": {
				backgroundColor: "var(--color-border-tab-selected-background)",
				color: "var(--color-border-tab-selected)",
			},
			".flexlayout__border_button--unselected": {
				backgroundColor: "var(--color-border-tab-unselected-background)",
				color: "var(--color-border-tab-unselected)",
			},
			".flexlayout__border_button_leading": { display: "flex" },
			".flexlayout__border_button_content": { display: "flex" },
			".flexlayout__border_button_trailing": {
				display: "flex",
				// borderRadius: "4px",
				visibility: "hidden",
			},
			".flexlayout__border_button_trailing:hover": {
				backgroundColor: "var(--color-6)",
			},
			".flexlayout__border_button--selected .flexlayout__border_button_trailing":
				{
					visibility: "visible",
				},
			".flexlayout__border_toolbar": {
				display: "flex",
				gap: "0.3em",
				alignItems: "center",
			},
			".flexlayout__border_toolbar_left, .flexlayout__border_toolbar_right": {
				flexDirection: "column",
				paddingTop: "0.5em",
				paddingBottom: "0.3em",
			},
			".flexlayout__border_toolbar_top, .flexlayout__border_toolbar_bottom": {
				paddingLeft: "0.5em",
				paddingRight: "0.3em",
			},
			".flexlayout__border_toolbar_button": {
				border: "none",
				outline: "none",
				fontSize: "inherit",
				backgroundColor: "transparent",
				// borderRadius: "4px",
				padding: "1px",
			},
			".flexlayout__border_toolbar_button_overflow": {
				display: "flex",
				alignItems: "center",
				border: "none",
				color: "var(--color-overflow)",
				fontSize: "inherit",
				backgroundColor: "transparent",
			},
			".flexlayout__popup_menu": {
				fontSize: "var(--font-size)",
				fontFamily: "var(--font-family)",
			},
			".flexlayout__popup_menu_item": {
				padding: "2px 0.5em",
				whiteSpace: "nowrap",
				cursor: "pointer",
				borderRadius: "2px",
			},
			".flexlayout__popup_menu_container": {
				boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.15)",
				border: "1px solid var(--color-popup-border)",
				color: "var(--color-popup-unselected)",
				background: "var(--color-popup-unselected-background)",
				borderRadius: "var(--chakra-radii-md)",
				position: "absolute",
				zIndex: 1000,
				maxHeight: "50%",
				minWidth: "100px",
				overflow: "auto",
				padding: "2px",
			},
			".flexlayout__floating_window _body": { height: "100%" },
			".flexlayout__floating_window_content": {
				left: "0",
				top: "0",
				right: "0",
				bottom: "0",
				position: "absolute",
			},
			".flexlayout__floating_window_tab": {
				overflow: "auto",
				left: "0",
				top: "0",
				right: "0",
				bottom: "0",
				position: "absolute",
				boxSizing: "border-box",
				backgroundColor: "var(--color-background)",
				color: "var(--color-text)",
			},
			".flexlayout__error_boundary_container": {
				left: "0",
				top: "0",
				right: "0",
				bottom: "0",
				position: "absolute",
				display: "flex",
				justifyContent: "center",
			},
			".flexlayout__error_boundary_content": {
				display: "flex",
				alignItems: "center",
			},
			".flexlayout__tabset_sizer": {
				paddingTop: "5px",
				paddingBottom: "var(--chakra-radii-md)",
				fontSize: "var(--font-size)",
				fontFamily: "var(--font-family)",
			},
			".flexlayout__tabset_header_sizer": {
				paddingTop: "var(--chakra-radii-md)",
				paddingBottom: "var(--chakra-radii-md)",
				fontSize: "var(--font-size)",
				fontFamily: "var(--font-family)",
			},
			".flexlayout__border_sizer": {
				paddingTop: "6px",
				paddingBottom: "5px",
				fontSize: "var(--font-size)",
				fontFamily: "var(--font-family)",
			},
		},
	}),
}

const $arrowBg = cssVar("popper-arrow-bg")

const components = {
	Button: {
		variants: {
			// Make a variant, we'll call it `base` here and leave it empty
			base: {
				color: "theme_text_desc",
				backgroundColor: "theme_hilight",
				// borderColor: "#434343",
				_hover: {
					bg: "theme_active",
					// borderColor: "#555555",
				},
				_active: {
					bg: "theme_active",
				},
			},
			primary: {
				color: "white",
				backgroundColor: "theme_primary",
				_hover: {
					bg: "theme_primary_active",
				},
			},
			outline: {
				color: "theme_text_desc",
				borderColor: "theme_text_desc",
			},
			solid: {
				backgroundColor: "theme_hilight",
			},
			ghost: {
				color: "theme_text_desc",
				// borderColor: "gray.400",
				_hover: {
					color: "white",
				},
			},
		},
		defaultProps: {
			// Then here we set the base variant as the default
			variant: "base",
		},
	},
	Tooltip: {
		baseStyle: {
			color: "theme_text_desc",
			bg: "theme_accent",
			[$arrowBg.variable]: "#1d1e1d", // The arrowBg.variable reference technique used here prevents theme colors from being resolved
			padding: "4",
			boxShadow: "dark-lg",
			borderRadius: "lg",
		},
	},
	FormLabel: {
		baseStyle: {
			color: "gray.300",
		},
	},
	Input: {
		variants: {
			outline: variantOutlined,
			filled: variantFilled,
			flushed: variantFlushed,
		},
	},
	Select: {
		variants: {
			outline: variantOutlined,
			filled: variantFilled,
			flushed: variantFlushed,
		},
	},
	Textarea: {
		variants: {
			outline: () => variantOutlined().field,
			filled: () => variantFilled().field,
			flushed: () => variantFlushed().field,
		},
	},
	Drawer: {
		baseStyle: (props) => ({
			dialog: {
				bg: mode("white", "theme_accent")(props),
			},
		}),
	},
	Popover: {
		baseStyle: {
			content: {
				...RaisedFloatingProps,
				p: 4,
				// overflow: "hidden",
				// bg: "theme_bg_elevated",
				// borderRadius: "20px",
				// border: "0.5px solid rgba(235, 235, 245, 0.3)",
			},
		},
	},
	Menu: {
		baseStyle: {
			list: {
				...RaisedFloatingProps,
				width: "256px",
				paddingX: 4,
				paddingY: 2,
			},
			group: {
				paddingLeft: 4,
				paddingBottom: 4,
			},
			groupTitle: {
				position: "relative",
				textAlign: "left",
				marginLeft: 0,
				color: "rgba(235, 235, 245, 0.6)",
			},
			item: {
				borderRadius: "md",
			},
			_focus: {
				border: "1px solid rgba(235, 235, 245, 0.3)",
			},
		},
	},
	Modal: {
		baseStyle: {
			dialog: {
				...RaisedFloatingProps,
				padding: 2,
				borderRadius: "lg",
			},
			// footer: {
			// 	paddingBottom: 6,
			// },
		},
	},
	Divider: {
		baseStyle: {
			borderColor: "theme_hilight",
			borderWidth: "2px",
		},
	},
	Checkbox: {
		variants: {
			right: {
				label: {
					order: 1,
					marginLeft: 0,
				},
				control: {
					order: 2,
					borderColor: "theme_text_desc",
					_checked: {
						bg: "theme_text_desc",
						borderColor: "theme_text_desc",
					},
				},
				icon: {
					bg: "theme_text_desc",
					borderColor: "theme_text_desc",
				},
			},
		},
	},
	Table: {
		baseStyle: {
			tr: {
				td: {
					borderColor: "theme_hilight",
					textAlign: "left",
				},
			},
		},
		variants: {
			striped: {
				tr: {
					td: {
						border: "none",
					},
					"&:nth-of-type(even)": {
						td: {
							bg: `${colors.theme_hilight} !important`,
						},
					},
					"&:nth-of-type(odd)": {
						td: {
							bg: `${colors.theme_accent} !important`,
						},
					},
				},
			},
		},
	},
	Switch: {
		baseStyle: {
			track: {
				_checked: {
					bg: "var(--chakra-ui-focus-ring-color)",
				},
			},
		},
	},
}

const theme = extendTheme({
	config,
	colors,
	fonts,
	breakpoints,
	styles,
	components,
	shadows: Shadows,
})

// Extend default props of Tooltip component
// #source: https://github.com/chakra-ui/chakra-ui/issues/1424#issuecomment-743342944
Tooltip.defaultProps = {
	...Tooltip.defaultProps,
	hasArrow: true,
	arrowSize: 15,
	gutter: 16,
	closeOnMouseDown: true,
	// Note: There is a current bug in Chakra UI which results in delayed tooltips being shown when their triggers lose focus
	// due to a click event. This happens in our tooltip icon buttons, expand/collapse menu controls, etc.
	// This is partially resolved in:
	// - https://github.com/chakra-ui/chakra-ui/issues/3659
	// - https://github.com/chakra-ui/chakra-ui/pull/4442
	// We can reintroduce delays once this is fully resolved.
	// openDelay: 500,
	// closeDelay: 100,
	// shouldWrapChildren: true,
	tabIndex: -1,
}

export default theme
