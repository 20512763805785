import * as Types from "./types"

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string
	String: string
	Boolean: boolean
	Int: number
	Float: number
	ImportStatusTypes: any
	ProjectStatusTypes: any
	SchemaType: any
	TenantRoleType: any
	TenantStatusType: any
	TenantType: any
	Tier: any
	UserActivityTypes: any
	_int8: any
	_text: any
	bigint: any
	json: any
	jsonb: any
	numeric: any
	timestamp: any
	timestamptz: any
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["Boolean"]>
	_gt?: InputMaybe<Scalars["Boolean"]>
	_gte?: InputMaybe<Scalars["Boolean"]>
	_in?: InputMaybe<Array<Scalars["Boolean"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["Boolean"]>
	_lte?: InputMaybe<Scalars["Boolean"]>
	_neq?: InputMaybe<Scalars["Boolean"]>
	_nin?: InputMaybe<Array<Scalars["Boolean"]>>
}

/** A json record with details on a new project */
export type CreateProjectInput = {
	/** The users name for the project */
	name: Scalars["String"]
	/** Comma seprated tags for the project */
	tags?: InputMaybe<Scalars["String"]>
	/** (to be implement; defaults to JWT user personal tenant) */
	tenantID?: InputMaybe<Scalars["String"]>
}

export type CreateProjectOutput = {
	__typename?: "CreateProjectOutput"
	id?: Maybe<Scalars["Int"]>
	name?: Maybe<Scalars["String"]>
	namespace?: Maybe<Scalars["String"]>
	tags?: Maybe<Scalars["String"]>
	tenantID?: Maybe<Scalars["String"]>
}

/** A json record with details on a new tenant */
export type CreateTenantInput = {
	/** The users name for the tenant */
	name: Scalars["String"]
	/** The type of tenant (SingleUser,Organization,Department,Unknown) */
	type?: InputMaybe<Scalars["String"]>
}

export type CreateTenantOutput = {
	__typename?: "CreateTenantOutput"
	id?: Maybe<Scalars["Int"]>
	name?: Maybe<Scalars["String"]>
	status?: Maybe<Scalars["String"]>
	type?: Maybe<Scalars["String"]>
}

/** A json record with details on what user to create. */
export type CreateUserInput = {
	/** Base64 encoded auth0UserId.  Must match JWT if a non-admin JWT is used */
	auth0UserId: Scalars["String"]
	/** The users contact email address. */
	contactEmail?: InputMaybe<Scalars["String"]>
	/** The users first name */
	firstName?: InputMaybe<Scalars["String"]>
	/** The users last name */
	lastName?: InputMaybe<Scalars["String"]>
	/** The ISO639-1 locale for the user */
	locale?: InputMaybe<Scalars["String"]>
	/** The tier for a user (Free, Pro, etc) */
	tier?: InputMaybe<Scalars["String"]>
}

export type CreateUserOutput = {
	__typename?: "CreateUserOutput"
	/** The auth0UserID for the newly created user */
	auth0UserId?: Maybe<Scalars["String"]>
	/** The contact email for the new user */
	contactEmail?: Maybe<Scalars["String"]>
	/** The tags supplied for the project */
	firstName?: Maybe<Scalars["String"]>
	/** The id of the newly created user */
	id?: Maybe<Scalars["String"]>
	/** The namespace for the project, a randomized URL string for routing */
	lastName?: Maybe<Scalars["String"]>
	/** The ISO 639 locale for the new user */
	locale?: Maybe<Scalars["String"]>
	/** The tier for the new user */
	tier?: Maybe<Scalars["String"]>
}

export type DeleteProjectInput = {
	id: Scalars["String"]
}

export type DeleteProjectOutput = {
	__typename?: "DeleteProjectOutput"
	id?: Maybe<Scalars["String"]>
}

/** A json record with a single id field of the user to delete */
export type DeleteUserInput = {
	/** The User ID of the user to delete. */
	id: Scalars["String"]
}

export type DeleteUserOutput = {
	__typename?: "DeleteUserOutput"
	/** The ID of the deleted user */
	id?: Maybe<Scalars["String"]>
}

/** Boolean expression to compare columns of type "ImportStatusTypes". All fields are combined with logical 'AND'. */
export type ImportStatusTypes_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["ImportStatusTypes"]>
	_gt?: InputMaybe<Scalars["ImportStatusTypes"]>
	_gte?: InputMaybe<Scalars["ImportStatusTypes"]>
	_in?: InputMaybe<Array<Scalars["ImportStatusTypes"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["ImportStatusTypes"]>
	_lte?: InputMaybe<Scalars["ImportStatusTypes"]>
	_neq?: InputMaybe<Scalars["ImportStatusTypes"]>
	_nin?: InputMaybe<Array<Scalars["ImportStatusTypes"]>>
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["Int"]>
	_gt?: InputMaybe<Scalars["Int"]>
	_gte?: InputMaybe<Scalars["Int"]>
	_in?: InputMaybe<Array<Scalars["Int"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["Int"]>
	_lte?: InputMaybe<Scalars["Int"]>
	_neq?: InputMaybe<Scalars["Int"]>
	_nin?: InputMaybe<Array<Scalars["Int"]>>
}

/** Boolean expression to compare columns of type "ProjectStatusTypes". All fields are combined with logical 'AND'. */
export type ProjectStatusTypes_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["ProjectStatusTypes"]>
	_gt?: InputMaybe<Scalars["ProjectStatusTypes"]>
	_gte?: InputMaybe<Scalars["ProjectStatusTypes"]>
	_in?: InputMaybe<Array<Scalars["ProjectStatusTypes"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["ProjectStatusTypes"]>
	_lte?: InputMaybe<Scalars["ProjectStatusTypes"]>
	_neq?: InputMaybe<Scalars["ProjectStatusTypes"]>
	_nin?: InputMaybe<Array<Scalars["ProjectStatusTypes"]>>
}

/** Boolean expression to compare columns of type "SchemaType". All fields are combined with logical 'AND'. */
export type SchemaType_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["SchemaType"]>
	_gt?: InputMaybe<Scalars["SchemaType"]>
	_gte?: InputMaybe<Scalars["SchemaType"]>
	_in?: InputMaybe<Array<Scalars["SchemaType"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["SchemaType"]>
	_lte?: InputMaybe<Scalars["SchemaType"]>
	_neq?: InputMaybe<Scalars["SchemaType"]>
	_nin?: InputMaybe<Array<Scalars["SchemaType"]>>
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["String"]>
	_gt?: InputMaybe<Scalars["String"]>
	_gte?: InputMaybe<Scalars["String"]>
	/** does the column match the given case-insensitive pattern */
	_ilike?: InputMaybe<Scalars["String"]>
	_in?: InputMaybe<Array<Scalars["String"]>>
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?: InputMaybe<Scalars["String"]>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	/** does the column match the given pattern */
	_like?: InputMaybe<Scalars["String"]>
	_lt?: InputMaybe<Scalars["String"]>
	_lte?: InputMaybe<Scalars["String"]>
	_neq?: InputMaybe<Scalars["String"]>
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?: InputMaybe<Scalars["String"]>
	_nin?: InputMaybe<Array<Scalars["String"]>>
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?: InputMaybe<Scalars["String"]>
	/** does the column NOT match the given pattern */
	_nlike?: InputMaybe<Scalars["String"]>
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?: InputMaybe<Scalars["String"]>
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?: InputMaybe<Scalars["String"]>
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?: InputMaybe<Scalars["String"]>
	/** does the column match the given SQL regular expression */
	_similar?: InputMaybe<Scalars["String"]>
}

/** Boolean expression to compare columns of type "TenantRoleType". All fields are combined with logical 'AND'. */
export type TenantRoleType_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["TenantRoleType"]>
	_gt?: InputMaybe<Scalars["TenantRoleType"]>
	_gte?: InputMaybe<Scalars["TenantRoleType"]>
	_in?: InputMaybe<Array<Scalars["TenantRoleType"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["TenantRoleType"]>
	_lte?: InputMaybe<Scalars["TenantRoleType"]>
	_neq?: InputMaybe<Scalars["TenantRoleType"]>
	_nin?: InputMaybe<Array<Scalars["TenantRoleType"]>>
}

/** Boolean expression to compare columns of type "TenantStatusType". All fields are combined with logical 'AND'. */
export type TenantStatusType_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["TenantStatusType"]>
	_gt?: InputMaybe<Scalars["TenantStatusType"]>
	_gte?: InputMaybe<Scalars["TenantStatusType"]>
	_in?: InputMaybe<Array<Scalars["TenantStatusType"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["TenantStatusType"]>
	_lte?: InputMaybe<Scalars["TenantStatusType"]>
	_neq?: InputMaybe<Scalars["TenantStatusType"]>
	_nin?: InputMaybe<Array<Scalars["TenantStatusType"]>>
}

/** Boolean expression to compare columns of type "TenantType". All fields are combined with logical 'AND'. */
export type TenantType_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["TenantType"]>
	_gt?: InputMaybe<Scalars["TenantType"]>
	_gte?: InputMaybe<Scalars["TenantType"]>
	_in?: InputMaybe<Array<Scalars["TenantType"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["TenantType"]>
	_lte?: InputMaybe<Scalars["TenantType"]>
	_neq?: InputMaybe<Scalars["TenantType"]>
	_nin?: InputMaybe<Array<Scalars["TenantType"]>>
}

/** Boolean expression to compare columns of type "Tier". All fields are combined with logical 'AND'. */
export type Tier_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["Tier"]>
	_gt?: InputMaybe<Scalars["Tier"]>
	_gte?: InputMaybe<Scalars["Tier"]>
	_in?: InputMaybe<Array<Scalars["Tier"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["Tier"]>
	_lte?: InputMaybe<Scalars["Tier"]>
	_neq?: InputMaybe<Scalars["Tier"]>
	_nin?: InputMaybe<Array<Scalars["Tier"]>>
}

/** Boolean expression to compare columns of type "UserActivityTypes". All fields are combined with logical 'AND'. */
export type UserActivityTypes_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["UserActivityTypes"]>
	_gt?: InputMaybe<Scalars["UserActivityTypes"]>
	_gte?: InputMaybe<Scalars["UserActivityTypes"]>
	_in?: InputMaybe<Array<Scalars["UserActivityTypes"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["UserActivityTypes"]>
	_lte?: InputMaybe<Scalars["UserActivityTypes"]>
	_neq?: InputMaybe<Scalars["UserActivityTypes"]>
	_nin?: InputMaybe<Array<Scalars["UserActivityTypes"]>>
}

/** Boolean expression to compare columns of type "_int8". All fields are combined with logical 'AND'. */
export type _Int8_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["_int8"]>
	_gt?: InputMaybe<Scalars["_int8"]>
	_gte?: InputMaybe<Scalars["_int8"]>
	_in?: InputMaybe<Array<Scalars["_int8"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["_int8"]>
	_lte?: InputMaybe<Scalars["_int8"]>
	_neq?: InputMaybe<Scalars["_int8"]>
	_nin?: InputMaybe<Array<Scalars["_int8"]>>
}

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["_text"]>
	_gt?: InputMaybe<Scalars["_text"]>
	_gte?: InputMaybe<Scalars["_text"]>
	_in?: InputMaybe<Array<Scalars["_text"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["_text"]>
	_lte?: InputMaybe<Scalars["_text"]>
	_neq?: InputMaybe<Scalars["_text"]>
	_nin?: InputMaybe<Array<Scalars["_text"]>>
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["bigint"]>
	_gt?: InputMaybe<Scalars["bigint"]>
	_gte?: InputMaybe<Scalars["bigint"]>
	_in?: InputMaybe<Array<Scalars["bigint"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["bigint"]>
	_lte?: InputMaybe<Scalars["bigint"]>
	_neq?: InputMaybe<Scalars["bigint"]>
	_nin?: InputMaybe<Array<Scalars["bigint"]>>
}

/** columns and relationships of "core.ImportOperation" */
export type Core_ImportOperation = {
	__typename?: "core_ImportOperation"
	/** An object relationship */
	Project?: Maybe<Core_Project>
	hash?: Maybe<Scalars["String"]>
	id: Scalars["String"]
	ingestDate?: Maybe<Scalars["timestamp"]>
	ingestPath?: Maybe<Scalars["String"]>
	messageCode?: Maybe<Scalars["String"]>
	messageDev?: Maybe<Scalars["String"]>
	messageUser?: Maybe<Scalars["String"]>
	name?: Maybe<Scalars["String"]>
	originalFilename?: Maybe<Scalars["String"]>
	projectID?: Maybe<Scalars["String"]>
	status?: Maybe<Scalars["ImportStatusTypes"]>
	statusDate?: Maybe<Scalars["timestamp"]>
	statusPercent?: Maybe<Scalars["Int"]>
	tags?: Maybe<Scalars["_text"]>
}

/** aggregated selection of "core.ImportOperation" */
export type Core_ImportOperation_Aggregate = {
	__typename?: "core_ImportOperation_aggregate"
	aggregate?: Maybe<Core_ImportOperation_Aggregate_Fields>
	nodes: Array<Core_ImportOperation>
}

/** aggregate fields of "core.ImportOperation" */
export type Core_ImportOperation_Aggregate_Fields = {
	__typename?: "core_ImportOperation_aggregate_fields"
	avg?: Maybe<Core_ImportOperation_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<Core_ImportOperation_Max_Fields>
	min?: Maybe<Core_ImportOperation_Min_Fields>
	stddev?: Maybe<Core_ImportOperation_Stddev_Fields>
	stddev_pop?: Maybe<Core_ImportOperation_Stddev_Pop_Fields>
	stddev_samp?: Maybe<Core_ImportOperation_Stddev_Samp_Fields>
	sum?: Maybe<Core_ImportOperation_Sum_Fields>
	var_pop?: Maybe<Core_ImportOperation_Var_Pop_Fields>
	var_samp?: Maybe<Core_ImportOperation_Var_Samp_Fields>
	variance?: Maybe<Core_ImportOperation_Variance_Fields>
}

/** aggregate fields of "core.ImportOperation" */
export type Core_ImportOperation_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Core_ImportOperation_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** order by aggregate values of table "core.ImportOperation" */
export type Core_ImportOperation_Aggregate_Order_By = {
	avg?: InputMaybe<Core_ImportOperation_Avg_Order_By>
	count?: InputMaybe<Order_By>
	max?: InputMaybe<Core_ImportOperation_Max_Order_By>
	min?: InputMaybe<Core_ImportOperation_Min_Order_By>
	stddev?: InputMaybe<Core_ImportOperation_Stddev_Order_By>
	stddev_pop?: InputMaybe<Core_ImportOperation_Stddev_Pop_Order_By>
	stddev_samp?: InputMaybe<Core_ImportOperation_Stddev_Samp_Order_By>
	sum?: InputMaybe<Core_ImportOperation_Sum_Order_By>
	var_pop?: InputMaybe<Core_ImportOperation_Var_Pop_Order_By>
	var_samp?: InputMaybe<Core_ImportOperation_Var_Samp_Order_By>
	variance?: InputMaybe<Core_ImportOperation_Variance_Order_By>
}

/** input type for inserting array relation for remote table "core.ImportOperation" */
export type Core_ImportOperation_Arr_Rel_Insert_Input = {
	data: Array<Core_ImportOperation_Insert_Input>
	/** upsert condition */
	on_conflict?: InputMaybe<Core_ImportOperation_On_Conflict>
}

/** aggregate avg on columns */
export type Core_ImportOperation_Avg_Fields = {
	__typename?: "core_ImportOperation_avg_fields"
	statusPercent?: Maybe<Scalars["Float"]>
}

/** order by avg() on columns of table "core.ImportOperation" */
export type Core_ImportOperation_Avg_Order_By = {
	statusPercent?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "core.ImportOperation". All fields are combined with a logical 'AND'. */
export type Core_ImportOperation_Bool_Exp = {
	Project?: InputMaybe<Core_Project_Bool_Exp>
	_and?: InputMaybe<Array<Core_ImportOperation_Bool_Exp>>
	_not?: InputMaybe<Core_ImportOperation_Bool_Exp>
	_or?: InputMaybe<Array<Core_ImportOperation_Bool_Exp>>
	hash?: InputMaybe<String_Comparison_Exp>
	id?: InputMaybe<String_Comparison_Exp>
	ingestDate?: InputMaybe<Timestamp_Comparison_Exp>
	ingestPath?: InputMaybe<String_Comparison_Exp>
	messageCode?: InputMaybe<String_Comparison_Exp>
	messageDev?: InputMaybe<String_Comparison_Exp>
	messageUser?: InputMaybe<String_Comparison_Exp>
	name?: InputMaybe<String_Comparison_Exp>
	originalFilename?: InputMaybe<String_Comparison_Exp>
	projectID?: InputMaybe<String_Comparison_Exp>
	status?: InputMaybe<ImportStatusTypes_Comparison_Exp>
	statusDate?: InputMaybe<Timestamp_Comparison_Exp>
	statusPercent?: InputMaybe<Int_Comparison_Exp>
	tags?: InputMaybe<_Text_Comparison_Exp>
}

/** unique or primary key constraints on table "core.ImportOperation" */
export enum Core_ImportOperation_Constraint {
	/** unique or primary key constraint on columns "id" */
	ImportOperationPkey = "ImportOperation_pkey",
}

/** input type for incrementing numeric columns in table "core.ImportOperation" */
export type Core_ImportOperation_Inc_Input = {
	statusPercent?: InputMaybe<Scalars["Int"]>
}

/** input type for inserting data into table "core.ImportOperation" */
export type Core_ImportOperation_Insert_Input = {
	Project?: InputMaybe<Core_Project_Obj_Rel_Insert_Input>
	hash?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["String"]>
	ingestDate?: InputMaybe<Scalars["timestamp"]>
	ingestPath?: InputMaybe<Scalars["String"]>
	messageCode?: InputMaybe<Scalars["String"]>
	messageDev?: InputMaybe<Scalars["String"]>
	messageUser?: InputMaybe<Scalars["String"]>
	name?: InputMaybe<Scalars["String"]>
	originalFilename?: InputMaybe<Scalars["String"]>
	projectID?: InputMaybe<Scalars["String"]>
	status?: InputMaybe<Scalars["ImportStatusTypes"]>
	statusDate?: InputMaybe<Scalars["timestamp"]>
	statusPercent?: InputMaybe<Scalars["Int"]>
	tags?: InputMaybe<Scalars["_text"]>
}

/** aggregate max on columns */
export type Core_ImportOperation_Max_Fields = {
	__typename?: "core_ImportOperation_max_fields"
	hash?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["String"]>
	ingestDate?: Maybe<Scalars["timestamp"]>
	ingestPath?: Maybe<Scalars["String"]>
	messageCode?: Maybe<Scalars["String"]>
	messageDev?: Maybe<Scalars["String"]>
	messageUser?: Maybe<Scalars["String"]>
	name?: Maybe<Scalars["String"]>
	originalFilename?: Maybe<Scalars["String"]>
	projectID?: Maybe<Scalars["String"]>
	status?: Maybe<Scalars["ImportStatusTypes"]>
	statusDate?: Maybe<Scalars["timestamp"]>
	statusPercent?: Maybe<Scalars["Int"]>
}

/** order by max() on columns of table "core.ImportOperation" */
export type Core_ImportOperation_Max_Order_By = {
	hash?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	ingestDate?: InputMaybe<Order_By>
	ingestPath?: InputMaybe<Order_By>
	messageCode?: InputMaybe<Order_By>
	messageDev?: InputMaybe<Order_By>
	messageUser?: InputMaybe<Order_By>
	name?: InputMaybe<Order_By>
	originalFilename?: InputMaybe<Order_By>
	projectID?: InputMaybe<Order_By>
	status?: InputMaybe<Order_By>
	statusDate?: InputMaybe<Order_By>
	statusPercent?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Core_ImportOperation_Min_Fields = {
	__typename?: "core_ImportOperation_min_fields"
	hash?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["String"]>
	ingestDate?: Maybe<Scalars["timestamp"]>
	ingestPath?: Maybe<Scalars["String"]>
	messageCode?: Maybe<Scalars["String"]>
	messageDev?: Maybe<Scalars["String"]>
	messageUser?: Maybe<Scalars["String"]>
	name?: Maybe<Scalars["String"]>
	originalFilename?: Maybe<Scalars["String"]>
	projectID?: Maybe<Scalars["String"]>
	status?: Maybe<Scalars["ImportStatusTypes"]>
	statusDate?: Maybe<Scalars["timestamp"]>
	statusPercent?: Maybe<Scalars["Int"]>
}

/** order by min() on columns of table "core.ImportOperation" */
export type Core_ImportOperation_Min_Order_By = {
	hash?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	ingestDate?: InputMaybe<Order_By>
	ingestPath?: InputMaybe<Order_By>
	messageCode?: InputMaybe<Order_By>
	messageDev?: InputMaybe<Order_By>
	messageUser?: InputMaybe<Order_By>
	name?: InputMaybe<Order_By>
	originalFilename?: InputMaybe<Order_By>
	projectID?: InputMaybe<Order_By>
	status?: InputMaybe<Order_By>
	statusDate?: InputMaybe<Order_By>
	statusPercent?: InputMaybe<Order_By>
}

/** response of any mutation on the table "core.ImportOperation" */
export type Core_ImportOperation_Mutation_Response = {
	__typename?: "core_ImportOperation_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<Core_ImportOperation>
}

/** on_conflict condition type for table "core.ImportOperation" */
export type Core_ImportOperation_On_Conflict = {
	constraint: Core_ImportOperation_Constraint
	update_columns?: Array<Core_ImportOperation_Update_Column>
	where?: InputMaybe<Core_ImportOperation_Bool_Exp>
}

/** Ordering options when selecting data from "core.ImportOperation". */
export type Core_ImportOperation_Order_By = {
	Project?: InputMaybe<Core_Project_Order_By>
	hash?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	ingestDate?: InputMaybe<Order_By>
	ingestPath?: InputMaybe<Order_By>
	messageCode?: InputMaybe<Order_By>
	messageDev?: InputMaybe<Order_By>
	messageUser?: InputMaybe<Order_By>
	name?: InputMaybe<Order_By>
	originalFilename?: InputMaybe<Order_By>
	projectID?: InputMaybe<Order_By>
	status?: InputMaybe<Order_By>
	statusDate?: InputMaybe<Order_By>
	statusPercent?: InputMaybe<Order_By>
	tags?: InputMaybe<Order_By>
}

/** primary key columns input for table: core_ImportOperation */
export type Core_ImportOperation_Pk_Columns_Input = {
	id: Scalars["String"]
}

/** select columns of table "core.ImportOperation" */
export enum Core_ImportOperation_Select_Column {
	/** column name */
	Hash = "hash",
	/** column name */
	Id = "id",
	/** column name */
	IngestDate = "ingestDate",
	/** column name */
	IngestPath = "ingestPath",
	/** column name */
	MessageCode = "messageCode",
	/** column name */
	MessageDev = "messageDev",
	/** column name */
	MessageUser = "messageUser",
	/** column name */
	Name = "name",
	/** column name */
	OriginalFilename = "originalFilename",
	/** column name */
	ProjectId = "projectID",
	/** column name */
	Status = "status",
	/** column name */
	StatusDate = "statusDate",
	/** column name */
	StatusPercent = "statusPercent",
	/** column name */
	Tags = "tags",
}

/** input type for updating data in table "core.ImportOperation" */
export type Core_ImportOperation_Set_Input = {
	hash?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["String"]>
	ingestDate?: InputMaybe<Scalars["timestamp"]>
	ingestPath?: InputMaybe<Scalars["String"]>
	messageCode?: InputMaybe<Scalars["String"]>
	messageDev?: InputMaybe<Scalars["String"]>
	messageUser?: InputMaybe<Scalars["String"]>
	name?: InputMaybe<Scalars["String"]>
	originalFilename?: InputMaybe<Scalars["String"]>
	projectID?: InputMaybe<Scalars["String"]>
	status?: InputMaybe<Scalars["ImportStatusTypes"]>
	statusDate?: InputMaybe<Scalars["timestamp"]>
	statusPercent?: InputMaybe<Scalars["Int"]>
	tags?: InputMaybe<Scalars["_text"]>
}

/** aggregate stddev on columns */
export type Core_ImportOperation_Stddev_Fields = {
	__typename?: "core_ImportOperation_stddev_fields"
	statusPercent?: Maybe<Scalars["Float"]>
}

/** order by stddev() on columns of table "core.ImportOperation" */
export type Core_ImportOperation_Stddev_Order_By = {
	statusPercent?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Core_ImportOperation_Stddev_Pop_Fields = {
	__typename?: "core_ImportOperation_stddev_pop_fields"
	statusPercent?: Maybe<Scalars["Float"]>
}

/** order by stddev_pop() on columns of table "core.ImportOperation" */
export type Core_ImportOperation_Stddev_Pop_Order_By = {
	statusPercent?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Core_ImportOperation_Stddev_Samp_Fields = {
	__typename?: "core_ImportOperation_stddev_samp_fields"
	statusPercent?: Maybe<Scalars["Float"]>
}

/** order by stddev_samp() on columns of table "core.ImportOperation" */
export type Core_ImportOperation_Stddev_Samp_Order_By = {
	statusPercent?: InputMaybe<Order_By>
}

/** aggregate sum on columns */
export type Core_ImportOperation_Sum_Fields = {
	__typename?: "core_ImportOperation_sum_fields"
	statusPercent?: Maybe<Scalars["Int"]>
}

/** order by sum() on columns of table "core.ImportOperation" */
export type Core_ImportOperation_Sum_Order_By = {
	statusPercent?: InputMaybe<Order_By>
}

/** update columns of table "core.ImportOperation" */
export enum Core_ImportOperation_Update_Column {
	/** column name */
	Hash = "hash",
	/** column name */
	Id = "id",
	/** column name */
	IngestDate = "ingestDate",
	/** column name */
	IngestPath = "ingestPath",
	/** column name */
	MessageCode = "messageCode",
	/** column name */
	MessageDev = "messageDev",
	/** column name */
	MessageUser = "messageUser",
	/** column name */
	Name = "name",
	/** column name */
	OriginalFilename = "originalFilename",
	/** column name */
	ProjectId = "projectID",
	/** column name */
	Status = "status",
	/** column name */
	StatusDate = "statusDate",
	/** column name */
	StatusPercent = "statusPercent",
	/** column name */
	Tags = "tags",
}

/** aggregate var_pop on columns */
export type Core_ImportOperation_Var_Pop_Fields = {
	__typename?: "core_ImportOperation_var_pop_fields"
	statusPercent?: Maybe<Scalars["Float"]>
}

/** order by var_pop() on columns of table "core.ImportOperation" */
export type Core_ImportOperation_Var_Pop_Order_By = {
	statusPercent?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Core_ImportOperation_Var_Samp_Fields = {
	__typename?: "core_ImportOperation_var_samp_fields"
	statusPercent?: Maybe<Scalars["Float"]>
}

/** order by var_samp() on columns of table "core.ImportOperation" */
export type Core_ImportOperation_Var_Samp_Order_By = {
	statusPercent?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Core_ImportOperation_Variance_Fields = {
	__typename?: "core_ImportOperation_variance_fields"
	statusPercent?: Maybe<Scalars["Float"]>
}

/** order by variance() on columns of table "core.ImportOperation" */
export type Core_ImportOperation_Variance_Order_By = {
	statusPercent?: InputMaybe<Order_By>
}

/** columns and relationships of "core.MigrationHistory" */
export type Core_MigrationHistory = {
	__typename?: "core_MigrationHistory"
	id: Scalars["bigint"]
	installedOn?: Maybe<Scalars["timestamptz"]>
	schemaName?: Maybe<Scalars["String"]>
	schemaType: Scalars["SchemaType"]
	schemaVersion?: Maybe<Scalars["bigint"]>
	step?: Maybe<Scalars["bigint"]>
	stepName?: Maybe<Scalars["String"]>
}

/** aggregated selection of "core.MigrationHistory" */
export type Core_MigrationHistory_Aggregate = {
	__typename?: "core_MigrationHistory_aggregate"
	aggregate?: Maybe<Core_MigrationHistory_Aggregate_Fields>
	nodes: Array<Core_MigrationHistory>
}

/** aggregate fields of "core.MigrationHistory" */
export type Core_MigrationHistory_Aggregate_Fields = {
	__typename?: "core_MigrationHistory_aggregate_fields"
	avg?: Maybe<Core_MigrationHistory_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<Core_MigrationHistory_Max_Fields>
	min?: Maybe<Core_MigrationHistory_Min_Fields>
	stddev?: Maybe<Core_MigrationHistory_Stddev_Fields>
	stddev_pop?: Maybe<Core_MigrationHistory_Stddev_Pop_Fields>
	stddev_samp?: Maybe<Core_MigrationHistory_Stddev_Samp_Fields>
	sum?: Maybe<Core_MigrationHistory_Sum_Fields>
	var_pop?: Maybe<Core_MigrationHistory_Var_Pop_Fields>
	var_samp?: Maybe<Core_MigrationHistory_Var_Samp_Fields>
	variance?: Maybe<Core_MigrationHistory_Variance_Fields>
}

/** aggregate fields of "core.MigrationHistory" */
export type Core_MigrationHistory_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Core_MigrationHistory_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type Core_MigrationHistory_Avg_Fields = {
	__typename?: "core_MigrationHistory_avg_fields"
	id?: Maybe<Scalars["Float"]>
	schemaVersion?: Maybe<Scalars["Float"]>
	step?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "core.MigrationHistory". All fields are combined with a logical 'AND'. */
export type Core_MigrationHistory_Bool_Exp = {
	_and?: InputMaybe<Array<Core_MigrationHistory_Bool_Exp>>
	_not?: InputMaybe<Core_MigrationHistory_Bool_Exp>
	_or?: InputMaybe<Array<Core_MigrationHistory_Bool_Exp>>
	id?: InputMaybe<Bigint_Comparison_Exp>
	installedOn?: InputMaybe<Timestamptz_Comparison_Exp>
	schemaName?: InputMaybe<String_Comparison_Exp>
	schemaType?: InputMaybe<SchemaType_Comparison_Exp>
	schemaVersion?: InputMaybe<Bigint_Comparison_Exp>
	step?: InputMaybe<Bigint_Comparison_Exp>
	stepName?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "core.MigrationHistory" */
export enum Core_MigrationHistory_Constraint {
	/** unique or primary key constraint on columns "id" */
	MigrationsId = "MigrationsID",
}

/** input type for incrementing numeric columns in table "core.MigrationHistory" */
export type Core_MigrationHistory_Inc_Input = {
	id?: InputMaybe<Scalars["bigint"]>
	schemaVersion?: InputMaybe<Scalars["bigint"]>
	step?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "core.MigrationHistory" */
export type Core_MigrationHistory_Insert_Input = {
	id?: InputMaybe<Scalars["bigint"]>
	installedOn?: InputMaybe<Scalars["timestamptz"]>
	schemaName?: InputMaybe<Scalars["String"]>
	schemaType?: InputMaybe<Scalars["SchemaType"]>
	schemaVersion?: InputMaybe<Scalars["bigint"]>
	step?: InputMaybe<Scalars["bigint"]>
	stepName?: InputMaybe<Scalars["String"]>
}

/** aggregate max on columns */
export type Core_MigrationHistory_Max_Fields = {
	__typename?: "core_MigrationHistory_max_fields"
	id?: Maybe<Scalars["bigint"]>
	installedOn?: Maybe<Scalars["timestamptz"]>
	schemaName?: Maybe<Scalars["String"]>
	schemaType?: Maybe<Scalars["SchemaType"]>
	schemaVersion?: Maybe<Scalars["bigint"]>
	step?: Maybe<Scalars["bigint"]>
	stepName?: Maybe<Scalars["String"]>
}

/** aggregate min on columns */
export type Core_MigrationHistory_Min_Fields = {
	__typename?: "core_MigrationHistory_min_fields"
	id?: Maybe<Scalars["bigint"]>
	installedOn?: Maybe<Scalars["timestamptz"]>
	schemaName?: Maybe<Scalars["String"]>
	schemaType?: Maybe<Scalars["SchemaType"]>
	schemaVersion?: Maybe<Scalars["bigint"]>
	step?: Maybe<Scalars["bigint"]>
	stepName?: Maybe<Scalars["String"]>
}

/** response of any mutation on the table "core.MigrationHistory" */
export type Core_MigrationHistory_Mutation_Response = {
	__typename?: "core_MigrationHistory_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<Core_MigrationHistory>
}

/** on_conflict condition type for table "core.MigrationHistory" */
export type Core_MigrationHistory_On_Conflict = {
	constraint: Core_MigrationHistory_Constraint
	update_columns?: Array<Core_MigrationHistory_Update_Column>
	where?: InputMaybe<Core_MigrationHistory_Bool_Exp>
}

/** Ordering options when selecting data from "core.MigrationHistory". */
export type Core_MigrationHistory_Order_By = {
	id?: InputMaybe<Order_By>
	installedOn?: InputMaybe<Order_By>
	schemaName?: InputMaybe<Order_By>
	schemaType?: InputMaybe<Order_By>
	schemaVersion?: InputMaybe<Order_By>
	step?: InputMaybe<Order_By>
	stepName?: InputMaybe<Order_By>
}

/** primary key columns input for table: core_MigrationHistory */
export type Core_MigrationHistory_Pk_Columns_Input = {
	id: Scalars["bigint"]
}

/** select columns of table "core.MigrationHistory" */
export enum Core_MigrationHistory_Select_Column {
	/** column name */
	Id = "id",
	/** column name */
	InstalledOn = "installedOn",
	/** column name */
	SchemaName = "schemaName",
	/** column name */
	SchemaType = "schemaType",
	/** column name */
	SchemaVersion = "schemaVersion",
	/** column name */
	Step = "step",
	/** column name */
	StepName = "stepName",
}

/** input type for updating data in table "core.MigrationHistory" */
export type Core_MigrationHistory_Set_Input = {
	id?: InputMaybe<Scalars["bigint"]>
	installedOn?: InputMaybe<Scalars["timestamptz"]>
	schemaName?: InputMaybe<Scalars["String"]>
	schemaType?: InputMaybe<Scalars["SchemaType"]>
	schemaVersion?: InputMaybe<Scalars["bigint"]>
	step?: InputMaybe<Scalars["bigint"]>
	stepName?: InputMaybe<Scalars["String"]>
}

/** aggregate stddev on columns */
export type Core_MigrationHistory_Stddev_Fields = {
	__typename?: "core_MigrationHistory_stddev_fields"
	id?: Maybe<Scalars["Float"]>
	schemaVersion?: Maybe<Scalars["Float"]>
	step?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type Core_MigrationHistory_Stddev_Pop_Fields = {
	__typename?: "core_MigrationHistory_stddev_pop_fields"
	id?: Maybe<Scalars["Float"]>
	schemaVersion?: Maybe<Scalars["Float"]>
	step?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type Core_MigrationHistory_Stddev_Samp_Fields = {
	__typename?: "core_MigrationHistory_stddev_samp_fields"
	id?: Maybe<Scalars["Float"]>
	schemaVersion?: Maybe<Scalars["Float"]>
	step?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type Core_MigrationHistory_Sum_Fields = {
	__typename?: "core_MigrationHistory_sum_fields"
	id?: Maybe<Scalars["bigint"]>
	schemaVersion?: Maybe<Scalars["bigint"]>
	step?: Maybe<Scalars["bigint"]>
}

/** update columns of table "core.MigrationHistory" */
export enum Core_MigrationHistory_Update_Column {
	/** column name */
	Id = "id",
	/** column name */
	InstalledOn = "installedOn",
	/** column name */
	SchemaName = "schemaName",
	/** column name */
	SchemaType = "schemaType",
	/** column name */
	SchemaVersion = "schemaVersion",
	/** column name */
	Step = "step",
	/** column name */
	StepName = "stepName",
}

/** aggregate var_pop on columns */
export type Core_MigrationHistory_Var_Pop_Fields = {
	__typename?: "core_MigrationHistory_var_pop_fields"
	id?: Maybe<Scalars["Float"]>
	schemaVersion?: Maybe<Scalars["Float"]>
	step?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type Core_MigrationHistory_Var_Samp_Fields = {
	__typename?: "core_MigrationHistory_var_samp_fields"
	id?: Maybe<Scalars["Float"]>
	schemaVersion?: Maybe<Scalars["Float"]>
	step?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type Core_MigrationHistory_Variance_Fields = {
	__typename?: "core_MigrationHistory_variance_fields"
	id?: Maybe<Scalars["Float"]>
	schemaVersion?: Maybe<Scalars["Float"]>
	step?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "core.Project" */
export type Core_Project = {
	__typename?: "core_Project"
	/** An array relationship */
	ImportOperations: Array<Core_ImportOperation>
	/** An aggregate relationship */
	ImportOperations_aggregate: Core_ImportOperation_Aggregate
	/** An object relationship */
	Tenant?: Maybe<Core_Tenant>
	created?: Maybe<Scalars["timestamp"]>
	globalShared?: Maybe<Scalars["Boolean"]>
	id: Scalars["String"]
	lastActivityType?: Maybe<Scalars["UserActivityTypes"]>
	name?: Maybe<Scalars["String"]>
	namespace?: Maybe<Scalars["String"]>
	rollupAssetCount?: Maybe<Scalars["Int"]>
	rollupImportCount?: Maybe<Scalars["Int"]>
	rollupImportStatus?: Maybe<Scalars["ImportStatusTypes"]>
	rollupImportStatusPercent?: Maybe<Scalars["Int"]>
	rollupMessageCode?: Maybe<Scalars["String"]>
	rollupMessageDev?: Maybe<Scalars["String"]>
	rollupMessageUser?: Maybe<Scalars["String"]>
	rollupProjectStatus?: Maybe<Scalars["ProjectStatusTypes"]>
	rollupSchemaVersion?: Maybe<Scalars["String"]>
	rollupTotalFilesize?: Maybe<Scalars["Int"]>
	schemaID?: Maybe<Scalars["String"]>
	tags?: Maybe<Scalars["_text"]>
	tenantID?: Maybe<Scalars["String"]>
	updated?: Maybe<Scalars["timestamp"]>
}

/** columns and relationships of "core.Project" */
export type Core_ProjectImportOperationsArgs = {
	distinct_on?: InputMaybe<Array<Core_ImportOperation_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_ImportOperation_Order_By>>
	where?: InputMaybe<Core_ImportOperation_Bool_Exp>
}

/** columns and relationships of "core.Project" */
export type Core_ProjectImportOperations_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_ImportOperation_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_ImportOperation_Order_By>>
	where?: InputMaybe<Core_ImportOperation_Bool_Exp>
}

/** aggregated selection of "core.Project" */
export type Core_Project_Aggregate = {
	__typename?: "core_Project_aggregate"
	aggregate?: Maybe<Core_Project_Aggregate_Fields>
	nodes: Array<Core_Project>
}

/** aggregate fields of "core.Project" */
export type Core_Project_Aggregate_Fields = {
	__typename?: "core_Project_aggregate_fields"
	avg?: Maybe<Core_Project_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<Core_Project_Max_Fields>
	min?: Maybe<Core_Project_Min_Fields>
	stddev?: Maybe<Core_Project_Stddev_Fields>
	stddev_pop?: Maybe<Core_Project_Stddev_Pop_Fields>
	stddev_samp?: Maybe<Core_Project_Stddev_Samp_Fields>
	sum?: Maybe<Core_Project_Sum_Fields>
	var_pop?: Maybe<Core_Project_Var_Pop_Fields>
	var_samp?: Maybe<Core_Project_Var_Samp_Fields>
	variance?: Maybe<Core_Project_Variance_Fields>
}

/** aggregate fields of "core.Project" */
export type Core_Project_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Core_Project_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** order by aggregate values of table "core.Project" */
export type Core_Project_Aggregate_Order_By = {
	avg?: InputMaybe<Core_Project_Avg_Order_By>
	count?: InputMaybe<Order_By>
	max?: InputMaybe<Core_Project_Max_Order_By>
	min?: InputMaybe<Core_Project_Min_Order_By>
	stddev?: InputMaybe<Core_Project_Stddev_Order_By>
	stddev_pop?: InputMaybe<Core_Project_Stddev_Pop_Order_By>
	stddev_samp?: InputMaybe<Core_Project_Stddev_Samp_Order_By>
	sum?: InputMaybe<Core_Project_Sum_Order_By>
	var_pop?: InputMaybe<Core_Project_Var_Pop_Order_By>
	var_samp?: InputMaybe<Core_Project_Var_Samp_Order_By>
	variance?: InputMaybe<Core_Project_Variance_Order_By>
}

/** input type for inserting array relation for remote table "core.Project" */
export type Core_Project_Arr_Rel_Insert_Input = {
	data: Array<Core_Project_Insert_Input>
	/** upsert condition */
	on_conflict?: InputMaybe<Core_Project_On_Conflict>
}

/** aggregate avg on columns */
export type Core_Project_Avg_Fields = {
	__typename?: "core_Project_avg_fields"
	rollupAssetCount?: Maybe<Scalars["Float"]>
	rollupImportCount?: Maybe<Scalars["Float"]>
	rollupImportStatusPercent?: Maybe<Scalars["Float"]>
	rollupTotalFilesize?: Maybe<Scalars["Float"]>
}

/** order by avg() on columns of table "core.Project" */
export type Core_Project_Avg_Order_By = {
	rollupAssetCount?: InputMaybe<Order_By>
	rollupImportCount?: InputMaybe<Order_By>
	rollupImportStatusPercent?: InputMaybe<Order_By>
	rollupTotalFilesize?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "core.Project". All fields are combined with a logical 'AND'. */
export type Core_Project_Bool_Exp = {
	ImportOperations?: InputMaybe<Core_ImportOperation_Bool_Exp>
	Tenant?: InputMaybe<Core_Tenant_Bool_Exp>
	_and?: InputMaybe<Array<Core_Project_Bool_Exp>>
	_not?: InputMaybe<Core_Project_Bool_Exp>
	_or?: InputMaybe<Array<Core_Project_Bool_Exp>>
	created?: InputMaybe<Timestamp_Comparison_Exp>
	globalShared?: InputMaybe<Boolean_Comparison_Exp>
	id?: InputMaybe<String_Comparison_Exp>
	lastActivityType?: InputMaybe<UserActivityTypes_Comparison_Exp>
	name?: InputMaybe<String_Comparison_Exp>
	namespace?: InputMaybe<String_Comparison_Exp>
	rollupAssetCount?: InputMaybe<Int_Comparison_Exp>
	rollupImportCount?: InputMaybe<Int_Comparison_Exp>
	rollupImportStatus?: InputMaybe<ImportStatusTypes_Comparison_Exp>
	rollupImportStatusPercent?: InputMaybe<Int_Comparison_Exp>
	rollupMessageCode?: InputMaybe<String_Comparison_Exp>
	rollupMessageDev?: InputMaybe<String_Comparison_Exp>
	rollupMessageUser?: InputMaybe<String_Comparison_Exp>
	rollupProjectStatus?: InputMaybe<ProjectStatusTypes_Comparison_Exp>
	rollupSchemaVersion?: InputMaybe<String_Comparison_Exp>
	rollupTotalFilesize?: InputMaybe<Int_Comparison_Exp>
	schemaID?: InputMaybe<String_Comparison_Exp>
	tags?: InputMaybe<_Text_Comparison_Exp>
	tenantID?: InputMaybe<String_Comparison_Exp>
	updated?: InputMaybe<Timestamp_Comparison_Exp>
}

/** unique or primary key constraints on table "core.Project" */
export enum Core_Project_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProjectPkey = "Project_pkey",
}

/** input type for incrementing numeric columns in table "core.Project" */
export type Core_Project_Inc_Input = {
	rollupAssetCount?: InputMaybe<Scalars["Int"]>
	rollupImportCount?: InputMaybe<Scalars["Int"]>
	rollupImportStatusPercent?: InputMaybe<Scalars["Int"]>
	rollupTotalFilesize?: InputMaybe<Scalars["Int"]>
}

/** input type for inserting data into table "core.Project" */
export type Core_Project_Insert_Input = {
	ImportOperations?: InputMaybe<Core_ImportOperation_Arr_Rel_Insert_Input>
	Tenant?: InputMaybe<Core_Tenant_Obj_Rel_Insert_Input>
	created?: InputMaybe<Scalars["timestamp"]>
	globalShared?: InputMaybe<Scalars["Boolean"]>
	id?: InputMaybe<Scalars["String"]>
	lastActivityType?: InputMaybe<Scalars["UserActivityTypes"]>
	name?: InputMaybe<Scalars["String"]>
	namespace?: InputMaybe<Scalars["String"]>
	rollupAssetCount?: InputMaybe<Scalars["Int"]>
	rollupImportCount?: InputMaybe<Scalars["Int"]>
	rollupImportStatus?: InputMaybe<Scalars["ImportStatusTypes"]>
	rollupImportStatusPercent?: InputMaybe<Scalars["Int"]>
	rollupMessageCode?: InputMaybe<Scalars["String"]>
	rollupMessageDev?: InputMaybe<Scalars["String"]>
	rollupMessageUser?: InputMaybe<Scalars["String"]>
	rollupProjectStatus?: InputMaybe<Scalars["ProjectStatusTypes"]>
	rollupSchemaVersion?: InputMaybe<Scalars["String"]>
	rollupTotalFilesize?: InputMaybe<Scalars["Int"]>
	schemaID?: InputMaybe<Scalars["String"]>
	tags?: InputMaybe<Scalars["_text"]>
	tenantID?: InputMaybe<Scalars["String"]>
	updated?: InputMaybe<Scalars["timestamp"]>
}

/** aggregate max on columns */
export type Core_Project_Max_Fields = {
	__typename?: "core_Project_max_fields"
	created?: Maybe<Scalars["timestamp"]>
	id?: Maybe<Scalars["String"]>
	lastActivityType?: Maybe<Scalars["UserActivityTypes"]>
	name?: Maybe<Scalars["String"]>
	namespace?: Maybe<Scalars["String"]>
	rollupAssetCount?: Maybe<Scalars["Int"]>
	rollupImportCount?: Maybe<Scalars["Int"]>
	rollupImportStatus?: Maybe<Scalars["ImportStatusTypes"]>
	rollupImportStatusPercent?: Maybe<Scalars["Int"]>
	rollupMessageCode?: Maybe<Scalars["String"]>
	rollupMessageDev?: Maybe<Scalars["String"]>
	rollupMessageUser?: Maybe<Scalars["String"]>
	rollupProjectStatus?: Maybe<Scalars["ProjectStatusTypes"]>
	rollupSchemaVersion?: Maybe<Scalars["String"]>
	rollupTotalFilesize?: Maybe<Scalars["Int"]>
	schemaID?: Maybe<Scalars["String"]>
	tenantID?: Maybe<Scalars["String"]>
	updated?: Maybe<Scalars["timestamp"]>
}

/** order by max() on columns of table "core.Project" */
export type Core_Project_Max_Order_By = {
	created?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	lastActivityType?: InputMaybe<Order_By>
	name?: InputMaybe<Order_By>
	namespace?: InputMaybe<Order_By>
	rollupAssetCount?: InputMaybe<Order_By>
	rollupImportCount?: InputMaybe<Order_By>
	rollupImportStatus?: InputMaybe<Order_By>
	rollupImportStatusPercent?: InputMaybe<Order_By>
	rollupMessageCode?: InputMaybe<Order_By>
	rollupMessageDev?: InputMaybe<Order_By>
	rollupMessageUser?: InputMaybe<Order_By>
	rollupProjectStatus?: InputMaybe<Order_By>
	rollupSchemaVersion?: InputMaybe<Order_By>
	rollupTotalFilesize?: InputMaybe<Order_By>
	schemaID?: InputMaybe<Order_By>
	tenantID?: InputMaybe<Order_By>
	updated?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Core_Project_Min_Fields = {
	__typename?: "core_Project_min_fields"
	created?: Maybe<Scalars["timestamp"]>
	id?: Maybe<Scalars["String"]>
	lastActivityType?: Maybe<Scalars["UserActivityTypes"]>
	name?: Maybe<Scalars["String"]>
	namespace?: Maybe<Scalars["String"]>
	rollupAssetCount?: Maybe<Scalars["Int"]>
	rollupImportCount?: Maybe<Scalars["Int"]>
	rollupImportStatus?: Maybe<Scalars["ImportStatusTypes"]>
	rollupImportStatusPercent?: Maybe<Scalars["Int"]>
	rollupMessageCode?: Maybe<Scalars["String"]>
	rollupMessageDev?: Maybe<Scalars["String"]>
	rollupMessageUser?: Maybe<Scalars["String"]>
	rollupProjectStatus?: Maybe<Scalars["ProjectStatusTypes"]>
	rollupSchemaVersion?: Maybe<Scalars["String"]>
	rollupTotalFilesize?: Maybe<Scalars["Int"]>
	schemaID?: Maybe<Scalars["String"]>
	tenantID?: Maybe<Scalars["String"]>
	updated?: Maybe<Scalars["timestamp"]>
}

/** order by min() on columns of table "core.Project" */
export type Core_Project_Min_Order_By = {
	created?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	lastActivityType?: InputMaybe<Order_By>
	name?: InputMaybe<Order_By>
	namespace?: InputMaybe<Order_By>
	rollupAssetCount?: InputMaybe<Order_By>
	rollupImportCount?: InputMaybe<Order_By>
	rollupImportStatus?: InputMaybe<Order_By>
	rollupImportStatusPercent?: InputMaybe<Order_By>
	rollupMessageCode?: InputMaybe<Order_By>
	rollupMessageDev?: InputMaybe<Order_By>
	rollupMessageUser?: InputMaybe<Order_By>
	rollupProjectStatus?: InputMaybe<Order_By>
	rollupSchemaVersion?: InputMaybe<Order_By>
	rollupTotalFilesize?: InputMaybe<Order_By>
	schemaID?: InputMaybe<Order_By>
	tenantID?: InputMaybe<Order_By>
	updated?: InputMaybe<Order_By>
}

/** response of any mutation on the table "core.Project" */
export type Core_Project_Mutation_Response = {
	__typename?: "core_Project_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<Core_Project>
}

/** input type for inserting object relation for remote table "core.Project" */
export type Core_Project_Obj_Rel_Insert_Input = {
	data: Core_Project_Insert_Input
	/** upsert condition */
	on_conflict?: InputMaybe<Core_Project_On_Conflict>
}

/** on_conflict condition type for table "core.Project" */
export type Core_Project_On_Conflict = {
	constraint: Core_Project_Constraint
	update_columns?: Array<Core_Project_Update_Column>
	where?: InputMaybe<Core_Project_Bool_Exp>
}

/** Ordering options when selecting data from "core.Project". */
export type Core_Project_Order_By = {
	ImportOperations_aggregate?: InputMaybe<Core_ImportOperation_Aggregate_Order_By>
	Tenant?: InputMaybe<Core_Tenant_Order_By>
	created?: InputMaybe<Order_By>
	globalShared?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	lastActivityType?: InputMaybe<Order_By>
	name?: InputMaybe<Order_By>
	namespace?: InputMaybe<Order_By>
	rollupAssetCount?: InputMaybe<Order_By>
	rollupImportCount?: InputMaybe<Order_By>
	rollupImportStatus?: InputMaybe<Order_By>
	rollupImportStatusPercent?: InputMaybe<Order_By>
	rollupMessageCode?: InputMaybe<Order_By>
	rollupMessageDev?: InputMaybe<Order_By>
	rollupMessageUser?: InputMaybe<Order_By>
	rollupProjectStatus?: InputMaybe<Order_By>
	rollupSchemaVersion?: InputMaybe<Order_By>
	rollupTotalFilesize?: InputMaybe<Order_By>
	schemaID?: InputMaybe<Order_By>
	tags?: InputMaybe<Order_By>
	tenantID?: InputMaybe<Order_By>
	updated?: InputMaybe<Order_By>
}

/** primary key columns input for table: core_Project */
export type Core_Project_Pk_Columns_Input = {
	id: Scalars["String"]
}

/** select columns of table "core.Project" */
export enum Core_Project_Select_Column {
	/** column name */
	Created = "created",
	/** column name */
	GlobalShared = "globalShared",
	/** column name */
	Id = "id",
	/** column name */
	LastActivityType = "lastActivityType",
	/** column name */
	Name = "name",
	/** column name */
	Namespace = "namespace",
	/** column name */
	RollupAssetCount = "rollupAssetCount",
	/** column name */
	RollupImportCount = "rollupImportCount",
	/** column name */
	RollupImportStatus = "rollupImportStatus",
	/** column name */
	RollupImportStatusPercent = "rollupImportStatusPercent",
	/** column name */
	RollupMessageCode = "rollupMessageCode",
	/** column name */
	RollupMessageDev = "rollupMessageDev",
	/** column name */
	RollupMessageUser = "rollupMessageUser",
	/** column name */
	RollupProjectStatus = "rollupProjectStatus",
	/** column name */
	RollupSchemaVersion = "rollupSchemaVersion",
	/** column name */
	RollupTotalFilesize = "rollupTotalFilesize",
	/** column name */
	SchemaId = "schemaID",
	/** column name */
	Tags = "tags",
	/** column name */
	TenantId = "tenantID",
	/** column name */
	Updated = "updated",
}

/** input type for updating data in table "core.Project" */
export type Core_Project_Set_Input = {
	created?: InputMaybe<Scalars["timestamp"]>
	globalShared?: InputMaybe<Scalars["Boolean"]>
	id?: InputMaybe<Scalars["String"]>
	lastActivityType?: InputMaybe<Scalars["UserActivityTypes"]>
	name?: InputMaybe<Scalars["String"]>
	namespace?: InputMaybe<Scalars["String"]>
	rollupAssetCount?: InputMaybe<Scalars["Int"]>
	rollupImportCount?: InputMaybe<Scalars["Int"]>
	rollupImportStatus?: InputMaybe<Scalars["ImportStatusTypes"]>
	rollupImportStatusPercent?: InputMaybe<Scalars["Int"]>
	rollupMessageCode?: InputMaybe<Scalars["String"]>
	rollupMessageDev?: InputMaybe<Scalars["String"]>
	rollupMessageUser?: InputMaybe<Scalars["String"]>
	rollupProjectStatus?: InputMaybe<Scalars["ProjectStatusTypes"]>
	rollupSchemaVersion?: InputMaybe<Scalars["String"]>
	rollupTotalFilesize?: InputMaybe<Scalars["Int"]>
	schemaID?: InputMaybe<Scalars["String"]>
	tags?: InputMaybe<Scalars["_text"]>
	tenantID?: InputMaybe<Scalars["String"]>
	updated?: InputMaybe<Scalars["timestamp"]>
}

/** aggregate stddev on columns */
export type Core_Project_Stddev_Fields = {
	__typename?: "core_Project_stddev_fields"
	rollupAssetCount?: Maybe<Scalars["Float"]>
	rollupImportCount?: Maybe<Scalars["Float"]>
	rollupImportStatusPercent?: Maybe<Scalars["Float"]>
	rollupTotalFilesize?: Maybe<Scalars["Float"]>
}

/** order by stddev() on columns of table "core.Project" */
export type Core_Project_Stddev_Order_By = {
	rollupAssetCount?: InputMaybe<Order_By>
	rollupImportCount?: InputMaybe<Order_By>
	rollupImportStatusPercent?: InputMaybe<Order_By>
	rollupTotalFilesize?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Core_Project_Stddev_Pop_Fields = {
	__typename?: "core_Project_stddev_pop_fields"
	rollupAssetCount?: Maybe<Scalars["Float"]>
	rollupImportCount?: Maybe<Scalars["Float"]>
	rollupImportStatusPercent?: Maybe<Scalars["Float"]>
	rollupTotalFilesize?: Maybe<Scalars["Float"]>
}

/** order by stddev_pop() on columns of table "core.Project" */
export type Core_Project_Stddev_Pop_Order_By = {
	rollupAssetCount?: InputMaybe<Order_By>
	rollupImportCount?: InputMaybe<Order_By>
	rollupImportStatusPercent?: InputMaybe<Order_By>
	rollupTotalFilesize?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Core_Project_Stddev_Samp_Fields = {
	__typename?: "core_Project_stddev_samp_fields"
	rollupAssetCount?: Maybe<Scalars["Float"]>
	rollupImportCount?: Maybe<Scalars["Float"]>
	rollupImportStatusPercent?: Maybe<Scalars["Float"]>
	rollupTotalFilesize?: Maybe<Scalars["Float"]>
}

/** order by stddev_samp() on columns of table "core.Project" */
export type Core_Project_Stddev_Samp_Order_By = {
	rollupAssetCount?: InputMaybe<Order_By>
	rollupImportCount?: InputMaybe<Order_By>
	rollupImportStatusPercent?: InputMaybe<Order_By>
	rollupTotalFilesize?: InputMaybe<Order_By>
}

/** aggregate sum on columns */
export type Core_Project_Sum_Fields = {
	__typename?: "core_Project_sum_fields"
	rollupAssetCount?: Maybe<Scalars["Int"]>
	rollupImportCount?: Maybe<Scalars["Int"]>
	rollupImportStatusPercent?: Maybe<Scalars["Int"]>
	rollupTotalFilesize?: Maybe<Scalars["Int"]>
}

/** order by sum() on columns of table "core.Project" */
export type Core_Project_Sum_Order_By = {
	rollupAssetCount?: InputMaybe<Order_By>
	rollupImportCount?: InputMaybe<Order_By>
	rollupImportStatusPercent?: InputMaybe<Order_By>
	rollupTotalFilesize?: InputMaybe<Order_By>
}

/** update columns of table "core.Project" */
export enum Core_Project_Update_Column {
	/** column name */
	Created = "created",
	/** column name */
	GlobalShared = "globalShared",
	/** column name */
	Id = "id",
	/** column name */
	LastActivityType = "lastActivityType",
	/** column name */
	Name = "name",
	/** column name */
	Namespace = "namespace",
	/** column name */
	RollupAssetCount = "rollupAssetCount",
	/** column name */
	RollupImportCount = "rollupImportCount",
	/** column name */
	RollupImportStatus = "rollupImportStatus",
	/** column name */
	RollupImportStatusPercent = "rollupImportStatusPercent",
	/** column name */
	RollupMessageCode = "rollupMessageCode",
	/** column name */
	RollupMessageDev = "rollupMessageDev",
	/** column name */
	RollupMessageUser = "rollupMessageUser",
	/** column name */
	RollupProjectStatus = "rollupProjectStatus",
	/** column name */
	RollupSchemaVersion = "rollupSchemaVersion",
	/** column name */
	RollupTotalFilesize = "rollupTotalFilesize",
	/** column name */
	SchemaId = "schemaID",
	/** column name */
	Tags = "tags",
	/** column name */
	TenantId = "tenantID",
	/** column name */
	Updated = "updated",
}

/** aggregate var_pop on columns */
export type Core_Project_Var_Pop_Fields = {
	__typename?: "core_Project_var_pop_fields"
	rollupAssetCount?: Maybe<Scalars["Float"]>
	rollupImportCount?: Maybe<Scalars["Float"]>
	rollupImportStatusPercent?: Maybe<Scalars["Float"]>
	rollupTotalFilesize?: Maybe<Scalars["Float"]>
}

/** order by var_pop() on columns of table "core.Project" */
export type Core_Project_Var_Pop_Order_By = {
	rollupAssetCount?: InputMaybe<Order_By>
	rollupImportCount?: InputMaybe<Order_By>
	rollupImportStatusPercent?: InputMaybe<Order_By>
	rollupTotalFilesize?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Core_Project_Var_Samp_Fields = {
	__typename?: "core_Project_var_samp_fields"
	rollupAssetCount?: Maybe<Scalars["Float"]>
	rollupImportCount?: Maybe<Scalars["Float"]>
	rollupImportStatusPercent?: Maybe<Scalars["Float"]>
	rollupTotalFilesize?: Maybe<Scalars["Float"]>
}

/** order by var_samp() on columns of table "core.Project" */
export type Core_Project_Var_Samp_Order_By = {
	rollupAssetCount?: InputMaybe<Order_By>
	rollupImportCount?: InputMaybe<Order_By>
	rollupImportStatusPercent?: InputMaybe<Order_By>
	rollupTotalFilesize?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Core_Project_Variance_Fields = {
	__typename?: "core_Project_variance_fields"
	rollupAssetCount?: Maybe<Scalars["Float"]>
	rollupImportCount?: Maybe<Scalars["Float"]>
	rollupImportStatusPercent?: Maybe<Scalars["Float"]>
	rollupTotalFilesize?: Maybe<Scalars["Float"]>
}

/** order by variance() on columns of table "core.Project" */
export type Core_Project_Variance_Order_By = {
	rollupAssetCount?: InputMaybe<Order_By>
	rollupImportCount?: InputMaybe<Order_By>
	rollupImportStatusPercent?: InputMaybe<Order_By>
	rollupTotalFilesize?: InputMaybe<Order_By>
}

/** columns and relationships of "core.Schemas" */
export type Core_Schemas = {
	__typename?: "core_Schemas"
	/** An array relationship */
	SchemaProjects: Array<Core_Project>
	/** An aggregate relationship */
	SchemaProjects_aggregate: Core_Project_Aggregate
	isMigrating?: Maybe<Scalars["Boolean"]>
	name: Scalars["String"]
	type: Scalars["SchemaType"]
	version?: Maybe<Scalars["bigint"]>
}

/** columns and relationships of "core.Schemas" */
export type Core_SchemasSchemaProjectsArgs = {
	distinct_on?: InputMaybe<Array<Core_Project_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Project_Order_By>>
	where?: InputMaybe<Core_Project_Bool_Exp>
}

/** columns and relationships of "core.Schemas" */
export type Core_SchemasSchemaProjects_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_Project_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Project_Order_By>>
	where?: InputMaybe<Core_Project_Bool_Exp>
}

/** aggregated selection of "core.Schemas" */
export type Core_Schemas_Aggregate = {
	__typename?: "core_Schemas_aggregate"
	aggregate?: Maybe<Core_Schemas_Aggregate_Fields>
	nodes: Array<Core_Schemas>
}

/** aggregate fields of "core.Schemas" */
export type Core_Schemas_Aggregate_Fields = {
	__typename?: "core_Schemas_aggregate_fields"
	avg?: Maybe<Core_Schemas_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<Core_Schemas_Max_Fields>
	min?: Maybe<Core_Schemas_Min_Fields>
	stddev?: Maybe<Core_Schemas_Stddev_Fields>
	stddev_pop?: Maybe<Core_Schemas_Stddev_Pop_Fields>
	stddev_samp?: Maybe<Core_Schemas_Stddev_Samp_Fields>
	sum?: Maybe<Core_Schemas_Sum_Fields>
	var_pop?: Maybe<Core_Schemas_Var_Pop_Fields>
	var_samp?: Maybe<Core_Schemas_Var_Samp_Fields>
	variance?: Maybe<Core_Schemas_Variance_Fields>
}

/** aggregate fields of "core.Schemas" */
export type Core_Schemas_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Core_Schemas_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type Core_Schemas_Avg_Fields = {
	__typename?: "core_Schemas_avg_fields"
	version?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "core.Schemas". All fields are combined with a logical 'AND'. */
export type Core_Schemas_Bool_Exp = {
	SchemaProjects?: InputMaybe<Core_Project_Bool_Exp>
	_and?: InputMaybe<Array<Core_Schemas_Bool_Exp>>
	_not?: InputMaybe<Core_Schemas_Bool_Exp>
	_or?: InputMaybe<Array<Core_Schemas_Bool_Exp>>
	isMigrating?: InputMaybe<Boolean_Comparison_Exp>
	name?: InputMaybe<String_Comparison_Exp>
	type?: InputMaybe<SchemaType_Comparison_Exp>
	version?: InputMaybe<Bigint_Comparison_Exp>
}

/** unique or primary key constraints on table "core.Schemas" */
export enum Core_Schemas_Constraint {
	/** unique or primary key constraint on columns "name" */
	SchemaName = "SchemaName",
}

/** input type for incrementing numeric columns in table "core.Schemas" */
export type Core_Schemas_Inc_Input = {
	version?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "core.Schemas" */
export type Core_Schemas_Insert_Input = {
	SchemaProjects?: InputMaybe<Core_Project_Arr_Rel_Insert_Input>
	isMigrating?: InputMaybe<Scalars["Boolean"]>
	name?: InputMaybe<Scalars["String"]>
	type?: InputMaybe<Scalars["SchemaType"]>
	version?: InputMaybe<Scalars["bigint"]>
}

/** aggregate max on columns */
export type Core_Schemas_Max_Fields = {
	__typename?: "core_Schemas_max_fields"
	name?: Maybe<Scalars["String"]>
	type?: Maybe<Scalars["SchemaType"]>
	version?: Maybe<Scalars["bigint"]>
}

/** aggregate min on columns */
export type Core_Schemas_Min_Fields = {
	__typename?: "core_Schemas_min_fields"
	name?: Maybe<Scalars["String"]>
	type?: Maybe<Scalars["SchemaType"]>
	version?: Maybe<Scalars["bigint"]>
}

/** response of any mutation on the table "core.Schemas" */
export type Core_Schemas_Mutation_Response = {
	__typename?: "core_Schemas_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<Core_Schemas>
}

/** on_conflict condition type for table "core.Schemas" */
export type Core_Schemas_On_Conflict = {
	constraint: Core_Schemas_Constraint
	update_columns?: Array<Core_Schemas_Update_Column>
	where?: InputMaybe<Core_Schemas_Bool_Exp>
}

/** Ordering options when selecting data from "core.Schemas". */
export type Core_Schemas_Order_By = {
	SchemaProjects_aggregate?: InputMaybe<Core_Project_Aggregate_Order_By>
	isMigrating?: InputMaybe<Order_By>
	name?: InputMaybe<Order_By>
	type?: InputMaybe<Order_By>
	version?: InputMaybe<Order_By>
}

/** primary key columns input for table: core_Schemas */
export type Core_Schemas_Pk_Columns_Input = {
	name: Scalars["String"]
}

/** select columns of table "core.Schemas" */
export enum Core_Schemas_Select_Column {
	/** column name */
	IsMigrating = "isMigrating",
	/** column name */
	Name = "name",
	/** column name */
	Type = "type",
	/** column name */
	Version = "version",
}

/** input type for updating data in table "core.Schemas" */
export type Core_Schemas_Set_Input = {
	isMigrating?: InputMaybe<Scalars["Boolean"]>
	name?: InputMaybe<Scalars["String"]>
	type?: InputMaybe<Scalars["SchemaType"]>
	version?: InputMaybe<Scalars["bigint"]>
}

/** aggregate stddev on columns */
export type Core_Schemas_Stddev_Fields = {
	__typename?: "core_Schemas_stddev_fields"
	version?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type Core_Schemas_Stddev_Pop_Fields = {
	__typename?: "core_Schemas_stddev_pop_fields"
	version?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type Core_Schemas_Stddev_Samp_Fields = {
	__typename?: "core_Schemas_stddev_samp_fields"
	version?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type Core_Schemas_Sum_Fields = {
	__typename?: "core_Schemas_sum_fields"
	version?: Maybe<Scalars["bigint"]>
}

/** update columns of table "core.Schemas" */
export enum Core_Schemas_Update_Column {
	/** column name */
	IsMigrating = "isMigrating",
	/** column name */
	Name = "name",
	/** column name */
	Type = "type",
	/** column name */
	Version = "version",
}

/** aggregate var_pop on columns */
export type Core_Schemas_Var_Pop_Fields = {
	__typename?: "core_Schemas_var_pop_fields"
	version?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type Core_Schemas_Var_Samp_Fields = {
	__typename?: "core_Schemas_var_samp_fields"
	version?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type Core_Schemas_Variance_Fields = {
	__typename?: "core_Schemas_variance_fields"
	version?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "core.Tenant" */
export type Core_Tenant = {
	__typename?: "core_Tenant"
	/** An array relationship */
	Projects: Array<Core_Project>
	/** An aggregate relationship */
	Projects_aggregate: Core_Project_Aggregate
	/** An array relationship */
	TenantUsers: Array<Core_TenantUser>
	/** An aggregate relationship */
	TenantUsers_aggregate: Core_TenantUser_Aggregate
	id: Scalars["String"]
	name?: Maybe<Scalars["String"]>
	status?: Maybe<Scalars["TenantStatusType"]>
	type?: Maybe<Scalars["TenantType"]>
}

/** columns and relationships of "core.Tenant" */
export type Core_TenantProjectsArgs = {
	distinct_on?: InputMaybe<Array<Core_Project_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Project_Order_By>>
	where?: InputMaybe<Core_Project_Bool_Exp>
}

/** columns and relationships of "core.Tenant" */
export type Core_TenantProjects_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_Project_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Project_Order_By>>
	where?: InputMaybe<Core_Project_Bool_Exp>
}

/** columns and relationships of "core.Tenant" */
export type Core_TenantTenantUsersArgs = {
	distinct_on?: InputMaybe<Array<Core_TenantUser_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_TenantUser_Order_By>>
	where?: InputMaybe<Core_TenantUser_Bool_Exp>
}

/** columns and relationships of "core.Tenant" */
export type Core_TenantTenantUsers_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_TenantUser_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_TenantUser_Order_By>>
	where?: InputMaybe<Core_TenantUser_Bool_Exp>
}

/** columns and relationships of "core.TenantUser" */
export type Core_TenantUser = {
	__typename?: "core_TenantUser"
	/** An object relationship */
	Tenant: Core_Tenant
	/** An object relationship */
	User: Core_User
	tenantID: Scalars["String"]
	tenantRole?: Maybe<Scalars["TenantRoleType"]>
	userID: Scalars["String"]
}

/** aggregated selection of "core.TenantUser" */
export type Core_TenantUser_Aggregate = {
	__typename?: "core_TenantUser_aggregate"
	aggregate?: Maybe<Core_TenantUser_Aggregate_Fields>
	nodes: Array<Core_TenantUser>
}

/** aggregate fields of "core.TenantUser" */
export type Core_TenantUser_Aggregate_Fields = {
	__typename?: "core_TenantUser_aggregate_fields"
	count: Scalars["Int"]
	max?: Maybe<Core_TenantUser_Max_Fields>
	min?: Maybe<Core_TenantUser_Min_Fields>
}

/** aggregate fields of "core.TenantUser" */
export type Core_TenantUser_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Core_TenantUser_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** order by aggregate values of table "core.TenantUser" */
export type Core_TenantUser_Aggregate_Order_By = {
	count?: InputMaybe<Order_By>
	max?: InputMaybe<Core_TenantUser_Max_Order_By>
	min?: InputMaybe<Core_TenantUser_Min_Order_By>
}

/** input type for inserting array relation for remote table "core.TenantUser" */
export type Core_TenantUser_Arr_Rel_Insert_Input = {
	data: Array<Core_TenantUser_Insert_Input>
	/** upsert condition */
	on_conflict?: InputMaybe<Core_TenantUser_On_Conflict>
}

/** Boolean expression to filter rows from the table "core.TenantUser". All fields are combined with a logical 'AND'. */
export type Core_TenantUser_Bool_Exp = {
	Tenant?: InputMaybe<Core_Tenant_Bool_Exp>
	User?: InputMaybe<Core_User_Bool_Exp>
	_and?: InputMaybe<Array<Core_TenantUser_Bool_Exp>>
	_not?: InputMaybe<Core_TenantUser_Bool_Exp>
	_or?: InputMaybe<Array<Core_TenantUser_Bool_Exp>>
	tenantID?: InputMaybe<String_Comparison_Exp>
	tenantRole?: InputMaybe<TenantRoleType_Comparison_Exp>
	userID?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "core.TenantUser" */
export enum Core_TenantUser_Constraint {
	/** unique or primary key constraint on columns "tenantID", "userID" */
	TenantUserPkey = "TenantUser_pkey",
}

/** input type for inserting data into table "core.TenantUser" */
export type Core_TenantUser_Insert_Input = {
	Tenant?: InputMaybe<Core_Tenant_Obj_Rel_Insert_Input>
	User?: InputMaybe<Core_User_Obj_Rel_Insert_Input>
	tenantID?: InputMaybe<Scalars["String"]>
	tenantRole?: InputMaybe<Scalars["TenantRoleType"]>
	userID?: InputMaybe<Scalars["String"]>
}

/** aggregate max on columns */
export type Core_TenantUser_Max_Fields = {
	__typename?: "core_TenantUser_max_fields"
	tenantID?: Maybe<Scalars["String"]>
	tenantRole?: Maybe<Scalars["TenantRoleType"]>
	userID?: Maybe<Scalars["String"]>
}

/** order by max() on columns of table "core.TenantUser" */
export type Core_TenantUser_Max_Order_By = {
	tenantID?: InputMaybe<Order_By>
	tenantRole?: InputMaybe<Order_By>
	userID?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Core_TenantUser_Min_Fields = {
	__typename?: "core_TenantUser_min_fields"
	tenantID?: Maybe<Scalars["String"]>
	tenantRole?: Maybe<Scalars["TenantRoleType"]>
	userID?: Maybe<Scalars["String"]>
}

/** order by min() on columns of table "core.TenantUser" */
export type Core_TenantUser_Min_Order_By = {
	tenantID?: InputMaybe<Order_By>
	tenantRole?: InputMaybe<Order_By>
	userID?: InputMaybe<Order_By>
}

/** response of any mutation on the table "core.TenantUser" */
export type Core_TenantUser_Mutation_Response = {
	__typename?: "core_TenantUser_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<Core_TenantUser>
}

/** on_conflict condition type for table "core.TenantUser" */
export type Core_TenantUser_On_Conflict = {
	constraint: Core_TenantUser_Constraint
	update_columns?: Array<Core_TenantUser_Update_Column>
	where?: InputMaybe<Core_TenantUser_Bool_Exp>
}

/** Ordering options when selecting data from "core.TenantUser". */
export type Core_TenantUser_Order_By = {
	Tenant?: InputMaybe<Core_Tenant_Order_By>
	User?: InputMaybe<Core_User_Order_By>
	tenantID?: InputMaybe<Order_By>
	tenantRole?: InputMaybe<Order_By>
	userID?: InputMaybe<Order_By>
}

/** primary key columns input for table: core_TenantUser */
export type Core_TenantUser_Pk_Columns_Input = {
	tenantID: Scalars["String"]
	userID: Scalars["String"]
}

/** select columns of table "core.TenantUser" */
export enum Core_TenantUser_Select_Column {
	/** column name */
	TenantId = "tenantID",
	/** column name */
	TenantRole = "tenantRole",
	/** column name */
	UserId = "userID",
}

/** input type for updating data in table "core.TenantUser" */
export type Core_TenantUser_Set_Input = {
	tenantID?: InputMaybe<Scalars["String"]>
	tenantRole?: InputMaybe<Scalars["TenantRoleType"]>
	userID?: InputMaybe<Scalars["String"]>
}

/** update columns of table "core.TenantUser" */
export enum Core_TenantUser_Update_Column {
	/** column name */
	TenantId = "tenantID",
	/** column name */
	TenantRole = "tenantRole",
	/** column name */
	UserId = "userID",
}

/** aggregated selection of "core.Tenant" */
export type Core_Tenant_Aggregate = {
	__typename?: "core_Tenant_aggregate"
	aggregate?: Maybe<Core_Tenant_Aggregate_Fields>
	nodes: Array<Core_Tenant>
}

/** aggregate fields of "core.Tenant" */
export type Core_Tenant_Aggregate_Fields = {
	__typename?: "core_Tenant_aggregate_fields"
	count: Scalars["Int"]
	max?: Maybe<Core_Tenant_Max_Fields>
	min?: Maybe<Core_Tenant_Min_Fields>
}

/** aggregate fields of "core.Tenant" */
export type Core_Tenant_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Core_Tenant_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** Boolean expression to filter rows from the table "core.Tenant". All fields are combined with a logical 'AND'. */
export type Core_Tenant_Bool_Exp = {
	Projects?: InputMaybe<Core_Project_Bool_Exp>
	TenantUsers?: InputMaybe<Core_TenantUser_Bool_Exp>
	_and?: InputMaybe<Array<Core_Tenant_Bool_Exp>>
	_not?: InputMaybe<Core_Tenant_Bool_Exp>
	_or?: InputMaybe<Array<Core_Tenant_Bool_Exp>>
	id?: InputMaybe<String_Comparison_Exp>
	name?: InputMaybe<String_Comparison_Exp>
	status?: InputMaybe<TenantStatusType_Comparison_Exp>
	type?: InputMaybe<TenantType_Comparison_Exp>
}

/** unique or primary key constraints on table "core.Tenant" */
export enum Core_Tenant_Constraint {
	/** unique or primary key constraint on columns "id" */
	TenantPkey = "Tenant_pkey",
}

/** input type for inserting data into table "core.Tenant" */
export type Core_Tenant_Insert_Input = {
	Projects?: InputMaybe<Core_Project_Arr_Rel_Insert_Input>
	TenantUsers?: InputMaybe<Core_TenantUser_Arr_Rel_Insert_Input>
	id?: InputMaybe<Scalars["String"]>
	name?: InputMaybe<Scalars["String"]>
	status?: InputMaybe<Scalars["TenantStatusType"]>
	type?: InputMaybe<Scalars["TenantType"]>
}

/** aggregate max on columns */
export type Core_Tenant_Max_Fields = {
	__typename?: "core_Tenant_max_fields"
	id?: Maybe<Scalars["String"]>
	name?: Maybe<Scalars["String"]>
	status?: Maybe<Scalars["TenantStatusType"]>
	type?: Maybe<Scalars["TenantType"]>
}

/** aggregate min on columns */
export type Core_Tenant_Min_Fields = {
	__typename?: "core_Tenant_min_fields"
	id?: Maybe<Scalars["String"]>
	name?: Maybe<Scalars["String"]>
	status?: Maybe<Scalars["TenantStatusType"]>
	type?: Maybe<Scalars["TenantType"]>
}

/** response of any mutation on the table "core.Tenant" */
export type Core_Tenant_Mutation_Response = {
	__typename?: "core_Tenant_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<Core_Tenant>
}

/** input type for inserting object relation for remote table "core.Tenant" */
export type Core_Tenant_Obj_Rel_Insert_Input = {
	data: Core_Tenant_Insert_Input
	/** upsert condition */
	on_conflict?: InputMaybe<Core_Tenant_On_Conflict>
}

/** on_conflict condition type for table "core.Tenant" */
export type Core_Tenant_On_Conflict = {
	constraint: Core_Tenant_Constraint
	update_columns?: Array<Core_Tenant_Update_Column>
	where?: InputMaybe<Core_Tenant_Bool_Exp>
}

/** Ordering options when selecting data from "core.Tenant". */
export type Core_Tenant_Order_By = {
	Projects_aggregate?: InputMaybe<Core_Project_Aggregate_Order_By>
	TenantUsers_aggregate?: InputMaybe<Core_TenantUser_Aggregate_Order_By>
	id?: InputMaybe<Order_By>
	name?: InputMaybe<Order_By>
	status?: InputMaybe<Order_By>
	type?: InputMaybe<Order_By>
}

/** primary key columns input for table: core_Tenant */
export type Core_Tenant_Pk_Columns_Input = {
	id: Scalars["String"]
}

/** select columns of table "core.Tenant" */
export enum Core_Tenant_Select_Column {
	/** column name */
	Id = "id",
	/** column name */
	Name = "name",
	/** column name */
	Status = "status",
	/** column name */
	Type = "type",
}

/** input type for updating data in table "core.Tenant" */
export type Core_Tenant_Set_Input = {
	id?: InputMaybe<Scalars["String"]>
	name?: InputMaybe<Scalars["String"]>
	status?: InputMaybe<Scalars["TenantStatusType"]>
	type?: InputMaybe<Scalars["TenantType"]>
}

/** update columns of table "core.Tenant" */
export enum Core_Tenant_Update_Column {
	/** column name */
	Id = "id",
	/** column name */
	Name = "name",
	/** column name */
	Status = "status",
	/** column name */
	Type = "type",
}

/** columns and relationships of "core.User" */
export type Core_User = {
	__typename?: "core_User"
	/** An array relationship */
	TenantUsers: Array<Core_TenantUser>
	/** An aggregate relationship */
	TenantUsers_aggregate: Core_TenantUser_Aggregate
	auth0UserID?: Maybe<Scalars["String"]>
	contactEmail?: Maybe<Scalars["String"]>
	emailVerified?: Maybe<Scalars["Boolean"]>
	firstName?: Maybe<Scalars["String"]>
	id: Scalars["String"]
	lastActivity?: Maybe<Scalars["timestamp"]>
	lastLogin?: Maybe<Scalars["timestamp"]>
	lastName?: Maybe<Scalars["String"]>
	locale?: Maybe<Scalars["String"]>
	tier?: Maybe<Scalars["Tier"]>
}

/** columns and relationships of "core.User" */
export type Core_UserTenantUsersArgs = {
	distinct_on?: InputMaybe<Array<Core_TenantUser_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_TenantUser_Order_By>>
	where?: InputMaybe<Core_TenantUser_Bool_Exp>
}

/** columns and relationships of "core.User" */
export type Core_UserTenantUsers_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_TenantUser_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_TenantUser_Order_By>>
	where?: InputMaybe<Core_TenantUser_Bool_Exp>
}

/** aggregated selection of "core.User" */
export type Core_User_Aggregate = {
	__typename?: "core_User_aggregate"
	aggregate?: Maybe<Core_User_Aggregate_Fields>
	nodes: Array<Core_User>
}

/** aggregate fields of "core.User" */
export type Core_User_Aggregate_Fields = {
	__typename?: "core_User_aggregate_fields"
	count: Scalars["Int"]
	max?: Maybe<Core_User_Max_Fields>
	min?: Maybe<Core_User_Min_Fields>
}

/** aggregate fields of "core.User" */
export type Core_User_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Core_User_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** Boolean expression to filter rows from the table "core.User". All fields are combined with a logical 'AND'. */
export type Core_User_Bool_Exp = {
	TenantUsers?: InputMaybe<Core_TenantUser_Bool_Exp>
	_and?: InputMaybe<Array<Core_User_Bool_Exp>>
	_not?: InputMaybe<Core_User_Bool_Exp>
	_or?: InputMaybe<Array<Core_User_Bool_Exp>>
	auth0UserID?: InputMaybe<String_Comparison_Exp>
	contactEmail?: InputMaybe<String_Comparison_Exp>
	emailVerified?: InputMaybe<Boolean_Comparison_Exp>
	firstName?: InputMaybe<String_Comparison_Exp>
	id?: InputMaybe<String_Comparison_Exp>
	lastActivity?: InputMaybe<Timestamp_Comparison_Exp>
	lastLogin?: InputMaybe<Timestamp_Comparison_Exp>
	lastName?: InputMaybe<String_Comparison_Exp>
	locale?: InputMaybe<String_Comparison_Exp>
	tier?: InputMaybe<Tier_Comparison_Exp>
}

/** unique or primary key constraints on table "core.User" */
export enum Core_User_Constraint {
	/** unique or primary key constraint on columns "id" */
	UserPkey = "User_pkey",
}

/** input type for inserting data into table "core.User" */
export type Core_User_Insert_Input = {
	TenantUsers?: InputMaybe<Core_TenantUser_Arr_Rel_Insert_Input>
	auth0UserID?: InputMaybe<Scalars["String"]>
	contactEmail?: InputMaybe<Scalars["String"]>
	emailVerified?: InputMaybe<Scalars["Boolean"]>
	firstName?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["String"]>
	lastActivity?: InputMaybe<Scalars["timestamp"]>
	lastLogin?: InputMaybe<Scalars["timestamp"]>
	lastName?: InputMaybe<Scalars["String"]>
	locale?: InputMaybe<Scalars["String"]>
	tier?: InputMaybe<Scalars["Tier"]>
}

/** aggregate max on columns */
export type Core_User_Max_Fields = {
	__typename?: "core_User_max_fields"
	auth0UserID?: Maybe<Scalars["String"]>
	contactEmail?: Maybe<Scalars["String"]>
	firstName?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["String"]>
	lastActivity?: Maybe<Scalars["timestamp"]>
	lastLogin?: Maybe<Scalars["timestamp"]>
	lastName?: Maybe<Scalars["String"]>
	locale?: Maybe<Scalars["String"]>
	tier?: Maybe<Scalars["Tier"]>
}

/** aggregate min on columns */
export type Core_User_Min_Fields = {
	__typename?: "core_User_min_fields"
	auth0UserID?: Maybe<Scalars["String"]>
	contactEmail?: Maybe<Scalars["String"]>
	firstName?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["String"]>
	lastActivity?: Maybe<Scalars["timestamp"]>
	lastLogin?: Maybe<Scalars["timestamp"]>
	lastName?: Maybe<Scalars["String"]>
	locale?: Maybe<Scalars["String"]>
	tier?: Maybe<Scalars["Tier"]>
}

/** response of any mutation on the table "core.User" */
export type Core_User_Mutation_Response = {
	__typename?: "core_User_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<Core_User>
}

/** input type for inserting object relation for remote table "core.User" */
export type Core_User_Obj_Rel_Insert_Input = {
	data: Core_User_Insert_Input
	/** upsert condition */
	on_conflict?: InputMaybe<Core_User_On_Conflict>
}

/** on_conflict condition type for table "core.User" */
export type Core_User_On_Conflict = {
	constraint: Core_User_Constraint
	update_columns?: Array<Core_User_Update_Column>
	where?: InputMaybe<Core_User_Bool_Exp>
}

/** Ordering options when selecting data from "core.User". */
export type Core_User_Order_By = {
	TenantUsers_aggregate?: InputMaybe<Core_TenantUser_Aggregate_Order_By>
	auth0UserID?: InputMaybe<Order_By>
	contactEmail?: InputMaybe<Order_By>
	emailVerified?: InputMaybe<Order_By>
	firstName?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	lastActivity?: InputMaybe<Order_By>
	lastLogin?: InputMaybe<Order_By>
	lastName?: InputMaybe<Order_By>
	locale?: InputMaybe<Order_By>
	tier?: InputMaybe<Order_By>
}

/** primary key columns input for table: core_User */
export type Core_User_Pk_Columns_Input = {
	id: Scalars["String"]
}

/** select columns of table "core.User" */
export enum Core_User_Select_Column {
	/** column name */
	Auth0UserId = "auth0UserID",
	/** column name */
	ContactEmail = "contactEmail",
	/** column name */
	EmailVerified = "emailVerified",
	/** column name */
	FirstName = "firstName",
	/** column name */
	Id = "id",
	/** column name */
	LastActivity = "lastActivity",
	/** column name */
	LastLogin = "lastLogin",
	/** column name */
	LastName = "lastName",
	/** column name */
	Locale = "locale",
	/** column name */
	Tier = "tier",
}

/** input type for updating data in table "core.User" */
export type Core_User_Set_Input = {
	auth0UserID?: InputMaybe<Scalars["String"]>
	contactEmail?: InputMaybe<Scalars["String"]>
	emailVerified?: InputMaybe<Scalars["Boolean"]>
	firstName?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["String"]>
	lastActivity?: InputMaybe<Scalars["timestamp"]>
	lastLogin?: InputMaybe<Scalars["timestamp"]>
	lastName?: InputMaybe<Scalars["String"]>
	locale?: InputMaybe<Scalars["String"]>
	tier?: InputMaybe<Scalars["Tier"]>
}

/** update columns of table "core.User" */
export enum Core_User_Update_Column {
	/** column name */
	Auth0UserId = "auth0UserID",
	/** column name */
	ContactEmail = "contactEmail",
	/** column name */
	EmailVerified = "emailVerified",
	/** column name */
	FirstName = "firstName",
	/** column name */
	Id = "id",
	/** column name */
	LastActivity = "lastActivity",
	/** column name */
	LastLogin = "lastLogin",
	/** column name */
	LastName = "lastName",
	/** column name */
	Locale = "locale",
	/** column name */
	Tier = "tier",
}

/** columns and relationships of "data.asset_roles" */
export type Data_Asset_Roles = {
	__typename?: "data_asset_roles"
	category?: Maybe<Scalars["String"]>
	icon?: Maybe<Scalars["String"]>
	id: Scalars["String"]
	name?: Maybe<Scalars["String"]>
	sort: Scalars["Int"]
}

/** aggregated selection of "data.asset_roles" */
export type Data_Asset_Roles_Aggregate = {
	__typename?: "data_asset_roles_aggregate"
	aggregate?: Maybe<Data_Asset_Roles_Aggregate_Fields>
	nodes: Array<Data_Asset_Roles>
}

/** aggregate fields of "data.asset_roles" */
export type Data_Asset_Roles_Aggregate_Fields = {
	__typename?: "data_asset_roles_aggregate_fields"
	avg?: Maybe<Data_Asset_Roles_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<Data_Asset_Roles_Max_Fields>
	min?: Maybe<Data_Asset_Roles_Min_Fields>
	stddev?: Maybe<Data_Asset_Roles_Stddev_Fields>
	stddev_pop?: Maybe<Data_Asset_Roles_Stddev_Pop_Fields>
	stddev_samp?: Maybe<Data_Asset_Roles_Stddev_Samp_Fields>
	sum?: Maybe<Data_Asset_Roles_Sum_Fields>
	var_pop?: Maybe<Data_Asset_Roles_Var_Pop_Fields>
	var_samp?: Maybe<Data_Asset_Roles_Var_Samp_Fields>
	variance?: Maybe<Data_Asset_Roles_Variance_Fields>
}

/** aggregate fields of "data.asset_roles" */
export type Data_Asset_Roles_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Data_Asset_Roles_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type Data_Asset_Roles_Avg_Fields = {
	__typename?: "data_asset_roles_avg_fields"
	sort?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "data.asset_roles". All fields are combined with a logical 'AND'. */
export type Data_Asset_Roles_Bool_Exp = {
	_and?: InputMaybe<Array<Data_Asset_Roles_Bool_Exp>>
	_not?: InputMaybe<Data_Asset_Roles_Bool_Exp>
	_or?: InputMaybe<Array<Data_Asset_Roles_Bool_Exp>>
	category?: InputMaybe<String_Comparison_Exp>
	icon?: InputMaybe<String_Comparison_Exp>
	id?: InputMaybe<String_Comparison_Exp>
	name?: InputMaybe<String_Comparison_Exp>
	sort?: InputMaybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "data.asset_roles" */
export enum Data_Asset_Roles_Constraint {
	/** unique or primary key constraint on columns "id" */
	AssetRolesPkey = "asset_roles_pkey",
}

/** input type for incrementing numeric columns in table "data.asset_roles" */
export type Data_Asset_Roles_Inc_Input = {
	sort?: InputMaybe<Scalars["Int"]>
}

/** input type for inserting data into table "data.asset_roles" */
export type Data_Asset_Roles_Insert_Input = {
	category?: InputMaybe<Scalars["String"]>
	icon?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["String"]>
	name?: InputMaybe<Scalars["String"]>
	sort?: InputMaybe<Scalars["Int"]>
}

/** aggregate max on columns */
export type Data_Asset_Roles_Max_Fields = {
	__typename?: "data_asset_roles_max_fields"
	category?: Maybe<Scalars["String"]>
	icon?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["String"]>
	name?: Maybe<Scalars["String"]>
	sort?: Maybe<Scalars["Int"]>
}

/** aggregate min on columns */
export type Data_Asset_Roles_Min_Fields = {
	__typename?: "data_asset_roles_min_fields"
	category?: Maybe<Scalars["String"]>
	icon?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["String"]>
	name?: Maybe<Scalars["String"]>
	sort?: Maybe<Scalars["Int"]>
}

/** response of any mutation on the table "data.asset_roles" */
export type Data_Asset_Roles_Mutation_Response = {
	__typename?: "data_asset_roles_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<Data_Asset_Roles>
}

/** on_conflict condition type for table "data.asset_roles" */
export type Data_Asset_Roles_On_Conflict = {
	constraint: Data_Asset_Roles_Constraint
	update_columns?: Array<Data_Asset_Roles_Update_Column>
	where?: InputMaybe<Data_Asset_Roles_Bool_Exp>
}

/** Ordering options when selecting data from "data.asset_roles". */
export type Data_Asset_Roles_Order_By = {
	category?: InputMaybe<Order_By>
	icon?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	name?: InputMaybe<Order_By>
	sort?: InputMaybe<Order_By>
}

/** primary key columns input for table: data_asset_roles */
export type Data_Asset_Roles_Pk_Columns_Input = {
	id: Scalars["String"]
}

/** select columns of table "data.asset_roles" */
export enum Data_Asset_Roles_Select_Column {
	/** column name */
	Category = "category",
	/** column name */
	Icon = "icon",
	/** column name */
	Id = "id",
	/** column name */
	Name = "name",
	/** column name */
	Sort = "sort",
}

/** input type for updating data in table "data.asset_roles" */
export type Data_Asset_Roles_Set_Input = {
	category?: InputMaybe<Scalars["String"]>
	icon?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["String"]>
	name?: InputMaybe<Scalars["String"]>
	sort?: InputMaybe<Scalars["Int"]>
}

/** aggregate stddev on columns */
export type Data_Asset_Roles_Stddev_Fields = {
	__typename?: "data_asset_roles_stddev_fields"
	sort?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type Data_Asset_Roles_Stddev_Pop_Fields = {
	__typename?: "data_asset_roles_stddev_pop_fields"
	sort?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type Data_Asset_Roles_Stddev_Samp_Fields = {
	__typename?: "data_asset_roles_stddev_samp_fields"
	sort?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type Data_Asset_Roles_Sum_Fields = {
	__typename?: "data_asset_roles_sum_fields"
	sort?: Maybe<Scalars["Int"]>
}

/** update columns of table "data.asset_roles" */
export enum Data_Asset_Roles_Update_Column {
	/** column name */
	Category = "category",
	/** column name */
	Icon = "icon",
	/** column name */
	Id = "id",
	/** column name */
	Name = "name",
	/** column name */
	Sort = "sort",
}

/** aggregate var_pop on columns */
export type Data_Asset_Roles_Var_Pop_Fields = {
	__typename?: "data_asset_roles_var_pop_fields"
	sort?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type Data_Asset_Roles_Var_Samp_Fields = {
	__typename?: "data_asset_roles_var_samp_fields"
	sort?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type Data_Asset_Roles_Variance_Fields = {
	__typename?: "data_asset_roles_variance_fields"
	sort?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "data.asset_types" */
export type Data_Asset_Types = {
	__typename?: "data_asset_types"
	id: Scalars["Int"]
}

/** aggregated selection of "data.asset_types" */
export type Data_Asset_Types_Aggregate = {
	__typename?: "data_asset_types_aggregate"
	aggregate?: Maybe<Data_Asset_Types_Aggregate_Fields>
	nodes: Array<Data_Asset_Types>
}

/** aggregate fields of "data.asset_types" */
export type Data_Asset_Types_Aggregate_Fields = {
	__typename?: "data_asset_types_aggregate_fields"
	avg?: Maybe<Data_Asset_Types_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<Data_Asset_Types_Max_Fields>
	min?: Maybe<Data_Asset_Types_Min_Fields>
	stddev?: Maybe<Data_Asset_Types_Stddev_Fields>
	stddev_pop?: Maybe<Data_Asset_Types_Stddev_Pop_Fields>
	stddev_samp?: Maybe<Data_Asset_Types_Stddev_Samp_Fields>
	sum?: Maybe<Data_Asset_Types_Sum_Fields>
	var_pop?: Maybe<Data_Asset_Types_Var_Pop_Fields>
	var_samp?: Maybe<Data_Asset_Types_Var_Samp_Fields>
	variance?: Maybe<Data_Asset_Types_Variance_Fields>
}

/** aggregate fields of "data.asset_types" */
export type Data_Asset_Types_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Data_Asset_Types_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type Data_Asset_Types_Avg_Fields = {
	__typename?: "data_asset_types_avg_fields"
	id?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "data.asset_types". All fields are combined with a logical 'AND'. */
export type Data_Asset_Types_Bool_Exp = {
	_and?: InputMaybe<Array<Data_Asset_Types_Bool_Exp>>
	_not?: InputMaybe<Data_Asset_Types_Bool_Exp>
	_or?: InputMaybe<Array<Data_Asset_Types_Bool_Exp>>
	id?: InputMaybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "data.asset_types" */
export enum Data_Asset_Types_Constraint {
	/** unique or primary key constraint on columns "id" */
	AssetTypesPkey = "asset_types_pkey",
}

/** input type for incrementing numeric columns in table "data.asset_types" */
export type Data_Asset_Types_Inc_Input = {
	id?: InputMaybe<Scalars["Int"]>
}

/** input type for inserting data into table "data.asset_types" */
export type Data_Asset_Types_Insert_Input = {
	id?: InputMaybe<Scalars["Int"]>
}

/** aggregate max on columns */
export type Data_Asset_Types_Max_Fields = {
	__typename?: "data_asset_types_max_fields"
	id?: Maybe<Scalars["Int"]>
}

/** aggregate min on columns */
export type Data_Asset_Types_Min_Fields = {
	__typename?: "data_asset_types_min_fields"
	id?: Maybe<Scalars["Int"]>
}

/** response of any mutation on the table "data.asset_types" */
export type Data_Asset_Types_Mutation_Response = {
	__typename?: "data_asset_types_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<Data_Asset_Types>
}

/** on_conflict condition type for table "data.asset_types" */
export type Data_Asset_Types_On_Conflict = {
	constraint: Data_Asset_Types_Constraint
	update_columns?: Array<Data_Asset_Types_Update_Column>
	where?: InputMaybe<Data_Asset_Types_Bool_Exp>
}

/** Ordering options when selecting data from "data.asset_types". */
export type Data_Asset_Types_Order_By = {
	id?: InputMaybe<Order_By>
}

/** primary key columns input for table: data_asset_types */
export type Data_Asset_Types_Pk_Columns_Input = {
	id: Scalars["Int"]
}

/** select columns of table "data.asset_types" */
export enum Data_Asset_Types_Select_Column {
	/** column name */
	Id = "id",
}

/** input type for updating data in table "data.asset_types" */
export type Data_Asset_Types_Set_Input = {
	id?: InputMaybe<Scalars["Int"]>
}

/** aggregate stddev on columns */
export type Data_Asset_Types_Stddev_Fields = {
	__typename?: "data_asset_types_stddev_fields"
	id?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type Data_Asset_Types_Stddev_Pop_Fields = {
	__typename?: "data_asset_types_stddev_pop_fields"
	id?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type Data_Asset_Types_Stddev_Samp_Fields = {
	__typename?: "data_asset_types_stddev_samp_fields"
	id?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type Data_Asset_Types_Sum_Fields = {
	__typename?: "data_asset_types_sum_fields"
	id?: Maybe<Scalars["Int"]>
}

/** update columns of table "data.asset_types" */
export enum Data_Asset_Types_Update_Column {
	/** column name */
	Id = "id",
}

/** aggregate var_pop on columns */
export type Data_Asset_Types_Var_Pop_Fields = {
	__typename?: "data_asset_types_var_pop_fields"
	id?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type Data_Asset_Types_Var_Samp_Fields = {
	__typename?: "data_asset_types_var_samp_fields"
	id?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type Data_Asset_Types_Variance_Fields = {
	__typename?: "data_asset_types_variance_fields"
	id?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "data.cves" */
export type Data_Cves = {
	__typename?: "data_cves"
	desc?: Maybe<Scalars["String"]>
	id: Scalars["String"]
	impact_score?: Maybe<Scalars["Int"]>
	references?: Maybe<Scalars["json"]>
}

/** columns and relationships of "data.cves" */
export type Data_CvesReferencesArgs = {
	path?: InputMaybe<Scalars["String"]>
}

/** aggregated selection of "data.cves" */
export type Data_Cves_Aggregate = {
	__typename?: "data_cves_aggregate"
	aggregate?: Maybe<Data_Cves_Aggregate_Fields>
	nodes: Array<Data_Cves>
}

/** aggregate fields of "data.cves" */
export type Data_Cves_Aggregate_Fields = {
	__typename?: "data_cves_aggregate_fields"
	avg?: Maybe<Data_Cves_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<Data_Cves_Max_Fields>
	min?: Maybe<Data_Cves_Min_Fields>
	stddev?: Maybe<Data_Cves_Stddev_Fields>
	stddev_pop?: Maybe<Data_Cves_Stddev_Pop_Fields>
	stddev_samp?: Maybe<Data_Cves_Stddev_Samp_Fields>
	sum?: Maybe<Data_Cves_Sum_Fields>
	var_pop?: Maybe<Data_Cves_Var_Pop_Fields>
	var_samp?: Maybe<Data_Cves_Var_Samp_Fields>
	variance?: Maybe<Data_Cves_Variance_Fields>
}

/** aggregate fields of "data.cves" */
export type Data_Cves_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Data_Cves_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type Data_Cves_Avg_Fields = {
	__typename?: "data_cves_avg_fields"
	impact_score?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "data.cves". All fields are combined with a logical 'AND'. */
export type Data_Cves_Bool_Exp = {
	_and?: InputMaybe<Array<Data_Cves_Bool_Exp>>
	_not?: InputMaybe<Data_Cves_Bool_Exp>
	_or?: InputMaybe<Array<Data_Cves_Bool_Exp>>
	desc?: InputMaybe<String_Comparison_Exp>
	id?: InputMaybe<String_Comparison_Exp>
	impact_score?: InputMaybe<Int_Comparison_Exp>
	references?: InputMaybe<Json_Comparison_Exp>
}

/** unique or primary key constraints on table "data.cves" */
export enum Data_Cves_Constraint {
	/** unique or primary key constraint on columns "id" */
	CvesPkey = "cves_pkey",
}

/** input type for incrementing numeric columns in table "data.cves" */
export type Data_Cves_Inc_Input = {
	impact_score?: InputMaybe<Scalars["Int"]>
}

/** input type for inserting data into table "data.cves" */
export type Data_Cves_Insert_Input = {
	desc?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["String"]>
	impact_score?: InputMaybe<Scalars["Int"]>
	references?: InputMaybe<Scalars["json"]>
}

/** aggregate max on columns */
export type Data_Cves_Max_Fields = {
	__typename?: "data_cves_max_fields"
	desc?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["String"]>
	impact_score?: Maybe<Scalars["Int"]>
}

/** aggregate min on columns */
export type Data_Cves_Min_Fields = {
	__typename?: "data_cves_min_fields"
	desc?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["String"]>
	impact_score?: Maybe<Scalars["Int"]>
}

/** response of any mutation on the table "data.cves" */
export type Data_Cves_Mutation_Response = {
	__typename?: "data_cves_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<Data_Cves>
}

/** on_conflict condition type for table "data.cves" */
export type Data_Cves_On_Conflict = {
	constraint: Data_Cves_Constraint
	update_columns?: Array<Data_Cves_Update_Column>
	where?: InputMaybe<Data_Cves_Bool_Exp>
}

/** Ordering options when selecting data from "data.cves". */
export type Data_Cves_Order_By = {
	desc?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	impact_score?: InputMaybe<Order_By>
	references?: InputMaybe<Order_By>
}

/** primary key columns input for table: data_cves */
export type Data_Cves_Pk_Columns_Input = {
	id: Scalars["String"]
}

/** select columns of table "data.cves" */
export enum Data_Cves_Select_Column {
	/** column name */
	Desc = "desc",
	/** column name */
	Id = "id",
	/** column name */
	ImpactScore = "impact_score",
	/** column name */
	References = "references",
}

/** input type for updating data in table "data.cves" */
export type Data_Cves_Set_Input = {
	desc?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["String"]>
	impact_score?: InputMaybe<Scalars["Int"]>
	references?: InputMaybe<Scalars["json"]>
}

/** aggregate stddev on columns */
export type Data_Cves_Stddev_Fields = {
	__typename?: "data_cves_stddev_fields"
	impact_score?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type Data_Cves_Stddev_Pop_Fields = {
	__typename?: "data_cves_stddev_pop_fields"
	impact_score?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type Data_Cves_Stddev_Samp_Fields = {
	__typename?: "data_cves_stddev_samp_fields"
	impact_score?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type Data_Cves_Sum_Fields = {
	__typename?: "data_cves_sum_fields"
	impact_score?: Maybe<Scalars["Int"]>
}

/** update columns of table "data.cves" */
export enum Data_Cves_Update_Column {
	/** column name */
	Desc = "desc",
	/** column name */
	Id = "id",
	/** column name */
	ImpactScore = "impact_score",
	/** column name */
	References = "references",
}

/** aggregate var_pop on columns */
export type Data_Cves_Var_Pop_Fields = {
	__typename?: "data_cves_var_pop_fields"
	impact_score?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type Data_Cves_Var_Samp_Fields = {
	__typename?: "data_cves_var_samp_fields"
	impact_score?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type Data_Cves_Variance_Fields = {
	__typename?: "data_cves_variance_fields"
	impact_score?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "data.network_protocols" */
export type Data_Network_Protocols = {
	__typename?: "data_network_protocols"
	id: Scalars["Int"]
}

/** aggregated selection of "data.network_protocols" */
export type Data_Network_Protocols_Aggregate = {
	__typename?: "data_network_protocols_aggregate"
	aggregate?: Maybe<Data_Network_Protocols_Aggregate_Fields>
	nodes: Array<Data_Network_Protocols>
}

/** aggregate fields of "data.network_protocols" */
export type Data_Network_Protocols_Aggregate_Fields = {
	__typename?: "data_network_protocols_aggregate_fields"
	avg?: Maybe<Data_Network_Protocols_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<Data_Network_Protocols_Max_Fields>
	min?: Maybe<Data_Network_Protocols_Min_Fields>
	stddev?: Maybe<Data_Network_Protocols_Stddev_Fields>
	stddev_pop?: Maybe<Data_Network_Protocols_Stddev_Pop_Fields>
	stddev_samp?: Maybe<Data_Network_Protocols_Stddev_Samp_Fields>
	sum?: Maybe<Data_Network_Protocols_Sum_Fields>
	var_pop?: Maybe<Data_Network_Protocols_Var_Pop_Fields>
	var_samp?: Maybe<Data_Network_Protocols_Var_Samp_Fields>
	variance?: Maybe<Data_Network_Protocols_Variance_Fields>
}

/** aggregate fields of "data.network_protocols" */
export type Data_Network_Protocols_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Data_Network_Protocols_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type Data_Network_Protocols_Avg_Fields = {
	__typename?: "data_network_protocols_avg_fields"
	id?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "data.network_protocols". All fields are combined with a logical 'AND'. */
export type Data_Network_Protocols_Bool_Exp = {
	_and?: InputMaybe<Array<Data_Network_Protocols_Bool_Exp>>
	_not?: InputMaybe<Data_Network_Protocols_Bool_Exp>
	_or?: InputMaybe<Array<Data_Network_Protocols_Bool_Exp>>
	id?: InputMaybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "data.network_protocols" */
export enum Data_Network_Protocols_Constraint {
	/** unique or primary key constraint on columns "id" */
	NetworkProtocolsPkey = "network_protocols_pkey",
}

/** input type for incrementing numeric columns in table "data.network_protocols" */
export type Data_Network_Protocols_Inc_Input = {
	id?: InputMaybe<Scalars["Int"]>
}

/** input type for inserting data into table "data.network_protocols" */
export type Data_Network_Protocols_Insert_Input = {
	id?: InputMaybe<Scalars["Int"]>
}

/** aggregate max on columns */
export type Data_Network_Protocols_Max_Fields = {
	__typename?: "data_network_protocols_max_fields"
	id?: Maybe<Scalars["Int"]>
}

/** aggregate min on columns */
export type Data_Network_Protocols_Min_Fields = {
	__typename?: "data_network_protocols_min_fields"
	id?: Maybe<Scalars["Int"]>
}

/** response of any mutation on the table "data.network_protocols" */
export type Data_Network_Protocols_Mutation_Response = {
	__typename?: "data_network_protocols_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<Data_Network_Protocols>
}

/** on_conflict condition type for table "data.network_protocols" */
export type Data_Network_Protocols_On_Conflict = {
	constraint: Data_Network_Protocols_Constraint
	update_columns?: Array<Data_Network_Protocols_Update_Column>
	where?: InputMaybe<Data_Network_Protocols_Bool_Exp>
}

/** Ordering options when selecting data from "data.network_protocols". */
export type Data_Network_Protocols_Order_By = {
	id?: InputMaybe<Order_By>
}

/** primary key columns input for table: data_network_protocols */
export type Data_Network_Protocols_Pk_Columns_Input = {
	id: Scalars["Int"]
}

/** select columns of table "data.network_protocols" */
export enum Data_Network_Protocols_Select_Column {
	/** column name */
	Id = "id",
}

/** input type for updating data in table "data.network_protocols" */
export type Data_Network_Protocols_Set_Input = {
	id?: InputMaybe<Scalars["Int"]>
}

/** aggregate stddev on columns */
export type Data_Network_Protocols_Stddev_Fields = {
	__typename?: "data_network_protocols_stddev_fields"
	id?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type Data_Network_Protocols_Stddev_Pop_Fields = {
	__typename?: "data_network_protocols_stddev_pop_fields"
	id?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type Data_Network_Protocols_Stddev_Samp_Fields = {
	__typename?: "data_network_protocols_stddev_samp_fields"
	id?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type Data_Network_Protocols_Sum_Fields = {
	__typename?: "data_network_protocols_sum_fields"
	id?: Maybe<Scalars["Int"]>
}

/** update columns of table "data.network_protocols" */
export enum Data_Network_Protocols_Update_Column {
	/** column name */
	Id = "id",
}

/** aggregate var_pop on columns */
export type Data_Network_Protocols_Var_Pop_Fields = {
	__typename?: "data_network_protocols_var_pop_fields"
	id?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type Data_Network_Protocols_Var_Samp_Fields = {
	__typename?: "data_network_protocols_var_samp_fields"
	id?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type Data_Network_Protocols_Variance_Fields = {
	__typename?: "data_network_protocols_variance_fields"
	id?: Maybe<Scalars["Float"]>
}

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["json"]>
	_gt?: InputMaybe<Scalars["json"]>
	_gte?: InputMaybe<Scalars["json"]>
	_in?: InputMaybe<Array<Scalars["json"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["json"]>
	_lte?: InputMaybe<Scalars["json"]>
	_neq?: InputMaybe<Scalars["json"]>
	_nin?: InputMaybe<Array<Scalars["json"]>>
}

export type Jsonb_Cast_Exp = {
	String?: InputMaybe<String_Comparison_Exp>
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
	_cast?: InputMaybe<Jsonb_Cast_Exp>
	/** is the column contained in the given json value */
	_contained_in?: InputMaybe<Scalars["jsonb"]>
	/** does the column contain the given json value at the top level */
	_contains?: InputMaybe<Scalars["jsonb"]>
	_eq?: InputMaybe<Scalars["jsonb"]>
	_gt?: InputMaybe<Scalars["jsonb"]>
	_gte?: InputMaybe<Scalars["jsonb"]>
	/** does the string exist as a top-level key in the column */
	_has_key?: InputMaybe<Scalars["String"]>
	/** do all of these strings exist as top-level keys in the column */
	_has_keys_all?: InputMaybe<Array<Scalars["String"]>>
	/** do any of these strings exist as top-level keys in the column */
	_has_keys_any?: InputMaybe<Array<Scalars["String"]>>
	_in?: InputMaybe<Array<Scalars["jsonb"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["jsonb"]>
	_lte?: InputMaybe<Scalars["jsonb"]>
	_neq?: InputMaybe<Scalars["jsonb"]>
	_nin?: InputMaybe<Array<Scalars["jsonb"]>>
}

/** mutation root */
export type Mutation_Root = {
	__typename?: "mutation_root"
	/** Deprecated:  use delete_core_Project (action). */
	delete_by_pk_core_Project_deprecated?: Maybe<Core_Project>
	/** Deprecated:  use delete_core_Tenant (action). */
	delete_by_pk_core_Tenant_deprecated?: Maybe<Core_Tenant>
	/** Deprecated:  use delete_core_User (action). */
	delete_by_pk_core_User_deprecated?: Maybe<Core_User>
	/** delete data from the table: "core.ImportOperation" */
	delete_core_ImportOperation?: Maybe<Core_ImportOperation_Mutation_Response>
	/** delete single row from the table: "core.ImportOperation" */
	delete_core_ImportOperation_by_pk?: Maybe<Core_ImportOperation>
	/** delete data from the table: "core.MigrationHistory" */
	delete_core_MigrationHistory?: Maybe<Core_MigrationHistory_Mutation_Response>
	/** delete single row from the table: "core.MigrationHistory" */
	delete_core_MigrationHistory_by_pk?: Maybe<Core_MigrationHistory>
	/** Deletes a Project given a Project ID (required).  Project must be owned by the JWT user. */
	delete_core_Project?: Maybe<DeleteProjectOutput>
	/** Deprecated:  use delete_core_Project (action). */
	delete_core_Project_deprecated?: Maybe<Core_Project_Mutation_Response>
	/** delete data from the table: "core.Schemas" */
	delete_core_Schemas?: Maybe<Core_Schemas_Mutation_Response>
	/** delete single row from the table: "core.Schemas" */
	delete_core_Schemas_by_pk?: Maybe<Core_Schemas>
	/** Deletes a Tenant given a tenant ID (required) */
	delete_core_Tenant?: Maybe<DeleteUserOutput>
	/** delete data from the table: "core.TenantUser" */
	delete_core_TenantUser?: Maybe<Core_TenantUser_Mutation_Response>
	/** delete single row from the table: "core.TenantUser" */
	delete_core_TenantUser_by_pk?: Maybe<Core_TenantUser>
	/** Deprecated:  use delete_core_Tenant (action). */
	delete_core_Tenant_deprecated?: Maybe<Core_Tenant_Mutation_Response>
	/** Deletes a User given a Project ID (required) */
	delete_core_User?: Maybe<DeleteUserOutput>
	/** Deprecated:  use delete_core_User (action). */
	delete_core_User_deprecated?: Maybe<Core_User_Mutation_Response>
	/** delete data from the table: "data.asset_roles" */
	delete_data_asset_roles?: Maybe<Data_Asset_Roles_Mutation_Response>
	/** delete single row from the table: "data.asset_roles" */
	delete_data_asset_roles_by_pk?: Maybe<Data_Asset_Roles>
	/** delete data from the table: "data.asset_types" */
	delete_data_asset_types?: Maybe<Data_Asset_Types_Mutation_Response>
	/** delete single row from the table: "data.asset_types" */
	delete_data_asset_types_by_pk?: Maybe<Data_Asset_Types>
	/** delete data from the table: "data.cves" */
	delete_data_cves?: Maybe<Data_Cves_Mutation_Response>
	/** delete single row from the table: "data.cves" */
	delete_data_cves_by_pk?: Maybe<Data_Cves>
	/** delete data from the table: "data.network_protocols" */
	delete_data_network_protocols?: Maybe<Data_Network_Protocols_Mutation_Response>
	/** delete single row from the table: "data.network_protocols" */
	delete_data_network_protocols_by_pk?: Maybe<Data_Network_Protocols>
	/** delete data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
	delete_p_asset_applications?: Maybe<P_Asset_Applications_Mutation_Response>
	/** delete single row from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
	delete_p_asset_applications_by_pk?: Maybe<P_Asset_Applications>
	/** delete data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
	delete_p_asset_badges?: Maybe<P_Asset_Badges_Mutation_Response>
	/** delete single row from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
	delete_p_asset_badges_by_pk?: Maybe<P_Asset_Badges>
	/** delete data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
	delete_p_asset_cves?: Maybe<P_Asset_Cves_Mutation_Response>
	/** delete single row from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
	delete_p_asset_cves_by_pk?: Maybe<P_Asset_Cves>
	/** delete data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
	delete_p_asset_distance?: Maybe<P_Asset_Distance_Mutation_Response>
	/** delete single row from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
	delete_p_asset_distance_by_pk?: Maybe<P_Asset_Distance>
	/** delete data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
	delete_p_asset_hardware?: Maybe<P_Asset_Hardware_Mutation_Response>
	/** delete single row from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
	delete_p_asset_hardware_by_pk?: Maybe<P_Asset_Hardware>
	/** delete data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
	delete_p_asset_hostnames?: Maybe<P_Asset_Hostnames_Mutation_Response>
	/** delete single row from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
	delete_p_asset_hostnames_by_pk?: Maybe<P_Asset_Hostnames>
	/** delete data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
	delete_p_asset_interfaces?: Maybe<P_Asset_Interfaces_Mutation_Response>
	/** delete single row from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
	delete_p_asset_interfaces_by_pk?: Maybe<P_Asset_Interfaces>
	/** delete data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
	delete_p_asset_os?: Maybe<P_Asset_Os_Mutation_Response>
	/** delete single row from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
	delete_p_asset_os_by_pk?: Maybe<P_Asset_Os>
	/** delete data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
	delete_p_asset_roles?: Maybe<P_Asset_Roles_Mutation_Response>
	/** delete single row from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
	delete_p_asset_roles_by_pk?: Maybe<P_Asset_Roles>
	/** delete data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
	delete_p_asset_services?: Maybe<P_Asset_Services_Mutation_Response>
	/** delete single row from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
	delete_p_asset_services_by_pk?: Maybe<P_Asset_Services>
	/** delete data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
	delete_p_asset_tags?: Maybe<P_Asset_Tags_Mutation_Response>
	/** delete single row from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
	delete_p_asset_tags_by_pk?: Maybe<P_Asset_Tags>
	/** delete data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
	delete_p_assets?: Maybe<P_Assets_Mutation_Response>
	/** delete single row from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
	delete_p_assets_by_pk?: Maybe<P_Assets>
	/** delete data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
	delete_p_broadcast_domains?: Maybe<P_Broadcast_Domains_Mutation_Response>
	/** delete single row from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
	delete_p_broadcast_domains_by_pk?: Maybe<P_Broadcast_Domains>
	/** delete data from the table: "tenant_14bed87a7c.project_b8d8cd96_packets" */
	delete_tenant_14bed87a7c_project_b8d8cd96_packets?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Mutation_Response>
	/** insert data into the table: "core.ImportOperation" */
	insert_core_ImportOperation?: Maybe<Core_ImportOperation_Mutation_Response>
	/** insert a single row into the table: "core.ImportOperation" */
	insert_core_ImportOperation_one?: Maybe<Core_ImportOperation>
	/** insert data into the table: "core.MigrationHistory" */
	insert_core_MigrationHistory?: Maybe<Core_MigrationHistory_Mutation_Response>
	/** insert a single row into the table: "core.MigrationHistory" */
	insert_core_MigrationHistory_one?: Maybe<Core_MigrationHistory>
	/** Deprecated:  use insert_core_Project_one (action) */
	insert_core_Project_deprecated?: Maybe<Core_Project_Mutation_Response>
	/** Creates Project given a name (requried) and tags (optional).  All projects are created in the JWT users persoanl tenant */
	insert_core_Project_one?: Maybe<CreateProjectOutput>
	/** Deprecated:  use insert_core_Project_one (action) */
	insert_core_Project_one_deprecated?: Maybe<Core_Project>
	/** insert data into the table: "core.Schemas" */
	insert_core_Schemas?: Maybe<Core_Schemas_Mutation_Response>
	/** insert a single row into the table: "core.Schemas" */
	insert_core_Schemas_one?: Maybe<Core_Schemas>
	/** insert data into the table: "core.TenantUser" */
	insert_core_TenantUser?: Maybe<Core_TenantUser_Mutation_Response>
	/** insert a single row into the table: "core.TenantUser" */
	insert_core_TenantUser_one?: Maybe<Core_TenantUser>
	/** Deprecated:  use insert_core_Tenant_one (action) */
	insert_core_Tenant_deprecated?: Maybe<Core_Tenant_Mutation_Response>
	/** Creates tenant with supplied name (required) and type.  If type is not provided, will be set to Unknown.  Returns new tenant including id. */
	insert_core_Tenant_one?: Maybe<CreateTenantOutput>
	/** Deprecated:  use insert_core_Tenant_one (action) */
	insert_core_Tenant_one_deprecated?: Maybe<Core_Tenant>
	/** Deprecated:  use insert_core_User_one (action) */
	insert_core_User_deprecated?: Maybe<Core_User_Mutation_Response>
	/** Creates user given a base64 encoded auth0UserId (requried) and returns the new user.  If the user already exists, the current user will be returned. */
	insert_core_User_one?: Maybe<CreateUserOutput>
	/** Deprecated:  use insert_core_User_one (action) */
	insert_core_User_one_deprecated?: Maybe<Core_User>
	/** insert data into the table: "data.asset_roles" */
	insert_data_asset_roles?: Maybe<Data_Asset_Roles_Mutation_Response>
	/** insert a single row into the table: "data.asset_roles" */
	insert_data_asset_roles_one?: Maybe<Data_Asset_Roles>
	/** insert data into the table: "data.asset_types" */
	insert_data_asset_types?: Maybe<Data_Asset_Types_Mutation_Response>
	/** insert a single row into the table: "data.asset_types" */
	insert_data_asset_types_one?: Maybe<Data_Asset_Types>
	/** insert data into the table: "data.cves" */
	insert_data_cves?: Maybe<Data_Cves_Mutation_Response>
	/** insert a single row into the table: "data.cves" */
	insert_data_cves_one?: Maybe<Data_Cves>
	/** insert data into the table: "data.network_protocols" */
	insert_data_network_protocols?: Maybe<Data_Network_Protocols_Mutation_Response>
	/** insert a single row into the table: "data.network_protocols" */
	insert_data_network_protocols_one?: Maybe<Data_Network_Protocols>
	/** insert data into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
	insert_p_asset_applications?: Maybe<P_Asset_Applications_Mutation_Response>
	/** insert a single row into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
	insert_p_asset_applications_one?: Maybe<P_Asset_Applications>
	/** insert data into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
	insert_p_asset_badges?: Maybe<P_Asset_Badges_Mutation_Response>
	/** insert a single row into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
	insert_p_asset_badges_one?: Maybe<P_Asset_Badges>
	/** insert data into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
	insert_p_asset_cves?: Maybe<P_Asset_Cves_Mutation_Response>
	/** insert a single row into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
	insert_p_asset_cves_one?: Maybe<P_Asset_Cves>
	/** insert data into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
	insert_p_asset_distance?: Maybe<P_Asset_Distance_Mutation_Response>
	/** insert a single row into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
	insert_p_asset_distance_one?: Maybe<P_Asset_Distance>
	/** insert data into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
	insert_p_asset_hardware?: Maybe<P_Asset_Hardware_Mutation_Response>
	/** insert a single row into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
	insert_p_asset_hardware_one?: Maybe<P_Asset_Hardware>
	/** insert data into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
	insert_p_asset_hostnames?: Maybe<P_Asset_Hostnames_Mutation_Response>
	/** insert a single row into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
	insert_p_asset_hostnames_one?: Maybe<P_Asset_Hostnames>
	/** insert data into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
	insert_p_asset_interfaces?: Maybe<P_Asset_Interfaces_Mutation_Response>
	/** insert a single row into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
	insert_p_asset_interfaces_one?: Maybe<P_Asset_Interfaces>
	/** insert data into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
	insert_p_asset_os?: Maybe<P_Asset_Os_Mutation_Response>
	/** insert a single row into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
	insert_p_asset_os_one?: Maybe<P_Asset_Os>
	/** insert data into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
	insert_p_asset_roles?: Maybe<P_Asset_Roles_Mutation_Response>
	/** insert a single row into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
	insert_p_asset_roles_one?: Maybe<P_Asset_Roles>
	/** insert data into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
	insert_p_asset_services?: Maybe<P_Asset_Services_Mutation_Response>
	/** insert a single row into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
	insert_p_asset_services_one?: Maybe<P_Asset_Services>
	/** insert data into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
	insert_p_asset_tags?: Maybe<P_Asset_Tags_Mutation_Response>
	/** insert a single row into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
	insert_p_asset_tags_one?: Maybe<P_Asset_Tags>
	/** insert data into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
	insert_p_assets?: Maybe<P_Assets_Mutation_Response>
	/** insert a single row into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
	insert_p_assets_one?: Maybe<P_Assets>
	/** insert data into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
	insert_p_broadcast_domains?: Maybe<P_Broadcast_Domains_Mutation_Response>
	/** insert a single row into the table: "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
	insert_p_broadcast_domains_one?: Maybe<P_Broadcast_Domains>
	/** insert data into the table: "tenant_14bed87a7c.project_b8d8cd96_packets" */
	insert_tenant_14bed87a7c_project_b8d8cd96_packets?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Mutation_Response>
	/** insert a single row into the table: "tenant_14bed87a7c.project_b8d8cd96_packets" */
	insert_tenant_14bed87a7c_project_b8d8cd96_packets_one?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets>
	/** update data of the table: "core.ImportOperation" */
	update_core_ImportOperation?: Maybe<Core_ImportOperation_Mutation_Response>
	/** update single row of the table: "core.ImportOperation" */
	update_core_ImportOperation_by_pk?: Maybe<Core_ImportOperation>
	/** update data of the table: "core.MigrationHistory" */
	update_core_MigrationHistory?: Maybe<Core_MigrationHistory_Mutation_Response>
	/** update single row of the table: "core.MigrationHistory" */
	update_core_MigrationHistory_by_pk?: Maybe<Core_MigrationHistory>
	/** update data of the table: "core.Project" */
	update_core_Project?: Maybe<Core_Project_Mutation_Response>
	/** update single row of the table: "core.Project" */
	update_core_Project_by_pk?: Maybe<Core_Project>
	/** update data of the table: "core.Schemas" */
	update_core_Schemas?: Maybe<Core_Schemas_Mutation_Response>
	/** update single row of the table: "core.Schemas" */
	update_core_Schemas_by_pk?: Maybe<Core_Schemas>
	/** update data of the table: "core.Tenant" */
	update_core_Tenant?: Maybe<Core_Tenant_Mutation_Response>
	/** update data of the table: "core.TenantUser" */
	update_core_TenantUser?: Maybe<Core_TenantUser_Mutation_Response>
	/** update single row of the table: "core.TenantUser" */
	update_core_TenantUser_by_pk?: Maybe<Core_TenantUser>
	/** update single row of the table: "core.Tenant" */
	update_core_Tenant_by_pk?: Maybe<Core_Tenant>
	/** update data of the table: "core.User" */
	update_core_User?: Maybe<Core_User_Mutation_Response>
	/** update single row of the table: "core.User" */
	update_core_User_by_pk?: Maybe<Core_User>
	/** update data of the table: "data.asset_roles" */
	update_data_asset_roles?: Maybe<Data_Asset_Roles_Mutation_Response>
	/** update single row of the table: "data.asset_roles" */
	update_data_asset_roles_by_pk?: Maybe<Data_Asset_Roles>
	/** update data of the table: "data.asset_types" */
	update_data_asset_types?: Maybe<Data_Asset_Types_Mutation_Response>
	/** update single row of the table: "data.asset_types" */
	update_data_asset_types_by_pk?: Maybe<Data_Asset_Types>
	/** update data of the table: "data.cves" */
	update_data_cves?: Maybe<Data_Cves_Mutation_Response>
	/** update single row of the table: "data.cves" */
	update_data_cves_by_pk?: Maybe<Data_Cves>
	/** update data of the table: "data.network_protocols" */
	update_data_network_protocols?: Maybe<Data_Network_Protocols_Mutation_Response>
	/** update single row of the table: "data.network_protocols" */
	update_data_network_protocols_by_pk?: Maybe<Data_Network_Protocols>
	/** update data of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
	update_p_asset_applications?: Maybe<P_Asset_Applications_Mutation_Response>
	/** update single row of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
	update_p_asset_applications_by_pk?: Maybe<P_Asset_Applications>
	/** update data of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
	update_p_asset_badges?: Maybe<P_Asset_Badges_Mutation_Response>
	/** update single row of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
	update_p_asset_badges_by_pk?: Maybe<P_Asset_Badges>
	/** update data of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
	update_p_asset_cves?: Maybe<P_Asset_Cves_Mutation_Response>
	/** update single row of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
	update_p_asset_cves_by_pk?: Maybe<P_Asset_Cves>
	/** update data of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
	update_p_asset_distance?: Maybe<P_Asset_Distance_Mutation_Response>
	/** update single row of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
	update_p_asset_distance_by_pk?: Maybe<P_Asset_Distance>
	/** update data of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
	update_p_asset_hardware?: Maybe<P_Asset_Hardware_Mutation_Response>
	/** update single row of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
	update_p_asset_hardware_by_pk?: Maybe<P_Asset_Hardware>
	/** update data of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
	update_p_asset_hostnames?: Maybe<P_Asset_Hostnames_Mutation_Response>
	/** update single row of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
	update_p_asset_hostnames_by_pk?: Maybe<P_Asset_Hostnames>
	/** update data of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
	update_p_asset_interfaces?: Maybe<P_Asset_Interfaces_Mutation_Response>
	/** update single row of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
	update_p_asset_interfaces_by_pk?: Maybe<P_Asset_Interfaces>
	/** update data of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
	update_p_asset_os?: Maybe<P_Asset_Os_Mutation_Response>
	/** update single row of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
	update_p_asset_os_by_pk?: Maybe<P_Asset_Os>
	/** update data of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
	update_p_asset_roles?: Maybe<P_Asset_Roles_Mutation_Response>
	/** update single row of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
	update_p_asset_roles_by_pk?: Maybe<P_Asset_Roles>
	/** update data of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
	update_p_asset_services?: Maybe<P_Asset_Services_Mutation_Response>
	/** update single row of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
	update_p_asset_services_by_pk?: Maybe<P_Asset_Services>
	/** update data of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
	update_p_asset_tags?: Maybe<P_Asset_Tags_Mutation_Response>
	/** update single row of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
	update_p_asset_tags_by_pk?: Maybe<P_Asset_Tags>
	/** update data of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
	update_p_assets?: Maybe<P_Assets_Mutation_Response>
	/** update single row of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
	update_p_assets_by_pk?: Maybe<P_Assets>
	/** update data of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
	update_p_broadcast_domains?: Maybe<P_Broadcast_Domains_Mutation_Response>
	/** update single row of the table: "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
	update_p_broadcast_domains_by_pk?: Maybe<P_Broadcast_Domains>
	/** update data of the table: "tenant_14bed87a7c.project_b8d8cd96_packets" */
	update_tenant_14bed87a7c_project_b8d8cd96_packets?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Mutation_Response>
}

/** mutation root */
export type Mutation_RootDelete_By_Pk_Core_Project_DeprecatedArgs = {
	id: Scalars["String"]
}

/** mutation root */
export type Mutation_RootDelete_By_Pk_Core_Tenant_DeprecatedArgs = {
	id: Scalars["String"]
}

/** mutation root */
export type Mutation_RootDelete_By_Pk_Core_User_DeprecatedArgs = {
	id: Scalars["String"]
}

/** mutation root */
export type Mutation_RootDelete_Core_ImportOperationArgs = {
	where: Core_ImportOperation_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Core_ImportOperation_By_PkArgs = {
	id: Scalars["String"]
}

/** mutation root */
export type Mutation_RootDelete_Core_MigrationHistoryArgs = {
	where: Core_MigrationHistory_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Core_MigrationHistory_By_PkArgs = {
	id: Scalars["bigint"]
}

/** mutation root */
export type Mutation_RootDelete_Core_ProjectArgs = {
	project: DeleteProjectInput
}

/** mutation root */
export type Mutation_RootDelete_Core_Project_DeprecatedArgs = {
	where: Core_Project_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Core_SchemasArgs = {
	where: Core_Schemas_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Core_Schemas_By_PkArgs = {
	name: Scalars["String"]
}

/** mutation root */
export type Mutation_RootDelete_Core_TenantArgs = {
	tenant: DeleteUserInput
}

/** mutation root */
export type Mutation_RootDelete_Core_TenantUserArgs = {
	where: Core_TenantUser_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Core_TenantUser_By_PkArgs = {
	tenantID: Scalars["String"]
	userID: Scalars["String"]
}

/** mutation root */
export type Mutation_RootDelete_Core_Tenant_DeprecatedArgs = {
	where: Core_Tenant_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Core_UserArgs = {
	user: DeleteUserInput
}

/** mutation root */
export type Mutation_RootDelete_Core_User_DeprecatedArgs = {
	where: Core_User_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Data_Asset_RolesArgs = {
	where: Data_Asset_Roles_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Data_Asset_Roles_By_PkArgs = {
	id: Scalars["String"]
}

/** mutation root */
export type Mutation_RootDelete_Data_Asset_TypesArgs = {
	where: Data_Asset_Types_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Data_Asset_Types_By_PkArgs = {
	id: Scalars["Int"]
}

/** mutation root */
export type Mutation_RootDelete_Data_CvesArgs = {
	where: Data_Cves_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Data_Cves_By_PkArgs = {
	id: Scalars["String"]
}

/** mutation root */
export type Mutation_RootDelete_Data_Network_ProtocolsArgs = {
	where: Data_Network_Protocols_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Data_Network_Protocols_By_PkArgs = {
	id: Scalars["Int"]
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_ApplicationsArgs = {
	where: P_Asset_Applications_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_Applications_By_PkArgs = {
	id: Scalars["bigint"]
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_BadgesArgs = {
	where: P_Asset_Badges_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_Badges_By_PkArgs = {
	id: Scalars["bigint"]
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_CvesArgs = {
	where: P_Asset_Cves_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_Cves_By_PkArgs = {
	id: Scalars["bigint"]
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_DistanceArgs = {
	where: P_Asset_Distance_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_Distance_By_PkArgs = {
	id: Scalars["bigint"]
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_HardwareArgs = {
	where: P_Asset_Hardware_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_Hardware_By_PkArgs = {
	id: Scalars["bigint"]
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_HostnamesArgs = {
	where: P_Asset_Hostnames_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_Hostnames_By_PkArgs = {
	id: Scalars["bigint"]
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_InterfacesArgs = {
	where: P_Asset_Interfaces_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_Interfaces_By_PkArgs = {
	id: Scalars["bigint"]
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_OsArgs = {
	where: P_Asset_Os_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_Os_By_PkArgs = {
	id: Scalars["bigint"]
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_RolesArgs = {
	where: P_Asset_Roles_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_Roles_By_PkArgs = {
	id: Scalars["bigint"]
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_ServicesArgs = {
	where: P_Asset_Services_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_Services_By_PkArgs = {
	id: Scalars["bigint"]
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_TagsArgs = {
	where: P_Asset_Tags_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P_Asset_Tags_By_PkArgs = {
	id: Scalars["bigint"]
}

/** mutation root */
export type Mutation_RootDelete_P_AssetsArgs = {
	where: P_Assets_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P_Assets_By_PkArgs = {
	id: Scalars["bigint"]
}

/** mutation root */
export type Mutation_RootDelete_P_Broadcast_DomainsArgs = {
	where: P_Broadcast_Domains_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_P_Broadcast_Domains_By_PkArgs = {
	id: Scalars["bigint"]
}

/** mutation root */
export type Mutation_RootDelete_Tenant_14bed87a7c_Project_B8d8cd96_PacketsArgs =
	{
		where: Tenant_14bed87a7c_Project_B8d8cd96_Packets_Bool_Exp
	}

/** mutation root */
export type Mutation_RootInsert_Core_ImportOperationArgs = {
	objects: Array<Core_ImportOperation_Insert_Input>
	on_conflict?: InputMaybe<Core_ImportOperation_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Core_ImportOperation_OneArgs = {
	object: Core_ImportOperation_Insert_Input
	on_conflict?: InputMaybe<Core_ImportOperation_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Core_MigrationHistoryArgs = {
	objects: Array<Core_MigrationHistory_Insert_Input>
	on_conflict?: InputMaybe<Core_MigrationHistory_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Core_MigrationHistory_OneArgs = {
	object: Core_MigrationHistory_Insert_Input
	on_conflict?: InputMaybe<Core_MigrationHistory_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Core_Project_DeprecatedArgs = {
	objects: Array<Core_Project_Insert_Input>
	on_conflict?: InputMaybe<Core_Project_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Core_Project_OneArgs = {
	project: CreateProjectInput
}

/** mutation root */
export type Mutation_RootInsert_Core_Project_One_DeprecatedArgs = {
	object: Core_Project_Insert_Input
	on_conflict?: InputMaybe<Core_Project_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Core_SchemasArgs = {
	objects: Array<Core_Schemas_Insert_Input>
	on_conflict?: InputMaybe<Core_Schemas_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Core_Schemas_OneArgs = {
	object: Core_Schemas_Insert_Input
	on_conflict?: InputMaybe<Core_Schemas_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Core_TenantUserArgs = {
	objects: Array<Core_TenantUser_Insert_Input>
	on_conflict?: InputMaybe<Core_TenantUser_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Core_TenantUser_OneArgs = {
	object: Core_TenantUser_Insert_Input
	on_conflict?: InputMaybe<Core_TenantUser_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Core_Tenant_DeprecatedArgs = {
	objects: Array<Core_Tenant_Insert_Input>
	on_conflict?: InputMaybe<Core_Tenant_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Core_Tenant_OneArgs = {
	tenant: CreateTenantInput
}

/** mutation root */
export type Mutation_RootInsert_Core_Tenant_One_DeprecatedArgs = {
	object: Core_Tenant_Insert_Input
	on_conflict?: InputMaybe<Core_Tenant_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Core_User_DeprecatedArgs = {
	objects: Array<Core_User_Insert_Input>
	on_conflict?: InputMaybe<Core_User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Core_User_OneArgs = {
	user: CreateUserInput
}

/** mutation root */
export type Mutation_RootInsert_Core_User_One_DeprecatedArgs = {
	object: Core_User_Insert_Input
	on_conflict?: InputMaybe<Core_User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Data_Asset_RolesArgs = {
	objects: Array<Data_Asset_Roles_Insert_Input>
	on_conflict?: InputMaybe<Data_Asset_Roles_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Data_Asset_Roles_OneArgs = {
	object: Data_Asset_Roles_Insert_Input
	on_conflict?: InputMaybe<Data_Asset_Roles_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Data_Asset_TypesArgs = {
	objects: Array<Data_Asset_Types_Insert_Input>
	on_conflict?: InputMaybe<Data_Asset_Types_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Data_Asset_Types_OneArgs = {
	object: Data_Asset_Types_Insert_Input
	on_conflict?: InputMaybe<Data_Asset_Types_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Data_CvesArgs = {
	objects: Array<Data_Cves_Insert_Input>
	on_conflict?: InputMaybe<Data_Cves_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Data_Cves_OneArgs = {
	object: Data_Cves_Insert_Input
	on_conflict?: InputMaybe<Data_Cves_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Data_Network_ProtocolsArgs = {
	objects: Array<Data_Network_Protocols_Insert_Input>
	on_conflict?: InputMaybe<Data_Network_Protocols_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Data_Network_Protocols_OneArgs = {
	object: Data_Network_Protocols_Insert_Input
	on_conflict?: InputMaybe<Data_Network_Protocols_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_ApplicationsArgs = {
	objects: Array<P_Asset_Applications_Insert_Input>
	on_conflict?: InputMaybe<P_Asset_Applications_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_Applications_OneArgs = {
	object: P_Asset_Applications_Insert_Input
	on_conflict?: InputMaybe<P_Asset_Applications_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_BadgesArgs = {
	objects: Array<P_Asset_Badges_Insert_Input>
	on_conflict?: InputMaybe<P_Asset_Badges_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_Badges_OneArgs = {
	object: P_Asset_Badges_Insert_Input
	on_conflict?: InputMaybe<P_Asset_Badges_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_CvesArgs = {
	objects: Array<P_Asset_Cves_Insert_Input>
	on_conflict?: InputMaybe<P_Asset_Cves_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_Cves_OneArgs = {
	object: P_Asset_Cves_Insert_Input
	on_conflict?: InputMaybe<P_Asset_Cves_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_DistanceArgs = {
	objects: Array<P_Asset_Distance_Insert_Input>
	on_conflict?: InputMaybe<P_Asset_Distance_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_Distance_OneArgs = {
	object: P_Asset_Distance_Insert_Input
	on_conflict?: InputMaybe<P_Asset_Distance_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_HardwareArgs = {
	objects: Array<P_Asset_Hardware_Insert_Input>
	on_conflict?: InputMaybe<P_Asset_Hardware_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_Hardware_OneArgs = {
	object: P_Asset_Hardware_Insert_Input
	on_conflict?: InputMaybe<P_Asset_Hardware_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_HostnamesArgs = {
	objects: Array<P_Asset_Hostnames_Insert_Input>
	on_conflict?: InputMaybe<P_Asset_Hostnames_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_Hostnames_OneArgs = {
	object: P_Asset_Hostnames_Insert_Input
	on_conflict?: InputMaybe<P_Asset_Hostnames_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_InterfacesArgs = {
	objects: Array<P_Asset_Interfaces_Insert_Input>
	on_conflict?: InputMaybe<P_Asset_Interfaces_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_Interfaces_OneArgs = {
	object: P_Asset_Interfaces_Insert_Input
	on_conflict?: InputMaybe<P_Asset_Interfaces_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_OsArgs = {
	objects: Array<P_Asset_Os_Insert_Input>
	on_conflict?: InputMaybe<P_Asset_Os_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_Os_OneArgs = {
	object: P_Asset_Os_Insert_Input
	on_conflict?: InputMaybe<P_Asset_Os_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_RolesArgs = {
	objects: Array<P_Asset_Roles_Insert_Input>
	on_conflict?: InputMaybe<P_Asset_Roles_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_Roles_OneArgs = {
	object: P_Asset_Roles_Insert_Input
	on_conflict?: InputMaybe<P_Asset_Roles_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_ServicesArgs = {
	objects: Array<P_Asset_Services_Insert_Input>
	on_conflict?: InputMaybe<P_Asset_Services_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_Services_OneArgs = {
	object: P_Asset_Services_Insert_Input
	on_conflict?: InputMaybe<P_Asset_Services_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_TagsArgs = {
	objects: Array<P_Asset_Tags_Insert_Input>
	on_conflict?: InputMaybe<P_Asset_Tags_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Asset_Tags_OneArgs = {
	object: P_Asset_Tags_Insert_Input
	on_conflict?: InputMaybe<P_Asset_Tags_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_AssetsArgs = {
	objects: Array<P_Assets_Insert_Input>
	on_conflict?: InputMaybe<P_Assets_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Assets_OneArgs = {
	object: P_Assets_Insert_Input
	on_conflict?: InputMaybe<P_Assets_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Broadcast_DomainsArgs = {
	objects: Array<P_Broadcast_Domains_Insert_Input>
	on_conflict?: InputMaybe<P_Broadcast_Domains_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_P_Broadcast_Domains_OneArgs = {
	object: P_Broadcast_Domains_Insert_Input
	on_conflict?: InputMaybe<P_Broadcast_Domains_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Tenant_14bed87a7c_Project_B8d8cd96_PacketsArgs =
	{
		objects: Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Insert_Input>
	}

/** mutation root */
export type Mutation_RootInsert_Tenant_14bed87a7c_Project_B8d8cd96_Packets_OneArgs =
	{
		object: Tenant_14bed87a7c_Project_B8d8cd96_Packets_Insert_Input
	}

/** mutation root */
export type Mutation_RootUpdate_Core_ImportOperationArgs = {
	_inc?: InputMaybe<Core_ImportOperation_Inc_Input>
	_set?: InputMaybe<Core_ImportOperation_Set_Input>
	where: Core_ImportOperation_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Core_ImportOperation_By_PkArgs = {
	_inc?: InputMaybe<Core_ImportOperation_Inc_Input>
	_set?: InputMaybe<Core_ImportOperation_Set_Input>
	pk_columns: Core_ImportOperation_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Core_MigrationHistoryArgs = {
	_inc?: InputMaybe<Core_MigrationHistory_Inc_Input>
	_set?: InputMaybe<Core_MigrationHistory_Set_Input>
	where: Core_MigrationHistory_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Core_MigrationHistory_By_PkArgs = {
	_inc?: InputMaybe<Core_MigrationHistory_Inc_Input>
	_set?: InputMaybe<Core_MigrationHistory_Set_Input>
	pk_columns: Core_MigrationHistory_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Core_ProjectArgs = {
	_inc?: InputMaybe<Core_Project_Inc_Input>
	_set?: InputMaybe<Core_Project_Set_Input>
	where: Core_Project_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Core_Project_By_PkArgs = {
	_inc?: InputMaybe<Core_Project_Inc_Input>
	_set?: InputMaybe<Core_Project_Set_Input>
	pk_columns: Core_Project_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Core_SchemasArgs = {
	_inc?: InputMaybe<Core_Schemas_Inc_Input>
	_set?: InputMaybe<Core_Schemas_Set_Input>
	where: Core_Schemas_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Core_Schemas_By_PkArgs = {
	_inc?: InputMaybe<Core_Schemas_Inc_Input>
	_set?: InputMaybe<Core_Schemas_Set_Input>
	pk_columns: Core_Schemas_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Core_TenantArgs = {
	_set?: InputMaybe<Core_Tenant_Set_Input>
	where: Core_Tenant_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Core_TenantUserArgs = {
	_set?: InputMaybe<Core_TenantUser_Set_Input>
	where: Core_TenantUser_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Core_TenantUser_By_PkArgs = {
	_set?: InputMaybe<Core_TenantUser_Set_Input>
	pk_columns: Core_TenantUser_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Core_Tenant_By_PkArgs = {
	_set?: InputMaybe<Core_Tenant_Set_Input>
	pk_columns: Core_Tenant_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Core_UserArgs = {
	_set?: InputMaybe<Core_User_Set_Input>
	where: Core_User_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Core_User_By_PkArgs = {
	_set?: InputMaybe<Core_User_Set_Input>
	pk_columns: Core_User_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Data_Asset_RolesArgs = {
	_inc?: InputMaybe<Data_Asset_Roles_Inc_Input>
	_set?: InputMaybe<Data_Asset_Roles_Set_Input>
	where: Data_Asset_Roles_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Data_Asset_Roles_By_PkArgs = {
	_inc?: InputMaybe<Data_Asset_Roles_Inc_Input>
	_set?: InputMaybe<Data_Asset_Roles_Set_Input>
	pk_columns: Data_Asset_Roles_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Data_Asset_TypesArgs = {
	_inc?: InputMaybe<Data_Asset_Types_Inc_Input>
	_set?: InputMaybe<Data_Asset_Types_Set_Input>
	where: Data_Asset_Types_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Data_Asset_Types_By_PkArgs = {
	_inc?: InputMaybe<Data_Asset_Types_Inc_Input>
	_set?: InputMaybe<Data_Asset_Types_Set_Input>
	pk_columns: Data_Asset_Types_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Data_CvesArgs = {
	_inc?: InputMaybe<Data_Cves_Inc_Input>
	_set?: InputMaybe<Data_Cves_Set_Input>
	where: Data_Cves_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Data_Cves_By_PkArgs = {
	_inc?: InputMaybe<Data_Cves_Inc_Input>
	_set?: InputMaybe<Data_Cves_Set_Input>
	pk_columns: Data_Cves_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Data_Network_ProtocolsArgs = {
	_inc?: InputMaybe<Data_Network_Protocols_Inc_Input>
	_set?: InputMaybe<Data_Network_Protocols_Set_Input>
	where: Data_Network_Protocols_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Data_Network_Protocols_By_PkArgs = {
	_inc?: InputMaybe<Data_Network_Protocols_Inc_Input>
	_set?: InputMaybe<Data_Network_Protocols_Set_Input>
	pk_columns: Data_Network_Protocols_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_ApplicationsArgs = {
	_inc?: InputMaybe<P_Asset_Applications_Inc_Input>
	_set?: InputMaybe<P_Asset_Applications_Set_Input>
	where: P_Asset_Applications_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_Applications_By_PkArgs = {
	_inc?: InputMaybe<P_Asset_Applications_Inc_Input>
	_set?: InputMaybe<P_Asset_Applications_Set_Input>
	pk_columns: P_Asset_Applications_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_BadgesArgs = {
	_inc?: InputMaybe<P_Asset_Badges_Inc_Input>
	_set?: InputMaybe<P_Asset_Badges_Set_Input>
	where: P_Asset_Badges_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_Badges_By_PkArgs = {
	_inc?: InputMaybe<P_Asset_Badges_Inc_Input>
	_set?: InputMaybe<P_Asset_Badges_Set_Input>
	pk_columns: P_Asset_Badges_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_CvesArgs = {
	_inc?: InputMaybe<P_Asset_Cves_Inc_Input>
	_set?: InputMaybe<P_Asset_Cves_Set_Input>
	where: P_Asset_Cves_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_Cves_By_PkArgs = {
	_inc?: InputMaybe<P_Asset_Cves_Inc_Input>
	_set?: InputMaybe<P_Asset_Cves_Set_Input>
	pk_columns: P_Asset_Cves_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_DistanceArgs = {
	_inc?: InputMaybe<P_Asset_Distance_Inc_Input>
	_set?: InputMaybe<P_Asset_Distance_Set_Input>
	where: P_Asset_Distance_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_Distance_By_PkArgs = {
	_inc?: InputMaybe<P_Asset_Distance_Inc_Input>
	_set?: InputMaybe<P_Asset_Distance_Set_Input>
	pk_columns: P_Asset_Distance_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_HardwareArgs = {
	_inc?: InputMaybe<P_Asset_Hardware_Inc_Input>
	_set?: InputMaybe<P_Asset_Hardware_Set_Input>
	where: P_Asset_Hardware_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_Hardware_By_PkArgs = {
	_inc?: InputMaybe<P_Asset_Hardware_Inc_Input>
	_set?: InputMaybe<P_Asset_Hardware_Set_Input>
	pk_columns: P_Asset_Hardware_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_HostnamesArgs = {
	_inc?: InputMaybe<P_Asset_Hostnames_Inc_Input>
	_set?: InputMaybe<P_Asset_Hostnames_Set_Input>
	where: P_Asset_Hostnames_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_Hostnames_By_PkArgs = {
	_inc?: InputMaybe<P_Asset_Hostnames_Inc_Input>
	_set?: InputMaybe<P_Asset_Hostnames_Set_Input>
	pk_columns: P_Asset_Hostnames_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_InterfacesArgs = {
	_inc?: InputMaybe<P_Asset_Interfaces_Inc_Input>
	_set?: InputMaybe<P_Asset_Interfaces_Set_Input>
	where: P_Asset_Interfaces_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_Interfaces_By_PkArgs = {
	_inc?: InputMaybe<P_Asset_Interfaces_Inc_Input>
	_set?: InputMaybe<P_Asset_Interfaces_Set_Input>
	pk_columns: P_Asset_Interfaces_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_OsArgs = {
	_inc?: InputMaybe<P_Asset_Os_Inc_Input>
	_set?: InputMaybe<P_Asset_Os_Set_Input>
	where: P_Asset_Os_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_Os_By_PkArgs = {
	_inc?: InputMaybe<P_Asset_Os_Inc_Input>
	_set?: InputMaybe<P_Asset_Os_Set_Input>
	pk_columns: P_Asset_Os_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_RolesArgs = {
	_inc?: InputMaybe<P_Asset_Roles_Inc_Input>
	_set?: InputMaybe<P_Asset_Roles_Set_Input>
	where: P_Asset_Roles_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_Roles_By_PkArgs = {
	_inc?: InputMaybe<P_Asset_Roles_Inc_Input>
	_set?: InputMaybe<P_Asset_Roles_Set_Input>
	pk_columns: P_Asset_Roles_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_ServicesArgs = {
	_inc?: InputMaybe<P_Asset_Services_Inc_Input>
	_set?: InputMaybe<P_Asset_Services_Set_Input>
	where: P_Asset_Services_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_Services_By_PkArgs = {
	_inc?: InputMaybe<P_Asset_Services_Inc_Input>
	_set?: InputMaybe<P_Asset_Services_Set_Input>
	pk_columns: P_Asset_Services_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_TagsArgs = {
	_inc?: InputMaybe<P_Asset_Tags_Inc_Input>
	_set?: InputMaybe<P_Asset_Tags_Set_Input>
	where: P_Asset_Tags_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P_Asset_Tags_By_PkArgs = {
	_inc?: InputMaybe<P_Asset_Tags_Inc_Input>
	_set?: InputMaybe<P_Asset_Tags_Set_Input>
	pk_columns: P_Asset_Tags_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P_AssetsArgs = {
	_inc?: InputMaybe<P_Assets_Inc_Input>
	_set?: InputMaybe<P_Assets_Set_Input>
	where: P_Assets_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P_Assets_By_PkArgs = {
	_inc?: InputMaybe<P_Assets_Inc_Input>
	_set?: InputMaybe<P_Assets_Set_Input>
	pk_columns: P_Assets_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_P_Broadcast_DomainsArgs = {
	_inc?: InputMaybe<P_Broadcast_Domains_Inc_Input>
	_set?: InputMaybe<P_Broadcast_Domains_Set_Input>
	where: P_Broadcast_Domains_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_P_Broadcast_Domains_By_PkArgs = {
	_inc?: InputMaybe<P_Broadcast_Domains_Inc_Input>
	_set?: InputMaybe<P_Broadcast_Domains_Set_Input>
	pk_columns: P_Broadcast_Domains_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Tenant_14bed87a7c_Project_B8d8cd96_PacketsArgs =
	{
		_append?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Append_Input>
		_delete_at_path?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Delete_At_Path_Input>
		_delete_elem?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Delete_Elem_Input>
		_delete_key?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Delete_Key_Input>
		_inc?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Inc_Input>
		_prepend?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Prepend_Input>
		_set?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Set_Input>
		where: Tenant_14bed87a7c_Project_B8d8cd96_Packets_Bool_Exp
	}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["numeric"]>
	_gt?: InputMaybe<Scalars["numeric"]>
	_gte?: InputMaybe<Scalars["numeric"]>
	_in?: InputMaybe<Array<Scalars["numeric"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["numeric"]>
	_lte?: InputMaybe<Scalars["numeric"]>
	_neq?: InputMaybe<Scalars["numeric"]>
	_nin?: InputMaybe<Array<Scalars["numeric"]>>
}

/** column ordering options */
export enum Order_By {
	/** in ascending order, nulls last */
	Asc = "asc",
	/** in ascending order, nulls first */
	AscNullsFirst = "asc_nulls_first",
	/** in ascending order, nulls last */
	AscNullsLast = "asc_nulls_last",
	/** in descending order, nulls first */
	Desc = "desc",
	/** in descending order, nulls first */
	DescNullsFirst = "desc_nulls_first",
	/** in descending order, nulls last */
	DescNullsLast = "desc_nulls_last",
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
export type P_Asset_Applications = {
	__typename?: "p_asset_applications"
	assetid?: Maybe<Scalars["bigint"]>
	developer?: Maybe<Scalars["String"]>
	id: Scalars["bigint"]
	name?: Maybe<Scalars["String"]>
	projectid?: Maybe<Scalars["String"]>
	time: Scalars["bigint"]
	timeend: Scalars["bigint"]
	version?: Maybe<Scalars["String"]>
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
export type P_Asset_Applications_Aggregate = {
	__typename?: "p_asset_applications_aggregate"
	aggregate?: Maybe<P_Asset_Applications_Aggregate_Fields>
	nodes: Array<P_Asset_Applications>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
export type P_Asset_Applications_Aggregate_Fields = {
	__typename?: "p_asset_applications_aggregate_fields"
	avg?: Maybe<P_Asset_Applications_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<P_Asset_Applications_Max_Fields>
	min?: Maybe<P_Asset_Applications_Min_Fields>
	stddev?: Maybe<P_Asset_Applications_Stddev_Fields>
	stddev_pop?: Maybe<P_Asset_Applications_Stddev_Pop_Fields>
	stddev_samp?: Maybe<P_Asset_Applications_Stddev_Samp_Fields>
	sum?: Maybe<P_Asset_Applications_Sum_Fields>
	var_pop?: Maybe<P_Asset_Applications_Var_Pop_Fields>
	var_samp?: Maybe<P_Asset_Applications_Var_Samp_Fields>
	variance?: Maybe<P_Asset_Applications_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
export type P_Asset_Applications_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<P_Asset_Applications_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type P_Asset_Applications_Avg_Fields = {
	__typename?: "p_asset_applications_avg_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications". All fields are combined with a logical 'AND'. */
export type P_Asset_Applications_Bool_Exp = {
	_and?: InputMaybe<Array<P_Asset_Applications_Bool_Exp>>
	_not?: InputMaybe<P_Asset_Applications_Bool_Exp>
	_or?: InputMaybe<Array<P_Asset_Applications_Bool_Exp>>
	assetid?: InputMaybe<Bigint_Comparison_Exp>
	developer?: InputMaybe<String_Comparison_Exp>
	id?: InputMaybe<Bigint_Comparison_Exp>
	name?: InputMaybe<String_Comparison_Exp>
	projectid?: InputMaybe<String_Comparison_Exp>
	time?: InputMaybe<Bigint_Comparison_Exp>
	timeend?: InputMaybe<Bigint_Comparison_Exp>
	version?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
export enum P_Asset_Applications_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProjectB8d8cd96TopologyAssetApplicationsPkey = "project_b8d8cd96_topology_asset_applications_pkey",
}

/** input type for incrementing numeric columns in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
export type P_Asset_Applications_Inc_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
export type P_Asset_Applications_Insert_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	developer?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	name?: InputMaybe<Scalars["String"]>
	projectid?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
	version?: InputMaybe<Scalars["String"]>
}

/** aggregate max on columns */
export type P_Asset_Applications_Max_Fields = {
	__typename?: "p_asset_applications_max_fields"
	assetid?: Maybe<Scalars["bigint"]>
	developer?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	name?: Maybe<Scalars["String"]>
	projectid?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
	version?: Maybe<Scalars["String"]>
}

/** aggregate min on columns */
export type P_Asset_Applications_Min_Fields = {
	__typename?: "p_asset_applications_min_fields"
	assetid?: Maybe<Scalars["bigint"]>
	developer?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	name?: Maybe<Scalars["String"]>
	projectid?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
	version?: Maybe<Scalars["String"]>
}

/** response of any mutation on the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
export type P_Asset_Applications_Mutation_Response = {
	__typename?: "p_asset_applications_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<P_Asset_Applications>
}

/** on_conflict condition type for table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
export type P_Asset_Applications_On_Conflict = {
	constraint: P_Asset_Applications_Constraint
	update_columns?: Array<P_Asset_Applications_Update_Column>
	where?: InputMaybe<P_Asset_Applications_Bool_Exp>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications". */
export type P_Asset_Applications_Order_By = {
	assetid?: InputMaybe<Order_By>
	developer?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	name?: InputMaybe<Order_By>
	projectid?: InputMaybe<Order_By>
	time?: InputMaybe<Order_By>
	timeend?: InputMaybe<Order_By>
	version?: InputMaybe<Order_By>
}

/** primary key columns input for table: p1657738961587697_asset_applications */
export type P_Asset_Applications_Pk_Columns_Input = {
	id: Scalars["bigint"]
}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
export enum P_Asset_Applications_Select_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Developer = "developer",
	/** column name */
	Id = "id",
	/** column name */
	Name = "name",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
	/** column name */
	Version = "version",
}

/** input type for updating data in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
export type P_Asset_Applications_Set_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	developer?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	name?: InputMaybe<Scalars["String"]>
	projectid?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
	version?: InputMaybe<Scalars["String"]>
}

/** aggregate stddev on columns */
export type P_Asset_Applications_Stddev_Fields = {
	__typename?: "p_asset_applications_stddev_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type P_Asset_Applications_Stddev_Pop_Fields = {
	__typename?: "p_asset_applications_stddev_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type P_Asset_Applications_Stddev_Samp_Fields = {
	__typename?: "p_asset_applications_stddev_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type P_Asset_Applications_Sum_Fields = {
	__typename?: "p_asset_applications_sum_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** update columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
export enum P_Asset_Applications_Update_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Developer = "developer",
	/** column name */
	Id = "id",
	/** column name */
	Name = "name",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
	/** column name */
	Version = "version",
}

/** aggregate var_pop on columns */
export type P_Asset_Applications_Var_Pop_Fields = {
	__typename?: "p_asset_applications_var_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type P_Asset_Applications_Var_Samp_Fields = {
	__typename?: "p_asset_applications_var_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type P_Asset_Applications_Variance_Fields = {
	__typename?: "p_asset_applications_variance_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
export type P_Asset_Badges = {
	__typename?: "p_asset_badges"
	assetid?: Maybe<Scalars["bigint"]>
	id: Scalars["bigint"]
	projectid?: Maybe<Scalars["String"]>
	text?: Maybe<Scalars["String"]>
	time: Scalars["bigint"]
	timeend: Scalars["bigint"]
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
export type P_Asset_Badges_Aggregate = {
	__typename?: "p_asset_badges_aggregate"
	aggregate?: Maybe<P_Asset_Badges_Aggregate_Fields>
	nodes: Array<P_Asset_Badges>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
export type P_Asset_Badges_Aggregate_Fields = {
	__typename?: "p_asset_badges_aggregate_fields"
	avg?: Maybe<P_Asset_Badges_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<P_Asset_Badges_Max_Fields>
	min?: Maybe<P_Asset_Badges_Min_Fields>
	stddev?: Maybe<P_Asset_Badges_Stddev_Fields>
	stddev_pop?: Maybe<P_Asset_Badges_Stddev_Pop_Fields>
	stddev_samp?: Maybe<P_Asset_Badges_Stddev_Samp_Fields>
	sum?: Maybe<P_Asset_Badges_Sum_Fields>
	var_pop?: Maybe<P_Asset_Badges_Var_Pop_Fields>
	var_samp?: Maybe<P_Asset_Badges_Var_Samp_Fields>
	variance?: Maybe<P_Asset_Badges_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
export type P_Asset_Badges_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<P_Asset_Badges_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type P_Asset_Badges_Avg_Fields = {
	__typename?: "p_asset_badges_avg_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges". All fields are combined with a logical 'AND'. */
export type P_Asset_Badges_Bool_Exp = {
	_and?: InputMaybe<Array<P_Asset_Badges_Bool_Exp>>
	_not?: InputMaybe<P_Asset_Badges_Bool_Exp>
	_or?: InputMaybe<Array<P_Asset_Badges_Bool_Exp>>
	assetid?: InputMaybe<Bigint_Comparison_Exp>
	id?: InputMaybe<Bigint_Comparison_Exp>
	projectid?: InputMaybe<String_Comparison_Exp>
	text?: InputMaybe<String_Comparison_Exp>
	time?: InputMaybe<Bigint_Comparison_Exp>
	timeend?: InputMaybe<Bigint_Comparison_Exp>
}

/** unique or primary key constraints on table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
export enum P_Asset_Badges_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProjectB8d8cd96TopologyAssetBadgesPkey = "project_b8d8cd96_topology_asset_badges_pkey",
}

/** input type for incrementing numeric columns in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
export type P_Asset_Badges_Inc_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
export type P_Asset_Badges_Insert_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	text?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** aggregate max on columns */
export type P_Asset_Badges_Max_Fields = {
	__typename?: "p_asset_badges_max_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	text?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** aggregate min on columns */
export type P_Asset_Badges_Min_Fields = {
	__typename?: "p_asset_badges_min_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	text?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** response of any mutation on the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
export type P_Asset_Badges_Mutation_Response = {
	__typename?: "p_asset_badges_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<P_Asset_Badges>
}

/** on_conflict condition type for table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
export type P_Asset_Badges_On_Conflict = {
	constraint: P_Asset_Badges_Constraint
	update_columns?: Array<P_Asset_Badges_Update_Column>
	where?: InputMaybe<P_Asset_Badges_Bool_Exp>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges". */
export type P_Asset_Badges_Order_By = {
	assetid?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	projectid?: InputMaybe<Order_By>
	text?: InputMaybe<Order_By>
	time?: InputMaybe<Order_By>
	timeend?: InputMaybe<Order_By>
}

/** primary key columns input for table: p1657738961587697_asset_badges */
export type P_Asset_Badges_Pk_Columns_Input = {
	id: Scalars["bigint"]
}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
export enum P_Asset_Badges_Select_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Id = "id",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Text = "text",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
}

/** input type for updating data in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
export type P_Asset_Badges_Set_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	text?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** aggregate stddev on columns */
export type P_Asset_Badges_Stddev_Fields = {
	__typename?: "p_asset_badges_stddev_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type P_Asset_Badges_Stddev_Pop_Fields = {
	__typename?: "p_asset_badges_stddev_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type P_Asset_Badges_Stddev_Samp_Fields = {
	__typename?: "p_asset_badges_stddev_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type P_Asset_Badges_Sum_Fields = {
	__typename?: "p_asset_badges_sum_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** update columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
export enum P_Asset_Badges_Update_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Id = "id",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Text = "text",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
}

/** aggregate var_pop on columns */
export type P_Asset_Badges_Var_Pop_Fields = {
	__typename?: "p_asset_badges_var_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type P_Asset_Badges_Var_Samp_Fields = {
	__typename?: "p_asset_badges_var_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type P_Asset_Badges_Variance_Fields = {
	__typename?: "p_asset_badges_variance_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
export type P_Asset_Cves = {
	__typename?: "p_asset_cves"
	assetid: Scalars["bigint"]
	cve: Scalars["String"]
	id: Scalars["bigint"]
	projectid?: Maybe<Scalars["String"]>
	time: Scalars["bigint"]
	timeend: Scalars["bigint"]
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
export type P_Asset_Cves_Aggregate = {
	__typename?: "p_asset_cves_aggregate"
	aggregate?: Maybe<P_Asset_Cves_Aggregate_Fields>
	nodes: Array<P_Asset_Cves>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
export type P_Asset_Cves_Aggregate_Fields = {
	__typename?: "p_asset_cves_aggregate_fields"
	avg?: Maybe<P_Asset_Cves_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<P_Asset_Cves_Max_Fields>
	min?: Maybe<P_Asset_Cves_Min_Fields>
	stddev?: Maybe<P_Asset_Cves_Stddev_Fields>
	stddev_pop?: Maybe<P_Asset_Cves_Stddev_Pop_Fields>
	stddev_samp?: Maybe<P_Asset_Cves_Stddev_Samp_Fields>
	sum?: Maybe<P_Asset_Cves_Sum_Fields>
	var_pop?: Maybe<P_Asset_Cves_Var_Pop_Fields>
	var_samp?: Maybe<P_Asset_Cves_Var_Samp_Fields>
	variance?: Maybe<P_Asset_Cves_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
export type P_Asset_Cves_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<P_Asset_Cves_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type P_Asset_Cves_Avg_Fields = {
	__typename?: "p_asset_cves_avg_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves". All fields are combined with a logical 'AND'. */
export type P_Asset_Cves_Bool_Exp = {
	_and?: InputMaybe<Array<P_Asset_Cves_Bool_Exp>>
	_not?: InputMaybe<P_Asset_Cves_Bool_Exp>
	_or?: InputMaybe<Array<P_Asset_Cves_Bool_Exp>>
	assetid?: InputMaybe<Bigint_Comparison_Exp>
	cve?: InputMaybe<String_Comparison_Exp>
	id?: InputMaybe<Bigint_Comparison_Exp>
	projectid?: InputMaybe<String_Comparison_Exp>
	time?: InputMaybe<Bigint_Comparison_Exp>
	timeend?: InputMaybe<Bigint_Comparison_Exp>
}

/** unique or primary key constraints on table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
export enum P_Asset_Cves_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProjectB8d8cd96TopologyAssetCvesPkey = "project_b8d8cd96_topology_asset_cves_pkey",
}

/** input type for incrementing numeric columns in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
export type P_Asset_Cves_Inc_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
export type P_Asset_Cves_Insert_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	cve?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** aggregate max on columns */
export type P_Asset_Cves_Max_Fields = {
	__typename?: "p_asset_cves_max_fields"
	assetid?: Maybe<Scalars["bigint"]>
	cve?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** aggregate min on columns */
export type P_Asset_Cves_Min_Fields = {
	__typename?: "p_asset_cves_min_fields"
	assetid?: Maybe<Scalars["bigint"]>
	cve?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** response of any mutation on the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
export type P_Asset_Cves_Mutation_Response = {
	__typename?: "p_asset_cves_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<P_Asset_Cves>
}

/** on_conflict condition type for table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
export type P_Asset_Cves_On_Conflict = {
	constraint: P_Asset_Cves_Constraint
	update_columns?: Array<P_Asset_Cves_Update_Column>
	where?: InputMaybe<P_Asset_Cves_Bool_Exp>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves". */
export type P_Asset_Cves_Order_By = {
	assetid?: InputMaybe<Order_By>
	cve?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	projectid?: InputMaybe<Order_By>
	time?: InputMaybe<Order_By>
	timeend?: InputMaybe<Order_By>
}

/** primary key columns input for table: p1657738961587697_asset_cves */
export type P_Asset_Cves_Pk_Columns_Input = {
	id: Scalars["bigint"]
}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
export enum P_Asset_Cves_Select_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Cve = "cve",
	/** column name */
	Id = "id",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
}

/** input type for updating data in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
export type P_Asset_Cves_Set_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	cve?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** aggregate stddev on columns */
export type P_Asset_Cves_Stddev_Fields = {
	__typename?: "p_asset_cves_stddev_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type P_Asset_Cves_Stddev_Pop_Fields = {
	__typename?: "p_asset_cves_stddev_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type P_Asset_Cves_Stddev_Samp_Fields = {
	__typename?: "p_asset_cves_stddev_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type P_Asset_Cves_Sum_Fields = {
	__typename?: "p_asset_cves_sum_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** update columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
export enum P_Asset_Cves_Update_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Cve = "cve",
	/** column name */
	Id = "id",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
}

/** aggregate var_pop on columns */
export type P_Asset_Cves_Var_Pop_Fields = {
	__typename?: "p_asset_cves_var_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type P_Asset_Cves_Var_Samp_Fields = {
	__typename?: "p_asset_cves_var_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type P_Asset_Cves_Variance_Fields = {
	__typename?: "p_asset_cves_variance_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
export type P_Asset_Distance = {
	__typename?: "p_asset_distance"
	behinddeviceid?: Maybe<Scalars["bigint"]>
	hops?: Maybe<Scalars["bigint"]>
	id: Scalars["bigint"]
	networkinterfaceid?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	taplocation?: Maybe<Scalars["String"]>
	time: Scalars["bigint"]
	timeend: Scalars["bigint"]
	vlan?: Maybe<Scalars["bigint"]>
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
export type P_Asset_Distance_Aggregate = {
	__typename?: "p_asset_distance_aggregate"
	aggregate?: Maybe<P_Asset_Distance_Aggregate_Fields>
	nodes: Array<P_Asset_Distance>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
export type P_Asset_Distance_Aggregate_Fields = {
	__typename?: "p_asset_distance_aggregate_fields"
	avg?: Maybe<P_Asset_Distance_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<P_Asset_Distance_Max_Fields>
	min?: Maybe<P_Asset_Distance_Min_Fields>
	stddev?: Maybe<P_Asset_Distance_Stddev_Fields>
	stddev_pop?: Maybe<P_Asset_Distance_Stddev_Pop_Fields>
	stddev_samp?: Maybe<P_Asset_Distance_Stddev_Samp_Fields>
	sum?: Maybe<P_Asset_Distance_Sum_Fields>
	var_pop?: Maybe<P_Asset_Distance_Var_Pop_Fields>
	var_samp?: Maybe<P_Asset_Distance_Var_Samp_Fields>
	variance?: Maybe<P_Asset_Distance_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
export type P_Asset_Distance_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<P_Asset_Distance_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type P_Asset_Distance_Avg_Fields = {
	__typename?: "p_asset_distance_avg_fields"
	behinddeviceid?: Maybe<Scalars["Float"]>
	hops?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	networkinterfaceid?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
	vlan?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance". All fields are combined with a logical 'AND'. */
export type P_Asset_Distance_Bool_Exp = {
	_and?: InputMaybe<Array<P_Asset_Distance_Bool_Exp>>
	_not?: InputMaybe<P_Asset_Distance_Bool_Exp>
	_or?: InputMaybe<Array<P_Asset_Distance_Bool_Exp>>
	behinddeviceid?: InputMaybe<Bigint_Comparison_Exp>
	hops?: InputMaybe<Bigint_Comparison_Exp>
	id?: InputMaybe<Bigint_Comparison_Exp>
	networkinterfaceid?: InputMaybe<Bigint_Comparison_Exp>
	projectid?: InputMaybe<String_Comparison_Exp>
	taplocation?: InputMaybe<String_Comparison_Exp>
	time?: InputMaybe<Bigint_Comparison_Exp>
	timeend?: InputMaybe<Bigint_Comparison_Exp>
	vlan?: InputMaybe<Bigint_Comparison_Exp>
}

/** unique or primary key constraints on table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
export enum P_Asset_Distance_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProjectB8d8cd96TopologyAssetDistancePkey = "project_b8d8cd96_topology_asset_distance_pkey",
}

/** input type for incrementing numeric columns in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
export type P_Asset_Distance_Inc_Input = {
	behinddeviceid?: InputMaybe<Scalars["bigint"]>
	hops?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	networkinterfaceid?: InputMaybe<Scalars["bigint"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
	vlan?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
export type P_Asset_Distance_Insert_Input = {
	behinddeviceid?: InputMaybe<Scalars["bigint"]>
	hops?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	networkinterfaceid?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	taplocation?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
	vlan?: InputMaybe<Scalars["bigint"]>
}

/** aggregate max on columns */
export type P_Asset_Distance_Max_Fields = {
	__typename?: "p_asset_distance_max_fields"
	behinddeviceid?: Maybe<Scalars["bigint"]>
	hops?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	networkinterfaceid?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	taplocation?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
	vlan?: Maybe<Scalars["bigint"]>
}

/** aggregate min on columns */
export type P_Asset_Distance_Min_Fields = {
	__typename?: "p_asset_distance_min_fields"
	behinddeviceid?: Maybe<Scalars["bigint"]>
	hops?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	networkinterfaceid?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	taplocation?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
	vlan?: Maybe<Scalars["bigint"]>
}

/** response of any mutation on the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
export type P_Asset_Distance_Mutation_Response = {
	__typename?: "p_asset_distance_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<P_Asset_Distance>
}

/** on_conflict condition type for table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
export type P_Asset_Distance_On_Conflict = {
	constraint: P_Asset_Distance_Constraint
	update_columns?: Array<P_Asset_Distance_Update_Column>
	where?: InputMaybe<P_Asset_Distance_Bool_Exp>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance". */
export type P_Asset_Distance_Order_By = {
	behinddeviceid?: InputMaybe<Order_By>
	hops?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	networkinterfaceid?: InputMaybe<Order_By>
	projectid?: InputMaybe<Order_By>
	taplocation?: InputMaybe<Order_By>
	time?: InputMaybe<Order_By>
	timeend?: InputMaybe<Order_By>
	vlan?: InputMaybe<Order_By>
}

/** primary key columns input for table: p1657738961587697_asset_distance */
export type P_Asset_Distance_Pk_Columns_Input = {
	id: Scalars["bigint"]
}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
export enum P_Asset_Distance_Select_Column {
	/** column name */
	Behinddeviceid = "behinddeviceid",
	/** column name */
	Hops = "hops",
	/** column name */
	Id = "id",
	/** column name */
	Networkinterfaceid = "networkinterfaceid",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Taplocation = "taplocation",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
	/** column name */
	Vlan = "vlan",
}

/** input type for updating data in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
export type P_Asset_Distance_Set_Input = {
	behinddeviceid?: InputMaybe<Scalars["bigint"]>
	hops?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	networkinterfaceid?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	taplocation?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
	vlan?: InputMaybe<Scalars["bigint"]>
}

/** aggregate stddev on columns */
export type P_Asset_Distance_Stddev_Fields = {
	__typename?: "p_asset_distance_stddev_fields"
	behinddeviceid?: Maybe<Scalars["Float"]>
	hops?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	networkinterfaceid?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
	vlan?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type P_Asset_Distance_Stddev_Pop_Fields = {
	__typename?: "p_asset_distance_stddev_pop_fields"
	behinddeviceid?: Maybe<Scalars["Float"]>
	hops?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	networkinterfaceid?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
	vlan?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type P_Asset_Distance_Stddev_Samp_Fields = {
	__typename?: "p_asset_distance_stddev_samp_fields"
	behinddeviceid?: Maybe<Scalars["Float"]>
	hops?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	networkinterfaceid?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
	vlan?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type P_Asset_Distance_Sum_Fields = {
	__typename?: "p_asset_distance_sum_fields"
	behinddeviceid?: Maybe<Scalars["bigint"]>
	hops?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	networkinterfaceid?: Maybe<Scalars["bigint"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
	vlan?: Maybe<Scalars["bigint"]>
}

/** update columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
export enum P_Asset_Distance_Update_Column {
	/** column name */
	Behinddeviceid = "behinddeviceid",
	/** column name */
	Hops = "hops",
	/** column name */
	Id = "id",
	/** column name */
	Networkinterfaceid = "networkinterfaceid",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Taplocation = "taplocation",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
	/** column name */
	Vlan = "vlan",
}

/** aggregate var_pop on columns */
export type P_Asset_Distance_Var_Pop_Fields = {
	__typename?: "p_asset_distance_var_pop_fields"
	behinddeviceid?: Maybe<Scalars["Float"]>
	hops?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	networkinterfaceid?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
	vlan?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type P_Asset_Distance_Var_Samp_Fields = {
	__typename?: "p_asset_distance_var_samp_fields"
	behinddeviceid?: Maybe<Scalars["Float"]>
	hops?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	networkinterfaceid?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
	vlan?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type P_Asset_Distance_Variance_Fields = {
	__typename?: "p_asset_distance_variance_fields"
	behinddeviceid?: Maybe<Scalars["Float"]>
	hops?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	networkinterfaceid?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
	vlan?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
export type P_Asset_Hardware = {
	__typename?: "p_asset_hardware"
	assetid?: Maybe<Scalars["bigint"]>
	cputype?: Maybe<Scalars["String"]>
	device?: Maybe<Scalars["String"]>
	formfactor?: Maybe<Scalars["String"]>
	id: Scalars["bigint"]
	manufacturer?: Maybe<Scalars["String"]>
	projectid?: Maybe<Scalars["String"]>
	time: Scalars["bigint"]
	timeend: Scalars["bigint"]
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
export type P_Asset_Hardware_Aggregate = {
	__typename?: "p_asset_hardware_aggregate"
	aggregate?: Maybe<P_Asset_Hardware_Aggregate_Fields>
	nodes: Array<P_Asset_Hardware>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
export type P_Asset_Hardware_Aggregate_Fields = {
	__typename?: "p_asset_hardware_aggregate_fields"
	avg?: Maybe<P_Asset_Hardware_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<P_Asset_Hardware_Max_Fields>
	min?: Maybe<P_Asset_Hardware_Min_Fields>
	stddev?: Maybe<P_Asset_Hardware_Stddev_Fields>
	stddev_pop?: Maybe<P_Asset_Hardware_Stddev_Pop_Fields>
	stddev_samp?: Maybe<P_Asset_Hardware_Stddev_Samp_Fields>
	sum?: Maybe<P_Asset_Hardware_Sum_Fields>
	var_pop?: Maybe<P_Asset_Hardware_Var_Pop_Fields>
	var_samp?: Maybe<P_Asset_Hardware_Var_Samp_Fields>
	variance?: Maybe<P_Asset_Hardware_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
export type P_Asset_Hardware_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<P_Asset_Hardware_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type P_Asset_Hardware_Avg_Fields = {
	__typename?: "p_asset_hardware_avg_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware". All fields are combined with a logical 'AND'. */
export type P_Asset_Hardware_Bool_Exp = {
	_and?: InputMaybe<Array<P_Asset_Hardware_Bool_Exp>>
	_not?: InputMaybe<P_Asset_Hardware_Bool_Exp>
	_or?: InputMaybe<Array<P_Asset_Hardware_Bool_Exp>>
	assetid?: InputMaybe<Bigint_Comparison_Exp>
	cputype?: InputMaybe<String_Comparison_Exp>
	device?: InputMaybe<String_Comparison_Exp>
	formfactor?: InputMaybe<String_Comparison_Exp>
	id?: InputMaybe<Bigint_Comparison_Exp>
	manufacturer?: InputMaybe<String_Comparison_Exp>
	projectid?: InputMaybe<String_Comparison_Exp>
	time?: InputMaybe<Bigint_Comparison_Exp>
	timeend?: InputMaybe<Bigint_Comparison_Exp>
}

/** unique or primary key constraints on table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
export enum P_Asset_Hardware_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProjectB8d8cd96TopologyAssetHardwarePkey = "project_b8d8cd96_topology_asset_hardware_pkey",
}

/** input type for incrementing numeric columns in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
export type P_Asset_Hardware_Inc_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
export type P_Asset_Hardware_Insert_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	cputype?: InputMaybe<Scalars["String"]>
	device?: InputMaybe<Scalars["String"]>
	formfactor?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	manufacturer?: InputMaybe<Scalars["String"]>
	projectid?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** aggregate max on columns */
export type P_Asset_Hardware_Max_Fields = {
	__typename?: "p_asset_hardware_max_fields"
	assetid?: Maybe<Scalars["bigint"]>
	cputype?: Maybe<Scalars["String"]>
	device?: Maybe<Scalars["String"]>
	formfactor?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	manufacturer?: Maybe<Scalars["String"]>
	projectid?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** aggregate min on columns */
export type P_Asset_Hardware_Min_Fields = {
	__typename?: "p_asset_hardware_min_fields"
	assetid?: Maybe<Scalars["bigint"]>
	cputype?: Maybe<Scalars["String"]>
	device?: Maybe<Scalars["String"]>
	formfactor?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	manufacturer?: Maybe<Scalars["String"]>
	projectid?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** response of any mutation on the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
export type P_Asset_Hardware_Mutation_Response = {
	__typename?: "p_asset_hardware_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<P_Asset_Hardware>
}

/** on_conflict condition type for table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
export type P_Asset_Hardware_On_Conflict = {
	constraint: P_Asset_Hardware_Constraint
	update_columns?: Array<P_Asset_Hardware_Update_Column>
	where?: InputMaybe<P_Asset_Hardware_Bool_Exp>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware". */
export type P_Asset_Hardware_Order_By = {
	assetid?: InputMaybe<Order_By>
	cputype?: InputMaybe<Order_By>
	device?: InputMaybe<Order_By>
	formfactor?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	manufacturer?: InputMaybe<Order_By>
	projectid?: InputMaybe<Order_By>
	time?: InputMaybe<Order_By>
	timeend?: InputMaybe<Order_By>
}

/** primary key columns input for table: p1657738961587697_asset_hardware */
export type P_Asset_Hardware_Pk_Columns_Input = {
	id: Scalars["bigint"]
}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
export enum P_Asset_Hardware_Select_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Cputype = "cputype",
	/** column name */
	Device = "device",
	/** column name */
	Formfactor = "formfactor",
	/** column name */
	Id = "id",
	/** column name */
	Manufacturer = "manufacturer",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
}

/** input type for updating data in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
export type P_Asset_Hardware_Set_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	cputype?: InputMaybe<Scalars["String"]>
	device?: InputMaybe<Scalars["String"]>
	formfactor?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	manufacturer?: InputMaybe<Scalars["String"]>
	projectid?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** aggregate stddev on columns */
export type P_Asset_Hardware_Stddev_Fields = {
	__typename?: "p_asset_hardware_stddev_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type P_Asset_Hardware_Stddev_Pop_Fields = {
	__typename?: "p_asset_hardware_stddev_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type P_Asset_Hardware_Stddev_Samp_Fields = {
	__typename?: "p_asset_hardware_stddev_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type P_Asset_Hardware_Sum_Fields = {
	__typename?: "p_asset_hardware_sum_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** update columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
export enum P_Asset_Hardware_Update_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Cputype = "cputype",
	/** column name */
	Device = "device",
	/** column name */
	Formfactor = "formfactor",
	/** column name */
	Id = "id",
	/** column name */
	Manufacturer = "manufacturer",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
}

/** aggregate var_pop on columns */
export type P_Asset_Hardware_Var_Pop_Fields = {
	__typename?: "p_asset_hardware_var_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type P_Asset_Hardware_Var_Samp_Fields = {
	__typename?: "p_asset_hardware_var_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type P_Asset_Hardware_Variance_Fields = {
	__typename?: "p_asset_hardware_variance_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
export type P_Asset_Hostnames = {
	__typename?: "p_asset_hostnames"
	assetid?: Maybe<Scalars["bigint"]>
	domain?: Maybe<Scalars["String"]>
	hostname?: Maybe<Scalars["String"]>
	id: Scalars["bigint"]
	projectid?: Maybe<Scalars["String"]>
	protocolname?: Maybe<Scalars["String"]>
	time: Scalars["bigint"]
	timeend: Scalars["bigint"]
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
export type P_Asset_Hostnames_Aggregate = {
	__typename?: "p_asset_hostnames_aggregate"
	aggregate?: Maybe<P_Asset_Hostnames_Aggregate_Fields>
	nodes: Array<P_Asset_Hostnames>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
export type P_Asset_Hostnames_Aggregate_Fields = {
	__typename?: "p_asset_hostnames_aggregate_fields"
	avg?: Maybe<P_Asset_Hostnames_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<P_Asset_Hostnames_Max_Fields>
	min?: Maybe<P_Asset_Hostnames_Min_Fields>
	stddev?: Maybe<P_Asset_Hostnames_Stddev_Fields>
	stddev_pop?: Maybe<P_Asset_Hostnames_Stddev_Pop_Fields>
	stddev_samp?: Maybe<P_Asset_Hostnames_Stddev_Samp_Fields>
	sum?: Maybe<P_Asset_Hostnames_Sum_Fields>
	var_pop?: Maybe<P_Asset_Hostnames_Var_Pop_Fields>
	var_samp?: Maybe<P_Asset_Hostnames_Var_Samp_Fields>
	variance?: Maybe<P_Asset_Hostnames_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
export type P_Asset_Hostnames_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<P_Asset_Hostnames_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type P_Asset_Hostnames_Avg_Fields = {
	__typename?: "p_asset_hostnames_avg_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames". All fields are combined with a logical 'AND'. */
export type P_Asset_Hostnames_Bool_Exp = {
	_and?: InputMaybe<Array<P_Asset_Hostnames_Bool_Exp>>
	_not?: InputMaybe<P_Asset_Hostnames_Bool_Exp>
	_or?: InputMaybe<Array<P_Asset_Hostnames_Bool_Exp>>
	assetid?: InputMaybe<Bigint_Comparison_Exp>
	domain?: InputMaybe<String_Comparison_Exp>
	hostname?: InputMaybe<String_Comparison_Exp>
	id?: InputMaybe<Bigint_Comparison_Exp>
	projectid?: InputMaybe<String_Comparison_Exp>
	protocolname?: InputMaybe<String_Comparison_Exp>
	time?: InputMaybe<Bigint_Comparison_Exp>
	timeend?: InputMaybe<Bigint_Comparison_Exp>
}

/** unique or primary key constraints on table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
export enum P_Asset_Hostnames_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProjectB8d8cd96TopologyAssetHostnamesPkey = "project_b8d8cd96_topology_asset_hostnames_pkey",
}

/** input type for incrementing numeric columns in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
export type P_Asset_Hostnames_Inc_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
export type P_Asset_Hostnames_Insert_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	domain?: InputMaybe<Scalars["String"]>
	hostname?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	protocolname?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** aggregate max on columns */
export type P_Asset_Hostnames_Max_Fields = {
	__typename?: "p_asset_hostnames_max_fields"
	assetid?: Maybe<Scalars["bigint"]>
	domain?: Maybe<Scalars["String"]>
	hostname?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	protocolname?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** aggregate min on columns */
export type P_Asset_Hostnames_Min_Fields = {
	__typename?: "p_asset_hostnames_min_fields"
	assetid?: Maybe<Scalars["bigint"]>
	domain?: Maybe<Scalars["String"]>
	hostname?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	protocolname?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** response of any mutation on the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
export type P_Asset_Hostnames_Mutation_Response = {
	__typename?: "p_asset_hostnames_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<P_Asset_Hostnames>
}

/** on_conflict condition type for table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
export type P_Asset_Hostnames_On_Conflict = {
	constraint: P_Asset_Hostnames_Constraint
	update_columns?: Array<P_Asset_Hostnames_Update_Column>
	where?: InputMaybe<P_Asset_Hostnames_Bool_Exp>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames". */
export type P_Asset_Hostnames_Order_By = {
	assetid?: InputMaybe<Order_By>
	domain?: InputMaybe<Order_By>
	hostname?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	projectid?: InputMaybe<Order_By>
	protocolname?: InputMaybe<Order_By>
	time?: InputMaybe<Order_By>
	timeend?: InputMaybe<Order_By>
}

/** primary key columns input for table: p1657738961587697_asset_hostnames */
export type P_Asset_Hostnames_Pk_Columns_Input = {
	id: Scalars["bigint"]
}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
export enum P_Asset_Hostnames_Select_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Domain = "domain",
	/** column name */
	Hostname = "hostname",
	/** column name */
	Id = "id",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Protocolname = "protocolname",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
}

/** input type for updating data in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
export type P_Asset_Hostnames_Set_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	domain?: InputMaybe<Scalars["String"]>
	hostname?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	protocolname?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** aggregate stddev on columns */
export type P_Asset_Hostnames_Stddev_Fields = {
	__typename?: "p_asset_hostnames_stddev_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type P_Asset_Hostnames_Stddev_Pop_Fields = {
	__typename?: "p_asset_hostnames_stddev_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type P_Asset_Hostnames_Stddev_Samp_Fields = {
	__typename?: "p_asset_hostnames_stddev_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type P_Asset_Hostnames_Sum_Fields = {
	__typename?: "p_asset_hostnames_sum_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** update columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
export enum P_Asset_Hostnames_Update_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Domain = "domain",
	/** column name */
	Hostname = "hostname",
	/** column name */
	Id = "id",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Protocolname = "protocolname",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
}

/** aggregate var_pop on columns */
export type P_Asset_Hostnames_Var_Pop_Fields = {
	__typename?: "p_asset_hostnames_var_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type P_Asset_Hostnames_Var_Samp_Fields = {
	__typename?: "p_asset_hostnames_var_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type P_Asset_Hostnames_Variance_Fields = {
	__typename?: "p_asset_hostnames_variance_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
export type P_Asset_Interfaces = {
	__typename?: "p_asset_interfaces"
	assetid?: Maybe<Scalars["bigint"]>
	broadcastdomain: Scalars["bigint"]
	connectedto?: Maybe<Scalars["bigint"]>
	connector?: Maybe<Scalars["String"]>
	description?: Maybe<Scalars["String"]>
	id: Scalars["bigint"]
	isvirtual?: Maybe<Scalars["Boolean"]>
	layer2?: Maybe<Scalars["_text"]>
	layer3?: Maybe<Scalars["_text"]>
	name?: Maybe<Scalars["String"]>
	projectid?: Maybe<Scalars["String"]>
	routesid?: Maybe<Scalars["_int8"]>
	time: Scalars["bigint"]
	timeend: Scalars["bigint"]
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
export type P_Asset_Interfaces_Aggregate = {
	__typename?: "p_asset_interfaces_aggregate"
	aggregate?: Maybe<P_Asset_Interfaces_Aggregate_Fields>
	nodes: Array<P_Asset_Interfaces>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
export type P_Asset_Interfaces_Aggregate_Fields = {
	__typename?: "p_asset_interfaces_aggregate_fields"
	avg?: Maybe<P_Asset_Interfaces_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<P_Asset_Interfaces_Max_Fields>
	min?: Maybe<P_Asset_Interfaces_Min_Fields>
	stddev?: Maybe<P_Asset_Interfaces_Stddev_Fields>
	stddev_pop?: Maybe<P_Asset_Interfaces_Stddev_Pop_Fields>
	stddev_samp?: Maybe<P_Asset_Interfaces_Stddev_Samp_Fields>
	sum?: Maybe<P_Asset_Interfaces_Sum_Fields>
	var_pop?: Maybe<P_Asset_Interfaces_Var_Pop_Fields>
	var_samp?: Maybe<P_Asset_Interfaces_Var_Samp_Fields>
	variance?: Maybe<P_Asset_Interfaces_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
export type P_Asset_Interfaces_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<P_Asset_Interfaces_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type P_Asset_Interfaces_Avg_Fields = {
	__typename?: "p_asset_interfaces_avg_fields"
	assetid?: Maybe<Scalars["Float"]>
	broadcastdomain?: Maybe<Scalars["Float"]>
	connectedto?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces". All fields are combined with a logical 'AND'. */
export type P_Asset_Interfaces_Bool_Exp = {
	_and?: InputMaybe<Array<P_Asset_Interfaces_Bool_Exp>>
	_not?: InputMaybe<P_Asset_Interfaces_Bool_Exp>
	_or?: InputMaybe<Array<P_Asset_Interfaces_Bool_Exp>>
	assetid?: InputMaybe<Bigint_Comparison_Exp>
	broadcastdomain?: InputMaybe<Bigint_Comparison_Exp>
	connectedto?: InputMaybe<Bigint_Comparison_Exp>
	connector?: InputMaybe<String_Comparison_Exp>
	description?: InputMaybe<String_Comparison_Exp>
	id?: InputMaybe<Bigint_Comparison_Exp>
	isvirtual?: InputMaybe<Boolean_Comparison_Exp>
	layer2?: InputMaybe<_Text_Comparison_Exp>
	layer3?: InputMaybe<_Text_Comparison_Exp>
	name?: InputMaybe<String_Comparison_Exp>
	projectid?: InputMaybe<String_Comparison_Exp>
	routesid?: InputMaybe<_Int8_Comparison_Exp>
	time?: InputMaybe<Bigint_Comparison_Exp>
	timeend?: InputMaybe<Bigint_Comparison_Exp>
}

/** unique or primary key constraints on table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
export enum P_Asset_Interfaces_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProjectB8d8cd96TopologyAssetInterfacesPkey = "project_b8d8cd96_topology_asset_interfaces_pkey",
}

/** input type for incrementing numeric columns in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
export type P_Asset_Interfaces_Inc_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	broadcastdomain?: InputMaybe<Scalars["bigint"]>
	connectedto?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
export type P_Asset_Interfaces_Insert_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	broadcastdomain?: InputMaybe<Scalars["bigint"]>
	connectedto?: InputMaybe<Scalars["bigint"]>
	connector?: InputMaybe<Scalars["String"]>
	description?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	isvirtual?: InputMaybe<Scalars["Boolean"]>
	layer2?: InputMaybe<Scalars["_text"]>
	layer3?: InputMaybe<Scalars["_text"]>
	name?: InputMaybe<Scalars["String"]>
	projectid?: InputMaybe<Scalars["String"]>
	routesid?: InputMaybe<Scalars["_int8"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** aggregate max on columns */
export type P_Asset_Interfaces_Max_Fields = {
	__typename?: "p_asset_interfaces_max_fields"
	assetid?: Maybe<Scalars["bigint"]>
	broadcastdomain?: Maybe<Scalars["bigint"]>
	connectedto?: Maybe<Scalars["bigint"]>
	connector?: Maybe<Scalars["String"]>
	description?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	name?: Maybe<Scalars["String"]>
	projectid?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** aggregate min on columns */
export type P_Asset_Interfaces_Min_Fields = {
	__typename?: "p_asset_interfaces_min_fields"
	assetid?: Maybe<Scalars["bigint"]>
	broadcastdomain?: Maybe<Scalars["bigint"]>
	connectedto?: Maybe<Scalars["bigint"]>
	connector?: Maybe<Scalars["String"]>
	description?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	name?: Maybe<Scalars["String"]>
	projectid?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** response of any mutation on the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
export type P_Asset_Interfaces_Mutation_Response = {
	__typename?: "p_asset_interfaces_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<P_Asset_Interfaces>
}

/** on_conflict condition type for table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
export type P_Asset_Interfaces_On_Conflict = {
	constraint: P_Asset_Interfaces_Constraint
	update_columns?: Array<P_Asset_Interfaces_Update_Column>
	where?: InputMaybe<P_Asset_Interfaces_Bool_Exp>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces". */
export type P_Asset_Interfaces_Order_By = {
	assetid?: InputMaybe<Order_By>
	broadcastdomain?: InputMaybe<Order_By>
	connectedto?: InputMaybe<Order_By>
	connector?: InputMaybe<Order_By>
	description?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	isvirtual?: InputMaybe<Order_By>
	layer2?: InputMaybe<Order_By>
	layer3?: InputMaybe<Order_By>
	name?: InputMaybe<Order_By>
	projectid?: InputMaybe<Order_By>
	routesid?: InputMaybe<Order_By>
	time?: InputMaybe<Order_By>
	timeend?: InputMaybe<Order_By>
}

/** primary key columns input for table: p1657738961587697_asset_interfaces */
export type P_Asset_Interfaces_Pk_Columns_Input = {
	id: Scalars["bigint"]
}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
export enum P_Asset_Interfaces_Select_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Broadcastdomain = "broadcastdomain",
	/** column name */
	Connectedto = "connectedto",
	/** column name */
	Connector = "connector",
	/** column name */
	Description = "description",
	/** column name */
	Id = "id",
	/** column name */
	Isvirtual = "isvirtual",
	/** column name */
	Layer2 = "layer2",
	/** column name */
	Layer3 = "layer3",
	/** column name */
	Name = "name",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Routesid = "routesid",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
}

/** input type for updating data in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
export type P_Asset_Interfaces_Set_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	broadcastdomain?: InputMaybe<Scalars["bigint"]>
	connectedto?: InputMaybe<Scalars["bigint"]>
	connector?: InputMaybe<Scalars["String"]>
	description?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	isvirtual?: InputMaybe<Scalars["Boolean"]>
	layer2?: InputMaybe<Scalars["_text"]>
	layer3?: InputMaybe<Scalars["_text"]>
	name?: InputMaybe<Scalars["String"]>
	projectid?: InputMaybe<Scalars["String"]>
	routesid?: InputMaybe<Scalars["_int8"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** aggregate stddev on columns */
export type P_Asset_Interfaces_Stddev_Fields = {
	__typename?: "p_asset_interfaces_stddev_fields"
	assetid?: Maybe<Scalars["Float"]>
	broadcastdomain?: Maybe<Scalars["Float"]>
	connectedto?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type P_Asset_Interfaces_Stddev_Pop_Fields = {
	__typename?: "p_asset_interfaces_stddev_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	broadcastdomain?: Maybe<Scalars["Float"]>
	connectedto?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type P_Asset_Interfaces_Stddev_Samp_Fields = {
	__typename?: "p_asset_interfaces_stddev_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	broadcastdomain?: Maybe<Scalars["Float"]>
	connectedto?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type P_Asset_Interfaces_Sum_Fields = {
	__typename?: "p_asset_interfaces_sum_fields"
	assetid?: Maybe<Scalars["bigint"]>
	broadcastdomain?: Maybe<Scalars["bigint"]>
	connectedto?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** update columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
export enum P_Asset_Interfaces_Update_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Broadcastdomain = "broadcastdomain",
	/** column name */
	Connectedto = "connectedto",
	/** column name */
	Connector = "connector",
	/** column name */
	Description = "description",
	/** column name */
	Id = "id",
	/** column name */
	Isvirtual = "isvirtual",
	/** column name */
	Layer2 = "layer2",
	/** column name */
	Layer3 = "layer3",
	/** column name */
	Name = "name",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Routesid = "routesid",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
}

/** aggregate var_pop on columns */
export type P_Asset_Interfaces_Var_Pop_Fields = {
	__typename?: "p_asset_interfaces_var_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	broadcastdomain?: Maybe<Scalars["Float"]>
	connectedto?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type P_Asset_Interfaces_Var_Samp_Fields = {
	__typename?: "p_asset_interfaces_var_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	broadcastdomain?: Maybe<Scalars["Float"]>
	connectedto?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type P_Asset_Interfaces_Variance_Fields = {
	__typename?: "p_asset_interfaces_variance_fields"
	assetid?: Maybe<Scalars["Float"]>
	broadcastdomain?: Maybe<Scalars["Float"]>
	connectedto?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
export type P_Asset_Os = {
	__typename?: "p_asset_os"
	assetid?: Maybe<Scalars["bigint"]>
	developer?: Maybe<Scalars["String"]>
	id: Scalars["bigint"]
	name?: Maybe<Scalars["String"]>
	projectid?: Maybe<Scalars["String"]>
	time: Scalars["bigint"]
	timeend: Scalars["bigint"]
	version?: Maybe<Scalars["String"]>
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
export type P_Asset_Os_Aggregate = {
	__typename?: "p_asset_os_aggregate"
	aggregate?: Maybe<P_Asset_Os_Aggregate_Fields>
	nodes: Array<P_Asset_Os>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
export type P_Asset_Os_Aggregate_Fields = {
	__typename?: "p_asset_os_aggregate_fields"
	avg?: Maybe<P_Asset_Os_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<P_Asset_Os_Max_Fields>
	min?: Maybe<P_Asset_Os_Min_Fields>
	stddev?: Maybe<P_Asset_Os_Stddev_Fields>
	stddev_pop?: Maybe<P_Asset_Os_Stddev_Pop_Fields>
	stddev_samp?: Maybe<P_Asset_Os_Stddev_Samp_Fields>
	sum?: Maybe<P_Asset_Os_Sum_Fields>
	var_pop?: Maybe<P_Asset_Os_Var_Pop_Fields>
	var_samp?: Maybe<P_Asset_Os_Var_Samp_Fields>
	variance?: Maybe<P_Asset_Os_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
export type P_Asset_Os_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<P_Asset_Os_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type P_Asset_Os_Avg_Fields = {
	__typename?: "p_asset_os_avg_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os". All fields are combined with a logical 'AND'. */
export type P_Asset_Os_Bool_Exp = {
	_and?: InputMaybe<Array<P_Asset_Os_Bool_Exp>>
	_not?: InputMaybe<P_Asset_Os_Bool_Exp>
	_or?: InputMaybe<Array<P_Asset_Os_Bool_Exp>>
	assetid?: InputMaybe<Bigint_Comparison_Exp>
	developer?: InputMaybe<String_Comparison_Exp>
	id?: InputMaybe<Bigint_Comparison_Exp>
	name?: InputMaybe<String_Comparison_Exp>
	projectid?: InputMaybe<String_Comparison_Exp>
	time?: InputMaybe<Bigint_Comparison_Exp>
	timeend?: InputMaybe<Bigint_Comparison_Exp>
	version?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
export enum P_Asset_Os_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProjectB8d8cd96TopologyAssetOsPkey = "project_b8d8cd96_topology_asset_os_pkey",
}

/** input type for incrementing numeric columns in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
export type P_Asset_Os_Inc_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
export type P_Asset_Os_Insert_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	developer?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	name?: InputMaybe<Scalars["String"]>
	projectid?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
	version?: InputMaybe<Scalars["String"]>
}

/** aggregate max on columns */
export type P_Asset_Os_Max_Fields = {
	__typename?: "p_asset_os_max_fields"
	assetid?: Maybe<Scalars["bigint"]>
	developer?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	name?: Maybe<Scalars["String"]>
	projectid?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
	version?: Maybe<Scalars["String"]>
}

/** aggregate min on columns */
export type P_Asset_Os_Min_Fields = {
	__typename?: "p_asset_os_min_fields"
	assetid?: Maybe<Scalars["bigint"]>
	developer?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	name?: Maybe<Scalars["String"]>
	projectid?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
	version?: Maybe<Scalars["String"]>
}

/** response of any mutation on the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
export type P_Asset_Os_Mutation_Response = {
	__typename?: "p_asset_os_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<P_Asset_Os>
}

/** on_conflict condition type for table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
export type P_Asset_Os_On_Conflict = {
	constraint: P_Asset_Os_Constraint
	update_columns?: Array<P_Asset_Os_Update_Column>
	where?: InputMaybe<P_Asset_Os_Bool_Exp>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os". */
export type P_Asset_Os_Order_By = {
	assetid?: InputMaybe<Order_By>
	developer?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	name?: InputMaybe<Order_By>
	projectid?: InputMaybe<Order_By>
	time?: InputMaybe<Order_By>
	timeend?: InputMaybe<Order_By>
	version?: InputMaybe<Order_By>
}

/** primary key columns input for table: p1657738961587697_asset_os */
export type P_Asset_Os_Pk_Columns_Input = {
	id: Scalars["bigint"]
}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
export enum P_Asset_Os_Select_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Developer = "developer",
	/** column name */
	Id = "id",
	/** column name */
	Name = "name",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
	/** column name */
	Version = "version",
}

/** input type for updating data in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
export type P_Asset_Os_Set_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	developer?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	name?: InputMaybe<Scalars["String"]>
	projectid?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
	version?: InputMaybe<Scalars["String"]>
}

/** aggregate stddev on columns */
export type P_Asset_Os_Stddev_Fields = {
	__typename?: "p_asset_os_stddev_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type P_Asset_Os_Stddev_Pop_Fields = {
	__typename?: "p_asset_os_stddev_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type P_Asset_Os_Stddev_Samp_Fields = {
	__typename?: "p_asset_os_stddev_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type P_Asset_Os_Sum_Fields = {
	__typename?: "p_asset_os_sum_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** update columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
export enum P_Asset_Os_Update_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Developer = "developer",
	/** column name */
	Id = "id",
	/** column name */
	Name = "name",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
	/** column name */
	Version = "version",
}

/** aggregate var_pop on columns */
export type P_Asset_Os_Var_Pop_Fields = {
	__typename?: "p_asset_os_var_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type P_Asset_Os_Var_Samp_Fields = {
	__typename?: "p_asset_os_var_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type P_Asset_Os_Variance_Fields = {
	__typename?: "p_asset_os_variance_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
export type P_Asset_Roles = {
	__typename?: "p_asset_roles"
	assetid?: Maybe<Scalars["bigint"]>
	description?: Maybe<Scalars["String"]>
	id: Scalars["bigint"]
	projectid?: Maybe<Scalars["String"]>
	time: Scalars["bigint"]
	timeend: Scalars["bigint"]
	type?: Maybe<Scalars["String"]>
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
export type P_Asset_Roles_Aggregate = {
	__typename?: "p_asset_roles_aggregate"
	aggregate?: Maybe<P_Asset_Roles_Aggregate_Fields>
	nodes: Array<P_Asset_Roles>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
export type P_Asset_Roles_Aggregate_Fields = {
	__typename?: "p_asset_roles_aggregate_fields"
	avg?: Maybe<P_Asset_Roles_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<P_Asset_Roles_Max_Fields>
	min?: Maybe<P_Asset_Roles_Min_Fields>
	stddev?: Maybe<P_Asset_Roles_Stddev_Fields>
	stddev_pop?: Maybe<P_Asset_Roles_Stddev_Pop_Fields>
	stddev_samp?: Maybe<P_Asset_Roles_Stddev_Samp_Fields>
	sum?: Maybe<P_Asset_Roles_Sum_Fields>
	var_pop?: Maybe<P_Asset_Roles_Var_Pop_Fields>
	var_samp?: Maybe<P_Asset_Roles_Var_Samp_Fields>
	variance?: Maybe<P_Asset_Roles_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
export type P_Asset_Roles_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<P_Asset_Roles_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type P_Asset_Roles_Avg_Fields = {
	__typename?: "p_asset_roles_avg_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles". All fields are combined with a logical 'AND'. */
export type P_Asset_Roles_Bool_Exp = {
	_and?: InputMaybe<Array<P_Asset_Roles_Bool_Exp>>
	_not?: InputMaybe<P_Asset_Roles_Bool_Exp>
	_or?: InputMaybe<Array<P_Asset_Roles_Bool_Exp>>
	assetid?: InputMaybe<Bigint_Comparison_Exp>
	description?: InputMaybe<String_Comparison_Exp>
	id?: InputMaybe<Bigint_Comparison_Exp>
	projectid?: InputMaybe<String_Comparison_Exp>
	time?: InputMaybe<Bigint_Comparison_Exp>
	timeend?: InputMaybe<Bigint_Comparison_Exp>
	type?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
export enum P_Asset_Roles_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProjectB8d8cd96TopologyAssetRolesPkey = "project_b8d8cd96_topology_asset_roles_pkey",
}

/** input type for incrementing numeric columns in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
export type P_Asset_Roles_Inc_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
export type P_Asset_Roles_Insert_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	description?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
	type?: InputMaybe<Scalars["String"]>
}

/** aggregate max on columns */
export type P_Asset_Roles_Max_Fields = {
	__typename?: "p_asset_roles_max_fields"
	assetid?: Maybe<Scalars["bigint"]>
	description?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
	type?: Maybe<Scalars["String"]>
}

/** aggregate min on columns */
export type P_Asset_Roles_Min_Fields = {
	__typename?: "p_asset_roles_min_fields"
	assetid?: Maybe<Scalars["bigint"]>
	description?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
	type?: Maybe<Scalars["String"]>
}

/** response of any mutation on the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
export type P_Asset_Roles_Mutation_Response = {
	__typename?: "p_asset_roles_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<P_Asset_Roles>
}

/** on_conflict condition type for table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
export type P_Asset_Roles_On_Conflict = {
	constraint: P_Asset_Roles_Constraint
	update_columns?: Array<P_Asset_Roles_Update_Column>
	where?: InputMaybe<P_Asset_Roles_Bool_Exp>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles". */
export type P_Asset_Roles_Order_By = {
	assetid?: InputMaybe<Order_By>
	description?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	projectid?: InputMaybe<Order_By>
	time?: InputMaybe<Order_By>
	timeend?: InputMaybe<Order_By>
	type?: InputMaybe<Order_By>
}

/** primary key columns input for table: p1657738961587697_asset_roles */
export type P_Asset_Roles_Pk_Columns_Input = {
	id: Scalars["bigint"]
}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
export enum P_Asset_Roles_Select_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Description = "description",
	/** column name */
	Id = "id",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
	/** column name */
	Type = "type",
}

/** input type for updating data in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
export type P_Asset_Roles_Set_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	description?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
	type?: InputMaybe<Scalars["String"]>
}

/** aggregate stddev on columns */
export type P_Asset_Roles_Stddev_Fields = {
	__typename?: "p_asset_roles_stddev_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type P_Asset_Roles_Stddev_Pop_Fields = {
	__typename?: "p_asset_roles_stddev_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type P_Asset_Roles_Stddev_Samp_Fields = {
	__typename?: "p_asset_roles_stddev_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type P_Asset_Roles_Sum_Fields = {
	__typename?: "p_asset_roles_sum_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** update columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
export enum P_Asset_Roles_Update_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Description = "description",
	/** column name */
	Id = "id",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
	/** column name */
	Type = "type",
}

/** aggregate var_pop on columns */
export type P_Asset_Roles_Var_Pop_Fields = {
	__typename?: "p_asset_roles_var_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type P_Asset_Roles_Var_Samp_Fields = {
	__typename?: "p_asset_roles_var_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type P_Asset_Roles_Variance_Fields = {
	__typename?: "p_asset_roles_variance_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
export type P_Asset_Services = {
	__typename?: "p_asset_services"
	assetid?: Maybe<Scalars["bigint"]>
	firewallopen?: Maybe<Scalars["Boolean"]>
	id: Scalars["bigint"]
	name?: Maybe<Scalars["String"]>
	networkinterfaceid?: Maybe<Scalars["bigint"]>
	port?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	protocolname?: Maybe<Scalars["String"]>
	standardprotocol?: Maybe<Scalars["String"]>
	time: Scalars["bigint"]
	timeend: Scalars["bigint"]
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
export type P_Asset_Services_Aggregate = {
	__typename?: "p_asset_services_aggregate"
	aggregate?: Maybe<P_Asset_Services_Aggregate_Fields>
	nodes: Array<P_Asset_Services>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
export type P_Asset_Services_Aggregate_Fields = {
	__typename?: "p_asset_services_aggregate_fields"
	avg?: Maybe<P_Asset_Services_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<P_Asset_Services_Max_Fields>
	min?: Maybe<P_Asset_Services_Min_Fields>
	stddev?: Maybe<P_Asset_Services_Stddev_Fields>
	stddev_pop?: Maybe<P_Asset_Services_Stddev_Pop_Fields>
	stddev_samp?: Maybe<P_Asset_Services_Stddev_Samp_Fields>
	sum?: Maybe<P_Asset_Services_Sum_Fields>
	var_pop?: Maybe<P_Asset_Services_Var_Pop_Fields>
	var_samp?: Maybe<P_Asset_Services_Var_Samp_Fields>
	variance?: Maybe<P_Asset_Services_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
export type P_Asset_Services_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<P_Asset_Services_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type P_Asset_Services_Avg_Fields = {
	__typename?: "p_asset_services_avg_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	networkinterfaceid?: Maybe<Scalars["Float"]>
	port?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services". All fields are combined with a logical 'AND'. */
export type P_Asset_Services_Bool_Exp = {
	_and?: InputMaybe<Array<P_Asset_Services_Bool_Exp>>
	_not?: InputMaybe<P_Asset_Services_Bool_Exp>
	_or?: InputMaybe<Array<P_Asset_Services_Bool_Exp>>
	assetid?: InputMaybe<Bigint_Comparison_Exp>
	firewallopen?: InputMaybe<Boolean_Comparison_Exp>
	id?: InputMaybe<Bigint_Comparison_Exp>
	name?: InputMaybe<String_Comparison_Exp>
	networkinterfaceid?: InputMaybe<Bigint_Comparison_Exp>
	port?: InputMaybe<Bigint_Comparison_Exp>
	projectid?: InputMaybe<String_Comparison_Exp>
	protocolname?: InputMaybe<String_Comparison_Exp>
	standardprotocol?: InputMaybe<String_Comparison_Exp>
	time?: InputMaybe<Bigint_Comparison_Exp>
	timeend?: InputMaybe<Bigint_Comparison_Exp>
}

/** unique or primary key constraints on table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
export enum P_Asset_Services_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProjectB8d8cd96TopologyAssetServicesPkey = "project_b8d8cd96_topology_asset_services_pkey",
}

/** input type for incrementing numeric columns in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
export type P_Asset_Services_Inc_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	networkinterfaceid?: InputMaybe<Scalars["bigint"]>
	port?: InputMaybe<Scalars["bigint"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
export type P_Asset_Services_Insert_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	firewallopen?: InputMaybe<Scalars["Boolean"]>
	id?: InputMaybe<Scalars["bigint"]>
	name?: InputMaybe<Scalars["String"]>
	networkinterfaceid?: InputMaybe<Scalars["bigint"]>
	port?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	protocolname?: InputMaybe<Scalars["String"]>
	standardprotocol?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** aggregate max on columns */
export type P_Asset_Services_Max_Fields = {
	__typename?: "p_asset_services_max_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	name?: Maybe<Scalars["String"]>
	networkinterfaceid?: Maybe<Scalars["bigint"]>
	port?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	protocolname?: Maybe<Scalars["String"]>
	standardprotocol?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** aggregate min on columns */
export type P_Asset_Services_Min_Fields = {
	__typename?: "p_asset_services_min_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	name?: Maybe<Scalars["String"]>
	networkinterfaceid?: Maybe<Scalars["bigint"]>
	port?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	protocolname?: Maybe<Scalars["String"]>
	standardprotocol?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** response of any mutation on the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
export type P_Asset_Services_Mutation_Response = {
	__typename?: "p_asset_services_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<P_Asset_Services>
}

/** on_conflict condition type for table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
export type P_Asset_Services_On_Conflict = {
	constraint: P_Asset_Services_Constraint
	update_columns?: Array<P_Asset_Services_Update_Column>
	where?: InputMaybe<P_Asset_Services_Bool_Exp>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services". */
export type P_Asset_Services_Order_By = {
	assetid?: InputMaybe<Order_By>
	firewallopen?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	name?: InputMaybe<Order_By>
	networkinterfaceid?: InputMaybe<Order_By>
	port?: InputMaybe<Order_By>
	projectid?: InputMaybe<Order_By>
	protocolname?: InputMaybe<Order_By>
	standardprotocol?: InputMaybe<Order_By>
	time?: InputMaybe<Order_By>
	timeend?: InputMaybe<Order_By>
}

/** primary key columns input for table: p1657738961587697_asset_services */
export type P_Asset_Services_Pk_Columns_Input = {
	id: Scalars["bigint"]
}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
export enum P_Asset_Services_Select_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Firewallopen = "firewallopen",
	/** column name */
	Id = "id",
	/** column name */
	Name = "name",
	/** column name */
	Networkinterfaceid = "networkinterfaceid",
	/** column name */
	Port = "port",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Protocolname = "protocolname",
	/** column name */
	Standardprotocol = "standardprotocol",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
}

/** input type for updating data in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
export type P_Asset_Services_Set_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	firewallopen?: InputMaybe<Scalars["Boolean"]>
	id?: InputMaybe<Scalars["bigint"]>
	name?: InputMaybe<Scalars["String"]>
	networkinterfaceid?: InputMaybe<Scalars["bigint"]>
	port?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	protocolname?: InputMaybe<Scalars["String"]>
	standardprotocol?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** aggregate stddev on columns */
export type P_Asset_Services_Stddev_Fields = {
	__typename?: "p_asset_services_stddev_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	networkinterfaceid?: Maybe<Scalars["Float"]>
	port?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type P_Asset_Services_Stddev_Pop_Fields = {
	__typename?: "p_asset_services_stddev_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	networkinterfaceid?: Maybe<Scalars["Float"]>
	port?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type P_Asset_Services_Stddev_Samp_Fields = {
	__typename?: "p_asset_services_stddev_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	networkinterfaceid?: Maybe<Scalars["Float"]>
	port?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type P_Asset_Services_Sum_Fields = {
	__typename?: "p_asset_services_sum_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	networkinterfaceid?: Maybe<Scalars["bigint"]>
	port?: Maybe<Scalars["bigint"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** update columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
export enum P_Asset_Services_Update_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Firewallopen = "firewallopen",
	/** column name */
	Id = "id",
	/** column name */
	Name = "name",
	/** column name */
	Networkinterfaceid = "networkinterfaceid",
	/** column name */
	Port = "port",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Protocolname = "protocolname",
	/** column name */
	Standardprotocol = "standardprotocol",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
}

/** aggregate var_pop on columns */
export type P_Asset_Services_Var_Pop_Fields = {
	__typename?: "p_asset_services_var_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	networkinterfaceid?: Maybe<Scalars["Float"]>
	port?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type P_Asset_Services_Var_Samp_Fields = {
	__typename?: "p_asset_services_var_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	networkinterfaceid?: Maybe<Scalars["Float"]>
	port?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type P_Asset_Services_Variance_Fields = {
	__typename?: "p_asset_services_variance_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	networkinterfaceid?: Maybe<Scalars["Float"]>
	port?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
export type P_Asset_Tags = {
	__typename?: "p_asset_tags"
	assetid?: Maybe<Scalars["bigint"]>
	id: Scalars["bigint"]
	projectid?: Maybe<Scalars["String"]>
	text?: Maybe<Scalars["String"]>
	time: Scalars["bigint"]
	timeend: Scalars["bigint"]
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
export type P_Asset_Tags_Aggregate = {
	__typename?: "p_asset_tags_aggregate"
	aggregate?: Maybe<P_Asset_Tags_Aggregate_Fields>
	nodes: Array<P_Asset_Tags>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
export type P_Asset_Tags_Aggregate_Fields = {
	__typename?: "p_asset_tags_aggregate_fields"
	avg?: Maybe<P_Asset_Tags_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<P_Asset_Tags_Max_Fields>
	min?: Maybe<P_Asset_Tags_Min_Fields>
	stddev?: Maybe<P_Asset_Tags_Stddev_Fields>
	stddev_pop?: Maybe<P_Asset_Tags_Stddev_Pop_Fields>
	stddev_samp?: Maybe<P_Asset_Tags_Stddev_Samp_Fields>
	sum?: Maybe<P_Asset_Tags_Sum_Fields>
	var_pop?: Maybe<P_Asset_Tags_Var_Pop_Fields>
	var_samp?: Maybe<P_Asset_Tags_Var_Samp_Fields>
	variance?: Maybe<P_Asset_Tags_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
export type P_Asset_Tags_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<P_Asset_Tags_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type P_Asset_Tags_Avg_Fields = {
	__typename?: "p_asset_tags_avg_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags". All fields are combined with a logical 'AND'. */
export type P_Asset_Tags_Bool_Exp = {
	_and?: InputMaybe<Array<P_Asset_Tags_Bool_Exp>>
	_not?: InputMaybe<P_Asset_Tags_Bool_Exp>
	_or?: InputMaybe<Array<P_Asset_Tags_Bool_Exp>>
	assetid?: InputMaybe<Bigint_Comparison_Exp>
	id?: InputMaybe<Bigint_Comparison_Exp>
	projectid?: InputMaybe<String_Comparison_Exp>
	text?: InputMaybe<String_Comparison_Exp>
	time?: InputMaybe<Bigint_Comparison_Exp>
	timeend?: InputMaybe<Bigint_Comparison_Exp>
}

/** unique or primary key constraints on table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
export enum P_Asset_Tags_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProjectB8d8cd96TopologyAssetTagsPkey = "project_b8d8cd96_topology_asset_tags_pkey",
}

/** input type for incrementing numeric columns in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
export type P_Asset_Tags_Inc_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
export type P_Asset_Tags_Insert_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	text?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** aggregate max on columns */
export type P_Asset_Tags_Max_Fields = {
	__typename?: "p_asset_tags_max_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	text?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** aggregate min on columns */
export type P_Asset_Tags_Min_Fields = {
	__typename?: "p_asset_tags_min_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	text?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** response of any mutation on the table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
export type P_Asset_Tags_Mutation_Response = {
	__typename?: "p_asset_tags_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<P_Asset_Tags>
}

/** on_conflict condition type for table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
export type P_Asset_Tags_On_Conflict = {
	constraint: P_Asset_Tags_Constraint
	update_columns?: Array<P_Asset_Tags_Update_Column>
	where?: InputMaybe<P_Asset_Tags_Bool_Exp>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags". */
export type P_Asset_Tags_Order_By = {
	assetid?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	projectid?: InputMaybe<Order_By>
	text?: InputMaybe<Order_By>
	time?: InputMaybe<Order_By>
	timeend?: InputMaybe<Order_By>
}

/** primary key columns input for table: p1657738961587697_asset_tags */
export type P_Asset_Tags_Pk_Columns_Input = {
	id: Scalars["bigint"]
}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
export enum P_Asset_Tags_Select_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Id = "id",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Text = "text",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
}

/** input type for updating data in table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
export type P_Asset_Tags_Set_Input = {
	assetid?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	text?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
}

/** aggregate stddev on columns */
export type P_Asset_Tags_Stddev_Fields = {
	__typename?: "p_asset_tags_stddev_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type P_Asset_Tags_Stddev_Pop_Fields = {
	__typename?: "p_asset_tags_stddev_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type P_Asset_Tags_Stddev_Samp_Fields = {
	__typename?: "p_asset_tags_stddev_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type P_Asset_Tags_Sum_Fields = {
	__typename?: "p_asset_tags_sum_fields"
	assetid?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
}

/** update columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
export enum P_Asset_Tags_Update_Column {
	/** column name */
	Assetid = "assetid",
	/** column name */
	Id = "id",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Text = "text",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
}

/** aggregate var_pop on columns */
export type P_Asset_Tags_Var_Pop_Fields = {
	__typename?: "p_asset_tags_var_pop_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type P_Asset_Tags_Var_Samp_Fields = {
	__typename?: "p_asset_tags_var_samp_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type P_Asset_Tags_Variance_Fields = {
	__typename?: "p_asset_tags_variance_fields"
	assetid?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
export type P_Assets = {
	__typename?: "p_assets"
	description?: Maybe<Scalars["String"]>
	id: Scalars["bigint"]
	name?: Maybe<Scalars["String"]>
	parentid?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
export type P_Assets_Aggregate = {
	__typename?: "p_assets_aggregate"
	aggregate?: Maybe<P_Assets_Aggregate_Fields>
	nodes: Array<P_Assets>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
export type P_Assets_Aggregate_Fields = {
	__typename?: "p_assets_aggregate_fields"
	avg?: Maybe<P_Assets_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<P_Assets_Max_Fields>
	min?: Maybe<P_Assets_Min_Fields>
	stddev?: Maybe<P_Assets_Stddev_Fields>
	stddev_pop?: Maybe<P_Assets_Stddev_Pop_Fields>
	stddev_samp?: Maybe<P_Assets_Stddev_Samp_Fields>
	sum?: Maybe<P_Assets_Sum_Fields>
	var_pop?: Maybe<P_Assets_Var_Pop_Fields>
	var_samp?: Maybe<P_Assets_Var_Samp_Fields>
	variance?: Maybe<P_Assets_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
export type P_Assets_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<P_Assets_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type P_Assets_Avg_Fields = {
	__typename?: "p_assets_avg_fields"
	id?: Maybe<Scalars["Float"]>
	parentid?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_topology_assets". All fields are combined with a logical 'AND'. */
export type P_Assets_Bool_Exp = {
	_and?: InputMaybe<Array<P_Assets_Bool_Exp>>
	_not?: InputMaybe<P_Assets_Bool_Exp>
	_or?: InputMaybe<Array<P_Assets_Bool_Exp>>
	description?: InputMaybe<String_Comparison_Exp>
	id?: InputMaybe<Bigint_Comparison_Exp>
	name?: InputMaybe<String_Comparison_Exp>
	parentid?: InputMaybe<Bigint_Comparison_Exp>
	projectid?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
export enum P_Assets_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProjectB8d8cd96TopologyAssetsPkey = "project_b8d8cd96_topology_assets_pkey",
}

/** input type for incrementing numeric columns in table "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
export type P_Assets_Inc_Input = {
	id?: InputMaybe<Scalars["bigint"]>
	parentid?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
export type P_Assets_Insert_Input = {
	description?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	name?: InputMaybe<Scalars["String"]>
	parentid?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
}

/** aggregate max on columns */
export type P_Assets_Max_Fields = {
	__typename?: "p_assets_max_fields"
	description?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	name?: Maybe<Scalars["String"]>
	parentid?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
}

/** aggregate min on columns */
export type P_Assets_Min_Fields = {
	__typename?: "p_assets_min_fields"
	description?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	name?: Maybe<Scalars["String"]>
	parentid?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
}

/** response of any mutation on the table "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
export type P_Assets_Mutation_Response = {
	__typename?: "p_assets_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<P_Assets>
}

/** on_conflict condition type for table "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
export type P_Assets_On_Conflict = {
	constraint: P_Assets_Constraint
	update_columns?: Array<P_Assets_Update_Column>
	where?: InputMaybe<P_Assets_Bool_Exp>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_topology_assets". */
export type P_Assets_Order_By = {
	description?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	name?: InputMaybe<Order_By>
	parentid?: InputMaybe<Order_By>
	projectid?: InputMaybe<Order_By>
}

/** primary key columns input for table: p1657738961587697_assets */
export type P_Assets_Pk_Columns_Input = {
	id: Scalars["bigint"]
}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
export enum P_Assets_Select_Column {
	/** column name */
	Description = "description",
	/** column name */
	Id = "id",
	/** column name */
	Name = "name",
	/** column name */
	Parentid = "parentid",
	/** column name */
	Projectid = "projectid",
}

/** input type for updating data in table "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
export type P_Assets_Set_Input = {
	description?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	name?: InputMaybe<Scalars["String"]>
	parentid?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
}

/** aggregate stddev on columns */
export type P_Assets_Stddev_Fields = {
	__typename?: "p_assets_stddev_fields"
	id?: Maybe<Scalars["Float"]>
	parentid?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type P_Assets_Stddev_Pop_Fields = {
	__typename?: "p_assets_stddev_pop_fields"
	id?: Maybe<Scalars["Float"]>
	parentid?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type P_Assets_Stddev_Samp_Fields = {
	__typename?: "p_assets_stddev_samp_fields"
	id?: Maybe<Scalars["Float"]>
	parentid?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type P_Assets_Sum_Fields = {
	__typename?: "p_assets_sum_fields"
	id?: Maybe<Scalars["bigint"]>
	parentid?: Maybe<Scalars["bigint"]>
}

/** update columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
export enum P_Assets_Update_Column {
	/** column name */
	Description = "description",
	/** column name */
	Id = "id",
	/** column name */
	Name = "name",
	/** column name */
	Parentid = "parentid",
	/** column name */
	Projectid = "projectid",
}

/** aggregate var_pop on columns */
export type P_Assets_Var_Pop_Fields = {
	__typename?: "p_assets_var_pop_fields"
	id?: Maybe<Scalars["Float"]>
	parentid?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type P_Assets_Var_Samp_Fields = {
	__typename?: "p_assets_var_samp_fields"
	id?: Maybe<Scalars["Float"]>
	parentid?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type P_Assets_Variance_Fields = {
	__typename?: "p_assets_variance_fields"
	id?: Maybe<Scalars["Float"]>
	parentid?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
export type P_Broadcast_Domains = {
	__typename?: "p_broadcast_domains"
	id: Scalars["bigint"]
	projectid?: Maybe<Scalars["String"]>
	virtual?: Maybe<Scalars["Boolean"]>
	vlan?: Maybe<Scalars["bigint"]>
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
export type P_Broadcast_Domains_Aggregate = {
	__typename?: "p_broadcast_domains_aggregate"
	aggregate?: Maybe<P_Broadcast_Domains_Aggregate_Fields>
	nodes: Array<P_Broadcast_Domains>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
export type P_Broadcast_Domains_Aggregate_Fields = {
	__typename?: "p_broadcast_domains_aggregate_fields"
	avg?: Maybe<P_Broadcast_Domains_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<P_Broadcast_Domains_Max_Fields>
	min?: Maybe<P_Broadcast_Domains_Min_Fields>
	stddev?: Maybe<P_Broadcast_Domains_Stddev_Fields>
	stddev_pop?: Maybe<P_Broadcast_Domains_Stddev_Pop_Fields>
	stddev_samp?: Maybe<P_Broadcast_Domains_Stddev_Samp_Fields>
	sum?: Maybe<P_Broadcast_Domains_Sum_Fields>
	var_pop?: Maybe<P_Broadcast_Domains_Var_Pop_Fields>
	var_samp?: Maybe<P_Broadcast_Domains_Var_Samp_Fields>
	variance?: Maybe<P_Broadcast_Domains_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
export type P_Broadcast_Domains_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<P_Broadcast_Domains_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type P_Broadcast_Domains_Avg_Fields = {
	__typename?: "p_broadcast_domains_avg_fields"
	id?: Maybe<Scalars["Float"]>
	vlan?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains". All fields are combined with a logical 'AND'. */
export type P_Broadcast_Domains_Bool_Exp = {
	_and?: InputMaybe<Array<P_Broadcast_Domains_Bool_Exp>>
	_not?: InputMaybe<P_Broadcast_Domains_Bool_Exp>
	_or?: InputMaybe<Array<P_Broadcast_Domains_Bool_Exp>>
	id?: InputMaybe<Bigint_Comparison_Exp>
	projectid?: InputMaybe<String_Comparison_Exp>
	virtual?: InputMaybe<Boolean_Comparison_Exp>
	vlan?: InputMaybe<Bigint_Comparison_Exp>
}

/** unique or primary key constraints on table "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
export enum P_Broadcast_Domains_Constraint {
	/** unique or primary key constraint on columns "id" */
	ProjectB8d8cd96TopologyBroadcastDomainsPkey = "project_b8d8cd96_topology_broadcast_domains_pkey",
}

/** input type for incrementing numeric columns in table "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
export type P_Broadcast_Domains_Inc_Input = {
	id?: InputMaybe<Scalars["bigint"]>
	vlan?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
export type P_Broadcast_Domains_Insert_Input = {
	id?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	virtual?: InputMaybe<Scalars["Boolean"]>
	vlan?: InputMaybe<Scalars["bigint"]>
}

/** aggregate max on columns */
export type P_Broadcast_Domains_Max_Fields = {
	__typename?: "p_broadcast_domains_max_fields"
	id?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	vlan?: Maybe<Scalars["bigint"]>
}

/** aggregate min on columns */
export type P_Broadcast_Domains_Min_Fields = {
	__typename?: "p_broadcast_domains_min_fields"
	id?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	vlan?: Maybe<Scalars["bigint"]>
}

/** response of any mutation on the table "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
export type P_Broadcast_Domains_Mutation_Response = {
	__typename?: "p_broadcast_domains_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<P_Broadcast_Domains>
}

/** on_conflict condition type for table "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
export type P_Broadcast_Domains_On_Conflict = {
	constraint: P_Broadcast_Domains_Constraint
	update_columns?: Array<P_Broadcast_Domains_Update_Column>
	where?: InputMaybe<P_Broadcast_Domains_Bool_Exp>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains". */
export type P_Broadcast_Domains_Order_By = {
	id?: InputMaybe<Order_By>
	projectid?: InputMaybe<Order_By>
	virtual?: InputMaybe<Order_By>
	vlan?: InputMaybe<Order_By>
}

/** primary key columns input for table: p1657738961587697_broadcast_domains */
export type P_Broadcast_Domains_Pk_Columns_Input = {
	id: Scalars["bigint"]
}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
export enum P_Broadcast_Domains_Select_Column {
	/** column name */
	Id = "id",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Virtual = "virtual",
	/** column name */
	Vlan = "vlan",
}

/** input type for updating data in table "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
export type P_Broadcast_Domains_Set_Input = {
	id?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	virtual?: InputMaybe<Scalars["Boolean"]>
	vlan?: InputMaybe<Scalars["bigint"]>
}

/** aggregate stddev on columns */
export type P_Broadcast_Domains_Stddev_Fields = {
	__typename?: "p_broadcast_domains_stddev_fields"
	id?: Maybe<Scalars["Float"]>
	vlan?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type P_Broadcast_Domains_Stddev_Pop_Fields = {
	__typename?: "p_broadcast_domains_stddev_pop_fields"
	id?: Maybe<Scalars["Float"]>
	vlan?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type P_Broadcast_Domains_Stddev_Samp_Fields = {
	__typename?: "p_broadcast_domains_stddev_samp_fields"
	id?: Maybe<Scalars["Float"]>
	vlan?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type P_Broadcast_Domains_Sum_Fields = {
	__typename?: "p_broadcast_domains_sum_fields"
	id?: Maybe<Scalars["bigint"]>
	vlan?: Maybe<Scalars["bigint"]>
}

/** update columns of table "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
export enum P_Broadcast_Domains_Update_Column {
	/** column name */
	Id = "id",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Virtual = "virtual",
	/** column name */
	Vlan = "vlan",
}

/** aggregate var_pop on columns */
export type P_Broadcast_Domains_Var_Pop_Fields = {
	__typename?: "p_broadcast_domains_var_pop_fields"
	id?: Maybe<Scalars["Float"]>
	vlan?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type P_Broadcast_Domains_Var_Samp_Fields = {
	__typename?: "p_broadcast_domains_var_samp_fields"
	id?: Maybe<Scalars["Float"]>
	vlan?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type P_Broadcast_Domains_Variance_Fields = {
	__typename?: "p_broadcast_domains_variance_fields"
	id?: Maybe<Scalars["Float"]>
	vlan?: Maybe<Scalars["Float"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_packets_minute_protocol_usage" */
export type P_Pru_M = {
	__typename?: "p_pru_m"
	bytessentclientserver?: Maybe<Scalars["numeric"]>
	bytesserverclient?: Maybe<Scalars["numeric"]>
	l7protocol?: Maybe<Scalars["String"]>
	minute?: Maybe<Scalars["bigint"]>
	packetsclientserver?: Maybe<Scalars["numeric"]>
	packetsserverclient?: Maybe<Scalars["numeric"]>
	project_id?: Maybe<Scalars["String"]>
	totalbytes?: Maybe<Scalars["numeric"]>
	totalpackets?: Maybe<Scalars["numeric"]>
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_packets_minute_protocol_usage" */
export type P_Pru_M_Aggregate = {
	__typename?: "p_pru_m_aggregate"
	aggregate?: Maybe<P_Pru_M_Aggregate_Fields>
	nodes: Array<P_Pru_M>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_packets_minute_protocol_usage" */
export type P_Pru_M_Aggregate_Fields = {
	__typename?: "p_pru_m_aggregate_fields"
	avg?: Maybe<P_Pru_M_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<P_Pru_M_Max_Fields>
	min?: Maybe<P_Pru_M_Min_Fields>
	stddev?: Maybe<P_Pru_M_Stddev_Fields>
	stddev_pop?: Maybe<P_Pru_M_Stddev_Pop_Fields>
	stddev_samp?: Maybe<P_Pru_M_Stddev_Samp_Fields>
	sum?: Maybe<P_Pru_M_Sum_Fields>
	var_pop?: Maybe<P_Pru_M_Var_Pop_Fields>
	var_samp?: Maybe<P_Pru_M_Var_Samp_Fields>
	variance?: Maybe<P_Pru_M_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_packets_minute_protocol_usage" */
export type P_Pru_M_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<P_Pru_M_Select_Column>>
	distinct?: InputMaybe<Scalars["Boolean"]>
}

/** aggregate avg on columns */
export type P_Pru_M_Avg_Fields = {
	__typename?: "p_pru_m_avg_fields"
	bytessentclientserver?: Maybe<Scalars["Float"]>
	bytesserverclient?: Maybe<Scalars["Float"]>
	minute?: Maybe<Scalars["Float"]>
	packetsclientserver?: Maybe<Scalars["Float"]>
	packetsserverclient?: Maybe<Scalars["Float"]>
	totalbytes?: Maybe<Scalars["Float"]>
	totalpackets?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_packets_minute_protocol_usage". All fields are combined with a logical 'AND'. */
export type P_Pru_M_Bool_Exp = {
	_and?: InputMaybe<Array<P_Pru_M_Bool_Exp>>
	_not?: InputMaybe<P_Pru_M_Bool_Exp>
	_or?: InputMaybe<Array<P_Pru_M_Bool_Exp>>
	bytessentclientserver?: InputMaybe<Numeric_Comparison_Exp>
	bytesserverclient?: InputMaybe<Numeric_Comparison_Exp>
	l7protocol?: InputMaybe<String_Comparison_Exp>
	minute?: InputMaybe<Bigint_Comparison_Exp>
	packetsclientserver?: InputMaybe<Numeric_Comparison_Exp>
	packetsserverclient?: InputMaybe<Numeric_Comparison_Exp>
	project_id?: InputMaybe<String_Comparison_Exp>
	totalbytes?: InputMaybe<Numeric_Comparison_Exp>
	totalpackets?: InputMaybe<Numeric_Comparison_Exp>
}

/** aggregate max on columns */
export type P_Pru_M_Max_Fields = {
	__typename?: "p_pru_m_max_fields"
	bytessentclientserver?: Maybe<Scalars["numeric"]>
	bytesserverclient?: Maybe<Scalars["numeric"]>
	l7protocol?: Maybe<Scalars["String"]>
	minute?: Maybe<Scalars["bigint"]>
	packetsclientserver?: Maybe<Scalars["numeric"]>
	packetsserverclient?: Maybe<Scalars["numeric"]>
	project_id?: Maybe<Scalars["String"]>
	totalbytes?: Maybe<Scalars["numeric"]>
	totalpackets?: Maybe<Scalars["numeric"]>
}

/** aggregate min on columns */
export type P_Pru_M_Min_Fields = {
	__typename?: "p_pru_m_min_fields"
	bytessentclientserver?: Maybe<Scalars["numeric"]>
	bytesserverclient?: Maybe<Scalars["numeric"]>
	l7protocol?: Maybe<Scalars["String"]>
	minute?: Maybe<Scalars["bigint"]>
	packetsclientserver?: Maybe<Scalars["numeric"]>
	packetsserverclient?: Maybe<Scalars["numeric"]>
	project_id?: Maybe<Scalars["String"]>
	totalbytes?: Maybe<Scalars["numeric"]>
	totalpackets?: Maybe<Scalars["numeric"]>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_packets_minute_protocol_usage". */
export type P_Pru_M_Order_By = {
	bytessentclientserver?: InputMaybe<Order_By>
	bytesserverclient?: InputMaybe<Order_By>
	l7protocol?: InputMaybe<Order_By>
	minute?: InputMaybe<Order_By>
	packetsclientserver?: InputMaybe<Order_By>
	packetsserverclient?: InputMaybe<Order_By>
	project_id?: InputMaybe<Order_By>
	totalbytes?: InputMaybe<Order_By>
	totalpackets?: InputMaybe<Order_By>
}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_packets_minute_protocol_usage" */
export enum P_Pru_M_Select_Column {
	/** column name */
	Bytessentclientserver = "bytessentclientserver",
	/** column name */
	Bytesserverclient = "bytesserverclient",
	/** column name */
	L7protocol = "l7protocol",
	/** column name */
	Minute = "minute",
	/** column name */
	Packetsclientserver = "packetsclientserver",
	/** column name */
	Packetsserverclient = "packetsserverclient",
	/** column name */
	ProjectId = "project_id",
	/** column name */
	Totalbytes = "totalbytes",
	/** column name */
	Totalpackets = "totalpackets",
}

/** aggregate stddev on columns */
export type P_Pru_M_Stddev_Fields = {
	__typename?: "p_pru_m_stddev_fields"
	bytessentclientserver?: Maybe<Scalars["Float"]>
	bytesserverclient?: Maybe<Scalars["Float"]>
	minute?: Maybe<Scalars["Float"]>
	packetsclientserver?: Maybe<Scalars["Float"]>
	packetsserverclient?: Maybe<Scalars["Float"]>
	totalbytes?: Maybe<Scalars["Float"]>
	totalpackets?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type P_Pru_M_Stddev_Pop_Fields = {
	__typename?: "p_pru_m_stddev_pop_fields"
	bytessentclientserver?: Maybe<Scalars["Float"]>
	bytesserverclient?: Maybe<Scalars["Float"]>
	minute?: Maybe<Scalars["Float"]>
	packetsclientserver?: Maybe<Scalars["Float"]>
	packetsserverclient?: Maybe<Scalars["Float"]>
	totalbytes?: Maybe<Scalars["Float"]>
	totalpackets?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type P_Pru_M_Stddev_Samp_Fields = {
	__typename?: "p_pru_m_stddev_samp_fields"
	bytessentclientserver?: Maybe<Scalars["Float"]>
	bytesserverclient?: Maybe<Scalars["Float"]>
	minute?: Maybe<Scalars["Float"]>
	packetsclientserver?: Maybe<Scalars["Float"]>
	packetsserverclient?: Maybe<Scalars["Float"]>
	totalbytes?: Maybe<Scalars["Float"]>
	totalpackets?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type P_Pru_M_Sum_Fields = {
	__typename?: "p_pru_m_sum_fields"
	bytessentclientserver?: Maybe<Scalars["numeric"]>
	bytesserverclient?: Maybe<Scalars["numeric"]>
	minute?: Maybe<Scalars["bigint"]>
	packetsclientserver?: Maybe<Scalars["numeric"]>
	packetsserverclient?: Maybe<Scalars["numeric"]>
	totalbytes?: Maybe<Scalars["numeric"]>
	totalpackets?: Maybe<Scalars["numeric"]>
}

/** aggregate var_pop on columns */
export type P_Pru_M_Var_Pop_Fields = {
	__typename?: "p_pru_m_var_pop_fields"
	bytessentclientserver?: Maybe<Scalars["Float"]>
	bytesserverclient?: Maybe<Scalars["Float"]>
	minute?: Maybe<Scalars["Float"]>
	packetsclientserver?: Maybe<Scalars["Float"]>
	packetsserverclient?: Maybe<Scalars["Float"]>
	totalbytes?: Maybe<Scalars["Float"]>
	totalpackets?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type P_Pru_M_Var_Samp_Fields = {
	__typename?: "p_pru_m_var_samp_fields"
	bytessentclientserver?: Maybe<Scalars["Float"]>
	bytesserverclient?: Maybe<Scalars["Float"]>
	minute?: Maybe<Scalars["Float"]>
	packetsclientserver?: Maybe<Scalars["Float"]>
	packetsserverclient?: Maybe<Scalars["Float"]>
	totalbytes?: Maybe<Scalars["Float"]>
	totalpackets?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type P_Pru_M_Variance_Fields = {
	__typename?: "p_pru_m_variance_fields"
	bytessentclientserver?: Maybe<Scalars["Float"]>
	bytesserverclient?: Maybe<Scalars["Float"]>
	minute?: Maybe<Scalars["Float"]>
	packetsclientserver?: Maybe<Scalars["Float"]>
	packetsserverclient?: Maybe<Scalars["Float"]>
	totalbytes?: Maybe<Scalars["Float"]>
	totalpackets?: Maybe<Scalars["Float"]>
}

export type Query_Root = {
	__typename?: "query_root"
	/** fetch data from the table: "core.ImportOperation" */
	core_ImportOperation: Array<Core_ImportOperation>
	/** fetch aggregated fields from the table: "core.ImportOperation" */
	core_ImportOperation_aggregate: Core_ImportOperation_Aggregate
	/** fetch data from the table: "core.ImportOperation" using primary key columns */
	core_ImportOperation_by_pk?: Maybe<Core_ImportOperation>
	/** fetch data from the table: "core.MigrationHistory" */
	core_MigrationHistory: Array<Core_MigrationHistory>
	/** fetch aggregated fields from the table: "core.MigrationHistory" */
	core_MigrationHistory_aggregate: Core_MigrationHistory_Aggregate
	/** fetch data from the table: "core.MigrationHistory" using primary key columns */
	core_MigrationHistory_by_pk?: Maybe<Core_MigrationHistory>
	/** fetch data from the table: "core.Project" */
	core_Project: Array<Core_Project>
	/** fetch aggregated fields from the table: "core.Project" */
	core_Project_aggregate: Core_Project_Aggregate
	/** fetch data from the table: "core.Project" using primary key columns */
	core_Project_by_pk?: Maybe<Core_Project>
	/** fetch data from the table: "core.Schemas" */
	core_Schemas: Array<Core_Schemas>
	/** fetch aggregated fields from the table: "core.Schemas" */
	core_Schemas_aggregate: Core_Schemas_Aggregate
	/** fetch data from the table: "core.Schemas" using primary key columns */
	core_Schemas_by_pk?: Maybe<Core_Schemas>
	/** fetch data from the table: "core.Tenant" */
	core_Tenant: Array<Core_Tenant>
	/** fetch data from the table: "core.TenantUser" */
	core_TenantUser: Array<Core_TenantUser>
	/** fetch aggregated fields from the table: "core.TenantUser" */
	core_TenantUser_aggregate: Core_TenantUser_Aggregate
	/** fetch data from the table: "core.TenantUser" using primary key columns */
	core_TenantUser_by_pk?: Maybe<Core_TenantUser>
	/** fetch aggregated fields from the table: "core.Tenant" */
	core_Tenant_aggregate: Core_Tenant_Aggregate
	/** fetch data from the table: "core.Tenant" using primary key columns */
	core_Tenant_by_pk?: Maybe<Core_Tenant>
	/** fetch data from the table: "core.User" */
	core_User: Array<Core_User>
	/** fetch aggregated fields from the table: "core.User" */
	core_User_aggregate: Core_User_Aggregate
	/** fetch data from the table: "core.User" using primary key columns */
	core_User_by_pk?: Maybe<Core_User>
	/** fetch data from the table: "data.asset_roles" */
	data_asset_roles: Array<Data_Asset_Roles>
	/** fetch aggregated fields from the table: "data.asset_roles" */
	data_asset_roles_aggregate: Data_Asset_Roles_Aggregate
	/** fetch data from the table: "data.asset_roles" using primary key columns */
	data_asset_roles_by_pk?: Maybe<Data_Asset_Roles>
	/** fetch data from the table: "data.asset_types" */
	data_asset_types: Array<Data_Asset_Types>
	/** fetch aggregated fields from the table: "data.asset_types" */
	data_asset_types_aggregate: Data_Asset_Types_Aggregate
	/** fetch data from the table: "data.asset_types" using primary key columns */
	data_asset_types_by_pk?: Maybe<Data_Asset_Types>
	/** fetch data from the table: "data.cves" */
	data_cves: Array<Data_Cves>
	/** fetch aggregated fields from the table: "data.cves" */
	data_cves_aggregate: Data_Cves_Aggregate
	/** fetch data from the table: "data.cves" using primary key columns */
	data_cves_by_pk?: Maybe<Data_Cves>
	/** fetch data from the table: "data.network_protocols" */
	data_network_protocols: Array<Data_Network_Protocols>
	/** fetch aggregated fields from the table: "data.network_protocols" */
	data_network_protocols_aggregate: Data_Network_Protocols_Aggregate
	/** fetch data from the table: "data.network_protocols" using primary key columns */
	data_network_protocols_by_pk?: Maybe<Data_Network_Protocols>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
	p_asset_applications: Array<P_Asset_Applications>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
	p_asset_applications_aggregate: P_Asset_Applications_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" using primary key columns */
	p_asset_applications_by_pk?: Maybe<P_Asset_Applications>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
	p_asset_badges: Array<P_Asset_Badges>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
	p_asset_badges_aggregate: P_Asset_Badges_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" using primary key columns */
	p_asset_badges_by_pk?: Maybe<P_Asset_Badges>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
	p_asset_cves: Array<P_Asset_Cves>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
	p_asset_cves_aggregate: P_Asset_Cves_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" using primary key columns */
	p_asset_cves_by_pk?: Maybe<P_Asset_Cves>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
	p_asset_distance: Array<P_Asset_Distance>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
	p_asset_distance_aggregate: P_Asset_Distance_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" using primary key columns */
	p_asset_distance_by_pk?: Maybe<P_Asset_Distance>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
	p_asset_hardware: Array<P_Asset_Hardware>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
	p_asset_hardware_aggregate: P_Asset_Hardware_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" using primary key columns */
	p_asset_hardware_by_pk?: Maybe<P_Asset_Hardware>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
	p_asset_hostnames: Array<P_Asset_Hostnames>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
	p_asset_hostnames_aggregate: P_Asset_Hostnames_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" using primary key columns */
	p_asset_hostnames_by_pk?: Maybe<P_Asset_Hostnames>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
	p_asset_interfaces: Array<P_Asset_Interfaces>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
	p_asset_interfaces_aggregate: P_Asset_Interfaces_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" using primary key columns */
	p_asset_interfaces_by_pk?: Maybe<P_Asset_Interfaces>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
	p_asset_os: Array<P_Asset_Os>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
	p_asset_os_aggregate: P_Asset_Os_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" using primary key columns */
	p_asset_os_by_pk?: Maybe<P_Asset_Os>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
	p_asset_roles: Array<P_Asset_Roles>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
	p_asset_roles_aggregate: P_Asset_Roles_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" using primary key columns */
	p_asset_roles_by_pk?: Maybe<P_Asset_Roles>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
	p_asset_services: Array<P_Asset_Services>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
	p_asset_services_aggregate: P_Asset_Services_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" using primary key columns */
	p_asset_services_by_pk?: Maybe<P_Asset_Services>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
	p_asset_tags: Array<P_Asset_Tags>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
	p_asset_tags_aggregate: P_Asset_Tags_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" using primary key columns */
	p_asset_tags_by_pk?: Maybe<P_Asset_Tags>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
	p_assets: Array<P_Assets>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
	p_assets_aggregate: P_Assets_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_assets" using primary key columns */
	p_assets_by_pk?: Maybe<P_Assets>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
	p_broadcast_domains: Array<P_Broadcast_Domains>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
	p_broadcast_domains_aggregate: P_Broadcast_Domains_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" using primary key columns */
	p_broadcast_domains_by_pk?: Maybe<P_Broadcast_Domains>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_packets_minute_protocol_usage" */
	p_pru_m: Array<P_Pru_M>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_packets_minute_protocol_usage" */
	p_pru_m_aggregate: P_Pru_M_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_packets" */
	tenant_14bed87a7c_project_b8d8cd96_packets: Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_packets" */
	tenant_14bed87a7c_project_b8d8cd96_packets_aggregate: Tenant_14bed87a7c_Project_B8d8cd96_Packets_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_packets_hourly_protocol_usage" */
	tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage: Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_packets_hourly_protocol_usage" */
	tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage_aggregate: Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_packets_second_protocol_usage" */
	tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage: Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_packets_second_protocol_usage" */
	tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage_aggregate: Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Aggregate
}

export type Query_RootCore_ImportOperationArgs = {
	distinct_on?: InputMaybe<Array<Core_ImportOperation_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_ImportOperation_Order_By>>
	where?: InputMaybe<Core_ImportOperation_Bool_Exp>
}

export type Query_RootCore_ImportOperation_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_ImportOperation_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_ImportOperation_Order_By>>
	where?: InputMaybe<Core_ImportOperation_Bool_Exp>
}

export type Query_RootCore_ImportOperation_By_PkArgs = {
	id: Scalars["String"]
}

export type Query_RootCore_MigrationHistoryArgs = {
	distinct_on?: InputMaybe<Array<Core_MigrationHistory_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_MigrationHistory_Order_By>>
	where?: InputMaybe<Core_MigrationHistory_Bool_Exp>
}

export type Query_RootCore_MigrationHistory_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_MigrationHistory_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_MigrationHistory_Order_By>>
	where?: InputMaybe<Core_MigrationHistory_Bool_Exp>
}

export type Query_RootCore_MigrationHistory_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Query_RootCore_ProjectArgs = {
	distinct_on?: InputMaybe<Array<Core_Project_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Project_Order_By>>
	where?: InputMaybe<Core_Project_Bool_Exp>
}

export type Query_RootCore_Project_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_Project_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Project_Order_By>>
	where?: InputMaybe<Core_Project_Bool_Exp>
}

export type Query_RootCore_Project_By_PkArgs = {
	id: Scalars["String"]
}

export type Query_RootCore_SchemasArgs = {
	distinct_on?: InputMaybe<Array<Core_Schemas_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Schemas_Order_By>>
	where?: InputMaybe<Core_Schemas_Bool_Exp>
}

export type Query_RootCore_Schemas_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_Schemas_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Schemas_Order_By>>
	where?: InputMaybe<Core_Schemas_Bool_Exp>
}

export type Query_RootCore_Schemas_By_PkArgs = {
	name: Scalars["String"]
}

export type Query_RootCore_TenantArgs = {
	distinct_on?: InputMaybe<Array<Core_Tenant_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Tenant_Order_By>>
	where?: InputMaybe<Core_Tenant_Bool_Exp>
}

export type Query_RootCore_TenantUserArgs = {
	distinct_on?: InputMaybe<Array<Core_TenantUser_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_TenantUser_Order_By>>
	where?: InputMaybe<Core_TenantUser_Bool_Exp>
}

export type Query_RootCore_TenantUser_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_TenantUser_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_TenantUser_Order_By>>
	where?: InputMaybe<Core_TenantUser_Bool_Exp>
}

export type Query_RootCore_TenantUser_By_PkArgs = {
	tenantID: Scalars["String"]
	userID: Scalars["String"]
}

export type Query_RootCore_Tenant_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_Tenant_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Tenant_Order_By>>
	where?: InputMaybe<Core_Tenant_Bool_Exp>
}

export type Query_RootCore_Tenant_By_PkArgs = {
	id: Scalars["String"]
}

export type Query_RootCore_UserArgs = {
	distinct_on?: InputMaybe<Array<Core_User_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_User_Order_By>>
	where?: InputMaybe<Core_User_Bool_Exp>
}

export type Query_RootCore_User_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_User_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_User_Order_By>>
	where?: InputMaybe<Core_User_Bool_Exp>
}

export type Query_RootCore_User_By_PkArgs = {
	id: Scalars["String"]
}

export type Query_RootData_Asset_RolesArgs = {
	distinct_on?: InputMaybe<Array<Data_Asset_Roles_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Asset_Roles_Order_By>>
	where?: InputMaybe<Data_Asset_Roles_Bool_Exp>
}

export type Query_RootData_Asset_Roles_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Data_Asset_Roles_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Asset_Roles_Order_By>>
	where?: InputMaybe<Data_Asset_Roles_Bool_Exp>
}

export type Query_RootData_Asset_Roles_By_PkArgs = {
	id: Scalars["String"]
}

export type Query_RootData_Asset_TypesArgs = {
	distinct_on?: InputMaybe<Array<Data_Asset_Types_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Asset_Types_Order_By>>
	where?: InputMaybe<Data_Asset_Types_Bool_Exp>
}

export type Query_RootData_Asset_Types_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Data_Asset_Types_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Asset_Types_Order_By>>
	where?: InputMaybe<Data_Asset_Types_Bool_Exp>
}

export type Query_RootData_Asset_Types_By_PkArgs = {
	id: Scalars["Int"]
}

export type Query_RootData_CvesArgs = {
	distinct_on?: InputMaybe<Array<Data_Cves_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Cves_Order_By>>
	where?: InputMaybe<Data_Cves_Bool_Exp>
}

export type Query_RootData_Cves_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Data_Cves_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Cves_Order_By>>
	where?: InputMaybe<Data_Cves_Bool_Exp>
}

export type Query_RootData_Cves_By_PkArgs = {
	id: Scalars["String"]
}

export type Query_RootData_Network_ProtocolsArgs = {
	distinct_on?: InputMaybe<Array<Data_Network_Protocols_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Network_Protocols_Order_By>>
	where?: InputMaybe<Data_Network_Protocols_Bool_Exp>
}

export type Query_RootData_Network_Protocols_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Data_Network_Protocols_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Network_Protocols_Order_By>>
	where?: InputMaybe<Data_Network_Protocols_Bool_Exp>
}

export type Query_RootData_Network_Protocols_By_PkArgs = {
	id: Scalars["Int"]
}

export type Query_RootP_Asset_ApplicationsArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Applications_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Applications_Order_By>>
	where?: InputMaybe<P_Asset_Applications_Bool_Exp>
}

export type Query_RootP_Asset_Applications_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Applications_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Applications_Order_By>>
	where?: InputMaybe<P_Asset_Applications_Bool_Exp>
}

export type Query_RootP_Asset_Applications_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Query_RootP_Asset_BadgesArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Badges_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Badges_Order_By>>
	where?: InputMaybe<P_Asset_Badges_Bool_Exp>
}

export type Query_RootP_Asset_Badges_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Badges_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Badges_Order_By>>
	where?: InputMaybe<P_Asset_Badges_Bool_Exp>
}

export type Query_RootP_Asset_Badges_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Query_RootP_Asset_CvesArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Cves_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Cves_Order_By>>
	where?: InputMaybe<P_Asset_Cves_Bool_Exp>
}

export type Query_RootP_Asset_Cves_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Cves_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Cves_Order_By>>
	where?: InputMaybe<P_Asset_Cves_Bool_Exp>
}

export type Query_RootP_Asset_Cves_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Query_RootP_Asset_DistanceArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Distance_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Distance_Order_By>>
	where?: InputMaybe<P_Asset_Distance_Bool_Exp>
}

export type Query_RootP_Asset_Distance_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Distance_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Distance_Order_By>>
	where?: InputMaybe<P_Asset_Distance_Bool_Exp>
}

export type Query_RootP_Asset_Distance_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Query_RootP_Asset_HardwareArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Hardware_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Hardware_Order_By>>
	where?: InputMaybe<P_Asset_Hardware_Bool_Exp>
}

export type Query_RootP_Asset_Hardware_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Hardware_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Hardware_Order_By>>
	where?: InputMaybe<P_Asset_Hardware_Bool_Exp>
}

export type Query_RootP_Asset_Hardware_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Query_RootP_Asset_HostnamesArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Hostnames_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Hostnames_Order_By>>
	where?: InputMaybe<P_Asset_Hostnames_Bool_Exp>
}

export type Query_RootP_Asset_Hostnames_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Hostnames_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Hostnames_Order_By>>
	where?: InputMaybe<P_Asset_Hostnames_Bool_Exp>
}

export type Query_RootP_Asset_Hostnames_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Query_RootP_Asset_InterfacesArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Interfaces_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Interfaces_Order_By>>
	where?: InputMaybe<P_Asset_Interfaces_Bool_Exp>
}

export type Query_RootP_Asset_Interfaces_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Interfaces_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Interfaces_Order_By>>
	where?: InputMaybe<P_Asset_Interfaces_Bool_Exp>
}

export type Query_RootP_Asset_Interfaces_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Query_RootP_Asset_OsArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Os_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Os_Order_By>>
	where?: InputMaybe<P_Asset_Os_Bool_Exp>
}

export type Query_RootP_Asset_Os_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Os_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Os_Order_By>>
	where?: InputMaybe<P_Asset_Os_Bool_Exp>
}

export type Query_RootP_Asset_Os_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Query_RootP_Asset_RolesArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Roles_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Roles_Order_By>>
	where?: InputMaybe<P_Asset_Roles_Bool_Exp>
}

export type Query_RootP_Asset_Roles_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Roles_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Roles_Order_By>>
	where?: InputMaybe<P_Asset_Roles_Bool_Exp>
}

export type Query_RootP_Asset_Roles_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Query_RootP_Asset_ServicesArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Services_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Services_Order_By>>
	where?: InputMaybe<P_Asset_Services_Bool_Exp>
}

export type Query_RootP_Asset_Services_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Services_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Services_Order_By>>
	where?: InputMaybe<P_Asset_Services_Bool_Exp>
}

export type Query_RootP_Asset_Services_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Query_RootP_Asset_TagsArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Tags_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Tags_Order_By>>
	where?: InputMaybe<P_Asset_Tags_Bool_Exp>
}

export type Query_RootP_Asset_Tags_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Tags_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Tags_Order_By>>
	where?: InputMaybe<P_Asset_Tags_Bool_Exp>
}

export type Query_RootP_Asset_Tags_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Query_RootP_AssetsArgs = {
	distinct_on?: InputMaybe<Array<P_Assets_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Assets_Order_By>>
	where?: InputMaybe<P_Assets_Bool_Exp>
}

export type Query_RootP_Assets_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Assets_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Assets_Order_By>>
	where?: InputMaybe<P_Assets_Bool_Exp>
}

export type Query_RootP_Assets_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Query_RootP_Broadcast_DomainsArgs = {
	distinct_on?: InputMaybe<Array<P_Broadcast_Domains_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Broadcast_Domains_Order_By>>
	where?: InputMaybe<P_Broadcast_Domains_Bool_Exp>
}

export type Query_RootP_Broadcast_Domains_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Broadcast_Domains_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Broadcast_Domains_Order_By>>
	where?: InputMaybe<P_Broadcast_Domains_Bool_Exp>
}

export type Query_RootP_Broadcast_Domains_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Query_RootP_Pru_MArgs = {
	distinct_on?: InputMaybe<Array<P_Pru_M_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Pru_M_Order_By>>
	where?: InputMaybe<P_Pru_M_Bool_Exp>
}

export type Query_RootP_Pru_M_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Pru_M_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Pru_M_Order_By>>
	where?: InputMaybe<P_Pru_M_Bool_Exp>
}

export type Query_RootTenant_14bed87a7c_Project_B8d8cd96_PacketsArgs = {
	distinct_on?: InputMaybe<
		Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Select_Column>
	>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<
		Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Order_By>
	>
	where?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Bool_Exp>
}

export type Query_RootTenant_14bed87a7c_Project_B8d8cd96_Packets_AggregateArgs =
	{
		distinct_on?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Select_Column>
		>
		limit?: InputMaybe<Scalars["Int"]>
		offset?: InputMaybe<Scalars["Int"]>
		order_by?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Order_By>
		>
		where?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Bool_Exp>
	}

export type Query_RootTenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_UsageArgs =
	{
		distinct_on?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Select_Column>
		>
		limit?: InputMaybe<Scalars["Int"]>
		offset?: InputMaybe<Scalars["Int"]>
		order_by?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Order_By>
		>
		where?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Bool_Exp>
	}

export type Query_RootTenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_AggregateArgs =
	{
		distinct_on?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Select_Column>
		>
		limit?: InputMaybe<Scalars["Int"]>
		offset?: InputMaybe<Scalars["Int"]>
		order_by?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Order_By>
		>
		where?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Bool_Exp>
	}

export type Query_RootTenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_UsageArgs =
	{
		distinct_on?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Select_Column>
		>
		limit?: InputMaybe<Scalars["Int"]>
		offset?: InputMaybe<Scalars["Int"]>
		order_by?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Order_By>
		>
		where?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Bool_Exp>
	}

export type Query_RootTenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_AggregateArgs =
	{
		distinct_on?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Select_Column>
		>
		limit?: InputMaybe<Scalars["Int"]>
		offset?: InputMaybe<Scalars["Int"]>
		order_by?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Order_By>
		>
		where?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Bool_Exp>
	}

export type Subscription_Root = {
	__typename?: "subscription_root"
	/** fetch data from the table: "core.ImportOperation" */
	core_ImportOperation: Array<Core_ImportOperation>
	/** fetch aggregated fields from the table: "core.ImportOperation" */
	core_ImportOperation_aggregate: Core_ImportOperation_Aggregate
	/** fetch data from the table: "core.ImportOperation" using primary key columns */
	core_ImportOperation_by_pk?: Maybe<Core_ImportOperation>
	/** fetch data from the table: "core.MigrationHistory" */
	core_MigrationHistory: Array<Core_MigrationHistory>
	/** fetch aggregated fields from the table: "core.MigrationHistory" */
	core_MigrationHistory_aggregate: Core_MigrationHistory_Aggregate
	/** fetch data from the table: "core.MigrationHistory" using primary key columns */
	core_MigrationHistory_by_pk?: Maybe<Core_MigrationHistory>
	/** fetch data from the table: "core.Project" */
	core_Project: Array<Core_Project>
	/** fetch aggregated fields from the table: "core.Project" */
	core_Project_aggregate: Core_Project_Aggregate
	/** fetch data from the table: "core.Project" using primary key columns */
	core_Project_by_pk?: Maybe<Core_Project>
	/** fetch data from the table: "core.Schemas" */
	core_Schemas: Array<Core_Schemas>
	/** fetch aggregated fields from the table: "core.Schemas" */
	core_Schemas_aggregate: Core_Schemas_Aggregate
	/** fetch data from the table: "core.Schemas" using primary key columns */
	core_Schemas_by_pk?: Maybe<Core_Schemas>
	/** fetch data from the table: "core.Tenant" */
	core_Tenant: Array<Core_Tenant>
	/** fetch data from the table: "core.TenantUser" */
	core_TenantUser: Array<Core_TenantUser>
	/** fetch aggregated fields from the table: "core.TenantUser" */
	core_TenantUser_aggregate: Core_TenantUser_Aggregate
	/** fetch data from the table: "core.TenantUser" using primary key columns */
	core_TenantUser_by_pk?: Maybe<Core_TenantUser>
	/** fetch aggregated fields from the table: "core.Tenant" */
	core_Tenant_aggregate: Core_Tenant_Aggregate
	/** fetch data from the table: "core.Tenant" using primary key columns */
	core_Tenant_by_pk?: Maybe<Core_Tenant>
	/** fetch data from the table: "core.User" */
	core_User: Array<Core_User>
	/** fetch aggregated fields from the table: "core.User" */
	core_User_aggregate: Core_User_Aggregate
	/** fetch data from the table: "core.User" using primary key columns */
	core_User_by_pk?: Maybe<Core_User>
	/** fetch data from the table: "data.asset_roles" */
	data_asset_roles: Array<Data_Asset_Roles>
	/** fetch aggregated fields from the table: "data.asset_roles" */
	data_asset_roles_aggregate: Data_Asset_Roles_Aggregate
	/** fetch data from the table: "data.asset_roles" using primary key columns */
	data_asset_roles_by_pk?: Maybe<Data_Asset_Roles>
	/** fetch data from the table: "data.asset_types" */
	data_asset_types: Array<Data_Asset_Types>
	/** fetch aggregated fields from the table: "data.asset_types" */
	data_asset_types_aggregate: Data_Asset_Types_Aggregate
	/** fetch data from the table: "data.asset_types" using primary key columns */
	data_asset_types_by_pk?: Maybe<Data_Asset_Types>
	/** fetch data from the table: "data.cves" */
	data_cves: Array<Data_Cves>
	/** fetch aggregated fields from the table: "data.cves" */
	data_cves_aggregate: Data_Cves_Aggregate
	/** fetch data from the table: "data.cves" using primary key columns */
	data_cves_by_pk?: Maybe<Data_Cves>
	/** fetch data from the table: "data.network_protocols" */
	data_network_protocols: Array<Data_Network_Protocols>
	/** fetch aggregated fields from the table: "data.network_protocols" */
	data_network_protocols_aggregate: Data_Network_Protocols_Aggregate
	/** fetch data from the table: "data.network_protocols" using primary key columns */
	data_network_protocols_by_pk?: Maybe<Data_Network_Protocols>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
	p_asset_applications: Array<P_Asset_Applications>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" */
	p_asset_applications_aggregate: P_Asset_Applications_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_applications" using primary key columns */
	p_asset_applications_by_pk?: Maybe<P_Asset_Applications>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
	p_asset_badges: Array<P_Asset_Badges>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" */
	p_asset_badges_aggregate: P_Asset_Badges_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_badges" using primary key columns */
	p_asset_badges_by_pk?: Maybe<P_Asset_Badges>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
	p_asset_cves: Array<P_Asset_Cves>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" */
	p_asset_cves_aggregate: P_Asset_Cves_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_cves" using primary key columns */
	p_asset_cves_by_pk?: Maybe<P_Asset_Cves>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
	p_asset_distance: Array<P_Asset_Distance>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" */
	p_asset_distance_aggregate: P_Asset_Distance_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_distance" using primary key columns */
	p_asset_distance_by_pk?: Maybe<P_Asset_Distance>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
	p_asset_hardware: Array<P_Asset_Hardware>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" */
	p_asset_hardware_aggregate: P_Asset_Hardware_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hardware" using primary key columns */
	p_asset_hardware_by_pk?: Maybe<P_Asset_Hardware>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
	p_asset_hostnames: Array<P_Asset_Hostnames>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" */
	p_asset_hostnames_aggregate: P_Asset_Hostnames_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_hostnames" using primary key columns */
	p_asset_hostnames_by_pk?: Maybe<P_Asset_Hostnames>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
	p_asset_interfaces: Array<P_Asset_Interfaces>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" */
	p_asset_interfaces_aggregate: P_Asset_Interfaces_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_interfaces" using primary key columns */
	p_asset_interfaces_by_pk?: Maybe<P_Asset_Interfaces>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
	p_asset_os: Array<P_Asset_Os>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" */
	p_asset_os_aggregate: P_Asset_Os_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_os" using primary key columns */
	p_asset_os_by_pk?: Maybe<P_Asset_Os>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
	p_asset_roles: Array<P_Asset_Roles>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" */
	p_asset_roles_aggregate: P_Asset_Roles_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_roles" using primary key columns */
	p_asset_roles_by_pk?: Maybe<P_Asset_Roles>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
	p_asset_services: Array<P_Asset_Services>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" */
	p_asset_services_aggregate: P_Asset_Services_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_services" using primary key columns */
	p_asset_services_by_pk?: Maybe<P_Asset_Services>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
	p_asset_tags: Array<P_Asset_Tags>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" */
	p_asset_tags_aggregate: P_Asset_Tags_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_asset_tags" using primary key columns */
	p_asset_tags_by_pk?: Maybe<P_Asset_Tags>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
	p_assets: Array<P_Assets>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_assets" */
	p_assets_aggregate: P_Assets_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_assets" using primary key columns */
	p_assets_by_pk?: Maybe<P_Assets>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
	p_broadcast_domains: Array<P_Broadcast_Domains>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" */
	p_broadcast_domains_aggregate: P_Broadcast_Domains_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_topology_broadcast_domains" using primary key columns */
	p_broadcast_domains_by_pk?: Maybe<P_Broadcast_Domains>
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_packets_minute_protocol_usage" */
	p_pru_m: Array<P_Pru_M>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_packets_minute_protocol_usage" */
	p_pru_m_aggregate: P_Pru_M_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_packets" */
	tenant_14bed87a7c_project_b8d8cd96_packets: Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_packets" */
	tenant_14bed87a7c_project_b8d8cd96_packets_aggregate: Tenant_14bed87a7c_Project_B8d8cd96_Packets_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_packets_hourly_protocol_usage" */
	tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage: Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_packets_hourly_protocol_usage" */
	tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage_aggregate: Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Aggregate
	/** fetch data from the table: "tenant_14bed87a7c.project_b8d8cd96_packets_second_protocol_usage" */
	tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage: Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage>
	/** fetch aggregated fields from the table: "tenant_14bed87a7c.project_b8d8cd96_packets_second_protocol_usage" */
	tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage_aggregate: Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Aggregate
}

export type Subscription_RootCore_ImportOperationArgs = {
	distinct_on?: InputMaybe<Array<Core_ImportOperation_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_ImportOperation_Order_By>>
	where?: InputMaybe<Core_ImportOperation_Bool_Exp>
}

export type Subscription_RootCore_ImportOperation_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_ImportOperation_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_ImportOperation_Order_By>>
	where?: InputMaybe<Core_ImportOperation_Bool_Exp>
}

export type Subscription_RootCore_ImportOperation_By_PkArgs = {
	id: Scalars["String"]
}

export type Subscription_RootCore_MigrationHistoryArgs = {
	distinct_on?: InputMaybe<Array<Core_MigrationHistory_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_MigrationHistory_Order_By>>
	where?: InputMaybe<Core_MigrationHistory_Bool_Exp>
}

export type Subscription_RootCore_MigrationHistory_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_MigrationHistory_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_MigrationHistory_Order_By>>
	where?: InputMaybe<Core_MigrationHistory_Bool_Exp>
}

export type Subscription_RootCore_MigrationHistory_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Subscription_RootCore_ProjectArgs = {
	distinct_on?: InputMaybe<Array<Core_Project_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Project_Order_By>>
	where?: InputMaybe<Core_Project_Bool_Exp>
}

export type Subscription_RootCore_Project_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_Project_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Project_Order_By>>
	where?: InputMaybe<Core_Project_Bool_Exp>
}

export type Subscription_RootCore_Project_By_PkArgs = {
	id: Scalars["String"]
}

export type Subscription_RootCore_SchemasArgs = {
	distinct_on?: InputMaybe<Array<Core_Schemas_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Schemas_Order_By>>
	where?: InputMaybe<Core_Schemas_Bool_Exp>
}

export type Subscription_RootCore_Schemas_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_Schemas_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Schemas_Order_By>>
	where?: InputMaybe<Core_Schemas_Bool_Exp>
}

export type Subscription_RootCore_Schemas_By_PkArgs = {
	name: Scalars["String"]
}

export type Subscription_RootCore_TenantArgs = {
	distinct_on?: InputMaybe<Array<Core_Tenant_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Tenant_Order_By>>
	where?: InputMaybe<Core_Tenant_Bool_Exp>
}

export type Subscription_RootCore_TenantUserArgs = {
	distinct_on?: InputMaybe<Array<Core_TenantUser_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_TenantUser_Order_By>>
	where?: InputMaybe<Core_TenantUser_Bool_Exp>
}

export type Subscription_RootCore_TenantUser_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_TenantUser_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_TenantUser_Order_By>>
	where?: InputMaybe<Core_TenantUser_Bool_Exp>
}

export type Subscription_RootCore_TenantUser_By_PkArgs = {
	tenantID: Scalars["String"]
	userID: Scalars["String"]
}

export type Subscription_RootCore_Tenant_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_Tenant_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_Tenant_Order_By>>
	where?: InputMaybe<Core_Tenant_Bool_Exp>
}

export type Subscription_RootCore_Tenant_By_PkArgs = {
	id: Scalars["String"]
}

export type Subscription_RootCore_UserArgs = {
	distinct_on?: InputMaybe<Array<Core_User_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_User_Order_By>>
	where?: InputMaybe<Core_User_Bool_Exp>
}

export type Subscription_RootCore_User_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Core_User_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Core_User_Order_By>>
	where?: InputMaybe<Core_User_Bool_Exp>
}

export type Subscription_RootCore_User_By_PkArgs = {
	id: Scalars["String"]
}

export type Subscription_RootData_Asset_RolesArgs = {
	distinct_on?: InputMaybe<Array<Data_Asset_Roles_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Asset_Roles_Order_By>>
	where?: InputMaybe<Data_Asset_Roles_Bool_Exp>
}

export type Subscription_RootData_Asset_Roles_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Data_Asset_Roles_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Asset_Roles_Order_By>>
	where?: InputMaybe<Data_Asset_Roles_Bool_Exp>
}

export type Subscription_RootData_Asset_Roles_By_PkArgs = {
	id: Scalars["String"]
}

export type Subscription_RootData_Asset_TypesArgs = {
	distinct_on?: InputMaybe<Array<Data_Asset_Types_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Asset_Types_Order_By>>
	where?: InputMaybe<Data_Asset_Types_Bool_Exp>
}

export type Subscription_RootData_Asset_Types_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Data_Asset_Types_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Asset_Types_Order_By>>
	where?: InputMaybe<Data_Asset_Types_Bool_Exp>
}

export type Subscription_RootData_Asset_Types_By_PkArgs = {
	id: Scalars["Int"]
}

export type Subscription_RootData_CvesArgs = {
	distinct_on?: InputMaybe<Array<Data_Cves_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Cves_Order_By>>
	where?: InputMaybe<Data_Cves_Bool_Exp>
}

export type Subscription_RootData_Cves_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Data_Cves_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Cves_Order_By>>
	where?: InputMaybe<Data_Cves_Bool_Exp>
}

export type Subscription_RootData_Cves_By_PkArgs = {
	id: Scalars["String"]
}

export type Subscription_RootData_Network_ProtocolsArgs = {
	distinct_on?: InputMaybe<Array<Data_Network_Protocols_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Network_Protocols_Order_By>>
	where?: InputMaybe<Data_Network_Protocols_Bool_Exp>
}

export type Subscription_RootData_Network_Protocols_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Data_Network_Protocols_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<Data_Network_Protocols_Order_By>>
	where?: InputMaybe<Data_Network_Protocols_Bool_Exp>
}

export type Subscription_RootData_Network_Protocols_By_PkArgs = {
	id: Scalars["Int"]
}

export type Subscription_RootP_Asset_ApplicationsArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Applications_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Applications_Order_By>>
	where?: InputMaybe<P_Asset_Applications_Bool_Exp>
}

export type Subscription_RootP_Asset_Applications_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Applications_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Applications_Order_By>>
	where?: InputMaybe<P_Asset_Applications_Bool_Exp>
}

export type Subscription_RootP_Asset_Applications_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Subscription_RootP_Asset_BadgesArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Badges_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Badges_Order_By>>
	where?: InputMaybe<P_Asset_Badges_Bool_Exp>
}

export type Subscription_RootP_Asset_Badges_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Badges_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Badges_Order_By>>
	where?: InputMaybe<P_Asset_Badges_Bool_Exp>
}

export type Subscription_RootP_Asset_Badges_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Subscription_RootP_Asset_CvesArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Cves_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Cves_Order_By>>
	where?: InputMaybe<P_Asset_Cves_Bool_Exp>
}

export type Subscription_RootP_Asset_Cves_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Cves_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Cves_Order_By>>
	where?: InputMaybe<P_Asset_Cves_Bool_Exp>
}

export type Subscription_RootP_Asset_Cves_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Subscription_RootP_Asset_DistanceArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Distance_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Distance_Order_By>>
	where?: InputMaybe<P_Asset_Distance_Bool_Exp>
}

export type Subscription_RootP_Asset_Distance_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Distance_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Distance_Order_By>>
	where?: InputMaybe<P_Asset_Distance_Bool_Exp>
}

export type Subscription_RootP_Asset_Distance_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Subscription_RootP_Asset_HardwareArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Hardware_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Hardware_Order_By>>
	where?: InputMaybe<P_Asset_Hardware_Bool_Exp>
}

export type Subscription_RootP_Asset_Hardware_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Hardware_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Hardware_Order_By>>
	where?: InputMaybe<P_Asset_Hardware_Bool_Exp>
}

export type Subscription_RootP_Asset_Hardware_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Subscription_RootP_Asset_HostnamesArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Hostnames_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Hostnames_Order_By>>
	where?: InputMaybe<P_Asset_Hostnames_Bool_Exp>
}

export type Subscription_RootP_Asset_Hostnames_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Hostnames_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Hostnames_Order_By>>
	where?: InputMaybe<P_Asset_Hostnames_Bool_Exp>
}

export type Subscription_RootP_Asset_Hostnames_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Subscription_RootP_Asset_InterfacesArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Interfaces_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Interfaces_Order_By>>
	where?: InputMaybe<P_Asset_Interfaces_Bool_Exp>
}

export type Subscription_RootP_Asset_Interfaces_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Interfaces_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Interfaces_Order_By>>
	where?: InputMaybe<P_Asset_Interfaces_Bool_Exp>
}

export type Subscription_RootP_Asset_Interfaces_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Subscription_RootP_Asset_OsArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Os_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Os_Order_By>>
	where?: InputMaybe<P_Asset_Os_Bool_Exp>
}

export type Subscription_RootP_Asset_Os_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Os_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Os_Order_By>>
	where?: InputMaybe<P_Asset_Os_Bool_Exp>
}

export type Subscription_RootP_Asset_Os_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Subscription_RootP_Asset_RolesArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Roles_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Roles_Order_By>>
	where?: InputMaybe<P_Asset_Roles_Bool_Exp>
}

export type Subscription_RootP_Asset_Roles_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Roles_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Roles_Order_By>>
	where?: InputMaybe<P_Asset_Roles_Bool_Exp>
}

export type Subscription_RootP_Asset_Roles_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Subscription_RootP_Asset_ServicesArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Services_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Services_Order_By>>
	where?: InputMaybe<P_Asset_Services_Bool_Exp>
}

export type Subscription_RootP_Asset_Services_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Services_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Services_Order_By>>
	where?: InputMaybe<P_Asset_Services_Bool_Exp>
}

export type Subscription_RootP_Asset_Services_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Subscription_RootP_Asset_TagsArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Tags_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Tags_Order_By>>
	where?: InputMaybe<P_Asset_Tags_Bool_Exp>
}

export type Subscription_RootP_Asset_Tags_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Asset_Tags_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Asset_Tags_Order_By>>
	where?: InputMaybe<P_Asset_Tags_Bool_Exp>
}

export type Subscription_RootP_Asset_Tags_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Subscription_RootP_AssetsArgs = {
	distinct_on?: InputMaybe<Array<P_Assets_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Assets_Order_By>>
	where?: InputMaybe<P_Assets_Bool_Exp>
}

export type Subscription_RootP_Assets_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Assets_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Assets_Order_By>>
	where?: InputMaybe<P_Assets_Bool_Exp>
}

export type Subscription_RootP_Assets_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Subscription_RootP_Broadcast_DomainsArgs = {
	distinct_on?: InputMaybe<Array<P_Broadcast_Domains_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Broadcast_Domains_Order_By>>
	where?: InputMaybe<P_Broadcast_Domains_Bool_Exp>
}

export type Subscription_RootP_Broadcast_Domains_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Broadcast_Domains_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Broadcast_Domains_Order_By>>
	where?: InputMaybe<P_Broadcast_Domains_Bool_Exp>
}

export type Subscription_RootP_Broadcast_Domains_By_PkArgs = {
	id: Scalars["bigint"]
}

export type Subscription_RootP_Pru_MArgs = {
	distinct_on?: InputMaybe<Array<P_Pru_M_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Pru_M_Order_By>>
	where?: InputMaybe<P_Pru_M_Bool_Exp>
}

export type Subscription_RootP_Pru_M_AggregateArgs = {
	distinct_on?: InputMaybe<Array<P_Pru_M_Select_Column>>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<Array<P_Pru_M_Order_By>>
	where?: InputMaybe<P_Pru_M_Bool_Exp>
}

export type Subscription_RootTenant_14bed87a7c_Project_B8d8cd96_PacketsArgs = {
	distinct_on?: InputMaybe<
		Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Select_Column>
	>
	limit?: InputMaybe<Scalars["Int"]>
	offset?: InputMaybe<Scalars["Int"]>
	order_by?: InputMaybe<
		Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Order_By>
	>
	where?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Bool_Exp>
}

export type Subscription_RootTenant_14bed87a7c_Project_B8d8cd96_Packets_AggregateArgs =
	{
		distinct_on?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Select_Column>
		>
		limit?: InputMaybe<Scalars["Int"]>
		offset?: InputMaybe<Scalars["Int"]>
		order_by?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Order_By>
		>
		where?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Bool_Exp>
	}

export type Subscription_RootTenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_UsageArgs =
	{
		distinct_on?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Select_Column>
		>
		limit?: InputMaybe<Scalars["Int"]>
		offset?: InputMaybe<Scalars["Int"]>
		order_by?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Order_By>
		>
		where?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Bool_Exp>
	}

export type Subscription_RootTenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_AggregateArgs =
	{
		distinct_on?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Select_Column>
		>
		limit?: InputMaybe<Scalars["Int"]>
		offset?: InputMaybe<Scalars["Int"]>
		order_by?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Order_By>
		>
		where?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Bool_Exp>
	}

export type Subscription_RootTenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_UsageArgs =
	{
		distinct_on?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Select_Column>
		>
		limit?: InputMaybe<Scalars["Int"]>
		offset?: InputMaybe<Scalars["Int"]>
		order_by?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Order_By>
		>
		where?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Bool_Exp>
	}

export type Subscription_RootTenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_AggregateArgs =
	{
		distinct_on?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Select_Column>
		>
		limit?: InputMaybe<Scalars["Int"]>
		offset?: InputMaybe<Scalars["Int"]>
		order_by?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Order_By>
		>
		where?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Bool_Exp>
	}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_packets" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets"
	bytessentcs?: Maybe<Scalars["bigint"]>
	bytessentsc?: Maybe<Scalars["bigint"]>
	filename: Scalars["String"]
	filetype: Scalars["String"]
	id: Scalars["bigint"]
	l2dest?: Maybe<Scalars["String"]>
	l2headersize?: Maybe<Scalars["Int"]>
	l2payloadsize?: Maybe<Scalars["Int"]>
	l2protocol?: Maybe<Scalars["String"]>
	l2source?: Maybe<Scalars["String"]>
	l2tunnelid?: Maybe<Scalars["Int"]>
	l2tunneltype?: Maybe<Scalars["String"]>
	l3dest?: Maybe<Scalars["String"]>
	l3headersize?: Maybe<Scalars["Int"]>
	l3payloadsize?: Maybe<Scalars["Int"]>
	l3protocol?: Maybe<Scalars["String"]>
	l3source?: Maybe<Scalars["String"]>
	l3ttl?: Maybe<Scalars["Int"]>
	l3tunnelid?: Maybe<Scalars["String"]>
	l3tunneltype?: Maybe<Scalars["String"]>
	l4dest?: Maybe<Scalars["Int"]>
	l4headersize?: Maybe<Scalars["Int"]>
	l4payloadsize?: Maybe<Scalars["Int"]>
	l4protocol?: Maybe<Scalars["String"]>
	l4source?: Maybe<Scalars["Int"]>
	l4stack?: Maybe<Scalars["String"]>
	l7protocol?: Maybe<Scalars["String"]>
	metadata?: Maybe<Scalars["jsonb"]>
	packetnumber?: Maybe<Scalars["bigint"]>
	packetoffset?: Maybe<Scalars["bigint"]>
	packetsize?: Maybe<Scalars["Int"]>
	packetssentcs?: Maybe<Scalars["bigint"]>
	packetssentsc?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	sessionid?: Maybe<Scalars["String"]>
	taplocation?: Maybe<Scalars["String"]>
	taptype?: Maybe<Scalars["String"]>
	time: Scalars["bigint"]
	timeend?: Maybe<Scalars["bigint"]>
	timeoffset: Scalars["String"]
	totalbytes?: Maybe<Scalars["bigint"]>
	totalpackets?: Maybe<Scalars["bigint"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_packets" */
export type Tenant_14bed87a7c_Project_B8d8cd96_PacketsMetadataArgs = {
	path?: InputMaybe<Scalars["String"]>
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_packets" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Aggregate = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_aggregate"
	aggregate?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Aggregate_Fields>
	nodes: Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_packets" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Aggregate_Fields = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_aggregate_fields"
	avg?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Avg_Fields>
	count: Scalars["Int"]
	max?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Max_Fields>
	min?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Min_Fields>
	stddev?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Stddev_Fields>
	stddev_pop?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Stddev_Pop_Fields>
	stddev_samp?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Stddev_Samp_Fields>
	sum?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Sum_Fields>
	var_pop?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Var_Pop_Fields>
	var_samp?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Var_Samp_Fields>
	variance?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Variance_Fields>
}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_packets" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Aggregate_FieldsCountArgs =
	{
		columns?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Select_Column>
		>
		distinct?: InputMaybe<Scalars["Boolean"]>
	}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Append_Input = {
	metadata?: InputMaybe<Scalars["jsonb"]>
}

/** aggregate avg on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Avg_Fields = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_avg_fields"
	bytessentcs?: Maybe<Scalars["Float"]>
	bytessentsc?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	l2headersize?: Maybe<Scalars["Float"]>
	l2payloadsize?: Maybe<Scalars["Float"]>
	l2tunnelid?: Maybe<Scalars["Float"]>
	l3headersize?: Maybe<Scalars["Float"]>
	l3payloadsize?: Maybe<Scalars["Float"]>
	l3ttl?: Maybe<Scalars["Float"]>
	l4dest?: Maybe<Scalars["Float"]>
	l4headersize?: Maybe<Scalars["Float"]>
	l4payloadsize?: Maybe<Scalars["Float"]>
	l4source?: Maybe<Scalars["Float"]>
	packetnumber?: Maybe<Scalars["Float"]>
	packetoffset?: Maybe<Scalars["Float"]>
	packetsize?: Maybe<Scalars["Float"]>
	packetssentcs?: Maybe<Scalars["Float"]>
	packetssentsc?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
	totalbytes?: Maybe<Scalars["Float"]>
	totalpackets?: Maybe<Scalars["Float"]>
}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_packets". All fields are combined with a logical 'AND'. */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Bool_Exp = {
	_and?: InputMaybe<Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Bool_Exp>>
	_not?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Bool_Exp>
	_or?: InputMaybe<Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Bool_Exp>>
	bytessentcs?: InputMaybe<Bigint_Comparison_Exp>
	bytessentsc?: InputMaybe<Bigint_Comparison_Exp>
	filename?: InputMaybe<String_Comparison_Exp>
	filetype?: InputMaybe<String_Comparison_Exp>
	id?: InputMaybe<Bigint_Comparison_Exp>
	l2dest?: InputMaybe<String_Comparison_Exp>
	l2headersize?: InputMaybe<Int_Comparison_Exp>
	l2payloadsize?: InputMaybe<Int_Comparison_Exp>
	l2protocol?: InputMaybe<String_Comparison_Exp>
	l2source?: InputMaybe<String_Comparison_Exp>
	l2tunnelid?: InputMaybe<Int_Comparison_Exp>
	l2tunneltype?: InputMaybe<String_Comparison_Exp>
	l3dest?: InputMaybe<String_Comparison_Exp>
	l3headersize?: InputMaybe<Int_Comparison_Exp>
	l3payloadsize?: InputMaybe<Int_Comparison_Exp>
	l3protocol?: InputMaybe<String_Comparison_Exp>
	l3source?: InputMaybe<String_Comparison_Exp>
	l3ttl?: InputMaybe<Int_Comparison_Exp>
	l3tunnelid?: InputMaybe<String_Comparison_Exp>
	l3tunneltype?: InputMaybe<String_Comparison_Exp>
	l4dest?: InputMaybe<Int_Comparison_Exp>
	l4headersize?: InputMaybe<Int_Comparison_Exp>
	l4payloadsize?: InputMaybe<Int_Comparison_Exp>
	l4protocol?: InputMaybe<String_Comparison_Exp>
	l4source?: InputMaybe<Int_Comparison_Exp>
	l4stack?: InputMaybe<String_Comparison_Exp>
	l7protocol?: InputMaybe<String_Comparison_Exp>
	metadata?: InputMaybe<Jsonb_Comparison_Exp>
	packetnumber?: InputMaybe<Bigint_Comparison_Exp>
	packetoffset?: InputMaybe<Bigint_Comparison_Exp>
	packetsize?: InputMaybe<Int_Comparison_Exp>
	packetssentcs?: InputMaybe<Bigint_Comparison_Exp>
	packetssentsc?: InputMaybe<Bigint_Comparison_Exp>
	projectid?: InputMaybe<String_Comparison_Exp>
	sessionid?: InputMaybe<String_Comparison_Exp>
	taplocation?: InputMaybe<String_Comparison_Exp>
	taptype?: InputMaybe<String_Comparison_Exp>
	time?: InputMaybe<Bigint_Comparison_Exp>
	timeend?: InputMaybe<Bigint_Comparison_Exp>
	timeoffset?: InputMaybe<String_Comparison_Exp>
	totalbytes?: InputMaybe<Bigint_Comparison_Exp>
	totalpackets?: InputMaybe<Bigint_Comparison_Exp>
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Delete_At_Path_Input = {
	metadata?: InputMaybe<Array<Scalars["String"]>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Delete_Elem_Input = {
	metadata?: InputMaybe<Scalars["Int"]>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Delete_Key_Input = {
	metadata?: InputMaybe<Scalars["String"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_packets_hourly_protocol_usage" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage"
	bytessentclientserver?: Maybe<Scalars["numeric"]>
	bytesserverclient?: Maybe<Scalars["numeric"]>
	hour?: Maybe<Scalars["bigint"]>
	l7protocol?: Maybe<Scalars["String"]>
	packetsclientserver?: Maybe<Scalars["numeric"]>
	packetsserverclient?: Maybe<Scalars["numeric"]>
	project_id?: Maybe<Scalars["String"]>
	totalbytes?: Maybe<Scalars["numeric"]>
	totalpackets?: Maybe<Scalars["numeric"]>
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_packets_hourly_protocol_usage" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Aggregate =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage_aggregate"
		aggregate?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Aggregate_Fields>
		nodes: Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage>
	}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_packets_hourly_protocol_usage" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Aggregate_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage_aggregate_fields"
		avg?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Avg_Fields>
		count: Scalars["Int"]
		max?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Max_Fields>
		min?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Min_Fields>
		stddev?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Stddev_Fields>
		stddev_pop?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Stddev_Pop_Fields>
		stddev_samp?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Stddev_Samp_Fields>
		sum?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Sum_Fields>
		var_pop?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Var_Pop_Fields>
		var_samp?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Var_Samp_Fields>
		variance?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Variance_Fields>
	}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_packets_hourly_protocol_usage" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Aggregate_FieldsCountArgs =
	{
		columns?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Select_Column>
		>
		distinct?: InputMaybe<Scalars["Boolean"]>
	}

/** aggregate avg on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Avg_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage_avg_fields"
		bytessentclientserver?: Maybe<Scalars["Float"]>
		bytesserverclient?: Maybe<Scalars["Float"]>
		hour?: Maybe<Scalars["Float"]>
		packetsclientserver?: Maybe<Scalars["Float"]>
		packetsserverclient?: Maybe<Scalars["Float"]>
		totalbytes?: Maybe<Scalars["Float"]>
		totalpackets?: Maybe<Scalars["Float"]>
	}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_packets_hourly_protocol_usage". All fields are combined with a logical 'AND'. */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Bool_Exp =
	{
		_and?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Bool_Exp>
		>
		_not?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Bool_Exp>
		_or?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Bool_Exp>
		>
		bytessentclientserver?: InputMaybe<Numeric_Comparison_Exp>
		bytesserverclient?: InputMaybe<Numeric_Comparison_Exp>
		hour?: InputMaybe<Bigint_Comparison_Exp>
		l7protocol?: InputMaybe<String_Comparison_Exp>
		packetsclientserver?: InputMaybe<Numeric_Comparison_Exp>
		packetsserverclient?: InputMaybe<Numeric_Comparison_Exp>
		project_id?: InputMaybe<String_Comparison_Exp>
		totalbytes?: InputMaybe<Numeric_Comparison_Exp>
		totalpackets?: InputMaybe<Numeric_Comparison_Exp>
	}

/** aggregate max on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Max_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage_max_fields"
		bytessentclientserver?: Maybe<Scalars["numeric"]>
		bytesserverclient?: Maybe<Scalars["numeric"]>
		hour?: Maybe<Scalars["bigint"]>
		l7protocol?: Maybe<Scalars["String"]>
		packetsclientserver?: Maybe<Scalars["numeric"]>
		packetsserverclient?: Maybe<Scalars["numeric"]>
		project_id?: Maybe<Scalars["String"]>
		totalbytes?: Maybe<Scalars["numeric"]>
		totalpackets?: Maybe<Scalars["numeric"]>
	}

/** aggregate min on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Min_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage_min_fields"
		bytessentclientserver?: Maybe<Scalars["numeric"]>
		bytesserverclient?: Maybe<Scalars["numeric"]>
		hour?: Maybe<Scalars["bigint"]>
		l7protocol?: Maybe<Scalars["String"]>
		packetsclientserver?: Maybe<Scalars["numeric"]>
		packetsserverclient?: Maybe<Scalars["numeric"]>
		project_id?: Maybe<Scalars["String"]>
		totalbytes?: Maybe<Scalars["numeric"]>
		totalpackets?: Maybe<Scalars["numeric"]>
	}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_packets_hourly_protocol_usage". */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Order_By =
	{
		bytessentclientserver?: InputMaybe<Order_By>
		bytesserverclient?: InputMaybe<Order_By>
		hour?: InputMaybe<Order_By>
		l7protocol?: InputMaybe<Order_By>
		packetsclientserver?: InputMaybe<Order_By>
		packetsserverclient?: InputMaybe<Order_By>
		project_id?: InputMaybe<Order_By>
		totalbytes?: InputMaybe<Order_By>
		totalpackets?: InputMaybe<Order_By>
	}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_packets_hourly_protocol_usage" */
export enum Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Select_Column {
	/** column name */
	Bytessentclientserver = "bytessentclientserver",
	/** column name */
	Bytesserverclient = "bytesserverclient",
	/** column name */
	Hour = "hour",
	/** column name */
	L7protocol = "l7protocol",
	/** column name */
	Packetsclientserver = "packetsclientserver",
	/** column name */
	Packetsserverclient = "packetsserverclient",
	/** column name */
	ProjectId = "project_id",
	/** column name */
	Totalbytes = "totalbytes",
	/** column name */
	Totalpackets = "totalpackets",
}

/** aggregate stddev on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Stddev_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage_stddev_fields"
		bytessentclientserver?: Maybe<Scalars["Float"]>
		bytesserverclient?: Maybe<Scalars["Float"]>
		hour?: Maybe<Scalars["Float"]>
		packetsclientserver?: Maybe<Scalars["Float"]>
		packetsserverclient?: Maybe<Scalars["Float"]>
		totalbytes?: Maybe<Scalars["Float"]>
		totalpackets?: Maybe<Scalars["Float"]>
	}

/** aggregate stddev_pop on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Stddev_Pop_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage_stddev_pop_fields"
		bytessentclientserver?: Maybe<Scalars["Float"]>
		bytesserverclient?: Maybe<Scalars["Float"]>
		hour?: Maybe<Scalars["Float"]>
		packetsclientserver?: Maybe<Scalars["Float"]>
		packetsserverclient?: Maybe<Scalars["Float"]>
		totalbytes?: Maybe<Scalars["Float"]>
		totalpackets?: Maybe<Scalars["Float"]>
	}

/** aggregate stddev_samp on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Stddev_Samp_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage_stddev_samp_fields"
		bytessentclientserver?: Maybe<Scalars["Float"]>
		bytesserverclient?: Maybe<Scalars["Float"]>
		hour?: Maybe<Scalars["Float"]>
		packetsclientserver?: Maybe<Scalars["Float"]>
		packetsserverclient?: Maybe<Scalars["Float"]>
		totalbytes?: Maybe<Scalars["Float"]>
		totalpackets?: Maybe<Scalars["Float"]>
	}

/** aggregate sum on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Sum_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage_sum_fields"
		bytessentclientserver?: Maybe<Scalars["numeric"]>
		bytesserverclient?: Maybe<Scalars["numeric"]>
		hour?: Maybe<Scalars["bigint"]>
		packetsclientserver?: Maybe<Scalars["numeric"]>
		packetsserverclient?: Maybe<Scalars["numeric"]>
		totalbytes?: Maybe<Scalars["numeric"]>
		totalpackets?: Maybe<Scalars["numeric"]>
	}

/** aggregate var_pop on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Var_Pop_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage_var_pop_fields"
		bytessentclientserver?: Maybe<Scalars["Float"]>
		bytesserverclient?: Maybe<Scalars["Float"]>
		hour?: Maybe<Scalars["Float"]>
		packetsclientserver?: Maybe<Scalars["Float"]>
		packetsserverclient?: Maybe<Scalars["Float"]>
		totalbytes?: Maybe<Scalars["Float"]>
		totalpackets?: Maybe<Scalars["Float"]>
	}

/** aggregate var_samp on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Var_Samp_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage_var_samp_fields"
		bytessentclientserver?: Maybe<Scalars["Float"]>
		bytesserverclient?: Maybe<Scalars["Float"]>
		hour?: Maybe<Scalars["Float"]>
		packetsclientserver?: Maybe<Scalars["Float"]>
		packetsserverclient?: Maybe<Scalars["Float"]>
		totalbytes?: Maybe<Scalars["Float"]>
		totalpackets?: Maybe<Scalars["Float"]>
	}

/** aggregate variance on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Hourly_Protocol_Usage_Variance_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_hourly_protocol_usage_variance_fields"
		bytessentclientserver?: Maybe<Scalars["Float"]>
		bytesserverclient?: Maybe<Scalars["Float"]>
		hour?: Maybe<Scalars["Float"]>
		packetsclientserver?: Maybe<Scalars["Float"]>
		packetsserverclient?: Maybe<Scalars["Float"]>
		totalbytes?: Maybe<Scalars["Float"]>
		totalpackets?: Maybe<Scalars["Float"]>
	}

/** input type for incrementing numeric columns in table "tenant_14bed87a7c.project_b8d8cd96_packets" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Inc_Input = {
	bytessentcs?: InputMaybe<Scalars["bigint"]>
	bytessentsc?: InputMaybe<Scalars["bigint"]>
	id?: InputMaybe<Scalars["bigint"]>
	l2headersize?: InputMaybe<Scalars["Int"]>
	l2payloadsize?: InputMaybe<Scalars["Int"]>
	l2tunnelid?: InputMaybe<Scalars["Int"]>
	l3headersize?: InputMaybe<Scalars["Int"]>
	l3payloadsize?: InputMaybe<Scalars["Int"]>
	l3ttl?: InputMaybe<Scalars["Int"]>
	l4dest?: InputMaybe<Scalars["Int"]>
	l4headersize?: InputMaybe<Scalars["Int"]>
	l4payloadsize?: InputMaybe<Scalars["Int"]>
	l4source?: InputMaybe<Scalars["Int"]>
	packetnumber?: InputMaybe<Scalars["bigint"]>
	packetoffset?: InputMaybe<Scalars["bigint"]>
	packetsize?: InputMaybe<Scalars["Int"]>
	packetssentcs?: InputMaybe<Scalars["bigint"]>
	packetssentsc?: InputMaybe<Scalars["bigint"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
	totalbytes?: InputMaybe<Scalars["bigint"]>
	totalpackets?: InputMaybe<Scalars["bigint"]>
}

/** input type for inserting data into table "tenant_14bed87a7c.project_b8d8cd96_packets" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Insert_Input = {
	bytessentcs?: InputMaybe<Scalars["bigint"]>
	bytessentsc?: InputMaybe<Scalars["bigint"]>
	filename?: InputMaybe<Scalars["String"]>
	filetype?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	l2dest?: InputMaybe<Scalars["String"]>
	l2headersize?: InputMaybe<Scalars["Int"]>
	l2payloadsize?: InputMaybe<Scalars["Int"]>
	l2protocol?: InputMaybe<Scalars["String"]>
	l2source?: InputMaybe<Scalars["String"]>
	l2tunnelid?: InputMaybe<Scalars["Int"]>
	l2tunneltype?: InputMaybe<Scalars["String"]>
	l3dest?: InputMaybe<Scalars["String"]>
	l3headersize?: InputMaybe<Scalars["Int"]>
	l3payloadsize?: InputMaybe<Scalars["Int"]>
	l3protocol?: InputMaybe<Scalars["String"]>
	l3source?: InputMaybe<Scalars["String"]>
	l3ttl?: InputMaybe<Scalars["Int"]>
	l3tunnelid?: InputMaybe<Scalars["String"]>
	l3tunneltype?: InputMaybe<Scalars["String"]>
	l4dest?: InputMaybe<Scalars["Int"]>
	l4headersize?: InputMaybe<Scalars["Int"]>
	l4payloadsize?: InputMaybe<Scalars["Int"]>
	l4protocol?: InputMaybe<Scalars["String"]>
	l4source?: InputMaybe<Scalars["Int"]>
	l4stack?: InputMaybe<Scalars["String"]>
	l7protocol?: InputMaybe<Scalars["String"]>
	metadata?: InputMaybe<Scalars["jsonb"]>
	packetnumber?: InputMaybe<Scalars["bigint"]>
	packetoffset?: InputMaybe<Scalars["bigint"]>
	packetsize?: InputMaybe<Scalars["Int"]>
	packetssentcs?: InputMaybe<Scalars["bigint"]>
	packetssentsc?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	sessionid?: InputMaybe<Scalars["String"]>
	taplocation?: InputMaybe<Scalars["String"]>
	taptype?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
	timeoffset?: InputMaybe<Scalars["String"]>
	totalbytes?: InputMaybe<Scalars["bigint"]>
	totalpackets?: InputMaybe<Scalars["bigint"]>
}

/** aggregate max on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Max_Fields = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_max_fields"
	bytessentcs?: Maybe<Scalars["bigint"]>
	bytessentsc?: Maybe<Scalars["bigint"]>
	filename?: Maybe<Scalars["String"]>
	filetype?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	l2dest?: Maybe<Scalars["String"]>
	l2headersize?: Maybe<Scalars["Int"]>
	l2payloadsize?: Maybe<Scalars["Int"]>
	l2protocol?: Maybe<Scalars["String"]>
	l2source?: Maybe<Scalars["String"]>
	l2tunnelid?: Maybe<Scalars["Int"]>
	l2tunneltype?: Maybe<Scalars["String"]>
	l3dest?: Maybe<Scalars["String"]>
	l3headersize?: Maybe<Scalars["Int"]>
	l3payloadsize?: Maybe<Scalars["Int"]>
	l3protocol?: Maybe<Scalars["String"]>
	l3source?: Maybe<Scalars["String"]>
	l3ttl?: Maybe<Scalars["Int"]>
	l3tunnelid?: Maybe<Scalars["String"]>
	l3tunneltype?: Maybe<Scalars["String"]>
	l4dest?: Maybe<Scalars["Int"]>
	l4headersize?: Maybe<Scalars["Int"]>
	l4payloadsize?: Maybe<Scalars["Int"]>
	l4protocol?: Maybe<Scalars["String"]>
	l4source?: Maybe<Scalars["Int"]>
	l4stack?: Maybe<Scalars["String"]>
	l7protocol?: Maybe<Scalars["String"]>
	packetnumber?: Maybe<Scalars["bigint"]>
	packetoffset?: Maybe<Scalars["bigint"]>
	packetsize?: Maybe<Scalars["Int"]>
	packetssentcs?: Maybe<Scalars["bigint"]>
	packetssentsc?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	sessionid?: Maybe<Scalars["String"]>
	taplocation?: Maybe<Scalars["String"]>
	taptype?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
	timeoffset?: Maybe<Scalars["String"]>
	totalbytes?: Maybe<Scalars["bigint"]>
	totalpackets?: Maybe<Scalars["bigint"]>
}

/** aggregate min on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Min_Fields = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_min_fields"
	bytessentcs?: Maybe<Scalars["bigint"]>
	bytessentsc?: Maybe<Scalars["bigint"]>
	filename?: Maybe<Scalars["String"]>
	filetype?: Maybe<Scalars["String"]>
	id?: Maybe<Scalars["bigint"]>
	l2dest?: Maybe<Scalars["String"]>
	l2headersize?: Maybe<Scalars["Int"]>
	l2payloadsize?: Maybe<Scalars["Int"]>
	l2protocol?: Maybe<Scalars["String"]>
	l2source?: Maybe<Scalars["String"]>
	l2tunnelid?: Maybe<Scalars["Int"]>
	l2tunneltype?: Maybe<Scalars["String"]>
	l3dest?: Maybe<Scalars["String"]>
	l3headersize?: Maybe<Scalars["Int"]>
	l3payloadsize?: Maybe<Scalars["Int"]>
	l3protocol?: Maybe<Scalars["String"]>
	l3source?: Maybe<Scalars["String"]>
	l3ttl?: Maybe<Scalars["Int"]>
	l3tunnelid?: Maybe<Scalars["String"]>
	l3tunneltype?: Maybe<Scalars["String"]>
	l4dest?: Maybe<Scalars["Int"]>
	l4headersize?: Maybe<Scalars["Int"]>
	l4payloadsize?: Maybe<Scalars["Int"]>
	l4protocol?: Maybe<Scalars["String"]>
	l4source?: Maybe<Scalars["Int"]>
	l4stack?: Maybe<Scalars["String"]>
	l7protocol?: Maybe<Scalars["String"]>
	packetnumber?: Maybe<Scalars["bigint"]>
	packetoffset?: Maybe<Scalars["bigint"]>
	packetsize?: Maybe<Scalars["Int"]>
	packetssentcs?: Maybe<Scalars["bigint"]>
	packetssentsc?: Maybe<Scalars["bigint"]>
	projectid?: Maybe<Scalars["String"]>
	sessionid?: Maybe<Scalars["String"]>
	taplocation?: Maybe<Scalars["String"]>
	taptype?: Maybe<Scalars["String"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
	timeoffset?: Maybe<Scalars["String"]>
	totalbytes?: Maybe<Scalars["bigint"]>
	totalpackets?: Maybe<Scalars["bigint"]>
}

/** response of any mutation on the table "tenant_14bed87a7c.project_b8d8cd96_packets" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Mutation_Response = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_mutation_response"
	/** number of rows affected by the mutation */
	affected_rows: Scalars["Int"]
	/** data from the rows affected by the mutation */
	returning: Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets>
}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_packets". */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Order_By = {
	bytessentcs?: InputMaybe<Order_By>
	bytessentsc?: InputMaybe<Order_By>
	filename?: InputMaybe<Order_By>
	filetype?: InputMaybe<Order_By>
	id?: InputMaybe<Order_By>
	l2dest?: InputMaybe<Order_By>
	l2headersize?: InputMaybe<Order_By>
	l2payloadsize?: InputMaybe<Order_By>
	l2protocol?: InputMaybe<Order_By>
	l2source?: InputMaybe<Order_By>
	l2tunnelid?: InputMaybe<Order_By>
	l2tunneltype?: InputMaybe<Order_By>
	l3dest?: InputMaybe<Order_By>
	l3headersize?: InputMaybe<Order_By>
	l3payloadsize?: InputMaybe<Order_By>
	l3protocol?: InputMaybe<Order_By>
	l3source?: InputMaybe<Order_By>
	l3ttl?: InputMaybe<Order_By>
	l3tunnelid?: InputMaybe<Order_By>
	l3tunneltype?: InputMaybe<Order_By>
	l4dest?: InputMaybe<Order_By>
	l4headersize?: InputMaybe<Order_By>
	l4payloadsize?: InputMaybe<Order_By>
	l4protocol?: InputMaybe<Order_By>
	l4source?: InputMaybe<Order_By>
	l4stack?: InputMaybe<Order_By>
	l7protocol?: InputMaybe<Order_By>
	metadata?: InputMaybe<Order_By>
	packetnumber?: InputMaybe<Order_By>
	packetoffset?: InputMaybe<Order_By>
	packetsize?: InputMaybe<Order_By>
	packetssentcs?: InputMaybe<Order_By>
	packetssentsc?: InputMaybe<Order_By>
	projectid?: InputMaybe<Order_By>
	sessionid?: InputMaybe<Order_By>
	taplocation?: InputMaybe<Order_By>
	taptype?: InputMaybe<Order_By>
	time?: InputMaybe<Order_By>
	timeend?: InputMaybe<Order_By>
	timeoffset?: InputMaybe<Order_By>
	totalbytes?: InputMaybe<Order_By>
	totalpackets?: InputMaybe<Order_By>
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Prepend_Input = {
	metadata?: InputMaybe<Scalars["jsonb"]>
}

/** columns and relationships of "tenant_14bed87a7c.project_b8d8cd96_packets_second_protocol_usage" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage"
	bytessentclientserver?: Maybe<Scalars["numeric"]>
	bytesserverclient?: Maybe<Scalars["numeric"]>
	l7protocol?: Maybe<Scalars["String"]>
	packetsclientserver?: Maybe<Scalars["numeric"]>
	packetsserverclient?: Maybe<Scalars["numeric"]>
	project_id?: Maybe<Scalars["String"]>
	second?: Maybe<Scalars["bigint"]>
	totalbytes?: Maybe<Scalars["numeric"]>
	totalpackets?: Maybe<Scalars["numeric"]>
}

/** aggregated selection of "tenant_14bed87a7c.project_b8d8cd96_packets_second_protocol_usage" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Aggregate =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage_aggregate"
		aggregate?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Aggregate_Fields>
		nodes: Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage>
	}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_packets_second_protocol_usage" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Aggregate_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage_aggregate_fields"
		avg?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Avg_Fields>
		count: Scalars["Int"]
		max?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Max_Fields>
		min?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Min_Fields>
		stddev?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Stddev_Fields>
		stddev_pop?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Stddev_Pop_Fields>
		stddev_samp?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Stddev_Samp_Fields>
		sum?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Sum_Fields>
		var_pop?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Var_Pop_Fields>
		var_samp?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Var_Samp_Fields>
		variance?: Maybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Variance_Fields>
	}

/** aggregate fields of "tenant_14bed87a7c.project_b8d8cd96_packets_second_protocol_usage" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Aggregate_FieldsCountArgs =
	{
		columns?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Select_Column>
		>
		distinct?: InputMaybe<Scalars["Boolean"]>
	}

/** aggregate avg on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Avg_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage_avg_fields"
		bytessentclientserver?: Maybe<Scalars["Float"]>
		bytesserverclient?: Maybe<Scalars["Float"]>
		packetsclientserver?: Maybe<Scalars["Float"]>
		packetsserverclient?: Maybe<Scalars["Float"]>
		second?: Maybe<Scalars["Float"]>
		totalbytes?: Maybe<Scalars["Float"]>
		totalpackets?: Maybe<Scalars["Float"]>
	}

/** Boolean expression to filter rows from the table "tenant_14bed87a7c.project_b8d8cd96_packets_second_protocol_usage". All fields are combined with a logical 'AND'. */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Bool_Exp =
	{
		_and?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Bool_Exp>
		>
		_not?: InputMaybe<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Bool_Exp>
		_or?: InputMaybe<
			Array<Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Bool_Exp>
		>
		bytessentclientserver?: InputMaybe<Numeric_Comparison_Exp>
		bytesserverclient?: InputMaybe<Numeric_Comparison_Exp>
		l7protocol?: InputMaybe<String_Comparison_Exp>
		packetsclientserver?: InputMaybe<Numeric_Comparison_Exp>
		packetsserverclient?: InputMaybe<Numeric_Comparison_Exp>
		project_id?: InputMaybe<String_Comparison_Exp>
		second?: InputMaybe<Bigint_Comparison_Exp>
		totalbytes?: InputMaybe<Numeric_Comparison_Exp>
		totalpackets?: InputMaybe<Numeric_Comparison_Exp>
	}

/** aggregate max on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Max_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage_max_fields"
		bytessentclientserver?: Maybe<Scalars["numeric"]>
		bytesserverclient?: Maybe<Scalars["numeric"]>
		l7protocol?: Maybe<Scalars["String"]>
		packetsclientserver?: Maybe<Scalars["numeric"]>
		packetsserverclient?: Maybe<Scalars["numeric"]>
		project_id?: Maybe<Scalars["String"]>
		second?: Maybe<Scalars["bigint"]>
		totalbytes?: Maybe<Scalars["numeric"]>
		totalpackets?: Maybe<Scalars["numeric"]>
	}

/** aggregate min on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Min_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage_min_fields"
		bytessentclientserver?: Maybe<Scalars["numeric"]>
		bytesserverclient?: Maybe<Scalars["numeric"]>
		l7protocol?: Maybe<Scalars["String"]>
		packetsclientserver?: Maybe<Scalars["numeric"]>
		packetsserverclient?: Maybe<Scalars["numeric"]>
		project_id?: Maybe<Scalars["String"]>
		second?: Maybe<Scalars["bigint"]>
		totalbytes?: Maybe<Scalars["numeric"]>
		totalpackets?: Maybe<Scalars["numeric"]>
	}

/** Ordering options when selecting data from "tenant_14bed87a7c.project_b8d8cd96_packets_second_protocol_usage". */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Order_By =
	{
		bytessentclientserver?: InputMaybe<Order_By>
		bytesserverclient?: InputMaybe<Order_By>
		l7protocol?: InputMaybe<Order_By>
		packetsclientserver?: InputMaybe<Order_By>
		packetsserverclient?: InputMaybe<Order_By>
		project_id?: InputMaybe<Order_By>
		second?: InputMaybe<Order_By>
		totalbytes?: InputMaybe<Order_By>
		totalpackets?: InputMaybe<Order_By>
	}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_packets_second_protocol_usage" */
export enum Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Select_Column {
	/** column name */
	Bytessentclientserver = "bytessentclientserver",
	/** column name */
	Bytesserverclient = "bytesserverclient",
	/** column name */
	L7protocol = "l7protocol",
	/** column name */
	Packetsclientserver = "packetsclientserver",
	/** column name */
	Packetsserverclient = "packetsserverclient",
	/** column name */
	ProjectId = "project_id",
	/** column name */
	Second = "second",
	/** column name */
	Totalbytes = "totalbytes",
	/** column name */
	Totalpackets = "totalpackets",
}

/** aggregate stddev on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Stddev_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage_stddev_fields"
		bytessentclientserver?: Maybe<Scalars["Float"]>
		bytesserverclient?: Maybe<Scalars["Float"]>
		packetsclientserver?: Maybe<Scalars["Float"]>
		packetsserverclient?: Maybe<Scalars["Float"]>
		second?: Maybe<Scalars["Float"]>
		totalbytes?: Maybe<Scalars["Float"]>
		totalpackets?: Maybe<Scalars["Float"]>
	}

/** aggregate stddev_pop on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Stddev_Pop_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage_stddev_pop_fields"
		bytessentclientserver?: Maybe<Scalars["Float"]>
		bytesserverclient?: Maybe<Scalars["Float"]>
		packetsclientserver?: Maybe<Scalars["Float"]>
		packetsserverclient?: Maybe<Scalars["Float"]>
		second?: Maybe<Scalars["Float"]>
		totalbytes?: Maybe<Scalars["Float"]>
		totalpackets?: Maybe<Scalars["Float"]>
	}

/** aggregate stddev_samp on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Stddev_Samp_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage_stddev_samp_fields"
		bytessentclientserver?: Maybe<Scalars["Float"]>
		bytesserverclient?: Maybe<Scalars["Float"]>
		packetsclientserver?: Maybe<Scalars["Float"]>
		packetsserverclient?: Maybe<Scalars["Float"]>
		second?: Maybe<Scalars["Float"]>
		totalbytes?: Maybe<Scalars["Float"]>
		totalpackets?: Maybe<Scalars["Float"]>
	}

/** aggregate sum on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Sum_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage_sum_fields"
		bytessentclientserver?: Maybe<Scalars["numeric"]>
		bytesserverclient?: Maybe<Scalars["numeric"]>
		packetsclientserver?: Maybe<Scalars["numeric"]>
		packetsserverclient?: Maybe<Scalars["numeric"]>
		second?: Maybe<Scalars["bigint"]>
		totalbytes?: Maybe<Scalars["numeric"]>
		totalpackets?: Maybe<Scalars["numeric"]>
	}

/** aggregate var_pop on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Var_Pop_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage_var_pop_fields"
		bytessentclientserver?: Maybe<Scalars["Float"]>
		bytesserverclient?: Maybe<Scalars["Float"]>
		packetsclientserver?: Maybe<Scalars["Float"]>
		packetsserverclient?: Maybe<Scalars["Float"]>
		second?: Maybe<Scalars["Float"]>
		totalbytes?: Maybe<Scalars["Float"]>
		totalpackets?: Maybe<Scalars["Float"]>
	}

/** aggregate var_samp on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Var_Samp_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage_var_samp_fields"
		bytessentclientserver?: Maybe<Scalars["Float"]>
		bytesserverclient?: Maybe<Scalars["Float"]>
		packetsclientserver?: Maybe<Scalars["Float"]>
		packetsserverclient?: Maybe<Scalars["Float"]>
		second?: Maybe<Scalars["Float"]>
		totalbytes?: Maybe<Scalars["Float"]>
		totalpackets?: Maybe<Scalars["Float"]>
	}

/** aggregate variance on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Second_Protocol_Usage_Variance_Fields =
	{
		__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_second_protocol_usage_variance_fields"
		bytessentclientserver?: Maybe<Scalars["Float"]>
		bytesserverclient?: Maybe<Scalars["Float"]>
		packetsclientserver?: Maybe<Scalars["Float"]>
		packetsserverclient?: Maybe<Scalars["Float"]>
		second?: Maybe<Scalars["Float"]>
		totalbytes?: Maybe<Scalars["Float"]>
		totalpackets?: Maybe<Scalars["Float"]>
	}

/** select columns of table "tenant_14bed87a7c.project_b8d8cd96_packets" */
export enum Tenant_14bed87a7c_Project_B8d8cd96_Packets_Select_Column {
	/** column name */
	Bytessentcs = "bytessentcs",
	/** column name */
	Bytessentsc = "bytessentsc",
	/** column name */
	Filename = "filename",
	/** column name */
	Filetype = "filetype",
	/** column name */
	Id = "id",
	/** column name */
	L2dest = "l2dest",
	/** column name */
	L2headersize = "l2headersize",
	/** column name */
	L2payloadsize = "l2payloadsize",
	/** column name */
	L2protocol = "l2protocol",
	/** column name */
	L2source = "l2source",
	/** column name */
	L2tunnelid = "l2tunnelid",
	/** column name */
	L2tunneltype = "l2tunneltype",
	/** column name */
	L3dest = "l3dest",
	/** column name */
	L3headersize = "l3headersize",
	/** column name */
	L3payloadsize = "l3payloadsize",
	/** column name */
	L3protocol = "l3protocol",
	/** column name */
	L3source = "l3source",
	/** column name */
	L3ttl = "l3ttl",
	/** column name */
	L3tunnelid = "l3tunnelid",
	/** column name */
	L3tunneltype = "l3tunneltype",
	/** column name */
	L4dest = "l4dest",
	/** column name */
	L4headersize = "l4headersize",
	/** column name */
	L4payloadsize = "l4payloadsize",
	/** column name */
	L4protocol = "l4protocol",
	/** column name */
	L4source = "l4source",
	/** column name */
	L4stack = "l4stack",
	/** column name */
	L7protocol = "l7protocol",
	/** column name */
	Metadata = "metadata",
	/** column name */
	Packetnumber = "packetnumber",
	/** column name */
	Packetoffset = "packetoffset",
	/** column name */
	Packetsize = "packetsize",
	/** column name */
	Packetssentcs = "packetssentcs",
	/** column name */
	Packetssentsc = "packetssentsc",
	/** column name */
	Projectid = "projectid",
	/** column name */
	Sessionid = "sessionid",
	/** column name */
	Taplocation = "taplocation",
	/** column name */
	Taptype = "taptype",
	/** column name */
	Time = "time",
	/** column name */
	Timeend = "timeend",
	/** column name */
	Timeoffset = "timeoffset",
	/** column name */
	Totalbytes = "totalbytes",
	/** column name */
	Totalpackets = "totalpackets",
}

/** input type for updating data in table "tenant_14bed87a7c.project_b8d8cd96_packets" */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Set_Input = {
	bytessentcs?: InputMaybe<Scalars["bigint"]>
	bytessentsc?: InputMaybe<Scalars["bigint"]>
	filename?: InputMaybe<Scalars["String"]>
	filetype?: InputMaybe<Scalars["String"]>
	id?: InputMaybe<Scalars["bigint"]>
	l2dest?: InputMaybe<Scalars["String"]>
	l2headersize?: InputMaybe<Scalars["Int"]>
	l2payloadsize?: InputMaybe<Scalars["Int"]>
	l2protocol?: InputMaybe<Scalars["String"]>
	l2source?: InputMaybe<Scalars["String"]>
	l2tunnelid?: InputMaybe<Scalars["Int"]>
	l2tunneltype?: InputMaybe<Scalars["String"]>
	l3dest?: InputMaybe<Scalars["String"]>
	l3headersize?: InputMaybe<Scalars["Int"]>
	l3payloadsize?: InputMaybe<Scalars["Int"]>
	l3protocol?: InputMaybe<Scalars["String"]>
	l3source?: InputMaybe<Scalars["String"]>
	l3ttl?: InputMaybe<Scalars["Int"]>
	l3tunnelid?: InputMaybe<Scalars["String"]>
	l3tunneltype?: InputMaybe<Scalars["String"]>
	l4dest?: InputMaybe<Scalars["Int"]>
	l4headersize?: InputMaybe<Scalars["Int"]>
	l4payloadsize?: InputMaybe<Scalars["Int"]>
	l4protocol?: InputMaybe<Scalars["String"]>
	l4source?: InputMaybe<Scalars["Int"]>
	l4stack?: InputMaybe<Scalars["String"]>
	l7protocol?: InputMaybe<Scalars["String"]>
	metadata?: InputMaybe<Scalars["jsonb"]>
	packetnumber?: InputMaybe<Scalars["bigint"]>
	packetoffset?: InputMaybe<Scalars["bigint"]>
	packetsize?: InputMaybe<Scalars["Int"]>
	packetssentcs?: InputMaybe<Scalars["bigint"]>
	packetssentsc?: InputMaybe<Scalars["bigint"]>
	projectid?: InputMaybe<Scalars["String"]>
	sessionid?: InputMaybe<Scalars["String"]>
	taplocation?: InputMaybe<Scalars["String"]>
	taptype?: InputMaybe<Scalars["String"]>
	time?: InputMaybe<Scalars["bigint"]>
	timeend?: InputMaybe<Scalars["bigint"]>
	timeoffset?: InputMaybe<Scalars["String"]>
	totalbytes?: InputMaybe<Scalars["bigint"]>
	totalpackets?: InputMaybe<Scalars["bigint"]>
}

/** aggregate stddev on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Stddev_Fields = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_stddev_fields"
	bytessentcs?: Maybe<Scalars["Float"]>
	bytessentsc?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	l2headersize?: Maybe<Scalars["Float"]>
	l2payloadsize?: Maybe<Scalars["Float"]>
	l2tunnelid?: Maybe<Scalars["Float"]>
	l3headersize?: Maybe<Scalars["Float"]>
	l3payloadsize?: Maybe<Scalars["Float"]>
	l3ttl?: Maybe<Scalars["Float"]>
	l4dest?: Maybe<Scalars["Float"]>
	l4headersize?: Maybe<Scalars["Float"]>
	l4payloadsize?: Maybe<Scalars["Float"]>
	l4source?: Maybe<Scalars["Float"]>
	packetnumber?: Maybe<Scalars["Float"]>
	packetoffset?: Maybe<Scalars["Float"]>
	packetsize?: Maybe<Scalars["Float"]>
	packetssentcs?: Maybe<Scalars["Float"]>
	packetssentsc?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
	totalbytes?: Maybe<Scalars["Float"]>
	totalpackets?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_pop on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Stddev_Pop_Fields = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_stddev_pop_fields"
	bytessentcs?: Maybe<Scalars["Float"]>
	bytessentsc?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	l2headersize?: Maybe<Scalars["Float"]>
	l2payloadsize?: Maybe<Scalars["Float"]>
	l2tunnelid?: Maybe<Scalars["Float"]>
	l3headersize?: Maybe<Scalars["Float"]>
	l3payloadsize?: Maybe<Scalars["Float"]>
	l3ttl?: Maybe<Scalars["Float"]>
	l4dest?: Maybe<Scalars["Float"]>
	l4headersize?: Maybe<Scalars["Float"]>
	l4payloadsize?: Maybe<Scalars["Float"]>
	l4source?: Maybe<Scalars["Float"]>
	packetnumber?: Maybe<Scalars["Float"]>
	packetoffset?: Maybe<Scalars["Float"]>
	packetsize?: Maybe<Scalars["Float"]>
	packetssentcs?: Maybe<Scalars["Float"]>
	packetssentsc?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
	totalbytes?: Maybe<Scalars["Float"]>
	totalpackets?: Maybe<Scalars["Float"]>
}

/** aggregate stddev_samp on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Stddev_Samp_Fields = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_stddev_samp_fields"
	bytessentcs?: Maybe<Scalars["Float"]>
	bytessentsc?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	l2headersize?: Maybe<Scalars["Float"]>
	l2payloadsize?: Maybe<Scalars["Float"]>
	l2tunnelid?: Maybe<Scalars["Float"]>
	l3headersize?: Maybe<Scalars["Float"]>
	l3payloadsize?: Maybe<Scalars["Float"]>
	l3ttl?: Maybe<Scalars["Float"]>
	l4dest?: Maybe<Scalars["Float"]>
	l4headersize?: Maybe<Scalars["Float"]>
	l4payloadsize?: Maybe<Scalars["Float"]>
	l4source?: Maybe<Scalars["Float"]>
	packetnumber?: Maybe<Scalars["Float"]>
	packetoffset?: Maybe<Scalars["Float"]>
	packetsize?: Maybe<Scalars["Float"]>
	packetssentcs?: Maybe<Scalars["Float"]>
	packetssentsc?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
	totalbytes?: Maybe<Scalars["Float"]>
	totalpackets?: Maybe<Scalars["Float"]>
}

/** aggregate sum on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Sum_Fields = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_sum_fields"
	bytessentcs?: Maybe<Scalars["bigint"]>
	bytessentsc?: Maybe<Scalars["bigint"]>
	id?: Maybe<Scalars["bigint"]>
	l2headersize?: Maybe<Scalars["Int"]>
	l2payloadsize?: Maybe<Scalars["Int"]>
	l2tunnelid?: Maybe<Scalars["Int"]>
	l3headersize?: Maybe<Scalars["Int"]>
	l3payloadsize?: Maybe<Scalars["Int"]>
	l3ttl?: Maybe<Scalars["Int"]>
	l4dest?: Maybe<Scalars["Int"]>
	l4headersize?: Maybe<Scalars["Int"]>
	l4payloadsize?: Maybe<Scalars["Int"]>
	l4source?: Maybe<Scalars["Int"]>
	packetnumber?: Maybe<Scalars["bigint"]>
	packetoffset?: Maybe<Scalars["bigint"]>
	packetsize?: Maybe<Scalars["Int"]>
	packetssentcs?: Maybe<Scalars["bigint"]>
	packetssentsc?: Maybe<Scalars["bigint"]>
	time?: Maybe<Scalars["bigint"]>
	timeend?: Maybe<Scalars["bigint"]>
	totalbytes?: Maybe<Scalars["bigint"]>
	totalpackets?: Maybe<Scalars["bigint"]>
}

/** aggregate var_pop on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Var_Pop_Fields = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_var_pop_fields"
	bytessentcs?: Maybe<Scalars["Float"]>
	bytessentsc?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	l2headersize?: Maybe<Scalars["Float"]>
	l2payloadsize?: Maybe<Scalars["Float"]>
	l2tunnelid?: Maybe<Scalars["Float"]>
	l3headersize?: Maybe<Scalars["Float"]>
	l3payloadsize?: Maybe<Scalars["Float"]>
	l3ttl?: Maybe<Scalars["Float"]>
	l4dest?: Maybe<Scalars["Float"]>
	l4headersize?: Maybe<Scalars["Float"]>
	l4payloadsize?: Maybe<Scalars["Float"]>
	l4source?: Maybe<Scalars["Float"]>
	packetnumber?: Maybe<Scalars["Float"]>
	packetoffset?: Maybe<Scalars["Float"]>
	packetsize?: Maybe<Scalars["Float"]>
	packetssentcs?: Maybe<Scalars["Float"]>
	packetssentsc?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
	totalbytes?: Maybe<Scalars["Float"]>
	totalpackets?: Maybe<Scalars["Float"]>
}

/** aggregate var_samp on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Var_Samp_Fields = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_var_samp_fields"
	bytessentcs?: Maybe<Scalars["Float"]>
	bytessentsc?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	l2headersize?: Maybe<Scalars["Float"]>
	l2payloadsize?: Maybe<Scalars["Float"]>
	l2tunnelid?: Maybe<Scalars["Float"]>
	l3headersize?: Maybe<Scalars["Float"]>
	l3payloadsize?: Maybe<Scalars["Float"]>
	l3ttl?: Maybe<Scalars["Float"]>
	l4dest?: Maybe<Scalars["Float"]>
	l4headersize?: Maybe<Scalars["Float"]>
	l4payloadsize?: Maybe<Scalars["Float"]>
	l4source?: Maybe<Scalars["Float"]>
	packetnumber?: Maybe<Scalars["Float"]>
	packetoffset?: Maybe<Scalars["Float"]>
	packetsize?: Maybe<Scalars["Float"]>
	packetssentcs?: Maybe<Scalars["Float"]>
	packetssentsc?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
	totalbytes?: Maybe<Scalars["Float"]>
	totalpackets?: Maybe<Scalars["Float"]>
}

/** aggregate variance on columns */
export type Tenant_14bed87a7c_Project_B8d8cd96_Packets_Variance_Fields = {
	__typename?: "tenant_14bed87a7c_project_b8d8cd96_packets_variance_fields"
	bytessentcs?: Maybe<Scalars["Float"]>
	bytessentsc?: Maybe<Scalars["Float"]>
	id?: Maybe<Scalars["Float"]>
	l2headersize?: Maybe<Scalars["Float"]>
	l2payloadsize?: Maybe<Scalars["Float"]>
	l2tunnelid?: Maybe<Scalars["Float"]>
	l3headersize?: Maybe<Scalars["Float"]>
	l3payloadsize?: Maybe<Scalars["Float"]>
	l3ttl?: Maybe<Scalars["Float"]>
	l4dest?: Maybe<Scalars["Float"]>
	l4headersize?: Maybe<Scalars["Float"]>
	l4payloadsize?: Maybe<Scalars["Float"]>
	l4source?: Maybe<Scalars["Float"]>
	packetnumber?: Maybe<Scalars["Float"]>
	packetoffset?: Maybe<Scalars["Float"]>
	packetsize?: Maybe<Scalars["Float"]>
	packetssentcs?: Maybe<Scalars["Float"]>
	packetssentsc?: Maybe<Scalars["Float"]>
	time?: Maybe<Scalars["Float"]>
	timeend?: Maybe<Scalars["Float"]>
	totalbytes?: Maybe<Scalars["Float"]>
	totalpackets?: Maybe<Scalars["Float"]>
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["timestamp"]>
	_gt?: InputMaybe<Scalars["timestamp"]>
	_gte?: InputMaybe<Scalars["timestamp"]>
	_in?: InputMaybe<Array<Scalars["timestamp"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["timestamp"]>
	_lte?: InputMaybe<Scalars["timestamp"]>
	_neq?: InputMaybe<Scalars["timestamp"]>
	_nin?: InputMaybe<Array<Scalars["timestamp"]>>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
	_eq?: InputMaybe<Scalars["timestamptz"]>
	_gt?: InputMaybe<Scalars["timestamptz"]>
	_gte?: InputMaybe<Scalars["timestamptz"]>
	_in?: InputMaybe<Array<Scalars["timestamptz"]>>
	_is_null?: InputMaybe<Scalars["Boolean"]>
	_lt?: InputMaybe<Scalars["timestamptz"]>
	_lte?: InputMaybe<Scalars["timestamptz"]>
	_neq?: InputMaybe<Scalars["timestamptz"]>
	_nin?: InputMaybe<Array<Scalars["timestamptz"]>>
}

export type AssetApplicationsFragment = {
	__typename?: "p_asset_applications"
	assetid?: any | null
	developer?: string | null
	name?: string | null
	time: any
	timeend: any
	id: any
	version?: string | null
}

export type AssetBadgesFragment = {
	__typename?: "p_asset_badges"
	assetid?: any | null
	text?: string | null
	time: any
	timeend: any
}

export type AssetCvesFragment = {
	__typename?: "p_asset_cves"
	assetid: any
	projectid?: string | null
	id: any
	cve: string
	time: any
	timeend: any
}

export type AssetDistanceFragment = {
	__typename?: "p_asset_distance"
	time: any
	timeend: any
	networkinterfaceid?: any | null
}

export type AssetHardwareFragment = {
	__typename?: "p_asset_hardware"
	projectid?: string | null
	assetid?: any | null
	cputype?: string | null
	device?: string | null
	manufacturer?: string | null
	formfactor?: string | null
	time: any
	timeend: any
	id: any
}

export type AssetHostnamesFragment = {
	__typename?: "p_asset_hostnames"
	assetid?: any | null
	domain?: string | null
	hostname?: string | null
	protocolname?: string | null
	time: any
	timeend: any
	id: any
}

export type AssetInterfacesFragment = {
	__typename?: "p_asset_interfaces"
	assetid?: any | null
	broadcastdomain: any
	connectedto?: any | null
	connector?: string | null
	description?: string | null
	layer2?: any | null
	isvirtual?: boolean | null
	layer3?: any | null
	name?: string | null
	projectid?: string | null
	routesid?: any | null
	time: any
	timeend: any
	id: any
}

export type AssetOsFragment = {
	__typename?: "p_asset_os"
	id: any
	timeend: any
	time: any
	assetid?: any | null
	developer?: string | null
	name?: string | null
	version?: string | null
}

export type AssetRolesFragment = {
	__typename?: "p_asset_roles"
	assetid?: any | null
	description?: string | null
	id: any
	projectid?: string | null
	time: any
	timeend: any
	type?: string | null
}

export type AssetServicesFragment = {
	__typename?: "p_asset_services"
	firewallopen?: boolean | null
	name?: string | null
	networkinterfaceid?: any | null
	port?: any | null
	protocolname?: string | null
	standardprotocol?: string | null
	time: any
	timeend: any
	id: any
}

export type AssetTagsFragment = {
	__typename?: "p_asset_tags"
	id: any
	timeend: any
	time: any
	text?: string | null
	assetid?: any | null
}

export type AssetsFragment = {
	__typename?: "p_assets"
	name?: string | null
	description?: string | null
	id: any
}

export type BroadcastDomainsFragment = {
	__typename?: "p_broadcast_domains"
	id: any
	vlan?: any | null
}

export type CoreProjectFragment = {
	__typename?: "core_Project"
	id: string
	updated?: any | null
	created?: any | null
	globalShared?: boolean | null
	lastActivityType?: any | null
	name?: string | null
	namespace?: string | null
	rollupAssetCount?: number | null
	rollupImportCount?: number | null
	rollupImportStatus?: any | null
	rollupImportStatusPercent?: number | null
	rollupMessageCode?: string | null
	rollupMessageDev?: string | null
	rollupMessageUser?: string | null
	rollupProjectStatus?: any | null
	rollupTotalFilesize?: number | null
	rollupSchemaVersion?: string | null
	schemaID?: string | null
	tags?: any | null
	ImportOperations: Array<{
		__typename?: "core_ImportOperation"
		hash?: string | null
		id: string
		ingestDate?: any | null
		ingestPath?: string | null
		messageCode?: string | null
		messageDev?: string | null
		messageUser?: string | null
		name?: string | null
		originalFilename?: string | null
		status?: any | null
		statusDate?: any | null
		statusPercent?: number | null
		tags?: any | null
	}>
	Tenant?: {
		__typename?: "core_Tenant"
		id: string
		name?: string | null
		type?: any | null
		TenantUsers: Array<{
			__typename?: "core_TenantUser"
			tenantID: string
			tenantRole?: any | null
			User: {
				__typename?: "core_User"
				emailVerified?: boolean | null
				id: string
				firstName?: string | null
				lastName?: string | null
				tier?: any | null
			}
		}>
	} | null
}

export type DataAssetRoleFragment = {
	__typename?: "data_asset_roles"
	id: string
	category?: string | null
	icon?: string | null
	name?: string | null
}

export type PruMFragment = {
	__typename?: "p_pru_m"
	l7protocol?: string | null
	minute?: any | null
	totalbytes?: any | null
}

export type AddAssetMutationVariables = Types.Exact<{
	data: Types.P_Assets_Insert_Input
}>

export type AddAssetMutation = {
	__typename?: "mutation_root"
	insert_p_assets_one?: {
		__typename?: "p_assets"
		name?: string | null
		description?: string | null
		id: any
	} | null
}

export type AddAssetHardwareMutationVariables = Types.Exact<{
	data: Types.P_Asset_Hardware_Insert_Input
}>

export type AddAssetHardwareMutation = {
	__typename?: "mutation_root"
	insert_p_asset_hardware_one?: {
		__typename?: "p_asset_hardware"
		projectid?: string | null
		assetid?: any | null
		cputype?: string | null
		device?: string | null
		manufacturer?: string | null
		formfactor?: string | null
		time: any
		timeend: any
		id: any
	} | null
}

export type AddAssetHostnamesMutationVariables = Types.Exact<{
	data:
		| Array<Types.P_Asset_Hostnames_Insert_Input>
		| Types.P_Asset_Hostnames_Insert_Input
}>

export type AddAssetHostnamesMutation = {
	__typename?: "mutation_root"
	insert_p_asset_hostnames?: {
		__typename?: "p_asset_hostnames_mutation_response"
		returning: Array<{
			__typename?: "p_asset_hostnames"
			assetid?: any | null
			domain?: string | null
			hostname?: string | null
			protocolname?: string | null
			time: any
			timeend: any
			id: any
		}>
	} | null
}

export type AddAssetOsMutationVariables = Types.Exact<{
	data: Types.P_Asset_Os_Insert_Input
}>

export type AddAssetOsMutation = {
	__typename?: "mutation_root"
	insert_p_asset_os_one?: {
		__typename?: "p_asset_os"
		id: any
		timeend: any
		time: any
		assetid?: any | null
		developer?: string | null
		name?: string | null
		version?: string | null
	} | null
}

export type AddAssetRoleMutationVariables = Types.Exact<{
	data:
		| Array<Types.P_Asset_Roles_Insert_Input>
		| Types.P_Asset_Roles_Insert_Input
}>

export type AddAssetRoleMutation = {
	__typename?: "mutation_root"
	insert_p_asset_roles?: {
		__typename?: "p_asset_roles_mutation_response"
		returning: Array<{
			__typename?: "p_asset_roles"
			assetid?: any | null
			description?: string | null
			id: any
			projectid?: string | null
			time: any
			timeend: any
			type?: string | null
		}>
	} | null
}

export type AddAssetTagMutationVariables = Types.Exact<{
	data: Types.P_Asset_Tags_Insert_Input
}>

export type AddAssetTagMutation = {
	__typename?: "mutation_root"
	insert_p_asset_tags_one?: {
		__typename?: "p_asset_tags"
		id: any
		timeend: any
		time: any
		text?: string | null
		assetid?: any | null
	} | null
}

export type AddAssetTagsMutationVariables = Types.Exact<{
	data: Array<Types.P_Asset_Tags_Insert_Input> | Types.P_Asset_Tags_Insert_Input
}>

export type AddAssetTagsMutation = {
	__typename?: "mutation_root"
	insert_p_asset_tags?: {
		__typename?: "p_asset_tags_mutation_response"
		returning: Array<{
			__typename?: "p_asset_tags"
			id: any
			timeend: any
			time: any
			text?: string | null
			assetid?: any | null
		}>
	} | null
}

export type AddAssetsHardwareMutationVariables = Types.Exact<{
	objects:
		| Array<Types.P_Asset_Hardware_Insert_Input>
		| Types.P_Asset_Hardware_Insert_Input
}>

export type AddAssetsHardwareMutation = {
	__typename?: "mutation_root"
	insert_p_asset_hardware?: {
		__typename?: "p_asset_hardware_mutation_response"
		returning: Array<{
			__typename?: "p_asset_hardware"
			projectid?: string | null
			assetid?: any | null
			cputype?: string | null
			device?: string | null
			manufacturer?: string | null
			formfactor?: string | null
			time: any
			timeend: any
			id: any
		}>
	} | null
}

export type AddAssetsOsMutationVariables = Types.Exact<{
	objects: Array<Types.P_Asset_Os_Insert_Input> | Types.P_Asset_Os_Insert_Input
}>

export type AddAssetsOsMutation = {
	__typename?: "mutation_root"
	insert_p_asset_os?: {
		__typename?: "p_asset_os_mutation_response"
		returning: Array<{
			__typename?: "p_asset_os"
			id: any
			timeend: any
			time: any
			assetid?: any | null
			developer?: string | null
			name?: string | null
			version?: string | null
		}>
	} | null
}

export type CreateProjectMutationVariables = Types.Exact<{
	name: Types.Scalars["String"]
	tags?: Types.InputMaybe<Types.Scalars["String"]>
}>

export type CreateProjectMutation = {
	__typename?: "mutation_root"
	insert_core_Project_one?: {
		__typename?: "CreateProjectOutput"
		name?: string | null
		id?: number | null
		tenantID?: string | null
	} | null
}

export type DeleteAssetHostnamesMutationVariables = Types.Exact<{
	ids: Array<Types.Scalars["bigint"]> | Types.Scalars["bigint"]
}>

export type DeleteAssetHostnamesMutation = {
	__typename?: "mutation_root"
	delete_p_asset_hostnames?: {
		__typename?: "p_asset_hostnames_mutation_response"
		returning: Array<{
			__typename?: "p_asset_hostnames"
			assetid?: any | null
			domain?: string | null
			hostname?: string | null
			protocolname?: string | null
			time: any
			timeend: any
			id: any
		}>
	} | null
}

export type DeleteAssetRolesMutationVariables = Types.Exact<{
	ids: Array<Types.Scalars["bigint"]> | Types.Scalars["bigint"]
}>

export type DeleteAssetRolesMutation = {
	__typename?: "mutation_root"
	delete_p_asset_roles?: {
		__typename?: "p_asset_roles_mutation_response"
		returning: Array<{
			__typename?: "p_asset_roles"
			assetid?: any | null
			description?: string | null
			id: any
			projectid?: string | null
			time: any
			timeend: any
			type?: string | null
		}>
	} | null
}

export type DeleteAssetTagMutationVariables = Types.Exact<{
	id: Types.Scalars["bigint"]
}>

export type DeleteAssetTagMutation = {
	__typename?: "mutation_root"
	delete_p_asset_tags_by_pk?: {
		__typename?: "p_asset_tags"
		id: any
		timeend: any
		time: any
		text?: string | null
		assetid?: any | null
	} | null
}

export type DeleteAssetTagsMutationVariables = Types.Exact<{
	where: Types.P_Asset_Tags_Bool_Exp
}>

export type DeleteAssetTagsMutation = {
	__typename?: "mutation_root"
	delete_p_asset_tags?: {
		__typename?: "p_asset_tags_mutation_response"
		returning: Array<{
			__typename?: "p_asset_tags"
			id: any
			timeend: any
			time: any
			text?: string | null
			assetid?: any | null
		}>
	} | null
}

export type DeleteProjectMutationVariables = Types.Exact<{
	projectId: Types.Scalars["String"]
}>

export type DeleteProjectMutation = {
	__typename?: "mutation_root"
	delete_core_Project?: {
		__typename?: "DeleteProjectOutput"
		id?: string | null
	} | null
}

export type InsertOnDuplicateKeyUpdateUserMutationVariables = Types.Exact<{
	user: Types.CreateUserInput
}>

export type InsertOnDuplicateKeyUpdateUserMutation = {
	__typename?: "mutation_root"
	insert_core_User_one?: {
		__typename?: "CreateUserOutput"
		auth0UserId?: string | null
		contactEmail?: string | null
		firstName?: string | null
		id?: string | null
		lastName?: string | null
		locale?: string | null
		tier?: string | null
	} | null
}

export type UpdateAssetMutationVariables = Types.Exact<{
	id: Types.Scalars["bigint"]
	data: Types.P_Assets_Set_Input
}>

export type UpdateAssetMutation = {
	__typename?: "mutation_root"
	update_p_assets_by_pk?: {
		__typename?: "p_assets"
		name?: string | null
		description?: string | null
		id: any
	} | null
}

export type UpdateAssetHardwareMutationVariables = Types.Exact<{
	id: Types.Scalars["bigint"]
	data: Types.P_Asset_Hardware_Set_Input
}>

export type UpdateAssetHardwareMutation = {
	__typename?: "mutation_root"
	update_p_asset_hardware_by_pk?: {
		__typename?: "p_asset_hardware"
		projectid?: string | null
		assetid?: any | null
		cputype?: string | null
		device?: string | null
		manufacturer?: string | null
		formfactor?: string | null
		time: any
		timeend: any
		id: any
	} | null
}

export type UpdateAssetOsMutationVariables = Types.Exact<{
	id: Types.Scalars["bigint"]
	data: Types.P_Asset_Os_Set_Input
}>

export type UpdateAssetOsMutation = {
	__typename?: "mutation_root"
	update_p_asset_os_by_pk?: {
		__typename?: "p_asset_os"
		id: any
		timeend: any
		time: any
		assetid?: any | null
		developer?: string | null
		name?: string | null
		version?: string | null
	} | null
}

export type UpdateAssetsMutationVariables = Types.Exact<{
	data?: Types.InputMaybe<Types.P_Assets_Set_Input>
	ids: Array<Types.Scalars["bigint"]> | Types.Scalars["bigint"]
}>

export type UpdateAssetsMutation = {
	__typename?: "mutation_root"
	update_p_assets?: {
		__typename?: "p_assets_mutation_response"
		returning: Array<{
			__typename?: "p_assets"
			name?: string | null
			description?: string | null
			id: any
		}>
	} | null
}

export type UpdateAssetsHardwareMutationVariables = Types.Exact<{
	ids?: Types.InputMaybe<
		Array<Types.Scalars["bigint"]> | Types.Scalars["bigint"]
	>
	data: Types.P_Asset_Hardware_Set_Input
}>

export type UpdateAssetsHardwareMutation = {
	__typename?: "mutation_root"
	update_p_asset_hardware?: {
		__typename?: "p_asset_hardware_mutation_response"
		returning: Array<{
			__typename?: "p_asset_hardware"
			projectid?: string | null
			assetid?: any | null
			cputype?: string | null
			device?: string | null
			manufacturer?: string | null
			formfactor?: string | null
			time: any
			timeend: any
			id: any
		}>
	} | null
}

export type UpdateAssetsOsMutationVariables = Types.Exact<{
	ids?: Types.InputMaybe<
		Array<Types.Scalars["bigint"]> | Types.Scalars["bigint"]
	>
	data: Types.P_Asset_Os_Set_Input
}>

export type UpdateAssetsOsMutation = {
	__typename?: "mutation_root"
	update_p_asset_os?: {
		__typename?: "p_asset_os_mutation_response"
		returning: Array<{
			__typename?: "p_asset_os"
			id: any
			timeend: any
			time: any
			assetid?: any | null
			developer?: string | null
			name?: string | null
			version?: string | null
		}>
	} | null
}

export type UpdateProjectNameMutationVariables = Types.Exact<{
	projectId: Types.Scalars["String"]
	projectName: Types.Scalars["String"]
}>

export type UpdateProjectNameMutation = {
	__typename?: "mutation_root"
	update_core_Project_by_pk?: { __typename?: "core_Project"; id: string } | null
}

export type AssetDetailsQueryVariables = Types.Exact<{
	assetids?: Types.InputMaybe<
		Array<Types.Scalars["bigint"]> | Types.Scalars["bigint"]
	>
}>

export type AssetDetailsQuery = {
	__typename?: "query_root"
	p_assets: Array<{
		__typename?: "p_assets"
		name?: string | null
		description?: string | null
		id: any
	}>
	p_asset_hardware: Array<{
		__typename?: "p_asset_hardware"
		projectid?: string | null
		assetid?: any | null
		cputype?: string | null
		device?: string | null
		manufacturer?: string | null
		formfactor?: string | null
		time: any
		timeend: any
		id: any
	}>
	p_asset_hostnames: Array<{
		__typename?: "p_asset_hostnames"
		assetid?: any | null
		domain?: string | null
		hostname?: string | null
		protocolname?: string | null
		time: any
		timeend: any
		id: any
	}>
	p_asset_os: Array<{
		__typename?: "p_asset_os"
		id: any
		timeend: any
		time: any
		assetid?: any | null
		developer?: string | null
		name?: string | null
		version?: string | null
	}>
	p_asset_roles: Array<{
		__typename?: "p_asset_roles"
		assetid?: any | null
		description?: string | null
		id: any
		projectid?: string | null
		time: any
		timeend: any
		type?: string | null
	}>
	p_asset_interfaces: Array<{
		__typename?: "p_asset_interfaces"
		assetid?: any | null
		broadcastdomain: any
		connectedto?: any | null
		connector?: string | null
		description?: string | null
		layer2?: any | null
		isvirtual?: boolean | null
		layer3?: any | null
		name?: string | null
		projectid?: string | null
		routesid?: any | null
		time: any
		timeend: any
		id: any
	}>
	p_asset_applications: Array<{
		__typename?: "p_asset_applications"
		assetid?: any | null
		developer?: string | null
		name?: string | null
		time: any
		timeend: any
		id: any
		version?: string | null
	}>
	p_asset_cves: Array<{
		__typename?: "p_asset_cves"
		assetid: any
		projectid?: string | null
		id: any
		cve: string
		time: any
		timeend: any
	}>
	data_asset_roles: Array<{
		__typename?: "data_asset_roles"
		id: string
		category?: string | null
		icon?: string | null
		name?: string | null
	}>
	p_asset_tags: Array<{
		__typename?: "p_asset_tags"
		id: any
		timeend: any
		time: any
		text?: string | null
		assetid?: any | null
	}>
}

export type AssetIdsSortedQueryVariables = Types.Exact<{
	order_by?: Types.InputMaybe<
		Array<Types.P_Assets_Order_By> | Types.P_Assets_Order_By
	>
}>

export type AssetIdsSortedQuery = {
	__typename?: "query_root"
	p_assets: Array<{
		__typename?: "p_assets"
		name?: string | null
		description?: string | null
		id: any
	}>
}

export type AssetSummaryPanelQueryVariables = Types.Exact<{
	assetid: Types.Scalars["bigint"]
}>

export type AssetSummaryPanelQuery = {
	__typename?: "query_root"
	p_assets_by_pk?: {
		__typename?: "p_assets"
		name?: string | null
		description?: string | null
		id: any
	} | null
	p_asset_interfaces: Array<{
		__typename?: "p_asset_interfaces"
		assetid?: any | null
		broadcastdomain: any
		connectedto?: any | null
		connector?: string | null
		description?: string | null
		layer2?: any | null
		isvirtual?: boolean | null
		layer3?: any | null
		name?: string | null
		projectid?: string | null
		routesid?: any | null
		time: any
		timeend: any
		id: any
	}>
	p_asset_hardware: Array<{
		__typename?: "p_asset_hardware"
		projectid?: string | null
		assetid?: any | null
		cputype?: string | null
		device?: string | null
		manufacturer?: string | null
		formfactor?: string | null
		time: any
		timeend: any
		id: any
	}>
	p_asset_hostnames: Array<{
		__typename?: "p_asset_hostnames"
		assetid?: any | null
		domain?: string | null
		hostname?: string | null
		protocolname?: string | null
		time: any
		timeend: any
		id: any
	}>
	p_asset_tags: Array<{
		__typename?: "p_asset_tags"
		id: any
		timeend: any
		time: any
		text?: string | null
		assetid?: any | null
	}>
	p_asset_cves: Array<{
		__typename?: "p_asset_cves"
		assetid: any
		projectid?: string | null
		id: any
		cve: string
		time: any
		timeend: any
	}>
}

export type AssetWithInterfacesQueryVariables = Types.Exact<{
	assetid: Types.Scalars["bigint"]
}>

export type AssetWithInterfacesQuery = {
	__typename?: "query_root"
	p_assets_by_pk?: {
		__typename?: "p_assets"
		name?: string | null
		description?: string | null
		id: any
	} | null
	p_asset_interfaces: Array<{
		__typename?: "p_asset_interfaces"
		assetid?: any | null
		broadcastdomain: any
		connectedto?: any | null
		connector?: string | null
		description?: string | null
		layer2?: any | null
		isvirtual?: boolean | null
		layer3?: any | null
		name?: string | null
		projectid?: string | null
		routesid?: any | null
		time: any
		timeend: any
		id: any
	}>
	p_asset_hardware: Array<{
		__typename?: "p_asset_hardware"
		projectid?: string | null
		assetid?: any | null
		cputype?: string | null
		device?: string | null
		manufacturer?: string | null
		formfactor?: string | null
		time: any
		timeend: any
		id: any
	}>
}

export type GetProjectsQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetProjectsQuery = {
	__typename?: "query_root"
	core_Project: Array<{
		__typename?: "core_Project"
		id: string
		updated?: any | null
		created?: any | null
		globalShared?: boolean | null
		lastActivityType?: any | null
		name?: string | null
		namespace?: string | null
		rollupAssetCount?: number | null
		rollupImportCount?: number | null
		rollupImportStatus?: any | null
		rollupImportStatusPercent?: number | null
		rollupMessageCode?: string | null
		rollupMessageDev?: string | null
		rollupMessageUser?: string | null
		rollupProjectStatus?: any | null
		rollupTotalFilesize?: number | null
		rollupSchemaVersion?: string | null
		schemaID?: string | null
		tags?: any | null
		ImportOperations: Array<{
			__typename?: "core_ImportOperation"
			hash?: string | null
			id: string
			ingestDate?: any | null
			ingestPath?: string | null
			messageCode?: string | null
			messageDev?: string | null
			messageUser?: string | null
			name?: string | null
			originalFilename?: string | null
			status?: any | null
			statusDate?: any | null
			statusPercent?: number | null
			tags?: any | null
		}>
		Tenant?: {
			__typename?: "core_Tenant"
			id: string
			name?: string | null
			type?: any | null
			TenantUsers: Array<{
				__typename?: "core_TenantUser"
				tenantID: string
				tenantRole?: any | null
				User: {
					__typename?: "core_User"
					emailVerified?: boolean | null
					id: string
					firstName?: string | null
					lastName?: string | null
					tier?: any | null
				}
			}>
		} | null
	}>
}

export type ProjectDataInitialQueryVariables = Types.Exact<{
	[key: string]: never
}>

export type ProjectDataInitialQuery = {
	__typename?: "query_root"
	p_assets: Array<{
		__typename?: "p_assets"
		name?: string | null
		description?: string | null
		id: any
	}>
	p_asset_applications: Array<{
		__typename?: "p_asset_applications"
		assetid?: any | null
		developer?: string | null
		name?: string | null
		time: any
		timeend: any
		id: any
		version?: string | null
	}>
	p_asset_badges: Array<{
		__typename?: "p_asset_badges"
		assetid?: any | null
		text?: string | null
		time: any
		timeend: any
	}>
	p_asset_cves: Array<{
		__typename?: "p_asset_cves"
		assetid: any
		projectid?: string | null
		id: any
		cve: string
		time: any
		timeend: any
	}>
	p_asset_distance: Array<{
		__typename?: "p_asset_distance"
		time: any
		timeend: any
		networkinterfaceid?: any | null
	}>
	p_asset_hardware: Array<{
		__typename?: "p_asset_hardware"
		projectid?: string | null
		assetid?: any | null
		cputype?: string | null
		device?: string | null
		manufacturer?: string | null
		formfactor?: string | null
		time: any
		timeend: any
		id: any
	}>
	p_asset_hostnames: Array<{
		__typename?: "p_asset_hostnames"
		assetid?: any | null
		domain?: string | null
		hostname?: string | null
		protocolname?: string | null
		time: any
		timeend: any
		id: any
	}>
	p_asset_interfaces: Array<{
		__typename?: "p_asset_interfaces"
		assetid?: any | null
		broadcastdomain: any
		connectedto?: any | null
		connector?: string | null
		description?: string | null
		layer2?: any | null
		isvirtual?: boolean | null
		layer3?: any | null
		name?: string | null
		projectid?: string | null
		routesid?: any | null
		time: any
		timeend: any
		id: any
	}>
	p_asset_roles: Array<{
		__typename?: "p_asset_roles"
		assetid?: any | null
		description?: string | null
		id: any
		projectid?: string | null
		time: any
		timeend: any
		type?: string | null
	}>
	p_asset_services: Array<{
		__typename?: "p_asset_services"
		firewallopen?: boolean | null
		name?: string | null
		networkinterfaceid?: any | null
		port?: any | null
		protocolname?: string | null
		standardprotocol?: string | null
		time: any
		timeend: any
		id: any
	}>
	p_asset_tags: Array<{
		__typename?: "p_asset_tags"
		id: any
		timeend: any
		time: any
		text?: string | null
		assetid?: any | null
	}>
	p_asset_os: Array<{
		__typename?: "p_asset_os"
		id: any
		timeend: any
		time: any
		assetid?: any | null
		developer?: string | null
		name?: string | null
		version?: string | null
	}>
	p_broadcast_domains: Array<{
		__typename?: "p_broadcast_domains"
		id: any
		vlan?: any | null
	}>
	p_pru_m: Array<{
		__typename?: "p_pru_m"
		l7protocol?: string | null
		minute?: any | null
		totalbytes?: any | null
	}>
	data_asset_roles: Array<{
		__typename?: "data_asset_roles"
		id: string
		category?: string | null
		icon?: string | null
		name?: string | null
	}>
}

export type ProjectGraphAssetsQueryVariables = Types.Exact<{
	[key: string]: never
}>

export type ProjectGraphAssetsQuery = {
	__typename?: "query_root"
	p_assets: Array<{
		__typename?: "p_assets"
		name?: string | null
		description?: string | null
		id: any
	}>
	p_asset_cves: Array<{
		__typename?: "p_asset_cves"
		assetid: any
		projectid?: string | null
		id: any
		cve: string
		time: any
		timeend: any
	}>
	p_asset_hardware: Array<{
		__typename?: "p_asset_hardware"
		projectid?: string | null
		assetid?: any | null
		cputype?: string | null
		device?: string | null
		manufacturer?: string | null
		formfactor?: string | null
		time: any
		timeend: any
		id: any
	}>
	p_asset_hostnames: Array<{
		__typename?: "p_asset_hostnames"
		assetid?: any | null
		domain?: string | null
		hostname?: string | null
		protocolname?: string | null
		time: any
		timeend: any
		id: any
	}>
	p_asset_interfaces: Array<{
		__typename?: "p_asset_interfaces"
		assetid?: any | null
		broadcastdomain: any
		connectedto?: any | null
		connector?: string | null
		description?: string | null
		layer2?: any | null
		isvirtual?: boolean | null
		layer3?: any | null
		name?: string | null
		projectid?: string | null
		routesid?: any | null
		time: any
		timeend: any
		id: any
	}>
	p_asset_roles: Array<{
		__typename?: "p_asset_roles"
		assetid?: any | null
		description?: string | null
		id: any
		projectid?: string | null
		time: any
		timeend: any
		type?: string | null
	}>
	p_asset_os: Array<{
		__typename?: "p_asset_os"
		id: any
		timeend: any
		time: any
		assetid?: any | null
		developer?: string | null
		name?: string | null
		version?: string | null
	}>
	p_broadcast_domains: Array<{
		__typename?: "p_broadcast_domains"
		id: any
		vlan?: any | null
	}>
}

export type ProjectGraphSessionsQueryVariables = Types.Exact<{
	[key: string]: never
}>

export type ProjectGraphSessionsQuery = {
	__typename?: "query_root"
	p_pru_m: Array<{
		__typename?: "p_pru_m"
		l7protocol?: string | null
		minute?: any | null
		totalbytes?: any | null
	}>
}

export type WatchProjectsSubscriptionVariables = Types.Exact<{
	[key: string]: never
}>

export type WatchProjectsSubscription = {
	__typename?: "subscription_root"
	core_Project: Array<{
		__typename?: "core_Project"
		id: string
		updated?: any | null
		created?: any | null
		globalShared?: boolean | null
		lastActivityType?: any | null
		name?: string | null
		namespace?: string | null
		rollupAssetCount?: number | null
		rollupImportCount?: number | null
		rollupImportStatus?: any | null
		rollupImportStatusPercent?: number | null
		rollupMessageCode?: string | null
		rollupMessageDev?: string | null
		rollupMessageUser?: string | null
		rollupProjectStatus?: any | null
		rollupTotalFilesize?: number | null
		rollupSchemaVersion?: string | null
		schemaID?: string | null
		tags?: any | null
		ImportOperations: Array<{
			__typename?: "core_ImportOperation"
			hash?: string | null
			id: string
			ingestDate?: any | null
			ingestPath?: string | null
			messageCode?: string | null
			messageDev?: string | null
			messageUser?: string | null
			name?: string | null
			originalFilename?: string | null
			status?: any | null
			statusDate?: any | null
			statusPercent?: number | null
			tags?: any | null
		}>
		Tenant?: {
			__typename?: "core_Tenant"
			id: string
			name?: string | null
			type?: any | null
			TenantUsers: Array<{
				__typename?: "core_TenantUser"
				tenantID: string
				tenantRole?: any | null
				User: {
					__typename?: "core_User"
					emailVerified?: boolean | null
					id: string
					firstName?: string | null
					lastName?: string | null
					tier?: any | null
				}
			}>
		} | null
	}>
}

export const AssetApplicationsFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "assetApplications" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "p_asset_applications" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "assetid" } },
					{ kind: "Field", name: { kind: "Name", value: "developer" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "time" } },
					{ kind: "Field", name: { kind: "Name", value: "timeend" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "version" } },
				],
			},
		},
	],
} as unknown as DocumentNode<AssetApplicationsFragment, unknown>
export const AssetBadgesFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "assetBadges" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "p_asset_badges" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "assetid" } },
					{ kind: "Field", name: { kind: "Name", value: "text" } },
					{ kind: "Field", name: { kind: "Name", value: "time" } },
					{ kind: "Field", name: { kind: "Name", value: "timeend" } },
				],
			},
		},
	],
} as unknown as DocumentNode<AssetBadgesFragment, unknown>
export const AssetCvesFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "assetCves" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "p_asset_cves" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "assetid" } },
					{ kind: "Field", name: { kind: "Name", value: "projectid" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "cve" } },
					{ kind: "Field", name: { kind: "Name", value: "time" } },
					{ kind: "Field", name: { kind: "Name", value: "timeend" } },
				],
			},
		},
	],
} as unknown as DocumentNode<AssetCvesFragment, unknown>
export const AssetDistanceFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "assetDistance" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "p_asset_distance" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "time" } },
					{ kind: "Field", name: { kind: "Name", value: "timeend" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "networkinterfaceid" },
					},
				],
			},
		},
	],
} as unknown as DocumentNode<AssetDistanceFragment, unknown>
export const AssetHardwareFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "assetHardware" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "p_asset_hardware" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "projectid" } },
					{ kind: "Field", name: { kind: "Name", value: "assetid" } },
					{ kind: "Field", name: { kind: "Name", value: "cputype" } },
					{ kind: "Field", name: { kind: "Name", value: "device" } },
					{ kind: "Field", name: { kind: "Name", value: "manufacturer" } },
					{ kind: "Field", name: { kind: "Name", value: "formfactor" } },
					{ kind: "Field", name: { kind: "Name", value: "time" } },
					{ kind: "Field", name: { kind: "Name", value: "timeend" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
				],
			},
		},
	],
} as unknown as DocumentNode<AssetHardwareFragment, unknown>
export const AssetHostnamesFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "assetHostnames" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "p_asset_hostnames" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "assetid" } },
					{ kind: "Field", name: { kind: "Name", value: "domain" } },
					{ kind: "Field", name: { kind: "Name", value: "hostname" } },
					{ kind: "Field", name: { kind: "Name", value: "protocolname" } },
					{ kind: "Field", name: { kind: "Name", value: "time" } },
					{ kind: "Field", name: { kind: "Name", value: "timeend" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
				],
			},
		},
	],
} as unknown as DocumentNode<AssetHostnamesFragment, unknown>
export const AssetInterfacesFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "assetInterfaces" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "p_asset_interfaces" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "assetid" } },
					{ kind: "Field", name: { kind: "Name", value: "broadcastdomain" } },
					{ kind: "Field", name: { kind: "Name", value: "connectedto" } },
					{ kind: "Field", name: { kind: "Name", value: "connector" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "layer2" } },
					{ kind: "Field", name: { kind: "Name", value: "isvirtual" } },
					{ kind: "Field", name: { kind: "Name", value: "layer3" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "projectid" } },
					{ kind: "Field", name: { kind: "Name", value: "routesid" } },
					{ kind: "Field", name: { kind: "Name", value: "time" } },
					{ kind: "Field", name: { kind: "Name", value: "timeend" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
				],
			},
		},
	],
} as unknown as DocumentNode<AssetInterfacesFragment, unknown>
export const AssetOsFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "assetOs" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "p_asset_os" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "timeend" } },
					{ kind: "Field", name: { kind: "Name", value: "time" } },
					{ kind: "Field", name: { kind: "Name", value: "assetid" } },
					{ kind: "Field", name: { kind: "Name", value: "developer" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "version" } },
				],
			},
		},
	],
} as unknown as DocumentNode<AssetOsFragment, unknown>
export const AssetRolesFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "assetRoles" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "p_asset_roles" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "assetid" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "projectid" } },
					{ kind: "Field", name: { kind: "Name", value: "time" } },
					{ kind: "Field", name: { kind: "Name", value: "timeend" } },
					{ kind: "Field", name: { kind: "Name", value: "type" } },
				],
			},
		},
	],
} as unknown as DocumentNode<AssetRolesFragment, unknown>
export const AssetServicesFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "assetServices" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "p_asset_services" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "firewallopen" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "networkinterfaceid" },
					},
					{ kind: "Field", name: { kind: "Name", value: "port" } },
					{ kind: "Field", name: { kind: "Name", value: "protocolname" } },
					{ kind: "Field", name: { kind: "Name", value: "standardprotocol" } },
					{ kind: "Field", name: { kind: "Name", value: "time" } },
					{ kind: "Field", name: { kind: "Name", value: "timeend" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
				],
			},
		},
	],
} as unknown as DocumentNode<AssetServicesFragment, unknown>
export const AssetTagsFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "assetTags" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "p_asset_tags" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "timeend" } },
					{ kind: "Field", name: { kind: "Name", value: "time" } },
					{ kind: "Field", name: { kind: "Name", value: "text" } },
					{ kind: "Field", name: { kind: "Name", value: "assetid" } },
				],
			},
		},
	],
} as unknown as DocumentNode<AssetTagsFragment, unknown>
export const AssetsFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "assets" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "p_assets" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "description" } },
					{ kind: "Field", name: { kind: "Name", value: "id" } },
				],
			},
		},
	],
} as unknown as DocumentNode<AssetsFragment, unknown>
export const BroadcastDomainsFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "broadcastDomains" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "p_broadcast_domains" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "vlan" } },
				],
			},
		},
	],
} as unknown as DocumentNode<BroadcastDomainsFragment, unknown>
export const CoreProjectFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "coreProject" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "core_Project" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "updated" } },
					{ kind: "Field", name: { kind: "Name", value: "created" } },
					{ kind: "Field", name: { kind: "Name", value: "globalShared" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "ImportOperations" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "hash" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "ingestDate" } },
								{ kind: "Field", name: { kind: "Name", value: "ingestPath" } },
								{ kind: "Field", name: { kind: "Name", value: "messageCode" } },
								{ kind: "Field", name: { kind: "Name", value: "messageDev" } },
								{ kind: "Field", name: { kind: "Name", value: "messageUser" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "originalFilename" },
								},
								{ kind: "Field", name: { kind: "Name", value: "status" } },
								{ kind: "Field", name: { kind: "Name", value: "statusDate" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "statusPercent" },
								},
								{ kind: "Field", name: { kind: "Name", value: "tags" } },
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "Tenant" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "type" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "TenantUsers" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "Field",
												name: { kind: "Name", value: "tenantID" },
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "tenantRole" },
											},
											{
												kind: "Field",
												name: { kind: "Name", value: "User" },
												selectionSet: {
													kind: "SelectionSet",
													selections: [
														{
															kind: "Field",
															name: { kind: "Name", value: "emailVerified" },
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "id" },
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "firstName" },
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "lastName" },
														},
														{
															kind: "Field",
															name: { kind: "Name", value: "tier" },
														},
													],
												},
											},
										],
									},
								},
							],
						},
					},
					{ kind: "Field", name: { kind: "Name", value: "lastActivityType" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
					{ kind: "Field", name: { kind: "Name", value: "namespace" } },
					{ kind: "Field", name: { kind: "Name", value: "rollupAssetCount" } },
					{ kind: "Field", name: { kind: "Name", value: "rollupImportCount" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "rollupImportStatus" },
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "rollupImportStatusPercent" },
					},
					{ kind: "Field", name: { kind: "Name", value: "rollupMessageCode" } },
					{ kind: "Field", name: { kind: "Name", value: "rollupMessageDev" } },
					{ kind: "Field", name: { kind: "Name", value: "rollupMessageUser" } },
					{
						kind: "Field",
						name: { kind: "Name", value: "rollupProjectStatus" },
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "rollupTotalFilesize" },
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "rollupSchemaVersion" },
					},
					{ kind: "Field", name: { kind: "Name", value: "schemaID" } },
					{ kind: "Field", name: { kind: "Name", value: "tags" } },
				],
			},
		},
	],
} as unknown as DocumentNode<CoreProjectFragment, unknown>
export const DataAssetRoleFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "dataAssetRole" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "data_asset_roles" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "id" } },
					{ kind: "Field", name: { kind: "Name", value: "category" } },
					{ kind: "Field", name: { kind: "Name", value: "icon" } },
					{ kind: "Field", name: { kind: "Name", value: "name" } },
				],
			},
		},
	],
} as unknown as DocumentNode<DataAssetRoleFragment, unknown>
export const PruMFragmentDoc = {
	kind: "Document",
	definitions: [
		{
			kind: "FragmentDefinition",
			name: { kind: "Name", value: "pruM" },
			typeCondition: {
				kind: "NamedType",
				name: { kind: "Name", value: "p_pru_m" },
			},
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{ kind: "Field", name: { kind: "Name", value: "l7protocol" } },
					{ kind: "Field", name: { kind: "Name", value: "minute" } },
					{ kind: "Field", name: { kind: "Name", value: "totalbytes" } },
				],
			},
		},
	],
} as unknown as DocumentNode<PruMFragment, unknown>
export const AddAssetDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addAsset" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "p_assets_insert_input" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "insert_p_assets_one" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "object" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "data" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assets" },
								},
							],
						},
					},
				],
			},
		},
		...AssetsFragmentDoc.definitions,
	],
} as unknown as DocumentNode<AddAssetMutation, AddAssetMutationVariables>
export const AddAssetHardwareDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addAssetHardware" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "p_asset_hardware_insert_input" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "insert_p_asset_hardware_one" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "object" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "data" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetHardware" },
								},
							],
						},
					},
				],
			},
		},
		...AssetHardwareFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	AddAssetHardwareMutation,
	AddAssetHardwareMutationVariables
>
export const AddAssetHostnamesDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addAssetHostnames" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: {
									kind: "NamedType",
									name: {
										kind: "Name",
										value: "p_asset_hostnames_insert_input",
									},
								},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "insert_p_asset_hostnames" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "objects" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "data" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "returning" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "assetHostnames" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		...AssetHostnamesFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	AddAssetHostnamesMutation,
	AddAssetHostnamesMutationVariables
>
export const AddAssetOsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addAssetOs" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "p_asset_os_insert_input" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "insert_p_asset_os_one" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "object" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "data" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetOs" },
								},
							],
						},
					},
				],
			},
		},
		...AssetOsFragmentDoc.definitions,
	],
} as unknown as DocumentNode<AddAssetOsMutation, AddAssetOsMutationVariables>
export const AddAssetRoleDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addAssetRole" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: {
									kind: "NamedType",
									name: { kind: "Name", value: "p_asset_roles_insert_input" },
								},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "insert_p_asset_roles" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "objects" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "data" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "returning" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "assetRoles" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		...AssetRolesFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	AddAssetRoleMutation,
	AddAssetRoleMutationVariables
>
export const AddAssetTagDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addAssetTag" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "p_asset_tags_insert_input" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "insert_p_asset_tags_one" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "object" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "data" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetTags" },
								},
							],
						},
					},
				],
			},
		},
		...AssetTagsFragmentDoc.definitions,
	],
} as unknown as DocumentNode<AddAssetTagMutation, AddAssetTagMutationVariables>
export const AddAssetTagsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addAssetTags" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: {
									kind: "NamedType",
									name: { kind: "Name", value: "p_asset_tags_insert_input" },
								},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "insert_p_asset_tags" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "objects" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "data" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "returning" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "assetTags" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		...AssetTagsFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	AddAssetTagsMutation,
	AddAssetTagsMutationVariables
>
export const AddAssetsHardwareDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addAssetsHardware" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "objects" },
					},
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: {
									kind: "NamedType",
									name: {
										kind: "Name",
										value: "p_asset_hardware_insert_input",
									},
								},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "insert_p_asset_hardware" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "objects" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "objects" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "returning" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "assetHardware" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		...AssetHardwareFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	AddAssetsHardwareMutation,
	AddAssetsHardwareMutationVariables
>
export const AddAssetsOsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "addAssetsOs" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "objects" },
					},
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: {
									kind: "NamedType",
									name: { kind: "Name", value: "p_asset_os_insert_input" },
								},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "insert_p_asset_os" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "objects" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "objects" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "returning" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "assetOs" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		...AssetOsFragmentDoc.definitions,
	],
} as unknown as DocumentNode<AddAssetsOsMutation, AddAssetsOsMutationVariables>
export const CreateProjectDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "CreateProject" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "String" },
						},
					},
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "tags" } },
					type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "insert_core_Project_one" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "project" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "name" },
											value: {
												kind: "Variable",
												name: { kind: "Name", value: "name" },
											},
										},
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "tags" },
											value: {
												kind: "Variable",
												name: { kind: "Name", value: "tags" },
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "name" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "tenantID" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	CreateProjectMutation,
	CreateProjectMutationVariables
>
export const DeleteAssetHostnamesDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "deleteAssetHostnames" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: {
									kind: "NamedType",
									name: { kind: "Name", value: "bigint" },
								},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "delete_p_asset_hostnames" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_in" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "ids" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "returning" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "assetHostnames" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		...AssetHostnamesFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	DeleteAssetHostnamesMutation,
	DeleteAssetHostnamesMutationVariables
>
export const DeleteAssetRolesDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "deleteAssetRoles" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: {
									kind: "NamedType",
									name: { kind: "Name", value: "bigint" },
								},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "delete_p_asset_roles" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_in" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "ids" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "returning" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "assetRoles" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		...AssetRolesFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	DeleteAssetRolesMutation,
	DeleteAssetRolesMutationVariables
>
export const DeleteAssetTagDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "deleteAssetTag" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "bigint" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "delete_p_asset_tags_by_pk" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "id" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetTags" },
								},
							],
						},
					},
				],
			},
		},
		...AssetTagsFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	DeleteAssetTagMutation,
	DeleteAssetTagMutationVariables
>
export const DeleteAssetTagsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "deleteAssetTags" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "where" },
					},
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "p_asset_tags_bool_exp" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "delete_p_asset_tags" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "where" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "returning" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "assetTags" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		...AssetTagsFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	DeleteAssetTagsMutation,
	DeleteAssetTagsMutationVariables
>
export const DeleteProjectDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "DeleteProject" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "projectId" },
					},
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "String" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "delete_core_Project" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "project" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "id" },
											value: {
												kind: "Variable",
												name: { kind: "Name", value: "projectId" },
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	DeleteProjectMutation,
	DeleteProjectMutationVariables
>
export const InsertOnDuplicateKeyUpdateUserDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "InsertOnDuplicateKeyUpdateUser" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "user" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "CreateUserInput" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "insert_core_User_one" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "user" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "user" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "auth0UserId" } },
								{
									kind: "Field",
									name: { kind: "Name", value: "contactEmail" },
								},
								{ kind: "Field", name: { kind: "Name", value: "firstName" } },
								{ kind: "Field", name: { kind: "Name", value: "id" } },
								{ kind: "Field", name: { kind: "Name", value: "lastName" } },
								{ kind: "Field", name: { kind: "Name", value: "locale" } },
								{ kind: "Field", name: { kind: "Name", value: "tier" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	InsertOnDuplicateKeyUpdateUserMutation,
	InsertOnDuplicateKeyUpdateUserMutationVariables
>
export const UpdateAssetDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "UpdateAsset" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "bigint" },
						},
					},
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "p_assets_set_input" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "update_p_assets_by_pk" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "pk_columns" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "id" },
											value: {
												kind: "Variable",
												name: { kind: "Name", value: "id" },
											},
										},
									],
								},
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "_set" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "data" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assets" },
								},
							],
						},
					},
				],
			},
		},
		...AssetsFragmentDoc.definitions,
	],
} as unknown as DocumentNode<UpdateAssetMutation, UpdateAssetMutationVariables>
export const UpdateAssetHardwareDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "UpdateAssetHardware" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "bigint" },
						},
					},
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "p_asset_hardware_set_input" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "update_p_asset_hardware_by_pk" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "pk_columns" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "id" },
											value: {
												kind: "Variable",
												name: { kind: "Name", value: "id" },
											},
										},
									],
								},
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "_set" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "data" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetHardware" },
								},
							],
						},
					},
				],
			},
		},
		...AssetHardwareFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	UpdateAssetHardwareMutation,
	UpdateAssetHardwareMutationVariables
>
export const UpdateAssetOsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "UpdateAssetOs" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "bigint" },
						},
					},
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "p_asset_os_set_input" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "update_p_asset_os_by_pk" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "pk_columns" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "id" },
											value: {
												kind: "Variable",
												name: { kind: "Name", value: "id" },
											},
										},
									],
								},
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "_set" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "data" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetOs" },
								},
							],
						},
					},
				],
			},
		},
		...AssetOsFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	UpdateAssetOsMutation,
	UpdateAssetOsMutationVariables
>
export const UpdateAssetsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "UpdateAssets" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
					type: {
						kind: "NamedType",
						name: { kind: "Name", value: "p_assets_set_input" },
					},
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "ListType",
							type: {
								kind: "NonNullType",
								type: {
									kind: "NamedType",
									name: { kind: "Name", value: "bigint" },
								},
							},
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "update_p_assets" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "_set" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "data" },
								},
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "id" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_in" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "ids" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "returning" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "assets" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		...AssetsFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	UpdateAssetsMutation,
	UpdateAssetsMutationVariables
>
export const UpdateAssetsHardwareDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "UpdateAssetsHardware" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
					type: {
						kind: "ListType",
						type: {
							kind: "NonNullType",
							type: {
								kind: "NamedType",
								name: { kind: "Name", value: "bigint" },
							},
						},
					},
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "p_asset_hardware_set_input" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "update_p_asset_hardware" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "_set" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "data" },
								},
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_in" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "ids" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "returning" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "assetHardware" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		...AssetHardwareFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	UpdateAssetsHardwareMutation,
	UpdateAssetsHardwareMutationVariables
>
export const UpdateAssetsOsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "UpdateAssetsOs" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
					type: {
						kind: "ListType",
						type: {
							kind: "NonNullType",
							type: {
								kind: "NamedType",
								name: { kind: "Name", value: "bigint" },
							},
						},
					},
				},
				{
					kind: "VariableDefinition",
					variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "p_asset_os_set_input" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "update_p_asset_os" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "_set" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "data" },
								},
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_in" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "ids" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "Field",
									name: { kind: "Name", value: "returning" },
									selectionSet: {
										kind: "SelectionSet",
										selections: [
											{
												kind: "FragmentSpread",
												name: { kind: "Name", value: "assetOs" },
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		...AssetOsFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	UpdateAssetsOsMutation,
	UpdateAssetsOsMutationVariables
>
export const UpdateProjectNameDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "mutation",
			name: { kind: "Name", value: "UpdateProjectName" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "projectId" },
					},
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "String" },
						},
					},
				},
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "projectName" },
					},
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "String" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "update_core_Project_by_pk" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "pk_columns" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "id" },
											value: {
												kind: "Variable",
												name: { kind: "Name", value: "projectId" },
											},
										},
									],
								},
							},
							{
								kind: "Argument",
								name: { kind: "Name", value: "_set" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "name" },
											value: {
												kind: "Variable",
												name: { kind: "Name", value: "projectName" },
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{ kind: "Field", name: { kind: "Name", value: "id" } },
							],
						},
					},
				],
			},
		},
	],
} as unknown as DocumentNode<
	UpdateProjectNameMutation,
	UpdateProjectNameMutationVariables
>
export const AssetDetailsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "assetDetails" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "assetids" },
					},
					type: {
						kind: "ListType",
						type: {
							kind: "NonNullType",
							type: {
								kind: "NamedType",
								name: { kind: "Name", value: "bigint" },
							},
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "p_assets" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "id" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_in" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetids" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assets" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_hardware" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_in" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetids" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetHardware" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_hostnames" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_in" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetids" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetHostnames" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_os" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_in" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetids" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetOs" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_roles" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_in" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetids" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetRoles" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_interfaces" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_in" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetids" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetInterfaces" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_applications" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_in" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetids" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetApplications" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_cves" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_in" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetids" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetCves" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "data_asset_roles" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "dataAssetRole" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_tags" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetTags" },
								},
							],
						},
					},
				],
			},
		},
		...AssetsFragmentDoc.definitions,
		...AssetHardwareFragmentDoc.definitions,
		...AssetHostnamesFragmentDoc.definitions,
		...AssetOsFragmentDoc.definitions,
		...AssetRolesFragmentDoc.definitions,
		...AssetInterfacesFragmentDoc.definitions,
		...AssetApplicationsFragmentDoc.definitions,
		...AssetCvesFragmentDoc.definitions,
		...DataAssetRoleFragmentDoc.definitions,
		...AssetTagsFragmentDoc.definitions,
	],
} as unknown as DocumentNode<AssetDetailsQuery, AssetDetailsQueryVariables>
export const AssetIdsSortedDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "assetIdsSorted" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "order_by" },
					},
					type: {
						kind: "ListType",
						type: {
							kind: "NonNullType",
							type: {
								kind: "NamedType",
								name: { kind: "Name", value: "p_assets_order_by" },
							},
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "p_assets" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "order_by" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "order_by" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assets" },
								},
							],
						},
					},
				],
			},
		},
		...AssetsFragmentDoc.definitions,
	],
} as unknown as DocumentNode<AssetIdsSortedQuery, AssetIdsSortedQueryVariables>
export const AssetSummaryPanelDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "assetSummaryPanel" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "assetid" },
					},
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "bigint" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "p_assets_by_pk" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "assetid" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assets" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_interfaces" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_eq" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetid" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetInterfaces" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_hardware" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_eq" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetid" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetHardware" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_hostnames" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_eq" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetid" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetHostnames" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_tags" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_eq" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetid" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetTags" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_cves" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_eq" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetid" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetCves" },
								},
							],
						},
					},
				],
			},
		},
		...AssetsFragmentDoc.definitions,
		...AssetInterfacesFragmentDoc.definitions,
		...AssetHardwareFragmentDoc.definitions,
		...AssetHostnamesFragmentDoc.definitions,
		...AssetTagsFragmentDoc.definitions,
		...AssetCvesFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	AssetSummaryPanelQuery,
	AssetSummaryPanelQueryVariables
>
export const AssetWithInterfacesDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "assetWithInterfaces" },
			variableDefinitions: [
				{
					kind: "VariableDefinition",
					variable: {
						kind: "Variable",
						name: { kind: "Name", value: "assetid" },
					},
					type: {
						kind: "NonNullType",
						type: {
							kind: "NamedType",
							name: { kind: "Name", value: "bigint" },
						},
					},
				},
			],
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "p_assets_by_pk" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "id" },
								value: {
									kind: "Variable",
									name: { kind: "Name", value: "assetid" },
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assets" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_interfaces" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_eq" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetid" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetInterfaces" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_hardware" },
						arguments: [
							{
								kind: "Argument",
								name: { kind: "Name", value: "where" },
								value: {
									kind: "ObjectValue",
									fields: [
										{
											kind: "ObjectField",
											name: { kind: "Name", value: "assetid" },
											value: {
												kind: "ObjectValue",
												fields: [
													{
														kind: "ObjectField",
														name: { kind: "Name", value: "_eq" },
														value: {
															kind: "Variable",
															name: { kind: "Name", value: "assetid" },
														},
													},
												],
											},
										},
									],
								},
							},
						],
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetHardware" },
								},
							],
						},
					},
				],
			},
		},
		...AssetsFragmentDoc.definitions,
		...AssetInterfacesFragmentDoc.definitions,
		...AssetHardwareFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	AssetWithInterfacesQuery,
	AssetWithInterfacesQueryVariables
>
export const GetProjectsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "GetProjects" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "core_Project" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "coreProject" },
								},
							],
						},
					},
				],
			},
		},
		...CoreProjectFragmentDoc.definitions,
	],
} as unknown as DocumentNode<GetProjectsQuery, GetProjectsQueryVariables>
export const ProjectDataInitialDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "projectDataInitial" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "p_assets" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assets" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_applications" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetApplications" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_badges" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetBadges" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_cves" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetCves" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_distance" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetDistance" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_hardware" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetHardware" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_hostnames" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetHostnames" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_interfaces" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetInterfaces" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_roles" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetRoles" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_services" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetServices" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_tags" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetTags" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_os" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetOs" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_broadcast_domains" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "broadcastDomains" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_pru_m" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "pruM" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "data_asset_roles" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "dataAssetRole" },
								},
							],
						},
					},
				],
			},
		},
		...AssetsFragmentDoc.definitions,
		...AssetApplicationsFragmentDoc.definitions,
		...AssetBadgesFragmentDoc.definitions,
		...AssetCvesFragmentDoc.definitions,
		...AssetDistanceFragmentDoc.definitions,
		...AssetHardwareFragmentDoc.definitions,
		...AssetHostnamesFragmentDoc.definitions,
		...AssetInterfacesFragmentDoc.definitions,
		...AssetRolesFragmentDoc.definitions,
		...AssetServicesFragmentDoc.definitions,
		...AssetTagsFragmentDoc.definitions,
		...AssetOsFragmentDoc.definitions,
		...BroadcastDomainsFragmentDoc.definitions,
		...PruMFragmentDoc.definitions,
		...DataAssetRoleFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	ProjectDataInitialQuery,
	ProjectDataInitialQueryVariables
>
export const ProjectGraphAssetsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "projectGraphAssets" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "p_assets" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assets" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_cves" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetCves" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_hardware" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetHardware" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_hostnames" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetHostnames" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_interfaces" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetInterfaces" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_roles" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetRoles" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_asset_os" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "assetOs" },
								},
							],
						},
					},
					{
						kind: "Field",
						name: { kind: "Name", value: "p_broadcast_domains" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "broadcastDomains" },
								},
							],
						},
					},
				],
			},
		},
		...AssetsFragmentDoc.definitions,
		...AssetCvesFragmentDoc.definitions,
		...AssetHardwareFragmentDoc.definitions,
		...AssetHostnamesFragmentDoc.definitions,
		...AssetInterfacesFragmentDoc.definitions,
		...AssetRolesFragmentDoc.definitions,
		...AssetOsFragmentDoc.definitions,
		...BroadcastDomainsFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	ProjectGraphAssetsQuery,
	ProjectGraphAssetsQueryVariables
>
export const ProjectGraphSessionsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "query",
			name: { kind: "Name", value: "projectGraphSessions" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "p_pru_m" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "pruM" },
								},
							],
						},
					},
				],
			},
		},
		...PruMFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	ProjectGraphSessionsQuery,
	ProjectGraphSessionsQueryVariables
>
export const WatchProjectsDocument = {
	kind: "Document",
	definitions: [
		{
			kind: "OperationDefinition",
			operation: "subscription",
			name: { kind: "Name", value: "WatchProjects" },
			selectionSet: {
				kind: "SelectionSet",
				selections: [
					{
						kind: "Field",
						name: { kind: "Name", value: "core_Project" },
						selectionSet: {
							kind: "SelectionSet",
							selections: [
								{
									kind: "FragmentSpread",
									name: { kind: "Name", value: "coreProject" },
								},
							],
						},
					},
				],
			},
		},
		...CoreProjectFragmentDoc.definitions,
	],
} as unknown as DocumentNode<
	WatchProjectsSubscription,
	WatchProjectsSubscriptionVariables
>
export const version = "normalized"
