import Link from "next/link"
import { Box, Button } from "@chakra-ui/react"
import Logo from "@common/components/Logo"

const AppLogin = () => {
	return (
		<Box textAlign="center" mt="30vh">
			<Box mb={2}>
				<Logo width={200} height={30} />
			</Box>
			<Link href="/api/auth/login" passHref>
				<Button variant="solid" size="lg" minW="15rem" mt="10">
					Login
				</Button>
			</Link>
		</Box>
	)
}

export default AppLogin
