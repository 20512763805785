import { ReactElement, ReactNode } from "react"
import { Checkbox } from "@chakra-ui/react"

export const Shadows = {
	"dark-md": "0px 0px 13px 0px rgba(0, 0, 0, 0.4)",
	"dark-lg":
		"rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px",
	outline: "0 0 0 3px var(--chakra-ui-focus-ring-color)",
}
export interface PageViewActionButton {
	icon?: JSX.Element
	title: string
	target: string
}

export interface PageOptions {
	title?: string // HTML page title (default: Teleseer)
	heading?: string // Bold heading text rendered at the top of each page
	actionButtons?: PageViewActionButton[] | JSX.Element // Action buttons are rendered to the right of the page heading
	pageWidthPx?: number // Default is 800. null to use default. -1 implies no width limit and no padding to enable full-view graph canvases
	hideSidebar?: boolean // Default is false. Pages (such as the Project Graph View) can set this to true for max visual real estate
	topToolbarContent?: JSX.Element // Toolbar will only be rendered with a background if contents are set
}

export interface PageViewProps {
	SetPageOptions(PageOptions): void
}

export interface PageViewComponent {
	(props: PageViewProps, context?: any): JSX.Element
	getLayout?: (page: ReactElement) => ReactNode
}

export const DefaultFloatingProps = {
	// #todo backdrop-filter styling inherited from RaisedFloatingProps is not being respected, and it is not clear why.
	// We can set backgroundColor alpha back to .8 once we get proper background blurs working.
	// backgroundColor: "rgba(32, 32, 32, .8)",
	// backdropFilter: "blur(4px)",
	// Further reading:
	// - https://stackoverflow.com/questions/59218666/backdrop-filter-not-working-with-overflow-hidden-on-parent
	// - https://github.com/chakra-ui/chakra-ui-docs/issues/210
	backgroundColor: "theme_accent",
	boxShadow: "dark-md",
	border: "none",
	borderRadius: "none",
}

export const RaisedFloatingProps = {
	...DefaultFloatingProps,
	boxShadow: `${Shadows["dark-lg"]} !important`,
	_hover: {
		borderColor: "none",
		boxShadow: `${Shadows["dark-lg"]} !important`,
	},
	_focus: {
		borderColor: "none",
		boxShadow: `${Shadows["dark-lg"]} !important`,
	},
	_active: {
		borderColor: "none",
		boxShadow: `${Shadows["dark-lg"]} !important`,
	},
}

export const DefaultFloatingPanelProps = {
	...DefaultFloatingProps,
	borderRadius: "xl",
	zIndex: 5,
}

export const DefaultIconButtonProps = {
	my: "2",
	cursor: "pointer",
	variant: "ghost",
	color: "theme_text_desc",
	borderRadius: "none",
	_hover: {
		color: "white",
		backgroundColor: "theme_hilight",
	},
	_active: {
		// backgroundColor: "theme_hilight",
		color: "var(--chakra-ui-focus-ring-color)",
		// #todo subtle but visually distinctive underline effect
		// textDecoration: "underline",
		// border: "1px solid white",
		background:
			"linear-gradient(var(--chakra-ui-focus-ring-color),var(--chakra-ui-focus-ring-color)) bottom/ 50% 2px no-repeat",
	},
	_disabled: {
		color: "#5f615f",
	},
}

export const DefaultTopToolbarButtonProps = {
	...DefaultIconButtonProps,
	borderRadius: "none",
	height: "36px",
	size: "sm",
}

export const CompactIconButtonProps = {
	...DefaultIconButtonProps,
	my: 0,
	mx: 0,
	px: 0,
}

export const ThumbnailIconButtonProps = {
	...CompactIconButtonProps,
	borderRadius: "0",
	_active: {
		// color: "white",
		backgroundColor: "theme_hilight",
		// borderBottom: "1px solid white",
	},
}

export const LogoButtonProps = {
	...DefaultIconButtonProps,
	// my: "2",
	// cursor: "pointer",
	variant: "ghost",
	// color: "gray.500",
	_hover: {
		color: "white",
		backgroundColor: "none",
	},
	_active: {
		color: "white",
		backgroundColor: "none",
	},
}
