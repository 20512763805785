import { GraphAssetType, GraphBadgeType, GraphEdgeType } from "./GraphAssets"
import { GraphAssetColor } from "./GraphIconColors"

export enum GraphRenderingMode {
	WebGL2 = "WEB_GL_2",
	SVG = "SVG",
}

export enum RenderingModeOption {
	WebGL = "WEB_GL",
	SVG = "SVG",
	AUTO = "AUTO",
}

export enum GraphProjectionMode {
	Flat = "FLAT",
	IsoLeft = "ISO_LEFT",
	IsoRight = "ISO_RIGHT",
}

export enum GraphGroupMode {
	Domain = "DOMAIN",
	Type = "TYPE",
	OS = "OS",
	None = "NONE",
}

export enum GraphNodeStyle {
	Icon = "ICON",
	Pedestal = "PEDESTAL",
	Box = "BOX",
}

export const GraphBenchmarkingModes = {
	// "250": {
	// 	title: "250",
	// 	style: "radial",
	// 	nodes: 250,
	// },
	"1000": {
		title: "1k",
		style: "radial",
		nodes: 1000,
	},
	"5000": {
		title: "5k",
		style: "radial",
		nodes: 5000,
	},
	"10000": {
		title: "10k",
		style: "radial",
		nodes: 10000,
	},
	"50000": {
		title: "50k",
		style: "radial",
		nodes: 50000,
	},
	"100000": {
		title: "100K",
		style: "radial",
		nodes: 100000,
	},
	"250000": {
		title: "250K",
		style: "radial",
		nodes: 250000,
	},
	// "1000000": {
	// 	title: "1M",
	// 	style: "radial",
	// 	nodes: 1000000,
	// },
}

export enum GraphColor {
	lightRed = "#cf706e",
	midRed = "#CD440F",
	darkRed = "#AE0000",
	lightOrange = "#FFC791",
	midOrange = "#fc9867",
	darkOrange = "#FF662E",
	lightYellow = "#FFF79C",
	midYellow = "#ffd866",
	darkYellow = "#EBDB00",
	lightGreen = "#A7FF80",
	midGreen = "#a8dd76",
	darkGreen = "#058D0E",
	lightBlue = "#6eafe1",
	midBlue = "#79dce8",
	darkBlue = "#34679A",
	lightPurple = "#9786be",
	midPurple = "#ac9cf2",
	darkPurple = "#6800F1",
	lightPink = "#b05279",
	midPink = "#ff6288",
	lightGrey = "#e9ebed",
	midGrey = "#A1A1A1",
	// midGrey = "#373737",
	darkGrey = "#1e1e1e",
	darkGreyTransparent = "rgba(30, 30, 30, .25)",
	textDesc = "#5f615f",
	palette1 = "#003f5c",
	palette2 = "#2f4b7c",
	palette3 = "#665191",
	palette4 = "#a05195",
	palette5 = "#d45087",
	palette6 = "#f95d6a",
	palette7 = "#ff7c43",
	palette8 = "#ffa600",
}

export const GraphColorPalette = [
	// GraphColor.midOrange,
	// GraphColor.midYellow,
	// GraphColor.midGreen,
	// GraphColor.midBlue,
	// GraphColor.midPurple,
	// GraphColor.midPink,
	// GraphColor.midRed,
	GraphColor.palette1,
	GraphColor.palette2,
	GraphColor.palette3,
	GraphColor.palette4,
	GraphColor.palette5,
	GraphColor.palette6,
	GraphColor.palette7,
	GraphColor.palette8,
]

export interface GraphColorRGB {
	r: number
	g: number
	b: number
}

export type GraphColorRGBInterface = {
	[key in GraphColor]?: GraphColorRGB
}

export const GraphColorRGB: GraphColorRGBInterface = Object.fromEntries(
	Object.keys(GraphColor).map((color) => {
		const hex = GraphColor[color].substring(1).match(/.{1,2}/g)
		return [
			GraphColor[color],
			{
				r: parseInt(hex[0], 16),
				g: parseInt(hex[1], 16),
				b: parseInt(hex[2], 16),
			},
		]
	})
)

export interface GraphAssetView {
	id: string
	x: number
	y: number
}

export interface GraphNodeData {
	type: GraphAssetType
	id?: string
	name?: string
	badge?: GraphBadgeType
	labels?: string[]
	color?: GraphAssetColor
	scale?: number // undefined or 1 renders this node at the default size. 2 renders it at twice the default size.
	layer?: number
	isRoot?: boolean
	views?: GraphAssetView[]
	positionFixed?: boolean
}

export interface GraphEdgeData {
	id?: string
	name?: string
	labels?: string[]
	type: GraphEdgeType
}

export interface GraphGroupData {
	id?: string
	label?: string
	type?: GraphAssetType
}

export const GraphNodeSize = {
	w: 64,
	h: 64,
}

export const GraphLayouts = {
	HierarchicLayout: "HierarchicLayout",
	RadialLayout: "RadialLayout",
	OrganicLayout: "OrganicLayout",
} as const
