import React from "react"
import useUser from "@common/hooks/useUser"
import AppLoading from "@common/layouts/AppLoading"
import AppLogin from "@common/layouts/AppLogin"
import { useAppToken } from "./AppTokenProvider"
import { useUserDetails } from "./AppUserDetailsProvider"

interface AppInitBlockerProps {
	children: React.ReactElement
}

const AppInitBlocker = ({
	children,
}: AppInitBlockerProps): React.ReactElement => {
	const { user, isLoading: userIsLoading } = useUser()
	const userDetails = useUserDetails()
	const { jwt: apiJwt } = useAppToken()

	// Init check
	if (userIsLoading || !apiJwt || userDetails === null) {
		return <AppLoading />
	}

	// Auth check
	if (!user) {
		return <AppLogin />
	}

	return children
}

export default AppInitBlocker
