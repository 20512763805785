import { createContext, useContext, useEffect, useState } from "react"
import React from "react"
import {
	Box,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay,
	FormControl,
	FormLabel,
	Switch,
	VStack,
	useDisclosure,
} from "@chakra-ui/react"
import AppConfig from "@common/constants/AppConfig"
import DevModeIcon from "@common/icons/DevModeIcon"
import {
	GraphGroupMode,
	GraphLayouts,
	GraphProjectionMode,
	RenderingModeOption,
} from "@graph/constants/GraphConstants"
import { ProjectDevMode } from "@modules/project/constants/ToolModes"
import { TsTokenContext, useAppToken } from "./AppTokenProvider"

export interface TsSettingsContextInterface {
	settings: TsSettings
	saveSettings: (update: Partial<TsSettings>) => void
	openSettings: () => void
}

const SETTINGS_KEY = "settings_v1"

export interface TsSettings {
	devMode: boolean
	adminMode: boolean // This is dynamically determined by inspecting the user's JWT, and cannot be set by the user
	// projectToolMode: ProjectToolMode
	projectDevMode: ProjectDevMode
	renderingMode: RenderingModeOption
	viewPinDetailsPanel: boolean
	graphShowGrid: boolean
	graphShowSessionEdges: boolean
	graphProjectionMode: GraphProjectionMode
	graphGroupMode: GraphGroupMode
	// compact?: boolean
	// direction?: "ltr" | "rtl"
	// graphShowOverview?: boolean
	// theme?: string
	// devForceUser0?: boolean
	graphLayout: keyof typeof GraphLayouts
	smartFollow: boolean
}

const initialSettings: TsSettings = {
	devMode: false,
	adminMode: false,
	// projectToolMode: ProjectToolMode[AppConfig.project_default_tool_mode],
	renderingMode: RenderingModeOption.AUTO,
	projectDevMode: ProjectDevMode[AppConfig.project_default_dev_mode],
	viewPinDetailsPanel: true,
	graphShowGrid: true,
	graphShowSessionEdges: true,
	graphProjectionMode: GraphProjectionMode.IsoLeft,
	graphGroupMode: GraphGroupMode.Domain,
	graphLayout: GraphLayouts.OrganicLayout,
	smartFollow: true,
}

interface SettingsProviderProps {
	children: React.ReactNode
}

export const SettingsContext = createContext<TsSettingsContextInterface>({
	settings: initialSettings,
	saveSettings: () => {},
	openSettings: () => {},
})

const storeSettings = (settings: TsSettings): void => {
	window.localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings))
}

const restoreSettings = (): TsSettings | null => {
	try {
		const storedData: string | null = window.localStorage.getItem(SETTINGS_KEY)
		if (storedData) {
			return JSON.parse(storedData)
		}
	} catch (err) {
		// If stored data is not a stringified JSON, this will fail and we will use default settings
		console.error(err)
	}
}

const SettingsProvider = ({ children }: SettingsProviderProps) => {
	const [settings, setSettings] = useState<TsSettings>(initialSettings)
	const { jwtPayload } = useAppToken()
	const { isOpen, onOpen, onClose } = useDisclosure()

	useEffect(() => {
		const restoredSettings = restoreSettings()
		if (restoredSettings) {
			const newSettings = {
				...initialSettings,
				...restoredSettings,
				adminMode: settings.adminMode,
			}
			setSettings(newSettings)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Drive adminMode setting by inspecting the user's JWT permissions
	useEffect(() => {
		if (!jwtPayload) {
			return
		}

		try {
			if (
				jwtPayload &&
				jwtPayload.permissions &&
				jwtPayload.permissions.includes("admin")
			) {
				setSettings((settings) => ({ ...settings, adminMode: true }))
			}
		} catch (err) {
			console.error("JWT parsing failed: ", err)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jwtPayload])

	const saveSettings = (updatedSettings: Partial<TsSettings>): void => {
		const newSettings = {
			...settings,
			...updatedSettings,
			adminMode: settings.adminMode,
		} as TsSettings

		setSettings(newSettings)
		storeSettings(newSettings)

		// console.log("Settings saved: ", newSettings)
	}

	const handleChange = (field, value): void => {
		saveSettings({
			[field]: value,
		})
	}

	return (
		<SettingsContext.Provider
			value={{
				settings,
				saveSettings,
				openSettings: () => onOpen(),
			}}
		>
			{children}
			<Drawer onClose={onClose} isOpen={isOpen} size="xs" placement="right">
				<DrawerOverlay />
				<DrawerContent>
					<DrawerHeader>Settings</DrawerHeader>
					<DrawerBody>
						<FormControl
							as={VStack}
							spacing={4}
							sx={{
								alignItems: "baseline",
								justifyContent: "center",
								maxWidth: "300px",
								div: {
									width: "100%",
									display: "flex",
									alignItems: "center",
								},
								"label:nth-of-type(1)": {
									fontSize: "1.2em",
									pt: ".3em",
									display: "inline-block",
									flex: 1,
								},
								svg: { fontSize: "1.5rem", mr: "4" },
								label: { cursor: "pointer" },
							}}
						>
							<Box>
								<DevModeIcon />
								<FormLabel htmlFor="devMode">Dev mode</FormLabel>
								<Switch
									id="devMode"
									isChecked={settings.devMode}
									onChange={(event): void =>
										handleChange("devMode", event.target.checked)
									}
								/>
							</Box>

							{/* <Box>
								<AdminModeIcon />
								<FormLabel htmlFor="adminMode">Admin mode</FormLabel>
								<Switch
									id="adminMode"
									isChecked={settings.adminMode}
									onChange={(event): void =>
										handleChange("adminMode", event.target.checked)
									}
								/>
							</Box> */}
						</FormControl>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</SettingsContext.Provider>
	)
}
export default SettingsProvider

export const useSettings = () => useContext(SettingsContext)
