import React from "react"
import {
	UseToastOptions,
	createStandaloneToast,
	useChakra,
} from "@chakra-ui/react"

const defaultToastOptions: UseToastOptions = {
	position: "top-right",
	variant: "solid",
	// variant: "left-accent",
	// duration: 3000,
	isClosable: true,
}

const useAppToast = (options?: UseToastOptions) => {
	const { theme, setColorMode, toggleColorMode, colorMode } = useChakra()

	return React.useMemo(() => {
		const defaultOptions: UseToastOptions = {
			...defaultToastOptions,
			...options,
		}

		return createStandaloneToast({
			theme,
			colorMode,
			setColorMode,
			toggleColorMode,
			defaultOptions,
		})
	}, [theme, setColorMode, toggleColorMode, colorMode, options])
}

export default useAppToast
