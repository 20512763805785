import { CachePersistor, LocalForageWrapper } from "apollo3-cache-persist"
import mapKeysDeep from "deepdash/mapKeysDeep"
import mapValuesDeep from "deepdash/mapValuesDeep"
import { parse, print } from "graphql"
import { createClient } from "graphql-ws"
import localforage from "localforage"
import {
	ApolloClient,
	ApolloLink,
	HttpLink,
	InMemoryCache,
	NormalizedCacheObject,
	from,
	split,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { RetryLink } from "@apollo/client/link/retry"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { getMainDefinition } from "@apollo/client/utilities"
import AppConfig from "@common/constants/AppConfig"
import { SPACENAME } from "../../../scripts/normalizeSchema/constants"
import replaceSpacenameData from "../../../scripts/normalizeSchema/replaceSpacename"

export const queryWithProjectMiddleware = () => {
	return new ApolloLink((operation, forward) => {
		const { headers } = operation.getContext()
		return forward(operation).map((response) => {
			if (headers?.projectNamespace) {
				const normalizedData = mapKeysDeep(response.data, (_, key) =>
					key.replace(headers.projectNamespace, SPACENAME)
				)
				const normalizedDataValues = mapValuesDeep(normalizedData, (value) => {
					if (typeof value === "string") {
						return value.replace(headers.projectNamespace, SPACENAME)
					}
					return value
				})
				return { data: normalizedDataValues }
			}
			return response
		})
	})
}

export const fetchWithProjectNormalization = (uri, options) => {
	const { projectnamespace: namespace, ...otherHeaders } = options?.headers
	if (namespace) {
		const body = JSON.parse(options.body)
		const parsedAst = parse(body.query)
		const replaced = replaceSpacenameData(parsedAst, {
			from: SPACENAME + "_",
			to: namespace + "_",
		})

		console.log(
			"fetchWithProjectNormalization :: namespace:",
			namespace,
			uri,
			options,
			print(replaced)
		)

		return fetch(uri, {
			...options,
			headers: otherHeaders,
			body: JSON.stringify({ ...body, query: print(replaced) }),
		})
	}
	return fetch(uri, options)
}

export default async function apolloClientInitialization({
	graphJwt = "",
	linkMiddlewares = [],
	httpLinkOptions = {},
	typePolicies = {},
	storageName = "gql",
} = {}): Promise<ApolloClient<NormalizedCacheObject>> {
	const graphUri = AppConfig.ts_gql_host
	const cache = new InMemoryCache(typePolicies)
	const newPersistor = new CachePersistor({
		cache,
		storage: new LocalForageWrapper(
			localforage.createInstance({
				name: storageName,
			})
		),
		key: `${graphUri}${storageName}`,
		debug: AppConfig.connect_apollo_dev_tools,
		trigger: "write",
	})
	await newPersistor.restore()
	const retryLink = new RetryLink({
		delay: {
			initial: 300,
			max: 5000,
			jitter: true,
		},
		attempts: {
			max: 5,
			retryIf: (error, { query }) => {
				console.log("retryLink :: query: ", query)
				return Boolean(error)
			},
		},
	})
	const onErrorLink = onError((data) => {
		console.log("onErrorLink :: data: ", data)
		const { graphQLErrors, networkError } = data
		if (graphQLErrors)
			graphQLErrors.map(({ message, locations, path }) =>
				console.log(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
				)
			)
		if (networkError) {
			console.log("[Network error]", networkError)
		}
	})
	const wsLink = new GraphQLWsLink(
		createClient({
			url: graphUri.replace(/https:/i, "wss:"),
			connectionParams: async () => {
				return {
					headers: {
						Authorization: `Bearer ${graphJwt}`,
					},
				}
			},
		})
	)
	const httpLink = new HttpLink({
		uri: graphUri,
		headers: {
			Authorization: `Bearer ${graphJwt}`,
		},
		...httpLinkOptions,
	})

	const apiLink = from([onErrorLink, retryLink, ...linkMiddlewares, httpLink])
	const subscriptionLink = from([onErrorLink, wsLink])
	const isSubscription = ({ query }) => {
		const definition = getMainDefinition(query)
		return (
			definition.kind === "OperationDefinition" &&
			definition.operation === "subscription"
		)
	}
	const link = split(isSubscription, subscriptionLink, apiLink)
	return new ApolloClient({
		link,
		cache,
		connectToDevTools: AppConfig.connect_apollo_dev_tools,
	})
}
