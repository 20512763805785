import moment from "moment"
import { useContext, useEffect, useState } from "react"
import React from "react"
import { useSubscription } from "@apollo/client"
import useAppToast from "@common/hooks/useAppToast"
import NormalizeGqlResponse from "@gql/normalization"
import { WatchProjectsDocument } from "@gql/normalized/operations"
import { GqlQueryType } from "@gql/queries"
import { SchemaVersion } from "@gql/schemaVersion"
export interface TsProjectDataInterface {
	projects: { [name: string]: TsProject } | null
	projectsLoading: boolean
	projectIdsByAlphaNum: string[] | null
	projectIdsByName: string[] | null
	projectIdsByCreated: string[] | null
}

export interface TsProjectContextInterface extends TsProjectDataInterface {
	updateProjects: () => void
}

export const TsProjectsContext =
	React.createContext<TsProjectContextInterface>(null)
export const TsProjectsProvider = TsProjectsContext.Provider

export interface TsProject {
	id: string
	name: string
	owner: any
	tags: string[]
	namespace: string
	schemaVersionString: string
	schemaVersion: SchemaVersion
	statusDetails: TsProjectStatus
	rollupAssetCount: number
	projectStatus: string
	rollupImportCount: number
	importStatus: string
	importStatusPercent: number
	rollupMessageUser: string
	rollupTotalFilesize: number
	created: string
	createdFormatted: string
	createdEpoch: string
	updated: string
}

interface TsProjectStatus {
	message: string | null
	color?: string
	ready?: boolean
	isCancelable?: boolean
	errorMessage?: string
}

export const TsProjectStatuses: Record<string, TsProjectStatus> = {
	UNKNOWN: {
		message: null,
	},
	READY: {
		message: "Ready",
		color: "green",
		ready: true,
	},
	SUSPENDED: {
		message: "Paused",
		color: "yellow",
		isCancelable: false,
	},
	ARCHIVED: {
		message: "Archived",
		color: "yellow",
		isCancelable: false,
	},
	SYNC: {
		message: "Updating",
		color: "yellow",
		isCancelable: false,
	},
	INITIALIZING: {
		message: "Initializing",
		color: "yellow",
		isCancelable: true,
	},
	UPLOADING: {
		message: "Uploading",
		color: "yellow",
		isCancelable: true,
	},
	ANALYZINGPCAP: {
		message: "Analyzing",
		color: "blue",
	},
	BUILDINGTOPOLOGY: {
		message: "Building",
		color: "orange",
	},
	ERROR: {
		message: "Error",
		color: "red",
	},
}

interface AppProjectsProviderProps {
	children: React.ReactNode
}

const formatProject = (row): TsProject => {
	const createdMoment = moment(row.created)

	const project: TsProject = {
		...row,
		statusDetails: getProjectStatus(
			row.projectStatus,
			row.importStatus,
			row.rollupMessageUser
		),
		createdFormatted: createdMoment.format("YYYY-MM-DD"),
		createdEpoch: createdMoment.unix(),
	}

	return project
}

const getProjectStatus = (
	status: string,
	importStatus: string,
	rollupMessageUser?: string
) => {
	let match = ""

	// Match against project status
	match = (status || "").toUpperCase()

	// Match against import status
	if (match === "READY" || !TsProjectStatuses[match]) {
		match = (importStatus || "").toUpperCase()
	}

	// No status/import match - display UNKNOWN status
	if (!TsProjectStatuses[match]) {
		match = "UNKNOWN"
	}

	return {
		...TsProjectStatuses[match],
		errorMessage: rollupMessageUser || null,
	}
}

const defaultProjects: TsProjectDataInterface = {
	projects: null,
	projectsLoading: false,
	projectIdsByAlphaNum: null,
	projectIdsByName: null,
	projectIdsByCreated: null,
}

const AppProjectsProvider = ({ children }: AppProjectsProviderProps) => {
	const toast = useAppToast()
	const [projectsData, setProjectsData] = useState<TsProjectDataInterface>({
		...defaultProjects,
		projectsLoading: true,
	} as TsProjectDataInterface)

	const { error: projectsError } = useSubscription(WatchProjectsDocument, {
		// The following onSubscriptionData callback is used to work around an ApolloClient
		// error in which loading is set to false before data is returned: https://github.com/apollographql/apollo-client/issues/7198
		onSubscriptionData: ({ subscriptionData }) => {
			if (!subscriptionData.data) {
				setProjectsData(defaultProjects)
			}

			if (subscriptionData.loading) {
				setProjectsData((pD) => ({
					...pD,
					projectsLoading: true,
				}))
				return
			}

			const responseNormalized = NormalizeGqlResponse(
				GqlQueryType.projectsList,
				subscriptionData.data?.core_Project
			)

			const projects = {}
			for (let row of responseNormalized || []) {
				if ((row.rollupProjectStatus || "").toUpperCase() === "DELETED") {
					continue
				}

				projects[row.id] = formatProject(row)
			}

			const sortByName = (a, b) => {
				if (projects[a].name < projects[b].name) {
					return -1
				}
				if (projects[a].name > projects[b].name) {
					return 1
				}
				return 0
			}

			const sortByCreated = (a, b) => {
				if (projects[a].createdEpoch > projects[b].createdEpoch) {
					return -1
				}
				if (projects[a].createdEpoch < projects[b].createdEpoch) {
					return 1
				}
				return 0
			}

			const projectKeys = Object.keys(projects)

			setProjectsData({
				projects,
				projectsLoading: false,
				projectIdsByAlphaNum: [...projectKeys].sort() || null,
				projectIdsByName: [...projectKeys].sort(sortByName) || null,
				projectIdsByCreated: [...projectKeys].sort(sortByCreated) || null,
			})
		},
	})

	useEffect(() => {
		if (!projectsError) {
			return
		}

		setProjectsData((pD) => ({
			...pD,
			projectsLoading: false,
		}))

		toast({
			status: "warning",
			title: `Projects could not be loaded`,
			description: projectsError?.message,
		})

		console.error("Projects could not be loaded: ", projectsError)
	}, [projectsError, toast])

	return (
		<TsProjectsProvider
			value={{
				...projectsData,
				updateProjects: () => {
					/* No longer necessary now that we've implemented subscription queries */
				},
			}}
		>
			{children}
		</TsProjectsProvider>
	)
}

export default AppProjectsProvider

export const useProjects = () => useContext(TsProjectsContext)
