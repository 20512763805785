import { Box, BoxProps, Spinner } from "@chakra-ui/react"

interface AppLoadingProps {
	message?: string
	props?: BoxProps
}

const AppLoading = ({ message, props }: AppLoadingProps) => {
	return (
		<Box
			textAlign="center"
			position="absolute"
			top="40vh"
			left="0"
			right="0"
			zIndex="99"
			opacity="0.5"
			{...props}
		>
			<Spinner size="lg" />
			<Box sx={{ marginTop: 4 }}>{message}</Box>
		</Box>
	)
}

export default AppLoading
